//resources

.more-resources {
	background: #ffffff;
	display: flex;
	padding: 20px 0 60px 0;
	flex: 1 0 auto;
	margin-top: 40px !important;
}

.more-resources-left-sidebar {
	width: 18%;
	padding: 0 10px;
	a {
		display: block;
		text-decoration: none;
	}
}

.more-resources-main {
	flex: 1 0 auto;
	width: 64%;
}

.more-resources-right-sidebar {
	width: 18%;
	padding: 0 10px;
}

.left-resources-p {
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
	background: #4682e5;
	text-align: center;
	margin: 0;
	margin-bottom: 10px;

}

.left-resources-a {
	font-size: 16px;
	color: white;
	font-weight: 700;
	text-align: left;
	margin-bottom: 10px;
	padding: 10px 30px 10px 10px;
	background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 20px;
	background-size: 10px 12px;
}

.left-resources-a:hover {
	font-size: 16px;
	color: white;
	font-weight: 700;
	text-align: left;
	margin-bottom: 10px;
	padding: 10px 30px 10px 10px;
	background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 20px;
	background-size: 10px 12px;
}
.left-resources-a._skills-desctop-hide.active,
.left-resources-a._topics-desctop-hide.active,
.left-resources-a.active-test-menu.active {
	background: #ff9d00 url("/images/landing/arrow-bottom.png") no-repeat center right 20px;
	background-size: 12px 12px;
}
.btn-resources {
	font-size: 16px;
	font-weight: 600;
	color: #4663e5;
	background: url("/images/resources/btn.png") no-repeat 0 0;
	background-size: 100% 100%;
	padding: 10px;
	text-align: center;
	margin: 20px 0 20px 0;
}

.btn-resources:active {
	font-size: 16px;
	font-weight: 600;
	color: #4663e5;
	background: url("/images/resources/btn_active.png") no-repeat 0 0;
	background-size: 100% 100%;
	padding: 10px;
	text-align: center;
	margin: 20px 0 20px 0;
}

.title-main-resources {
	font-size: 20px;
	font-weight: 700;
	color: #fff;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
	margin: 0;
	background: url("/images/resources/bcg.png") no-repeat 0 0;
	background-size: 100% 100%;
	padding: 5px 0;
	text-align: center;
}

.resources-right-sidebar-bcg {
	padding: 10px 25px;
	background: url("/images/resources/sitebar.png") no-repeat 0 0;
	background-size: 100% 100%;
	text-align: center;

	p {
		margin: 0;
	}
}

.img-resources-sidebar {
	-webkit-box-shadow: 0 0 13px 4px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 13px 4px rgba(0,0,0,.75);
	box-shadow: 0 0 13px 4px rgba(0,0,0,.75);
	border-radius: 50%;
	width: 70%;
	margin-bottom: 15px;
}
.our-resources {
	font-family: Oduda;
	font-weight: 700;
	font-size: 28px;
	color: #236fc4;
	padding: 10px 0;
}
.choose-resources {
	font-size: 16px;
	font-weight: 600;
	color: #00539a;
}
.save-resources {
	font-family: Oduda;
	font-weight: 700;
	font-size: 28px;
	color: #ff4200;
	padding: 15px 0;
}
.child-test-resources {
	font-family: Oduda;
	font-size: 18px;
	font-weight: 600;
	color: #02b1cb;
}
.del-resources {
	display: inline-block;
	font-size: 18px;
	color: #474747;
	font-weight: 700;
	margin: 25px 0;
	position: relative;
}

.del-resources:before {
	content: "";
	width: 143px;
	height: 68px;
	display: block;
	position: absolute;
	background: url("/images/resources/del.png") no-repeat 0 0;
	top: 0;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
}

.price-resoueces {
	font-family: Oduda;
	font-size: 24px;
	font-weight: 600;
	color: #ff8227;
}
.btn-buy-resources {
	display: block;
	padding: 15px 0;
	margin: 20px 0;
	width: 100%;
	text-decoration: none;
	color: white;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	background: url("/images/resources/side-btn.png") no-repeat 0 0;
	background-size: 100% 100%;
}

.btn-buy-resources:active {
	display: block;
	padding: 15px 0;
	margin: 20px 0;
	width: 100%;
	text-decoration: none;
	color: white;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	background: url("/images/resources/side-btn_active.png") no-repeat 0 0;
	background-size: 100% 100%;
}

.inner-item-a {
	display: block;
	text-decoration: none;
	font-size: 16px;
	color: white;
	font-weight: 600;
	width: 100%;
	background: #009fe3;
	padding: 5px 0;
	text-align: center;
	margin: 10px 0 15px 0;
}

.inner-item-a:hover {
	display: block;
	text-decoration: none;
	font-size: 16px;
	color: white;
	font-weight: 600;
	width: 100%;
	background: #ff8227;
	padding: 5px 0;
	text-align: center;
	margin: 10px 0 15px 0;
}

.inner-item-img {
	width: 100%;
}

.item-resources {
	width: 150px;
    max-width: 150px;
    height: 100%;
    flex-grow: 1;
    margin-right: 10px;
}

.box-for-item-resources {
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	// padding: 0px 30px;
	width: 80%;
    margin: 0 auto;
}

.inner-title-main-resources {
	font-size: 20px;
	font-weight: 600;
	color: #0063b7;
	// padding-left: 30px;
	width: 80%;
    margin: 20px auto;
}

.more-res-left-sidebar-menu .active-res-test-menu {
	display: none;
}
.more-res-left-sidebar-menu .active-res-test-menu._active-hide-menu {
	display: none;
}
//mobile sidebar

.mobile-more-resources-right-sidebar {
	display: none;
	// display: flex;
	font-family: Oduda;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
	padding: 15px 30px;
	width: 84%;
	margin: 15px auto;
	background: url("/images/sidebar/banner_horizontal.png") no-repeat 0 0;
	background-size: 100% 100%;
	-webkit-box-shadow: 0 5px 4px 3px rgba(4,140,222,.75);
	-moz-box-shadow: 0 5px 4px 3px rgba(4,140,222,.75);
	box-shadow: 0 5px 4px 3px rgba(4,140,222,.75);
	border-radius: 30px;

	p {
		margin: 0;
	}
}

.box-side-mob-save {
	display: flex;
    align-items: flex-end;
    // height: 100%;
}

.side-mob-save {
	font-family: Oduda;
	font-size: 20px;
	color: #ff4200;
	transform: rotate(-15deg);
    margin-bottom: 10px;
}

.side-mob-choose {
	font-size: 20px;
	font-weight: 800;
	color: #1892ff;
	padding-bottom: 10px;
}

.side-mob-child {
	font-size: 16px;
	font-weight: 800;
	color: #1892ff;
	text-align: center;
}

.btn-buy-resources-sidebar {
	width: 120px;
	padding: 10px 0;
}

.btn-buy-resources-sidebar:active {
	width: 120px;
	padding: 10px 0;
}

.inner-mob-side-parent {
	display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
    
.img-side-bar-mob {
	img {
		width: 70px;
		height: 70px;
	}
}

.price-res-side {
    padding-top: 25px;
    padding-bottom: 10px;
}
