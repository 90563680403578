.main-contact-us {
	flex: 1 0 auto;

		&__section {
		margin-top: 68px;
	}
}

.contact__container {
	display: flex;
	padding-left: 14vw;
	padding-right: 10px;
}

.title-contact {
	text-align: center;
	font-weight: 700;
	color: #fff;
	font-size: 20px;
	padding: 0px 0 5px 0;
	margin: 0;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
}

.title-contact-loc {
	text-align: center;
	font-weight: 700;
	color: #fff;
	font-size: 20px;
	padding: 10px 0 10px 0;
	margin: 0;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
}

.contact {
	background: #fff;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);

	p {
		margin: 0;
	}
}

.contact-form {
	color: black;
	font-weight: 600;
	font-size: 16px;
	
	input, textarea {
		width: 45%;
		background: #e9fdff;
		border: none;
		resize: none;
		padding: 10px;
	}
	
	textarea {
		width: 100%;
		height: 150px;
	}

	&__title {
		padding: 20px 0 20px 0;
	}
	
	&__subject, &__robot, &__comments {
		padding: 20px 0 0 0;
	}

	&__valid-email, &__valid-robot {
		font-size: 14px;
		color: red;
	}

	&__privacy {
		padding: 10px 0 15px 0;
		a {
			color: black;
		}
	}
}

.box-submit {
	text-align: right;

	&__submit {
		display: inline-block;
		color: #fff;
		font-size: 16px;
		font-weight: 600;
		text-decoration: none;
		background: #009fe3;
		padding: 10px 0 10px 0;
		width: 40%;
		text-align: center;
		margin: 20px 0 20px 0;
	}
	&__submit:hover {
		background: #ff8227;
	}
}

.adress {
	margin: 0 0 20px 0;
	background: #fff;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);
	font-size: 16px;
	color: black;
	font-weight: 700;
	p {
		margin: 0;
	}

	&__mailing {
		text-align: center;
		padding: 20px 0 10px 0;
	}

	&__box {
		text-align: center;
		padding: 0px 0 20px 0;
	}
}