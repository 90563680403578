@media (max-width: 1100px) {
	.more-test {
		flex-wrap: wrap;
	}
	.our-resources {
		font-size: 20px;
	}
	.choose-resources {
		font-size: 14px;
	}
	.save-resources {
		font-size: 20px;
	}
	.child-test-resources {
		font-size: 16px;
	}
	.del-resources {
		font-size: 16px;
	}
	.del-resources:before {
		content: "";
	    width: 143px;
	    height: 68px;
	    display: block;
	    position: absolute;
	    background: url(/images/resources/del.png) no-repeat 0 0;
	    top: 0;
	    left: 10px;
	    right: 0;
	    bottom: -20px;
	    margin: auto;
	    background-size: 60%;
	}
	.price-resoueces {
		font-size: 18px;
	}
	.btn-buy-resources {
		font-size: 14px;
	}
	.btn-buy-resources:active {
		font-size: 14px;
	}
	.inner-tests-table-second span {
		font-size: 14px;
	}

//////900

// .tests-table-first {
// 		overflow-x: scroll;

// 		div {
// 			width: 450px;
// 		}
// 	}
	// .tests-table-second {
	// 	overflow-x: scroll;

	// 	div {
	// 		width: 450px;
	// 	}
	// }
	.more-resources-right-sidebar {
		width: 20%;
		margin: 10px auto;
		min-width: 200px;
	}
	.more-resources-left-sidebar {
		width: 28%;
	}
	.more-tests-main {
		width: 71%;
	}
	.tests-table-first span {
		font-size: 14px;
	}
	.tests-table-first {
		width: 85%;
	}
}

// @media (max-width: 900px) {
	// .tests-table-first {
	// 	overflow-x: scroll;

	// 	div {
	// 		width: 450px;
	// 	}
	// }
	// .tests-table-second {
	// 	overflow-x: scroll;

	// 	div {
	// 		width: 450px;
	// 	}
	// }
	// .more-resources-right-sidebar {
	// 	width: 20%;
	// 	margin: 10px auto;
	// 	min-width: 200px;
	// }
	// .more-resources-left-sidebar {
	// 	width: 28%;
	// }
	// .more-tests-main {
	// 	width: 71%;
	// }
@media (min-width: 696px)  {
	.more-resources-left-sidebar.more-test-left-sidebar-menu ._all-test-menu {
		display: block !important;
	}
}


@media (max-width: 695px) {
	.more-tests-main {
		width: 100%;
	}
	.more-resources-left-sidebar {
	    width: 50%;
	    margin-left: auto;
	    margin-right: auto;
	}

	.more-test-left-sidebar-menu .active-test-menu {
		display: block !important;
	}

	// .more-test-left-sidebar-menu a {
	// 	display: none;
	// }

	// .more-test-left-sidebar-menu:hover a,
	// .more-test-left-sidebar-menu:hover .active-test-menu {
	// 	display: block;
	// }

	.btn-more-tests {
		display: none !important;
	}
	._all-test-menu {
	    max-height: 200px;
	    overflow-y: auto;
	    margin-bottom: 10px;
	}
	
}

@media (max-width: 550px) {
	.tests-table-first span {
		font-size: 10px;
	}
	.tests-table-first {
		width: 100%;
	}
	.first-span-test {
		padding: 5px 0 5px 5px;
	}
	.tests-table-second {
		width: 100%;
	}
	.inner-tests-table-second span {
		font-size: 10px;
	}
}
