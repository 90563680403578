//media dashboard

@media (max-width: 1921px) {
		.student-margin {
		margin-bottom: 15px;
	}
}

// @media (max-width: 1600px) {
// 	.outer-manage-block .table-div-box:nth-child(odd) {
// 		justify-content: center;
// 	}
// 	.outer-manage-block .table-div-box:nth-child(even) {
// 		justify-content: center;
// 	}
// }

// @media (max-width: 1750px) {
// 	.logo {
// 		position: absolute;
// 	    left: -20%;
// 	}
// }

// @media (max-width: 1366px) {

// //rewards

// .outer-rewards-box {
// 	padding-bottom: 5px;
// 	margin-bottom: 25px;
// 	span.reward-span {
// 		font-size: 15px;
// 		font-weight: 600;
// 		color: #474747;
// 		width: 50%;
// 	}
// 	.inner-reward-img {
// 		width: 50%;
//     	display: flex;
//     	justify-content: center;
// 	}
// 	.cont-box:last-child {
// 		border-bottom: none;
// 	} 
// }

// .submit-rewards {
// 	padding-bottom: 170px;
// 	background: url("/images/baloon.png") no-repeat 90% 30%;
// 	.rewards-p {
// 		width: 35%;
// 		padding: 15px 50px;
// 		color: white;
// 		font-size: 14px;
// 		font-weight: 600;
// 		background: #009fe3;
// 		margin-bottom: 10px;
// 	}
// 	.marg-rew {
// 		margin-bottom: 25px;
// 	}
// 	.submit-title-rew {
// 		font-size: 15px;
// 		font-weight: 900;
// 		color: #005c83;
// 		padding-left: 2%;
// 	}
// }

// .select-reward {
// 	display: flex;
//     justify-content: space-between;
// 	font-size: 15px;
// 	font-weight: 600;
// 	color: #474747;
// 		#reward {
// 		display: inline-block;
// 		overflow: hidden;
// 		border: none;
// 		width: 40%;
//     	padding: 10px;
//     	min-width: 290px;
//     	min-height: 95px;
// 	}
// }

// .text-reward { 
// 	display: flex;
//     justify-content: space-between;
//     align-items: center;
// 	input {
// 		border: none;
// 		font-size: 15px;
// 		font-weight: 600;
// 		color: #474747;
// 		text-align: center;
// 		padding: 10px 0;
// 		width: 65%;
// 	}
// }
// .num-reward {
// 	display: flex;
//     justify-content: space-between;
//     align-items: center;
// 	input {
// 		border: none;
// 		font-size: 15px;
// 		font-weight: 600;
// 		color: #474747;
// 		width: 150px;
// 		padding-left: 25px;
// 	}
// }

// .main-reward {
// 	width: 70%;
// 	position: relative;
// }

// .buy-reward {
// 	width: 30% !important;
// 	min-width: 200px;
// 	position: absolute;
//     // right: -250px;
//     right: -45%;
//     bottom: 0;
//     font-size: 14px;
//     font-weight: 600;
// }

// .buy-reward:hover {
// 	width: 30% !important;
// }

// .logo {
// 	margin: 0;
// 	padding: 0;
// 	padding-top: 5px;
// 	padding-left: 21%;
// 	float: left;

// 	img {
// 		width: 50%;
// 	}
// }

// .main-nav {
// 	position: relative;
// 	height: 38px;
// 	background-color: #0ec3fc;
// 	-webkit-box-shadow: 0 3px 2px rgba(0,0,0,.34);
// 	-moz-box-shadow: 0 3px 2px rgba(0,0,0,.34);
// 	box-shadow: 0 3px 2px rgba(0,0,0,.34);
// 	border: none;
// 	text-align: right;
// }

// .head-right-nav {
//     min-height: 38px;
// }

// .head-right-nav li a {
//     padding: 3px 15px;
//     margin-top: 7px;
//     margin-bottom: 7px;
// }

// .devider-line {
// 	height: 45px;
// 	width: 2px;
// 	border-right: 2px solid #e1e1e1;
// 	position: absolute;
// 	top: 9px;
// 	padding: 0;
// 	margin: 0;
// 	margin-left: -1px;
// }

// #app-layout {
// 	background: url('/images/background.png') no-repeat;
// 	background-size: cover;
// }
// .main-content {
// 	width: 100%;
// 	display: flex;
// }

// .user-info {
// 	float: right;
// 	width: 60%;
// }
// .inner-content {
// 	width: 64%;
// 	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
// 	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
// 	box-shadow: 0 0 3px rgba(0,0,0,.75);
// 	background: #b3f4fe;
//     padding-top: 35px;
//     // padding-right: 130px;
//     // padding-left: 130px;
//     padding-right: 4%;
//     padding-left: 4%;
//     order: 2;
//     flex-grow: 3;
// }

// .inner-h1 {
// 	font-size: 24px;
// 	text-align: center;
// 	font-weight: 900;
// 	color: #005c83;
// 	padding-bottom: 65px;
// }

// .avatar {
// 	width: 30%;
// 	float: left;
// 	// margin-left: 3%;
// 	// margin-right: 5%;
// 	img {
// 		width: 50%;
// 	}
// }

// .padding-label {
// 	padding-right: 23px;
// }

// .label-style {
// 	font-size: 15px;
// 	font-weight: 900;
// 	color: #005c83;
// 	padding-top: 45px;
// 	padding-bottom: 5px;
// }

// .label-style:first-child {
// 	padding-top: 0;
// }
// .input-style {
// 	display: block;
// 	font-size: 14px;
// 	padding: 9.5px;
// 	border: none;
// 	width: 100%;
// }

// .alert {
// 	color: #ff0000;
// 	font-size: 14px;
// 	font-weight: normal;
// 	padding-left: 0;
// 	padding-bottom: 0;
// }

// .pass-info {
// 	font-size: 14px;
// 	font-weight: normal;
// 	padding-left: 0;
// 	padding-bottom: 0;
// }

// .login-card {
// 	// display: flex;
// 	// flex-wrap: wrap;
// }

// .title-label {
// 	font-size: 15px;
// 	padding-bottom: 65px;
// 	color: #005c83;
// 	font-weight: 900;
// 	display: inline-block;
// }

// .title-block {
// 	font-size: 15px;
// 	padding: 80px 0;
// 	color: #005c83;
// 	font-weight: 900;
// }

// .sign-up {
// 	display: block;
// 	background: #ff8227;
// 	color: white;
// 	padding: 5px 20%;
// 	margin-bottom: 120px;
// 	font-size: 15px;
// 	border: none;
// 	outline: none;
// 	margin-top: 20px;
// 	cursor: pointer;
// 	text-decoration: none;
// 	text-align: center;
// }

// .sign-up:hover {
// 	background: #009fe3;
// 	color: white;
// 	outline: none;
// }

// .label-radio {
// 	margin-left: 20px;
// 	display: inline-block;
// 	height: 50px;
// 	width: 65px;
// 	background: white;
// 	font-size: 21px;
// 	padding-top: 9px;
// 	text-align: center;
// }

// .radio-check {
// 	margin-left: 30px !important;
// }

// label {
// 	display: inline-block;
// 	position: relative;
// }

// .custom-radio {
// 	position: absolute;
// 	display: block;
// 	width: 39px;
// 	height: 39px;
// 	background: url('/images/radio.png');
// 	top: 5px;
//     left: 15px;
// }

// label > input[type=checkbox]:checked + span {
// 		background: url('/images/radio_checked.png');
//    }

// label > input[type=radio]:checked + span {
// 		background: url('/images/radio_checked.png');
//    }

// .right-sidebar {
// 	width: 18%;
// 	// padding-left: 10px;
// 	flex-wrap: wrap;
// 	order: 3;
//     flex-grow: 1;
// }

// .socio-box {
// 	padding-left: 10px;
// }

// .right-sicio-img {
// 	float: left;
// 	display: inline-block;
// 	width: 85px;
// 	height: 37px;
// 	background: #6477a4;
// 	text-decoration: none;
// 	color: white;
// 	text-align: center;
// 	padding-top: 10px;
// 	margin-bottom: 10px;
// 	margin-right: 5px;
// }

// .right-sicio-like {
// 	float: left;
// 	display: flex;
// 	width: 85px;
// 	height: 37px;
// 	background: #6477a4;
// 	text-decoration: none;
// 	color: white;
// 	margin-bottom: 10px;
// 	margin-right: 5px;
// }

// .right-sicio-tweet {
// 	display: flex;
// 	width: 85px;
// 	height: 37px;
// 	background: #00aced;
// 	text-decoration: none;
// 	color: white;
// 	text-align: center;
// 	// padding-top: 12px;
// 	margin-bottom: 10px;
// }

// .inner-soci-span {
// 	padding-top: 10px;
//     padding-left: 0px;
// }

// .icon-socio-like {
// 	display: inline-block;
// 	width: 37px;
// 	height: 37px;
// 	background: url("/images/like.png") no-repeat;
// 	// margin-top: -12px;
//     margin-left: 5px;
//     margin-right: 5px;
// }

// .icon-socio-tweet {
// 	display: inline-block;
// 	width: 37px;
// 	height: 37px;
// 	background: url("/images/twitter.png") no-repeat;
// 	// margin-top: -12px;
//     margin-left: 0px;
// }

// .font-24 {
// 	font-size: 14px;
// }

// // add student
// .form-add-child {
// 	width: 65%;
//     margin: 0 auto;
// }

// .add-student-page {
// 	display: flex;
//     justify-content: center;
//     padding-bottom: 80px;
// }

// .select-one {
// 	// margin-right: 130px;
// 	margin-right: 20%;
// }

// .under-user {
// 	display: block;
// 	margin-top: 30px;
// 	text-align: center;
// 	padding-right: 20px;
// 	cursor: pointer;
// }

// .custom-radio-add {
// 	position: absolute;
// 	display: block;
// 	width: 39px;
// 	height: 39px;
// 	background: url('/images/radio.png');
// 	top: -10px;
//     left: 55px;
// }

// .label-add {
// 	font-size: 16px;
// 	color: #0085be; 
// 	padding-left: 20px;
// }

// .inner-block-add-input {
// 	margin: 0 auto;
// }

// .add-student-button {
// 	margin-top: 40px;
// 	margin-bottom: 60px;
// 	float: right;
// }

// .block-sales {
// 	margin: 0 auto;
// 	background: #009fe3;
// 	padding: 10px;
// 	margin-top: 110px;
// 	width: 80%;
// 	max-width: 180px;
// }

// .our-deal, .inner-text-right-sidebar, .save-procent {
// 	text-align: center;
// }

// .our-deal {
// 	font-size: 30px; 
// 	color: #feec00; 
// 	margin-bottom: 20px;
// }
// .inner-text-right-sidebar {
// 	font-size: 14px; 
// 	color: white; 
// 	margin-bottom: 20px;
// }
// .save-procent {
// 	font-size: 20px; 
// 	color: #feec00; 
// 	margin-bottom: 20px; 
// 	margin-top: 20px;
// }

// .parent-box {
// 	img {
// 		width: 100%;
// 	}
// 	background: white;
// 	padding: 20px;
// 	margin-bottom: 50px;
// 	padding-bottom: 50px;
// }

// .child-profile {
// 	font-size: 16px;
// 	color: #4f5050;
// 	text-align: center;
// }

// .price-text {
// 	font-family: Oduda;
// 	font-size: 14px;
// 	color: #ff8227;
// 	text-align: center;
// }

// .buy {
// 	display: inline-block;
// 	color: white;
// 	background: #009fe3;
// 	width: 100%;
// 	height: 45px;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	font-size: 15px;
// 	font-weight: 800;
// }

// .buy:hover {
// 	display: inline-block;
// 	color: white;
// 	background: #ff8227;
// 	width: 100%;
// 	height: 45px;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// }

// .left-sidebar {
// 	flex-grow: 1;
//     // width: 18%;
//     width: 0;
//     order: 1;
// }

// //profile

// .nav-profile {
// 	display: flex;
// 	justify-content: space-between;
// 	padding-top: 35px;
// 	padding-bottom: 35px;
// }

// .profile-button {
// 	font-size: 14px;
// 	font-weight: 900;
// 	display: inline-block;
// 	width: 30%;
// 	color: white;
// 	background: #009fe3;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	padding: 10px 0;
// }

// .profile-button:hover {
// 	font-size: 14px;
// 	font-weight: 900;
// 	display: inline-block;
// 	width: 30%;
// 	color: white;
// 	background: #ff8227;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	padding: 10px 0;
// }

// .inner-block-input-profile {
// 	width: 56%;
//     margin: 0 auto;
// }

// .change-profile {
// 	padding: 10px 0 0 0;
// 	margin: 0;
// 	text-align: right;
// 	font-size: 14px;
// 	color: #4f5050;
// }

// // add new student profile

// .inner-block-input-student {
// 	width: 55%;
// 	margin: 0 auto;
// 	margin-bottom: 100px;
// }

// .new-student-button {
// 	font-size: 14px;
// 	font-weight: 900;
// 	margin: 0 auto;
// 	float: none;
// 	margin-bottom: 50px;
// 	width: 55%;
//     padding: 10px;
// }

// .add-student-input {
// 	padding-bottom: 10px;
// }

// // manage purchases

// .manage-purchases {
// 	width: 100%;

// 	.inner-h1-manage {
// 		font-size: 24px;
// 		font-weight: 900;
// 		text-align: center;
// 		color: #005c83;
// 		padding-top: 0px;
// 		margin-top: 0px;
// 		padding-bottom: 50px;
// 	}

// 	.title-manage {
// 		padding-left: 70px;
// 		font-weight: 900;
// 		font-size: 15px;
// 		color: #005c83;
// 		background: #78dfff;
// 		margin-bottom: 35px;
// 	}
// }

// .outer-manage-block {
// 	padding: 0px 30px 30px 30px;
// 	background: #e9fdff;
// 	overflow-y: scroll;
// 	overflow-x: scroll;

// 	.table-div-box:nth-child(odd) {
// 		display: flex;
// 		padding-bottom: 30px;
// 		padding-left: 40px;
// 		padding-right: 20px;
// 		justify-content: space-between;
// 		flex-wrap: wrap;
// 	}
// 	.table-div-box:nth-child(even) {
// 		display: flex;
// 		padding-top: 30px;
// 		padding-left: 40px;
// 		padding-right: 20px;
// 		justify-content: space-between;
// 		flex-wrap: wrap;
// 	}
// 	.table-div-box:last-child {
// 		border-bottom: none;
// 	}
// }

// .inner-line-manage {
// 	display: flex;
// 	padding-top: 30px;
// 	padding-bottom: 30px;
// 	padding-left: 40px;
// 	padding-right: 20px;
// 	justify-content: space-between;
// 	flex-wrap: wrap;
// }

// .inner-two-line-manage {
// 	display: flex;
// 	padding-top: 30px;
// 	padding-bottom: 30px;
// 	padding-left: 40px;
// 	padding-right: 20px;
// 	justify-content: space-between;
// 	flex-wrap: wrap;
// }

// .inner-line-manage, .inner-two-line-manage  {
// 	border-bottom: 2px solid #78dfff;
//  }

// .inner-flex {
// 	display: flex;
// 	align-items: center;
// }

// .media-in-flex {
// 	display: flex;
// }

//  .p-inner-manage {
//  	font-size: 15px;
//  	color: #474747;
//  	padding-left: 20px;
//  	font-weight: 600;
//  }

// .button-inner-line {
// 	width: 120px;
// 	align-self: center;
// 	font-size: 14px;
// 	height: 75px;
// 	font-weight: 900;
// 	display: inline-block;
// 	color: white;
// 	background: #009fe3;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	margin-left: 80px;
// } 

// .button-inner-line:hover {
// 	font-size: 14px;
// 	height: 75px;
// 	font-weight: 900;
// 	display: inline-block;
// 	color: white;
// 	background: #ff8227;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// } 

// .buy-manage {
// 	width: 35%;
// 	margin: 50px 0 100px 0;
// }

// .buy-manage:hover {
// 	width: 35%;
// }

// .button-buy-manage {
// 	display: flex;
// 	justify-content: center;
// }

// .inner-h1-manage-two {
// 	padding-top: 50px;
// 	font-size: 24px;
// 	font-weight: 900;
// 	text-align: center;
// 	color: #005c83;
// 	padding-bottom: 50px;
// }

// .radio-manage {
// 	align-self: center;

// 	span {
// 		top: -10px;
// 	}

// 	.title-radio {
// 		font-size: 14px;
// 		color: #474747;
// 		font-weight: 600;
// 		padding-left: 5px;
// 	}
// }

// //invite

// .inner-h1-invite {
// 	font-size: 24px;
// 	text-align: center;
// 	font-weight: 900;
// 	color: #005c83;
// 	padding-bottom: 35px;
// 	padding-top: 50px;
// 	margin: 0;
// }

// .invite-title {
// 	font-size: 15px;
// 	text-align: center;
// 	font-weight: 600;
// 	margin-top: 0;
// 	margin-bottom: 100px;
// 	color: #005c83;
// }

// .invite-card {
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// }

// .invite-avatar {
// 	width: 40%;
// 	// height: 520px;
// 	order: 2;
// 	position: relative;
// 	bottom: 120px;
// }

// .invite-info {
// 	order: 1;
// 	width: 50%;
// }

// .invite-button {
// 	display: flex;
// 	margin-top: 110px;
// 	justify-content: flex-end;
// }

// .invite-submit {
// 	margin-right: -40%;
// 	z-index: 1000;
// 	min-width: 85%;
// }

// //parent dash

// .btn-blue {
// 	color: white;
// 	background: #009fe3;
// 	border: none;
// 	outline: none;
// 	padding: 10px 40px;
// 	cursor: pointer;
// }

// .btn-blue:hover {
// 	background: #ff8227;
	
// }

// .p-student-title {
// 	color: #0085be;
// 	font-size: 14px;
// 	font-weight: bold;
// 	margin: 0;
// }

// .parent-dash {
// 	display: flex;
// 	justify-content: space-around;
// 	//padding-left: 7%;
//     //padding-right: 7%;
//  	padding-left: 0%;
//     padding-right: 0%;

// .inner-parent-dash {
// 	    display: flex;
//     	align-items: center;
//     	flex-direction: column;
//     }
// }

// .btn-box-parent-dash {
// 	align-self: flex-end;
// 	margin-left: 10%;

// 	button, a {
// 		width: 305px;
// 		display: inline-block;
//     	text-decoration: none;
//     	text-align: center;		
// 	}
// }

// .student-margin-top {
// 	margin-bottom: 30px;
// }

// .student-margin {
// 	margin-right: 15px;
// }

// .result-student {
// 	background: #78dfff;
// 	display: flex;
//     justify-content: space-around;
//     align-items: flex-end;
//     flex-wrap: wrap;
//     margin: 35px 0;
//     padding-right: 4%;
//     padding-left: 4%;
// }

// .inner-result-student {
// 	padding: 15px;

// 	img {width: 50%;}

// 	p:last-child {
// 		font-size: 14px;
// 		color: #005c83;
// 		font-weight: bold;
// 		margin: 0;
// 	}
// 	p:first-child, .clock {
// 		font-family: Oduda;
// 		font-size: 25px;
// 		color: #ff8227;
// 		font-weight: bold;
// 		margin: 0;
// 	}
// }

// .clock-box {
// 	display: flex;
//     align-items: center;
//     flex-direction: column;

//     img {width: 50%;}
// }

// .nav-table-dash {
// 	display: flex;
// 	justify-content: center;
// 	margin-bottom: 40px;
// 	button {
// 		margin-right: 15px;
// 	}
// 	button:last-child {
// 		margin-right: 0px;
// 	}
// }

// .report-student-title {
// 	font-size: 14px;
// 	font-weight: bold;
// 	color: white;
// 	padding: 10px 6%;
// 	background: #32b7eb;
// 	margin: 0 0 60px 0;
// }

// .table-report {
// 	display: flex;
// 	background: #78dfff;
// 	padding-right: 6%;
// 	padding-left: 6%;
//     align-items: center;
//     margin-bottom: 45px;

// 	span {
// 		font-size: 14px;
// 		color: #0085be;
// 		width: 15.2%;
// 		text-align: center;
// 		padding: 10px 0;
// 		font-weight: 600;
// 	}
// 	span:first-child {
// 		font-size: 15px;
// 		color: #005c83;
// 		width: 24%;
// 		font-weight: 900;
// 		text-align: left;
// 	}
// 	span:last-child {
// 		padding-right: 3%;
// 	}
	
// }

// .cont-box {
// 	margin-right: 4%;
// 	margin-left: 4%;
// 	display: flex;
// 	align-items: center;
// 	border-bottom: 2px solid #78dfff;

// 	span {
// 		font-size: 14px;
// 		font-weight: 600;
// 		color: #7c7c7c;
// 		width: 15.2%;
// 		text-align: center;
// 		padding: 15px 0;
// 	}
// 	span:first-child {
// 		font-size: 14px;
// 		color: #7c7c7c;
// 		width: 24%;
// 		text-align: left;
// 		padding-left: 3%;
// 	}
// 	span:last-child {
// 		padding-right: 3%;
// 	}
// }

// .outer-cont-box {
// 	background: #e9fdff;
// 	padding-bottom: 10px;
// 	margin-bottom: 90px;
// }

// .indicatorContainer {
// 	height: 60px;
// }

// //curriculum

// .title-curr {
// 	padding: 10px 0 10px 6%;
//     font-weight: 900;
//     font-size: 15px;
//     color: #005c83;
//     background: #78dfff;
//     margin-bottom: 35px;
// }

// .buy-curriculum {
// 	height: auto;
// 	font-size: 18px;
// 	font-weight: 600;
// 	margin: 30px 0 80px 0;
// 	padding: 10px 0;
// }

// .buy-curriculum:hover {
// 	height: auto;
// }}

//full page media

// @media (min-width: 1280px) and (min-height: 600px) and (max-height: 900px) {

@media (min-width: 1px) and (min-height: 1px) and (max-height: 900px) {
//right sidebar 
	.block-sales {
		margin-top: 20px;
	}
	.our-deal {
		margin: 0;
	}
	.inner-text-right-sidebar {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.save-procent {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.parent-box img {
		width: 75%;
    	margin: 0 auto;
    	display: block;
	}
	.child-profile {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.parent-box {
    	margin-bottom: 10px;
    	padding-bottom: 10px;
    	padding-top: 10px;
	}

//center box 
	.inner-content {
		padding-top: 5px;

		.inner-h1 {
			padding-bottom: 15px;
    		margin: 0;
    		padding-top: 10px;
		}
	}
	.select-one img, .select-two img {
		width: 50%;
	}
	.select-one, .select-two {
		text-align: center;
	}
	.under-user {
		margin-top: 15px;
	}
	.label-style {
		padding-top: 5px;
	}
	.input-style {
		padding: 5px;
	}
	.add-student-page {
		padding-bottom: 10px;
	}
	.add-student-button {
		margin-top: 15px;
    	margin-bottom: 10px;
	}
	.label-radio {
		font-size: 16px;
    	padding-top: 7px;
    	height: 30px;
	}
	.custom-radio {
		top: -3px;
	}
	.title-label {
		font-size: 16px;
		padding-bottom: 10px;
	}
	.change-profile {
		padding: 0;
	}
	.select-one {
		margin-right: 0;
	}

//profile 
	.title-block {
		padding: 0 0;
	}
	// .title-label {
	// 	padding-bottom: 30px;
	// }
	.nav-profile {
		padding-top: 10px;
    	padding-bottom: 10px;
	}
	.manage-purchases .inner-h1-manage {
		padding-bottom: 0;
	}
	.inner-h1-manage-two {
		padding: 0;
	}
	.result-student {
		margin: 0 0 10px 0;
	}
	.title-curr {
		margin-bottom: 10px;
    	margin-top: 0;
	}
	.nav-table-dash {
		margin-bottom: 10px;
	}
	.outer-manage-block .table-div-box:nth-child(even),
	.outer-manage-block .table-div-box:nth-child(odd) {
		padding-bottom: 20px;
		padding-top: 20px;
	}

//invite

	.invite-title {
		margin-bottom: 50px;
	}
	.invite-button {
		margin-top: 50px;
	}
	.invite-avatar {
		bottom: 100px;
	}
	.invite-submit {
		margin-bottom: 80px;
	}
}

@media (min-width: 1px) and (min-height: 901px) {
	//right sidebar 
	.block-sales {
		margin-top: 20px;
	}
	.our-deal {
		margin: 0;
	}
	.inner-text-right-sidebar {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.save-procent {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.parent-box img {
		width: 75%;
    	margin: 0 auto;
    	display: block;
	}
	.child-profile {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.parent-box {
    	margin-bottom: 10px;
    	padding-bottom: 10px;
    	padding-top: 10px;
	}

//center box 
	.inner-content {
		padding-top: 5px;

		.inner-h1 {
			padding-top: 10px;
			padding-bottom: 15px;
    		margin: 0;
		}
	}
	.select-one img, .select-two img {
		width: 100%;
	}
	.select-one, .select-two {
		text-align: center;
	}
	.under-user {
		margin-top: 15px;
	}
	.label-style {
		padding-top: 5px;
	}
	.input-style {
		padding: 10px;
	}
	.add-student-page {
		padding-bottom: 10px;
	}
	.add-student-button {
		margin-top: 15px;
    	margin-bottom: 10px;
	}
//profile 
	.title-block {
		padding: 0 0;
	}
	.title-label {
		padding-bottom: 30px;
	}
	.label-radio {
		font-size: 16px;
    	padding-top: 7px;
    	height: 30px;
	}
	.custom-radio {
		top: -3px;
	}
	.title-label {
		font-size: 16px;
		padding-bottom: 10px;
	}
	.change-profile {
		padding: 0;
	}
	.nav-profile {
		padding-bottom: 50px;
	}
}
////////////////////////////////////////////////////////////////////////////////////////////
@media (max-width: 1300px) {
	.left-sidebar {
		display: none;
	}
}

@media (max-width: 1200px) {
	.socio-box {
		display: flex;
		flex-direction: column;
		align-items: center;

		.right-sicio-img, .right-sicio-like {
			margin-right: 0; 
		}
	}
}

@media (max-width: 900px) {
	.socio-box {
		flex-direction: row;
	    justify-content: center;

	.right-sicio-like {
		margin-bottom: 0;
	    margin-right: 5px;
	}

	.right-sicio-img {
		margin-bottom: 0;
	    margin-right: 5px;
	}
		
	.right-sicio-tweet {
		margin-bottom: 0;
	}
}


	.under-user input[type="radio"] {
		display: none;
	}
	.under-user {
		display: flex;
    	justify-content: center;
    	align-items: center;

    	.custom-radio-add {
			position: static;
    	}
	}
	.manage-purchases .inner-h1-manage, .inner-h1-manage-two {
		font-size: 16px;
	}
	.right-sidebar {
		margin-bottom: 10px;
	}
	.main-content {
		flex-direction: column;
	}
	.inner-content {
		width: 100%;
		margin-bottom: 10px;
	}
	.login-card {
		display: flex;
    	flex-direction: column;
    	align-items: center;
	}
	.inner-content .inner-h1 {
		font-size: 16px;
	}
}

@media (max-width: 695px) {
	.p-inner-manage {
		display: none;
	}
	.three-child .btn-box-parent-dash button, .three-child .btn-box-parent-dash a {
		padding: 10px 5px;
		max-width: 100%;
		min-width: 100%;
	}
	.main-reward {
		width: 100%;
	}
	.submit-rewards {
		padding-bottom: 20px;
	}
	.buy-reward {
		position: static;
    	margin: 10px auto;
	}
	.select-reward {
		flex-direction: column;
	}
	.submit-rewards .marg-rew {
		display: flex;
    	flex-direction: column;
    	align-items: flex-start;
	}
	.submit-rewards .text-reward .reward-radio {
		label {
			width: 20%;
		    height: 60px;
		    background-size: 90%;
		}
		label.baloon {
			background-size: 60%;
		}
	}
	.submit-rewards .submit-title-rew {
		white-space: normal;
	}
	.submit-rewards .rewards-p {
		padding: 10px 20px;
	}
	.nav-table-dash {
		flex-direction: column;
	}
	.nav-table-dash button {
		margin-right: 0;
		margin-bottom: 10px;
	}
	// .outer-manage-block .table-div-box:nth-child(even),
	// .outer-manage-block .table-div-box:nth-child(odd) {
	// 	width: 600px;
	// }
	.outer-cont-box {
		// min-width: 600px;
		margin-bottom: 0;
	}
	.report-student-title, .table-report, .outer-cont-box {
		// min-width: 600px;
	}
	// .report-media {
	// 	overflow-x: scroll;
	// }
	.user-info {
		width: 95%;
	}
	.invite-card {
		flex-direction: column;
	}
	.invite-avatar {
		text-align: center;
		order: 1;
		bottom: 15px;

		img {
			min-width: 120px;
		}
	}
	.invite-submit {
		margin-right: 0;
		margin: 0 auto;
	}
	.inner-h1-invite {
		font-size: 16px;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	.invite-title {
		margin-bottom: 40px;
	}
	.parent-dash {
		flex-wrap: wrap;
	}
	.parent-dash .inner-parent-dash {
		order: 2;
	}
	.three-child .btn-box-parent-dash {
		order: 1;
		margin-left: 0;
		margin-bottom: 10px;
	}
	._three-child-slider {
		order: 2;
	}
	.three-child .inner-parent-dash {
		width: 33.3%;
		flex-grow: 1;
	}
	.table-report span:nth-child(n + 2){
		display: none;
	}
	.three-child .btn-box-parent-dash {
	width: 100%;
    max-width: 100%;
    min-width: 100%;
	}
}

@media (max-width: 610px) {
	.marg-bot .add-student-button {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	.title-block {
		text-align: center;
	}
	.outer-media-profile {
		.inner-media-profile {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.label-inner-media-profile {
			margin-bottom: 5px;
		}
	}
}

@media (max-width: 550px) {
	.inner-line-manage {
		flex-direction: column;
	}
}

@media (max-width: 450px) {
	.title-block {
		text-align: center;
	}
	.check-box-week {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.custom-label-media {
		margin-bottom: 5px;
	}
	.sign-up {
		cursor: pointer;
    	margin-left: auto;
    	margin-right: auto;
	}
	.media-in-flex {
		flex-direction: column;
	}
	.radio-manage {
		margin: 10px 0;
		margin-left: -30px;
	}
	.button-inner-line {
		margin-left: 0;
	}
	.cont-box span:first-child {
		display: none;
	}
	.cont-box {
		justify-content: center;
		margin-right: 0;
    	margin-left: 0;
	}
	.cont-box span {
		width: 19.5%;
	}
}
@media (max-width: 400px) {
	.ie-avatar {
		width: 80%;
	}
}