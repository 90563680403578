//nav child

.center-padd {
	padding-left: 2%;
}

.flex-auto {
	flex: 1 0 auto;
}

.inner-child-cont, .surprise-rewards-main-no-rewards,
.surprise-rewards-main, .pet-rewards-main {
	max-width: 1300px;
	margin: 0 auto;
}

.child-nav {
	display: flex;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	position: relative;
	height: 38px;
	background-color: #0ec3fc;
	-webkit-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	-moz-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	box-shadow: 0 3px 2px rgba(0,0,0,.34);
	border: none;
	text-align: right;
.child-logo {
		margin: 0;
		padding: 0;
		padding-top: 5px;
		padding-left: 60px;
		float: left;

		img {
			max-width: 100%;
		}
	}
}

.head-child-nav {
	font-size: 0;
	padding-left: 0;
	list-style-type: none;
	background: #009fe3;
	float: right;
	margin-bottom: 30px;
	min-height: 38px;

	li {
		display: inline-block;
		margin-left: -1px;
    	margin-right: -2px;	
	}
			
	li:last-child {
		margin-right: 0;
	}

	li:first-child a {
		border-left: none;	
	}

	li:last-child a {
		border-right: none;	
	}

	li a {
		display: inline-block;
		padding: 1px;;
		color: white;
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		margin-top: 9px;
    	margin-bottom: 9px;
    	border-right: 3px solid #e1e1e1;
    	padding-left: 15px;
    	padding-right: 15px;
	}

	li:hover {
		background: #ff8227;
	}

	li:hover a {
		border-color: transparent;
	}
}

.main-child-nav-box {
	display: -webkit-flex;
	display: flex;
}

.main-login-child {
	font-size: 18px;
	color: white;
	background: #009fe3;
	text-decoration: none;
	width: 129px;
	height: 32px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	text-align: center;
    padding-top: 5px;
    margin-left: 35px;
    margin-right: 35px;
    font-weight: 600;
}

.main-login-child:hover {
	background: #ff8227;
}

.child-nav-box {
	display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
}

//footer

.footer-child {
	height: auto;
	background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
	background-size: 115% 115%;
	padding: 5px 15px 5px 15px;
}

.inner-footer-child {
	height: 100%;
	display: -webkit-flex;
	-webkit-align-items: center;
	-webkit-justify-content: space-between;
	-webkit-flex-wrap: wrap;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer-div-child {
	display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
    	width: 181px;
    	margin-right: 0px;
    }
}

.link-footer-child {
	font-size: 16px;
	color: #4166e5;
	text-decoration: none;
	margin-left: 65px;
}

// contenier

.main-child-container {
	background: url("/images/childDash/child_bcg.png") no-repeat center center;
	background-size: cover;
	// padding-bottom: 20px;
	padding-bottom: 115px;
	height: 100%;
	flex: 1 0 auto;
}

//right sidebar

.active-box, .active-ball {
	display: block;

	img {
		max-width: 70%;
	}
}

.left-child-content {
	width: 80%;
	// padding-right: 5%;
	padding-right: 10%;
	padding-left: 10%;
}

.right-child-sidebar {
	width: 20%;
}

.image-right-box-child {
	padding-bottom: 60px;
	text-align: center;
	p {
		font-size: 14px;
		color: #3d298b;
		font-weight: 700;
		text-align: center;
	}
}

//left content

.child-dash-avatar {
	border-radius: 50%;
    box-shadow: 0 0 10px 0px #000000, 0 0 158px rgba(255,255,190,.75);
}

.inner-child-cont {
	display: -webkit-flex;
	display: flex;
	padding: 10px 20px 0 20px;
}

.child-inner-avatar {
	display: -webkit-flex;
	-webkit-align-items: center;
	-webkit-flex-wrap: wrap;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	p {
		font-size: 24px;
		font-family: Oduda;
		font-weight: 700;
		color: #ff8227;
		height: 60px;
		line-height: 63px;
		-webkit-border-radius: 45px;
		-moz-border-radius: 45px;
		border-radius: 45px;
		background-color: #fff362;
		text-align: left;
		width: 40%;
    	padding-left: 5%;
    	margin-left: 40px;
	}
}

.result-child-rewards{
	background: url("/images/childDash/bcgRewards.png") no-repeat center center;
	background-size: 100% 100%;
	border-radius: 30px;
	display: -webkit-flex;
    -webkit-justify-content: space-around;
    -webkit-align-items: flex-end;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 10px 0;
    padding-right: 4%;
    padding-left: 4%;
    -webkit-box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);
	-moz-box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);
	box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);

	.inner-result-student {
		padding: 5px;

		img {
			width: 35%;
		}
		span {
			font-size: 18px;
		}
	}
}

.result-child-rewards {
	.inner-result-student p:last-child {
		font-size: 12px;
	}
}

.title-child-score {
	display: -webkit-flex;
	-webkit-align-items: center;
	display: flex;
	align-items: center;

	.left-title-score {
		font-family: Oduda;
		display: inline-block;
		font-size: 30px;
		font-weight: 700;
		color: #1892ff;
		width: 59%;
    	padding-left: 3%;
	}
	.right-title-score {
		font-family: Oduda;
		display: inline-block;
		font-size: 24px;
		font-weight: 700;
		color: #ff8227;
		width: 40%;
	}
}

.section-play {
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	-webkit-flex-wrap: wrap;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background: url("/images/childDash/bcgFish.png") no-repeat center center;
	background-size: 100% 100%;
	border-radius: 45px;
	-webkit-box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);
	-moz-box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);
	box-shadow: 0 10px 4px 3px rgba(4,140,222,.75);
	margin-bottom: 10px;
	// height: 65px;
}

.inner-section-play-first {
	// width: 52%;
	display: -webkit-flex;
	-webkit-align-items: center;
	display: flex;
	align-items: center;
	img {
		-webkit-align-self: center;
		align-self: center;
		margin-left: 30px;
	}
	p {
		font-size: 28px;
		font-family: Oduda;
		font-weight: 700;
		color: #ff8000;
		padding-left: 75px;
	}
}

.inner-section-play-second {
		// width: 48%;
		display: -webkit-flex;
	    -webkit-align-items: center;
		display: flex;
	    align-items: center;
	    justify-content: space-between;
	    padding: 5px 10px 5px 0;
	.fish-container-0 {
		width: 305px;
		height: 65px;
		background: url("/images/childDash/goldfish_empty.png") no-repeat center center;
	}
	.fish-container-1 {
		width: 305px;
		height: 65px;
		background: url("/images/childDash/goldfish1.png") no-repeat center center,
		url("/images/childDash/goldfish_empty.png") no-repeat center center;
	}
	.fish-container-2 {
		width: 305px;
		height: 65px;
		background: url("/images/childDash/goldfish2.png") no-repeat center center,
		url("/images/childDash/goldfish_empty.png") no-repeat center center;
	}
	.fish-container-3 {
		width: 305px;
		height: 65px;
		background: url("/images/childDash/goldfish3.png") no-repeat center center,
		url("/images/childDash/goldfish_empty.png") no-repeat center center;
	}
	.play-btn-child {
		display: inline-block;
		width: 199px;
		height: 65px;
		background: url("/images/childDash/play.png") no-repeat center center;
		margin-right: 30px;
		margin-left: 30px;
	}
	.play-btn-child:active {
		display: inline-block;
		width: 199px;
		height: 65px;
		background: url("/images/childDash/play_pressed.png") no-repeat center center;
		margin-right: 30px;
		margin-left: 30px;
	}
	.finish-btn-child {
		display: inline-block;
		width: 199px;
		height: 65px;
		background: url("/images/childDash/finish.png") no-repeat center center;
		margin-right: 30px;
		margin-left: 30px;
	}
	.finish-btn-child:active {
		display: inline-block;
		width: 199px;
		height: 65px;
		background: url("/images/childDash/finish_pressed.png") no-repeat center center;
		margin-right: 30px;
		margin-left: 30px;
	}
} 

//child dash report

.percent-title-child {
	padding-bottom: 5px;
	
	span {
		font-family: Oduda;
		font-size: 16px;
		font-weight: 600;
		color: #1892ff;
		display: inline-block;
		width: 16%;
	}

	span:first-child {
		font-size: 18px;
		font-weight: 700;
		display: inline-block;
    	width: 48%;
    	padding-left: 3%;
	}
}

.container-report-child .section-play .inner-section-play-first p {
	font-size: 20px;
}

.container-report-child .section-play .inner-section-play-first {
	width: 46%;
}

.round-progress-child {
	width: 50%;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
    -webkit-align-items: center;
    display: flex;
	justify-content: space-between;
    align-items: center;

	.inner-round-progress-child {
		position: relative;
		display: inline-block;
		width: 30%;
		font-size: 16px;
		color: #912c03;
		font-weight: 600;
	}
}

.inner-progress-percent {
	position: absolute;
    top: calc(50% - 10px);
    left: 0;
    display: block;
    width: 100%;
    font-size: 16px;
    color: #912c03;
    text-align: center;
    font-weight: 600;
}  

.outer-div-progress-percent {
	position: relative;
    margin-right: 19%;
    margin-left: 4%;
    margin-top: 3px;
}

//child dash choose avatar

.child-choose-avatar .main-child-container .inner-child-cont
.left-child-content .section-play p {
	font-size: 21px;
}

.change-avatar-p {
	font-size: 24px;
	font-weight: 700;
	font-family: Oduda;
	color: #ff8227;
	padding: 40px 0 10px 2%;
	margin: 0;
}

.choose-avatar-number {
	display: -webkit-flex;
    -webkit-align-items: center;
	display: flex;
    align-items: center;
    padding-left: 40px;
    padding-bottom: 30px;
	.radio-avatar {
		display: none;
	}
	.label-avatar {
		cursor: pointer;
		position: relative;
		font-size: 25px;
		font-family: Oduda;
		color: #ff8227;
		padding-left: 90px;
		padding-right: 30px;
		white-space: nowrap;
		display: flex;
   		align-items: center;
		img {
			margin-left: 30px;
		}
	}

	span {
		background-image: none !important;
	}

	.span-avatar {
		content: "";
	    position: absolute;
	    left: 0;
	    top: calc(50% - 14.5px);
	    display: block;
	 	width: 29px;
    	height: 29px;
    	border-radius: 50%;
		background-color: #fff362;
		-webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
		-moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
		box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
		border: solid 3px #fff;
	} 
	.radio-avatar:checked + .span-avatar {
		content: "";
		position: absolute;
		left: 0;
		display: block;
	 	width: 29px;
		height: 29px;
		border-radius: 50%;
		background-color: #ff8227;
		-webkit-box-shadow: 0 0 10px 1px rgba(0,0,0,.75), inset 0 0 2px 5px #00c6f6;
		-moz-box-shadow: 0 0 10px 1px rgba(0,0,0,.75), inset 0 0 2px 5px #00c6f6;
		box-shadow: 0 0 10px 1px rgba(0,0,0,.75), inset 0 0 2px 5px #00c6f6;
	}
}

.choose-avatar-number:last-child {
	padding-bottom: 0;
}

.outer-div-avatar {
	width: 100%;
	display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    padding-bottom: 60px;
    // flex-wrap: wrap;
}

.inner-child-cont-avatar {
	padding: 0px 20px 0 20px;
}

//child dash pet rewards

.pet-rewards-main {
	max-width: 800px;
	min-height: 500px;
	padding: 0 60px 25px 60px;
	width: 60%;
	margin: 0 auto;
	background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
	background-size: 100% 100%;
}

.title-pet {
	font-size: 28px;
	font-family: Oduda;
	font-weight: 700;
	color: #fff;
	text-shadow: 5px 5px 5px #912c03;
	margin: 0;
	text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.main-pet-contenier {
	display: flex;
	justify-content: space-between;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	text-align: center;
	padding-top: 25px;
	.inner-pet-container {
		position: relative;
		img {
			width: 60%;
			max-width: 60%;
			min-width: 110px;
			height: 100%;
		}
		p {
			font-size: 16px;
			font-family: "Open Sans";
			color: #2b010c;
			font-weight: 700;
			margin: 0;
			text-align: center;
			position: absolute;
    		width: 100%;
    		bottom: 18%;
		}
	}
}

// .main-pet-contenier:last-child {
// 	padding-bottom: 90px;
// }

//surprise rewards first

.surprise-rewards-main {
	min-height: 450px;
	max-width: 800px;
	padding: 0 10px;
	width: 60%;
	// min-height: 1375px;
	margin: 0 auto 200px auto;
	background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
	background-size: 100% 100%;
	padding-bottom: 10px;
}

.title-surprise {
	font-size: 42px;
	font-family: Oduda;
	font-weight: 700;
	color: #fff;
	text-shadow: 5px 5px 5px #912c03;
	margin: 0;
	text-align: center;
    padding-top: 75px;
    padding-bottom: 100px;
}

 .cont-surprise {
 	position: relative;
 	display: -webkit-flex;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 230px;
	-webkit-border-radius: 28px/37px 37px 28px 28px;
	-moz-border-radius: 28px/37px 37px 28px 28px;
	border-radius: 28px/37px 37px 28px 28px;
	background-color: #ffcb04;
	-webkit-box-shadow: 0 0 42px 7px rgba(255,255,255,.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111,51,35,.75);
	-moz-box-shadow: 0 0 42px 7px rgba(255,255,255,.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111,51,35,.75);
	box-shadow: 0 0 42px 7px rgba(255,255,255,.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111,51,35,.75);
	border: solid 6px #c76904;
	background-image: -webkit-linear-gradient(top, #ffcf0a, #fff137 30%);
	background-image: -moz-linear-gradient(top, #ffcf0a, #fff137 30%);
	background-image: -o-linear-gradient(top, #ffcf0a, #fff137 30%);
	background-image: -ms-linear-gradient(top, #ffcf0a, #fff137 30%);
	background-image: linear-gradient(to bottom, #ffcf0a, #fff137 30%);
}

.index-surprice {
	z-index: 1000;
}

.light-bcg {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	width: 100%;
}

.section-surprise {
	display: -webkit-flex;
	-webkit-justify-content: space-between; 
	-webkit-align-items: center;
	-webkit-flex-wrap: wrap;
	display: flex;
	justify-content: space-between; 
	align-items: center;
	flex-wrap: wrap;
    width: 100%;
    padding-bottom: 10px;

    p {
    	font-size: 20px;
    	font-family: Oduda;
    	color: #0080e3;
    	font-weight: 700;
    	padding-left: 60px;
    	margin: 0;
    }
}   

.width-surprice {
	width: 55%;
}

.flex-surprice {
	display: flex;
	display: -webkit-flex;
	padding-top: 15px;
}

.right-progress-side {
	width: 45%;
	padding-top: 0;
	display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner-right-surp-side {
	font-size: 20px;
	font-family: Oduda;
	font-weight: 700;
	color: #0068b8;
	margin: 0;
	text-align: center;
}

.inner-right-surp-side-second {
	font-size: 21px;
	font-family: Oduda;
	font-weight: 700;
	color: #0068b8;
	margin: 0;
	text-align: center;
}

.inner-big-title-progress {
	font-size: 20px;
	font-family: Oduda;
	font-weight: 700;
	color: #0080e3;
	position: absolute;
	margin: 0;
	width: 100%;
	text-align: center;
	top: calc(50% - 13px);
}

.outer-box-progree-big {
	display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    position: relative;
}

.pos-rel-big {
	position: relative;
}

.pos-abs-progress {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	width: 50%;
}

//surprice parent no rewards 

.right-progress-side-no-rewards {
	width: 100%;
	padding-top: 90px;
}

.flex-surprice-no-rewards {
	display: -webkit-flex;
	-webkit-justify-content: center;
	-webkit-flex-direction: column;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.surprise-rewards-main-no-rewards {
	max-width: 800px;
	padding: 0 60px;
	width: 50%;
	// min-height: 1375px;
	margin: 0 auto;
	background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
	background-size: 100% 100%;
	padding-bottom: 340px;
}

.no-surprice-p {
	font-size: 20px;
	font-family: Oduda;
	font-weight: 700;
	color: #0080e3;
	margin: 0;
	text-align: center;
}

/////////////////////////////////////////////////////

	.child-nav .child-logo img {
		// width: 26vw;
		width: 250px;
		max-width: 100%;
	}
//dash 1
	.inner-section-play-first p {
		font-size: 20px;
		padding-left: 1vw;
		margin: 0;
	}
	.title-child-score .right-title-score {
		font-size: 18px;
	}
	.title-child-score .left-title-score {
		font-size: 22px;
	}
	.child-inner-avatar p {
		font-size: 18px;
	    font-family: Oduda;
	    font-weight: 700;
	    color: #ff8227;
	    height: 40px;
	    line-height: 43px;
	    border-radius: 45px;
	    background-color: #fff362;
	    text-align: left;
	    width: auto;
	    min-width: 130px;
	    padding-left: 20px;
	    padding-right: 20px;
	    margin-left: 40px;
	}
	.inner-section-play-second .fish-container-0,
	.inner-section-play-second .fish-container-1,
	.inner-section-play-second .fish-container-2,
	.inner-section-play-second .fish-container-3 {
		// width: 10vw;
		width: 155px;
		background-size: 100%;
	}
	.inner-section-play-second .play-btn-child,
	.inner-section-play-second .play-btn-child:active,
	.inner-section-play-second .finish-btn-child:active,
	.inner-section-play-second .finish-btn-child {
		width: 100px;
		background-size: 100%;
	}
	.inner-section-play-first img {
		// width: 7vw;
		width: 80px;
		border-radius: 15px;
	}
	.child-dash-avatar {
		width: 110px;
		min-width: 110px;
		height: 108px;
	}
	.choose-avatar-number .child-dash-avatar {
		width: 90px;
		min-width: 90px;
		height: 88px;
	}

//dash 2
	// .percent-title-child span:first-child {
	// 	font-size: 1.875vw;
	// }
	// .percent-title-child span,
	// .round-progress-child .inner-round-progress-child,
	// .inner-progress-percent {
	// 	font-size: 1.57vw;
	// }
	// .container-report-child .section-play .inner-section-play-first p {
	// 	font-size: 2.08vw;
	// }

//dash 3 
	// .child-choose-avatar .main-child-container .inner-child-cont
	// .left-child-content .section-play p {
	// 	font-size: 2.24vw;
	// }
	// .change-avatar-p {
	// 	font-size: 3.54vw;
	// }
	// .choose-avatar-number .label-avatar {
	// 	font-size: 2.6vw;
	// }

//dash 4
	.title-pet {
		// font-size: 4.43vw;
		padding-bottom: 25px;
	}
	// .no-surprice-p {
	// 	font-size: 3.58vw;
	// }
	// .inner-right-surp-side {
	// 	font-size: 3.17vw;
	// }
	// .inner-right-surp-side-second {
	// 	font-size: 2.24vw;
	// }
	.inner-big-title-progress {
		// font-size: 3.02vw;
		height: 33px;
	}
	.surprise-rewards-main-no-rewards {
		padding-bottom: 100px;
	}
	.right-progress-side-no-rewards {
		padding-top: 10px;
	}
	// .main-pet-contenier .inner-pet-container p {
	// 	font-size: 2.1875vw;
	// }
	.section-surprise p {
		// font-size: 3vw;
		padding-left: 8.25vw;
	}
	.cont-surprise {
		width: 100px;
		height: 90px;

	}
	.index-surprice {
		max-width: 60%;
		width: 60%;
	}

.rel-pos-li {
	position: relative;
}

.dropDownMenuChild {
    position: absolute;
    left: 0;
    top: 38px;
    text-align: center;
    margin: 0;
    padding: 0;
	width: 100%;
	background: #009fe3;
	z-index: 100;
	
    li {
    	display: block;
    	font-size: 16px;
    	margin: 0;
    	padding: 0;
    	border: none;
    }
}

.dropDownMenuChild li a {
	padding: 3px 28px;
    border-right: none;
   	cursor: pointer;
}

.bord-right-none {
	border-right: none !important;
}

.rel-pos-li.logout-child {
	display: none;
}

.box-switch-test {
	display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.switch-test {
	font-family: Oduda;
	font-size: 16px;
	font-weight: 700;
	color: #1892ff;
	display: flex;
    align-items: center;
   	justify-content: space-between;
	text-decoration: none;
	width: 180px;
	height: 40px;
	margin: 10px 15px 10px 0;
	// padding: 0 30px;
	text-align: center;
	-webkit-border-radius: 24px/36px 36px 37px 37px;
	-moz-border-radius: 24px/36px 36px 37px 37px;
	border-radius: 24px/36px 36px 37px 37px;
	background-color: #fff362;
	-webkit-box-shadow: -1px 6px 4px 3px rgba(51,153,255,.76);
	-moz-box-shadow: -1px 6px 4px 3px rgba(51,153,255,.76);
	box-shadow: -1px 6px 4px 3px rgba(51,153,255,.76);
}

.arrow-switch-left {
	display: block;
	width: 20px;
	height: 30px;
	background: url("/images/childDash/left_arr.png") no-repeat center center;
	background-size: 100% 100%;
	margin-left: 5px;
}

.arrow-switch-right {
	display: block;
	width: 20px;
	height: 30px;
	background: url("/images/childDash/right_arr.png") no-repeat center center;
	background-size: 100% 100%;
	margin-right: 5px;
}

.arrow-switch-left:hover {
	display: block;
	width: 20px;
	height: 30px;
	background: url("/images/childDash/left_arr_active.png") no-repeat center center;
	background-size: 100% 100%;
	margin-left: 5px;
}

.arrow-switch-right:hover {
	display: block;
	width: 20px;
	height: 30px;
	background: url("/images/childDash/right_arr_active.png") no-repeat center center;
	background-size: 100% 100%;
	margin-right: 5px;
}