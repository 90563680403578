.ngdialog.popup-cancelation.ngdialog-theme-default {
	margin-right: -20px;
	padding-top: 68px;

	.ngdialog-content {
		background: transparent;
		padding: 0;
	}
}

.box-cancel-sub {
	background: #fff;
	text-align: center;
	padding-bottom: 10px;
}

.head-cancel {
	font-family: 'Open Sans', sans-serif;
	font-size: 24px;
	font-weight: 900;
	color: #fff;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
	margin: 0;
	text-align: center;
	padding: 10px 0;
	background-color: #0ec3fc;
	-webkit-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	-moz-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	box-shadow: 0 3px 2px rgba(0,0,0,.34);
	position: relative;
}

.cancel-p {
	font-family: 'Open Sans', sans-serif;
	color: #000;
	font-size: 18px;
	font-weight: 900;
	padding: 0 5px;
}

.cancel-p-red {
	font-weight: 900;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #c00;
	padding: 0 5px;

	a {
		color: #c00;
	}
}

.btn-yes-no {
	display: inline-block;
	font-weight: 900;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	padding: 5px 40px;
	background: #009fe3;
	text-decoration: none;
	outline: none;
}

.btn-yes-no:first-child {
	margin-right: 20px;
}

.btn-yes-no:hover {
	display: inline-block;
	font-weight: 900;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	padding: 5px 40px;
	background: #ff8227;
	text-decoration: none;
	outline: none;
}

.btn-close-sub {
	text-decoration: none;
	position: absolute;
	right: 10px;
    top: 3px;
    cursor: pointer;
}

.sub-title {
	font-weight: 800;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	background: #ff6b08;
	padding: 10px 0;
	margin-left: 5px;
    margin-right: 5px;
}

.btn-yes-no-close:first-child {
	margin-right: 0;
}