//deals

.deals {
	min-height: 1000px;
	background: #b3f4fe;
}

.deals-inner-six-section {
	display: flex;
}

.deals-six-right-sidebar {
	width: 18%;
}

.deals-six-left-sidebar {
	width: 18%;
    position: relative;
    text-align: center;
}

.deals-six-left-sidebar .pos-rel {
    position: relative;
}

.deals-six-left-sidebar img {
    max-width: 75%;
    min-width: 150px;
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;
}

.deals-six-left-sidebar p {
	width: 100%;
    position: absolute;
    font-size: 24px;
    font-family: Oduda;
    top: 27%;
    margin: 0;
    left: 0;
    color: #005c83;
    font-weight: 800;
    text-align: center;
}

.deals-center-sidebar {
	width: 64%;
    background: #b3f4fe;

    h1 {
	    font-size: 24px;
	    font-weight: 600;
	    color: #005c83;
	    text-align: center;
	    margin-top: 10px;
	    margin-bottom: 10px;
	}
    h2 {
	    font-size: 18px;
	    font-weight: 400;
	    color: #005c83;
	    text-align: center;
	    margin-top: 0;
	    margin-bottom: 10px;
	}
}

.deals-flex-center .deals-inner-center-sidebar {
	margin-right: 40px;
}

 .deals-inner-center-sidebar {
 	width: 27%;
 	text-align: center;

 	img {
 		max-width: 70%;
 	}
 }

 .deals-head-btn {
 	display: inline-block;
    font-size: 16px;
    color: #ffffff;
    background: #009fe3;
    text-decoration: none;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 600;
 }

  .deals-head-btn:hover {
 	display: inline-block;
    font-size: 16px;
    color: #ffffff;
    background: #ff8227;
    text-decoration: none;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 600;
 }

.deals-footer-btn {
	display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background: #009fe3;
    text-decoration: none;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.deals-footer-btn:hover {
	display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background: #ff8227;
    text-decoration: none;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.deals-parent-box {
	background: white;
    padding: 15px;
    margin-bottom: 0px;

}

.deals-child-profile {
	font-size: 16px;
    color: #4f5050;
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
}

.deals-child-profile-center {
	font-size: 16px;
    color: #4f5050;
    text-align: center;
    margin: 0;
    margin-bottom: 51px;
}

.deals-flex-center {
    display: flex;
    justify-content: center;
}

.deals-price-text {
	font-family: Oduda;
    font-size: 20px;
    color: #ff8227;
    text-align: center;
    margin-bottom: 0;

}

.deals-del-price {
	position: relative;
	font-size: 16px;
}

.deals-del-price:before {
    border-bottom: 2px solid red;
    position: absolute;
    content: "";
    width: 140%;
    height: 50%;
    transform: rotate(-172deg);
    left: -20px;
    bottom: 0px;
    right: 0;
    top: 10px;
}

.cancel-anytime {
	display: block;
	text-align: center;
	color: #ff0000;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
    outline: none;
}

.cancel-anytime:hover {
	display: block;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
	color: #c51f1f;
}

.ask-title {
	font-size: 36px;
	font-weight: 600;
	color: #005c83;
	padding: 20px 0;
	text-align: center;
	background: #e9fdff;
	border-bottom: 1px solid #78dfff;
	margin-bottom: 25px;

	p {
		margin: 0;
	}
}

.box-text-bottom-deals {
	width: 64%;
	margin: 0 auto;
	p {
		padding: 10px 0 0 20px;
		margin: 0;
	}
	p:nth-child(odd) {
		color: #005c83;
		font-size: 18px;
		font-weight: 700;
	}
	p:nth-child(even) {
		color: #005c83;
		font-size: 18px;
		font-weight: 600;
	}
}