@media (max-width: 1100px) {
	.more-resources {
		flex-wrap: wrap;
	}
	.more-resources-right-sidebar {
		display: none;
		width: 20%;
		margin: 10px auto;
		min-width: 200px;
	}
	.mobile-more-resources-right-sidebar {
		display: flex;
	}
	.more-resources-left-sidebar {
		width: 28%;
	}
	.more-resources-main {
		width: 71%;
	}
}

@media (min-width: 696px) {
	.more-res-left-sidebar-menu .active-res-test-menu {
		display: block !important;
	}
	.more-res-left-sidebar-menu .active-res-test-menu._active-hide-menu {
		display: none !important;
	}
	.more-resources-left-sidebar .more-res-left-sidebar-menu ._all-test-menu {
		display: block !important;
	}
	.more-res-left-sidebar-menu .left-resources-a.active-res-test-menu._topics-desctop-hide {
		display: none !important;
	}
	.more-res-left-sidebar-menu .left-resources-a.active-res-test-menu._skills-desctop-hide {
		display: none !important;
	}
}

@media (max-width: 695px) {
	.more-resources-main {
		width: 100%;
	}
	.more-resources-left-sidebar {
	    width: 50%;
	    margin-left: auto;
	    margin-right: auto;
	}

	.more-res-left-sidebar-menu .active-res-test-menu {
		display: block !important;
	}

	// .more-res-left-sidebar-menu  a {
	// 	display: none;
	// }

	// .more-res-left-sidebar-menu:hover a,
	// .more-res-left-sidebar-menu:hover .active-res-test-menu {
	// 	display: block;
	// }

	.btn-first-res, .btn-more-skils-res {
		display: none !important;
	}

}

@media (max-width: 400px) {
	.item-resources {
		margin: 0 auto;
	}
}
