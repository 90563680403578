@media (min-width: 1400px) {
	.main-child-container {
		background: url("/images/childDash/child_bcg.png") no-repeat center center;
		background-size: 100% 100%;
		// padding-bottom: 20px;
		padding-bottom: 115px;
		height: 100%;
		flex: 1 0 auto;
	}
}

@media (max-width: 1190px) {

	.dropDownMenuChild {
	    position: absolute;
	    left: -115px;
	    top: 0;
	    text-align: center;
	    margin: 0;
	    padding: 0;
		width: 100%;
		background: #009fe3;
	
    li {
    	display: block;
    	font-size: 16px;
    	margin: 0 !important;
    	padding: 0;
    	border: none;
    }
}

.dropDownMenuChild li a {
		padding: 3px 28px;
    	border-right: none;
    	cursor: pointer;
    }
}

@media (max-width: 1060px) {
	.left-child-content {
		padding-right: 5%;
		padding-left: 0;
	}
}

@media (max-width: 1000px) {
	.surprise-rewards-main-no-rewards {
		.title-pet {
			font-size: 18px;
		}
		.no-surprice-p, .inner-right-surp-side, .inner-right-surp-side-second{
			font-size: 16px;
		}
	}
	.surprise-rewards-main {

		.title-pet {
			font-size: 18px;
		}
		.inner-right-surp-side, .inner-right-surp-side-second {
			font-size: 18px;
		}

		.section-surprise p {
			padding-left: 6.25vw;
			font-size: 16px;
		}
	}
}

@media (max-width: 900px) {
	.outer-div-avatar .choose-avatar-number {
		padding-bottom: 0px;
		padding-top: 30px;
	}
	.pet-rewards-main {
		width: 70%;
		padding: 0 30px 25px 30px;
		.title-pet {
			font-size: 18px;
			padding-top: 35px;
		}
	}	
	.main-pet-contenier {
		flex-wrap: wrap;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-webkit-justify-content: center;
	}
	.inner-pet-container {
		margin-right: 15px;
	}
	.main-pet-contenier .inner-pet-container img {
		width: 120px;
	}
	.surprise-rewards-main {
		width: 65%;
	}
	.media-left-child-content .section-play {
		flex-direction: column;
    	align-items: center;
    	-webkit-flex-direction: column;
    	-webkit-align-items: center;
    	padding: 5px 0;
	}
	// .container-report-child {
	// 	overflow-x: scroll;

	// 	.percent-title-child, .section-play {
	// 		width: 650px;
	// 	}
	// }
	.outer-div-avatar {
		flex-direction: -webkit-column;
		flex-direction: column;
	}
	.surprise-rewards-main-no-rewards {
		width: 60%;
	}
	.percent-title-child {
		display: none;
	}
	.box-switch-test {
		margin-bottom: 10px;
	}

}

@media (max-width: 800px) {
	.surprise-rewards-main {
		width: 75%;
	}
}

@media (max-width: 695px) {

	.rel-pos-li.logout-child {
		display: block;
	}

	.dropDownMenuChild {
	    position: absolute;
	    left: 0;
	    top: 48px;
	    text-align: center;
	    margin: 0;
	    padding: 0;
		width: 100%;
		background: #009fe3;
	}

/////////////////////////test
	.flow {
		display: block;
		-webkit-flex-direction: initial;
		flex-direction: initial;
	}
/////////////////////////test
	.pet-rewards-main {
		width: 85%;
		padding: 0 20px 25px 20px;
	}
	.surprise-rewards-main {
		width: 85%;
	}
	.surprise-rewards-main-no-rewards {
		width: 75%;
	}
	.container-report-child .section-play .inner-section-play-first p {
		display: none;
	}
	.outer-div-progress-percent {
		margin-right: 0;
		margin-left: 0;
	}
	.round-progress-child .inner-round-progress-child {
		text-align: center;
	}
}

@media (max-width: 550px) {
	.pet-rewards-main {
		width: 100%;
		padding: 0 10px 25px 10px;
	}
	.surprise-rewards-main {
		width: 100%;
		padding-bottom: 20px;

		.title-pet {
			padding-top: 40px;
		}
	}
	.section-surprise {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.right-progress-side {
		padding-right: 25px;
	}
	.surprise-rewards-main-no-rewards {
		width: 100%;
	}
	.inner-child-cont {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.left-child-content {
		width: 100%;
		padding-right: 0;
	}
	.right-child-sidebar {
		width: 30%;
    	margin: 0 auto;
	}
	.image-right-box-child {
		padding-bottom: 10px;
	}
	.choose-avatar-number .label-avatar {
		padding-left: 45px;
	}
	.outer-div-progress-percent {
		margin-right: 0;
    	margin-left: 0;
	}
	.round-progress-child .inner-round-progress-child {
		text-align: center;
	}
	.round-progress-child {
		padding-right: 20px;
		width: 60%;
	}
	.container-report-child .section-play .inner-section-play-first {
		width: 30%;
	}
}

@media (max-width: 445px) {
	.pet-rewards-main .title-pet {
		padding-top: 50px;
	}
}

@media (max-width: 400px) {
	.media-left-child-content .inner-section-play-second {
		-webkit-flex-direction: column;
		flex-direction: column;
	}
	.child-inner-avatar {
		-webkit-flex-direction: column;
		flex-direction: column;

		p {
			margin-left: 0;
		}
	}
	.result-child-rewards .inner-result-student {
		text-align: center;
	}
	.inner-section-play-second .fish-container-0,
	.inner-section-play-second .fish-container-1,
	.inner-section-play-second .fish-container-2,
	.inner-section-play-second .fish-container-3 {
		height: 45px;
	}
	.inner-section-play-second .play-btn-child,
	.inner-section-play-second .play-btn-child:active,
	.inner-section-play-second .finish-btn-child:active,
	.inner-section-play-second .finish-btn-child {
		height: 50px;
		display: none;
	}
	.inner-section-play-first img {
		width: 55px;
    	-webkit-border-radius: 12px;
    	border-radius: 12px;
    	margin-left: 15px;
	}
	.round-progress-child {
		width: 70%;
	}
}




// @media (max-width: 1920px) {
// 	.child-nav .child-logo img {
// 		width: 26vw;
// 	}
// //dash 1
// 	.inner-section-play-first p {
// 		font-size: 2.7vw;
// 		padding-left: 1vw;
// 	}
// 	.title-child-score .right-title-score {
// 		font-size: 2.5vw;
// 	}
// 	.title-child-score .left-title-score {
// 		font-size: 3.1vw;
// 	}
// 	.child-inner-avatar p {
// 		font-size: 2.5vw;
// 	}
// 	.inner-section-play-second .fish-container-0,
// 	.inner-section-play-second .fish-container-1,
// 	.inner-section-play-second .fish-container-2,
// 	.inner-section-play-second .fish-container-3 {
// 		width: 15.9vw;
// 		background-size: 100%;
// 	}
// 	.inner-section-play-second .play-btn-child,
// 	.inner-section-play-second .play-btn-child:active,
// 	.inner-section-play-second .finish-btn-child:active,
// 	.inner-section-play-second .finish-btn-child {
// 		width: 10.4vw;
// 		background-size: 100%;
// 	}
// 	.inner-section-play-first img {
// 		width: 7vw;
// 	}
// 	.child-dash-avatar {
// 		width: 14.6vw;
// 	}

// //dash 2
// 	.percent-title-child span:first-child {
// 		font-size: 1.875vw;
// 	}
// 	.percent-title-child span,
// 	.round-progress-child .inner-round-progress-child,
// 	.inner-progress-percent {
// 		font-size: 1.57vw;
// 	}
// 	.container-report-child .section-play .inner-section-play-first p {
// 		font-size: 2.08vw;
// 	}

// //dash 3 
// 	.child-choose-avatar .main-child-container .inner-child-cont
// 	.left-child-content .section-play p {
// 		font-size: 2.24vw;
// 	}
// 	.change-avatar-p {
// 		font-size: 3.54vw;
// 	}
// 	.choose-avatar-number .label-avatar {
// 		font-size: 2.6vw;
// 	}
// 	.choose-avatar-number .child-dash-avatar {
// 		width: 11vw;
// 	}

// //dash 4
// 	.title-pet {
// 		font-size: 4.43vw;
// 		padding-bottom: 5.2vw;
// 	}
// 	.no-surprice-p {
// 		font-size: 3.58vw;
// 	}
// 	.inner-right-surp-side {
// 		font-size: 3.17vw;
// 	}
// 	.inner-right-surp-side-second {
// 		font-size: 2.24vw;
// 	}
// 	.inner-big-title-progress {
// 		font-size: 3.02vw;
// 		height: 33px;
// 	}
// 	.surprise-rewards-main-no-rewards {
// 		padding-bottom: 17.7vw;
// 	}
// 	.right-progress-side-no-rewards {
// 		padding-top: 4.6875vw;
// 	}
// 	.main-pet-contenier .inner-pet-container p {
// 		font-size: 2.1875vw;
// 	}
// 	.section-surprise p {
// 		font-size: 3vw;
// 		padding-left: 6.25vw;
// 	}
// 	.cont-surprise {
// 		width: 13.02vw;
// 		height: 11.98vw;

// 	}
// 	.index-surprice {
// 		max-width: 60%;
// 		width: 60%;
// 	}
// }