//customize

.customize {
	background: #ffffff;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    padding-bottom: 20px;
    flex: 1 0 auto;
}

.inner-customize {
	margin: 0 auto;
}
 
.customize-title {
	text-align: center;
	margin: 0;
	font-size: 30px;
	font-weight: bold;
	color: #fff;
	text-shadow: 0 0 3px rgba(0,0,0,.75);
	padding-bottom: 10px;
}

.align-box {
	width: 80%;
	margin: 0 auto;
}

.inner-title-cust {
	font-size: 18px;
	color: black;
	margin: 0;
	padding: 10px 0;
	font-weight: 700;
}

.box-btn-customize {

    a {
    	display: inline-block;
    	color: white;
    	background: #009fe3;
    	text-decoration: none;
    	padding: 10px 0;
    	text-align: center;
    	width: 30%;
    }
    a:hover {
    	display: inline-block;
    	color: white;
    	background: #ff8227;
    }
        a:nth-child(n+2) {
    	margin-left: 36px;
    }
}

.width-100 {
	display: flex;
}

.width-30 {
	width: 30%;
	display: flex;
    justify-content: space-between;
    align-items: center;

	p {
		display: flex;
    	justify-content: space-between;
    	align-items: center;
    	width: 100%;
		span {
    	display: block;
    	background: #e9fdff;
    	padding: 10px 20px;
    	color: #005c83;
    	font-size: 18px;
    	font-weight: 600;
    }
  }   
}

.two-column-customize {
	 display: flex;
    // justify-content: space-between;
}

.width-left-column {
	width: calc(60% + 36px);
	margin-right: 36px;
}

.width-right-column {
	width: 30%;
}

.bcg-b {
	background: #e9fdff;
	padding: 20px;

	p {
		margin: 0;
	}

}

.check-cust {
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 1px solid #6d7677;
	background: none !important;
	margin-right: 15px;
}

.check-cust-label {
	cursor: pointer;
	position: relative;
	input {
		display: none;
	}
}

.check-cust-label .check:checked + .check-cust:before {
	content: "";
	width: 18px;
	height: 16px;
	display: inline-block;
	position: absolute;
	background: url("/images/customize/chosen.png") no-repeat center center;
	bottom: 3px;
	left: 0;
	top: 0;
}

.title-bold-cust {
	font-size: 18px;
	font-weight: 700;
	color: black;
	margin: 0;
	padding: 10px 0;
}

.cupon-code {
	border: none;
	background: #e9fdff;
	width: 47.5%;
	padding: 10px;
}

.error-cupon {
	margin: 0;
	color: red;
	padding: 5px 0;
	font-size: 16px;
}

.cupon-box {
	padding-top: 20px;
	display: flex;
    align-items: center;
}

.content-all-info {
	background: #e9fdff;
	padding: 5px;
	p {
		margin: 0;
	}
}

.content-all-info-title p {
	font-size: 18px;
	color: black;
	font-weight: 600;
}

.content-all-info-title p:last-child {
	padding-bottom: 30px;
}

.inner-p-content-all-info {
	font-size: 16px;
	font-weight: 700;
	color: black;
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px;
}

.total-price-p {
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 700;
	color: #cc3333;
	padding-top: 15px;
	padding-bottom: 13px;
}

.inner-cupon-box {
	width: calc(60% + 36px);
    margin-right: 36px;
}

.secure-payment {
	display: block;
	padding: 10px;
	width: 30%;
	text-decoration: none;
	color: white;
	background: #009fe3;
	text-align: center;
}

.secure-payment:hover {
	display: block;
	padding: 10px;
	width: 30%;
	text-decoration: none;
	color: white;
	background: #ff8227;
	text-align: center;
}
 
.red-price {
	color: red;
}