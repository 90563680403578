//landing 

#mobile-login {
	display: none;
}

.yellow-btn {
	display: none;
}

.two-section-landing {
	min-height: 100%;
}

.fix-nav {
	position: fixed;
	top: 0;
    width: 100%;
    z-index: 10000;
}

.max-flex {
	display: flex;
	justify-content: space-between;
}

.inner-main-content-landing, .pin-text, .outer-box-checkbox,
.input-box-pin, .inner-img-planshet, .left-title-planshet, .three-section-h1,
.three-section-h2, .container-three, .max-content, .outer-five-section,
.five-section-h1, .two-slider-outer, .inner-six-section, .seven-section-h1,
.seven-section-p, .slider-box, .eight-section-h1, .blog-box, .about-h1,
.about-box, .inner-ten-box, .inner-footer {
	max-width: 1300px !important;
	margin: 0 auto !important;
	width: 100%;
}

.max-content {
	width: 100%;
}

.mobile-nav {
	border: none;
	outline: none;
	padding-top: 8px;
    padding-bottom: 3px;
	border-radius: 10px;
	background: #ff8227;
	cursor: pointer;
	display: none;
	z-index: 30000;
	// width: 42px;
	// height: 32px;
	width: auto;
	height: auto;
}	

.mobile-nav:hover {
	background: #009fe3;
}

.line-nav {
	height: 2px;
	background: white;
	width: 30px;
	display: block;
	margin-bottom: 5px;
	cursor: pointer;
	margin-left: auto;
    margin-right: auto;
}

.pos-relative {
	position: relative;
} 

.box-checkbox {
	display: flex;
    flex-direction: column;

    label {
    	font-size: 40px;
    	font-weight: 600;
    	color: #1a397f;
    	display: flex;
    	align-items: flex-end;
    	margin-right: 35px;
    	margin-bottom: 15px;
    	cursor: default;
    }
    input[type=checkbox] {
    	display: none;
    }
}

.checkbox-style {
	display: inline-block;
	width: 61px;
	height: 56px;
	background: url("/images/landing/check.png") no-repeat left bottom;
	margin-right: 15px;
}

input[type=checkbox]:checked + .checkbox-style {
	background: url("/images/landing/check-active.png") no-repeat left bottom;
	margin-right: 15px;
}


.outer-box-checkbox {
	display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.txt-align {
	text-align: center;
}

.nav-landing {
	display: flex;
	justify-content: space-between;
.main-logo {
		margin: 0;
		padding: 0;
		padding-top: 5px;
		padding-left: 1%;
		float: left;

		img {
			max-width: 100%;
		}
	}
}

.main-nav-box {
	display: flex;
}

.main-login {
	font-size: 28px;
	color: white;
	background: #ff8227;
	text-decoration: none;
	width: 129px;
	height: 40px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background-color: #ff8227;
	text-align: center;
    padding-top: 3px;
    margin-left: 10px;
    outline: none;
}

.main-login:hover {
	background: #009fe3;
}

.main-img-search {
	opacity: 0;
	width: 32px;
	height: 31px;
	background: url("/images/landing/scale.png") no-repeat center center;
}

.main-img-search:hover {
	background: url("/images/landing/scale_hover.png") no-repeat center center;
}

.block-search {
	display: flex;
	align-items: center;
}

.main-content-landing {
	width: 100%;
	padding-bottom: 500px;
	background: url("/images/landing/bcg_1.png") no-repeat center 0;
	background-size: 100% 100%;
	// max-width: 1400px;
	margin: 0 auto;
}

.inner-main-content-landing {
	display: flex;
	justify-content: center;
}

.pin-container {
	margin-left: 115px;
	text-align: center;
	img {
		width: 80%;
	}
}    

.pin-text {
	display: flex;
    flex-direction: column;
	font-family: Oduda;
	font-size: 57px;
	font-weight: 900;
	color: #ff8227;

	p {
		margin: 0;
		align-self: center;
	}
}

.inner-text-pin {
	font-family: "Open Sans";
	font-size: 24px;
	font-weight: 900;
	color: #1a397f;
	// padding-left: 8%;
	padding-top: 25px;
   	width: 50%;
}

.input-box-pin {
	display: flex;
	justify-content: center;

	p {
		font-family: "Open Sans";
		font-size: 30px;
		font-weight: 900;
		color: #1a397f;
		margin-top: 45px;
    	margin-bottom: 10px;
	}

	.get-started {
		min-width: 370px;
		font-family: Oduda;
		font-size: 36px;
		font-weight: 600;
		color: #ff8227;
		margin-left: 45px;
		border: none;
		height: 70px;
		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		box-shadow: 0 5px 3px rgba(4,140,222,.75);
		border-radius: 20px;
		background: url("/images/landing/bgc_btn.png") no-repeat;
		outline: none;
		cursor: pointer;
		text-align: center;
    	padding-top: 12px;
    	text-decoration: none;
	}

	input {
		height: 70px;
		font-family: Oduda;
		font-size: 36px;
		font-weight: 900;
		color: #015f99;
		background-color: #86f4ff;
		border-radius: 20px;
		border: none;
		outline: none;
		padding-left: 30px;
		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		box-shadow: 0 5px 3px rgba(4,140,222,.75);
		padding-right: 155px;
		max-width: 500px;
		text-align: center;
	}
	 input::-webkit-input-placeholder {color:#015f99;}
}

.flex {
	display: flex;
	flex-wrap: wrap;
}

.flex-sub {
	flex-direction: column;
    align-items: center;
}

.flex-outer {
	display: flex;
    justify-content: center;
}

.main-submit {
	position: absolute;
    top: 0;
    right: 0;
	font-family: Oduda;
	width: 140px;
	font-size: 24px;
	font-weight: 600;
	color: white;
	border: none;
	height: 70px;
	border-radius: 0 20px 20px 0;
	background: url("/images/landing/widget_btn.png") no-repeat;
	background-size: 100% 100%;
	outline: none;
	cursor: pointer;
}

// planshet-landing

.planshet-landing {
	background: #0f86e8;
	padding-bottom: 40px;
}

.inner-img-planshet {
	text-align: center;
	// display: flex;
	// justify-content: center;
	padding-top: 30px;
	img {
		width: 90%;
		height: auto;
	}
}

.title-planshet-landing {
	// display: flex;
	background: #0873d7;
	margin-top: 40px;
	padding: 0 0 15px 0;
}

.inner-title-section-planshet {
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.left-title-planshet {
	width: 100%;
}

.inner-left-title-planshet {
	display: flex;
	width: 40%;
	justify-content: space-between;
	img {
		max-width: 100%;
		// height: 100%;
	}
	p {
		margin: 0;
		font-size: 28px;
		color: white;
		width: 70%;
		padding-top: 10px;
	}
}

.inner-width-planshet {
	width: 25%;
    // display: flex;  // IE
    // flex-direction: column;
    // align-items: flex-end;
}

.inner-title-section-planshet {
	.inner-left-title-planshet:first-child {
		padding-right: 2%;
	}
	.inner-left-title-planshet:last-child {
		padding-left: 2%;
	}
}

.contact-us {
	color: #a4e8ff;
}

//three-section
 
.list-style-land {
	position: absolute;
	left: 0;
	top: 0;
	display: inline-block;
	width: 42px;
	height: 37px;
	background: url("/images/landing/style-type.png") no-repeat center center;
}

.three-section {
	background: #6bdcff;
}

.three-section-h1 {
	font-size: 72px;
	color: #014ca9;
	font-weight: 600;
	text-align: center;
	padding-top: 75px;
	padding-bottom: 45px;
	margin: 0;
}

.three-section-h2 {
	font-size: 48px;
	color: #014ca9;
	font-weight: 400;
	text-align: center;
	margin: 0;
	padding-bottom: 70px;
}

.box-img-three {
	padding: 40px 60px;
	background: url("/images/landing/bcg-item.png") no-repeat center center;
	background-size: 100% 100%;
	width: 30%;
	min-width: 500px;

	ul {
		font-size: 28px;
		color: #1a397f;
		font-weight: 400;
		list-style-type: none;
		padding: 0;
		// list-style-image: url("/images/landing/style-type.png");

		li {
			display: flex;
			align-items: center;
			position: relative;
			padding-left: 52px;
    		height: 45px;
		}
	}
}

.inner-box-img-three {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 88px;

	img {
		-webkit-border-radius: 21px/19px;
		-moz-border-radius: 21px/19px;
		border-radius: 21px/19px;
		-webkit-box-shadow: 0 0 16px rgba(0,0,0,.75);
		-moz-box-shadow: 0 0 16px rgba(0,0,0,.75);
		box-shadow: 0 0 16px rgba(0,0,0,.75);
	}

	p {
		font-size: 38px;
		color: #014687;
		font-weight: 800;
		margin: 0;
		padding-left: 15px;
	}
}

.container-three {
	display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .box-img-three:first-child {
		margin-right: 3%;	
    }
    .box-img-three:last-child {
		margin-left: 3%;	
    }
}

.btn-more-topic {
	width: 20%;
	background: url("/images/landing/btn.png") no-repeat center center;
	background-size: 100% 100%;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 34px;
	color: #014687;
	font-weight: 600;
	padding: 25px 0px;
	margin-top: 30px;
	margin-bottom: 130px;
	display: inline-block;
}

.btn-more-topic:active {
	background: url("/images/landing/btn-pressed.png") no-repeat center center;
	width: 20%;
	background-size: 100% 100%;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 34px;
	color: #014687;
	font-weight: 600;
	padding: 25px 0px;
	margin-top: 30px;
	margin-bottom: 130px;
	display: inline-block;
}

//four section

.four-section {
	background: #22caff;
}

.column-four {
	display: flex;
    justify-content: center;

    .inner-column:first-child {
		padding-right: 2%;
    }
    .inner-column:last-child {
		padding-left: 2%;
    }
}    

.inner-column {
	width: 30%;
	
	p {
		text-align: center;
	}

	a {
		display: inline-block;
		background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 40px;
		color: white;
		text-decoration: none;
		padding: 20px 0;
		width: 100%;
		font-size: 36px;
		font-weight: 900;
	}
	a:hover {
		display: inline-block;
		background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 40px;
		color: white;
		text-decoration: none;
		padding: 20px 0;
		width: 100%;
		font-size: 36px;
		font-weight: 900;
	}
}

.btn-four {
	margin-bottom: 70px;
}

.btn-four:active {
	margin-bottom: 70px;
}

//five section 

.five-section {
	padding-bottom: 30px;
	background: #ffffff;
}

.outer-five-section {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.inner-five-section {
	width: 57%;
	display: flex;
	align-self: center;
}

.contenier-for-slide {
	display: -webkit-box !important; 
	display: -webkit-flex !important;
	-webkit-justify-content: space-between;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-left: 2px;
}

.bold {
	font-weight: 800 !important;
}

.five-section-first-p {
	font-size: 24px;
	color: #1a397f;
	font-weight: 600;
	margin: 0;
	padding-left: 25px;
}

.five-section-two-p {
	font-size: 24px;
	color: #1a397f;
	font-weight: 800;
	margin: 45px 0 10px 0;
	padding-left: 25px;
}

.five-section-three-p {
	font-size: 18px;
	color: #1a397f;
	font-weight: 600;
	margin: 0;
	padding-left: 25px;
}

.pagination {
	display: none;

	// margin: 45px 0;
	// display: flex;
 //    justify-content: center;
	// .active-pagin {
	// 	display: inline-block;
	// 	width: 100px;
	// 	height: 13px;
	// 	background: #ff7800;
	// }
	// span {
	// 	display: inline-block;
	// 	width: 100px;
	// 	height: 13px;
	// 	background: #568da4;
	// 	cursor: pointer;
	// 	margin-right: 35px;
	// }
	// span:last-child {
	// 	margin-right: 0;
	// }
}

.five-section-img {
	max-width: 221px;
	max-height: 208px;
	margin-right: 25px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	background-color: #fff;
	-webkit-box-shadow: 1px 1px 4px rgba(0,0,0,.75);
	-moz-box-shadow: 1px 1px 4px rgba(0,0,0,.75);
	box-shadow: 1px 1px 4px rgba(0,0,0,.75);
	border: solid 1px #c8edfb;
}

.five-section-h1 {
	padding-top: 0;
	padding-bottom: 80px;
}

.two-slider-outer {
	display: flex;
    justify-content: center;
}

.inner-two-slider {
	display: flex;
	width: 57%;
	justify-content: space-between;
}

.container-for-second-slider {
	display: flex !important;
	justify-content: space-between !important;
	margin-left: 2px;

	p {
		padding: 0;
	}

	.item-slider {
		width: 30%;
	}
}

.five-section-img {
	margin: 0 !important;
}

.item-slider {
	width: 20%;
}

//six section

.six-section {
	background-color: #b3f4fe;
	background-image: -webkit-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -moz-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -o-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -ms-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: linear-gradient(to bottom, #4fc7fe, #d0f8fe);
}

.inner-six-section {
	display: flex;
}

.six-left-sidebar {
	width: 18%;
	position: relative;
	text-align: center;
	img {
		max-width: 100%;
    	padding-top: 170px;
	}
	p {
		width: 100%;
    	text-align: center;
    	left: 0;
		position: absolute;
    	font-size: 60px;
    	font-family: Oduda;
    	top: 26%;
    	margin: 0;
    	color: #005c83;
    	font-weight: 800;
    	text-align: center;
	}
	.pos-rel {
		position: relative;
	}
	// background: url("/images/landing/ball.png") no-repeat center 170px;
	// background-size: 85%;
}

.center-sidebar {
	width: 64%;
	background: #b3f4fe;

	h1 {
		font-size: 72px;
		font-weight: 600;
		color: #005c83;
		text-align: center;
		margin-top: 80px;
		margin-bottom: 30px;

	}
	h2 {
		font-size: 48px;
		font-weight: 400;
		color: #005c83;
		text-align: center;
		margin-top: 0;
		margin-bottom: 90px;
	}
	.parent-box {
		padding: 45px 20px;
		
		.child-profile {
			text-align: left;
			font-size: 30px;
		}
		.price-text {
			font-size: 40px;
			margin-bottom: 0;
    		margin-top: 50px;
		}
		.del-price {
			font-size: 30px;
		}
	}
}

.six-right-sidebar {width: 18%;}

.inner-center-sidebar {
	width: 21%;
	text-align: center;
	img {
		max-width: 100%;
	}
	.parent-box {
		margin-bottom: 80px;
		margin-top: 30px;
	}
}

.child-profile-price {
	text-align: center !important;
}

.head-btn {
	display: inline-block;
	font-size: 24px;
	color: #ffffff;
	background: #009fe3;
	text-decoration: none;
	padding: 10px 0;
	width: 100%;
	text-align: center;
	margin-bottom: 45px;
	cursor: pointer;
	font-weight: 600;
}

.footer-btn {
	display: inline-block;
	font-size: 24px;
	font-weight: 600;
	color: #ffffff;
	background: #009fe3;
	text-decoration: none;
	padding: 10px 0;
	width: 100%;
	text-align: center;
	margin-bottom: 95px;
	cursor: pointer;
}

.head-btn:hover, .footer-btn:hover {
	background: #ff8227;	
}

.flex-center {
	display: flex;
    justify-content: center;

    .inner-center-sidebar {
		margin-right: 5%;
    }
    .inner-center-sidebar:last-child {
		margin-right: 0;
    }
}

// seven section

.seven-section {
	padding-bottom: 50px;
	background: white;
}

.seven-section-h1 {
	font-size: 72px;
	font-weight: 600;
	text-align: center;
	margin: 0;
	padding: 75px 0 30px 0;
	color: #00539a;
}

.seven-section-p {
	font-size: 48px;
	font-weight: 600;
	color: #0063b7;
	margin: 0;
	padding-bottom: 30px;
	padding-left: 18%;
    text-align: left;

	a {
		font-size: 36px;
		color: #009fe3;
		padding-left: 35px;
	}
}

.slider-box {
	display: flex;
    justify-content: center;
}

.inner-slider-box {
	// display: flex;
    // justify-content: center;
    width: 80%;
    position: relative;
}


.box-sliders {
	display: flex;
    justify-content: center;
	// .slider-item {
	// 	padding-right: 2%;
	// }
	.slider-item:last-child {
	padding-right: 0;
  }
}

.left-slide-nav {
	position: absolute;
	top: calc(50% - 74px);
	left: -104px;
	width: 84px;
	height: 148px;
	background: url("/images/landing/left-arrow.png") no-repeat;
	cursor: pointer;
	z-index: 9990;
}

.left-slide-nav:hover {
	background: url("/images/landing/left-arrow-hover.png") no-repeat;
}

.right-slide-nav {
	position: absolute;
	top: calc(50% - 74px);
	right: -104px;
	width: 84px;
	height: 148px;
	background: url("/images/landing/right-arrow.png") no-repeat;
	cursor: pointer;
	z-index: 9990;
}

.right-slide-nav:hover {
	background: url("/images/landing/right-arrow-hover.png") no-repeat;
}

.two-slider {
	margin-top: 95px;
}

// eight section 

.eight-section {
	padding-bottom: 30px;
	background: #e6fcff;
}

.eight-section-h1 {
	font-size: 72px;
	font-weight: 600;
	color: #00539a;
	margin: 0;
	padding-top: 85px;
	text-align: center;
}

.eight-section-p {
	font-size: 48px;
	font-weight: 600;
	color: #0048b5;
	margin: 0;
	padding-top: 65px;
}

.eight-section-two-p {
	font-size: 30px;
	font-weight: 400;
	color: #4f5050;
	margin: 0;
	padding: 0 0 20px 0;
	width: 100%;
}

.link-blog {
	font-size: 30px;
	color: #0f86e8;
	display: inline-block;
}

.blog-box {
	width: 75%;
    margin: 0 auto;
    height: 100%; //IE
}

.img-box {
	align-self: flex-start;
	height: 100%;
}

.title-blog {
	display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-left: 5%;
}

.outer-titel {
	display: flex;
}

.date-p {
	padding-top: 15px;
}

//nine section 

.nine-section {
	padding-bottom: 150px;
	background: #0f86e8;
}

.about-h1 {
	font-size: 72px;
	color: #ffed00;
	margin: 0;
	padding: 80px 0 10px 0;
	text-align: center;
}

.about-box {
	display: flex;
	align-items: center;
	width: 75%;
	margin: 0 auto;
}

.about-p {
	font-size: 30px;
	color: #ffffff;
	margin: 0;
	padding-left: 40px;
	width: 75%; //IE 11
}

// ten section 

.ten-section {
	background: #4663e5;
	padding-top: 70px;
	padding-bottom: 135px;
}

.first-inner-box-ten {
	width: 26%;
}

.first-inner-box-p {
	font-size: 30px;
	color: #ffed00;
	margin: 0;
	padding-bottom: 25px;
}

.two-inner-box-p {
	font-size: 18px;
	color: white;
	margin: 0;
	line-height: 2;
}

.pad-ten-box {
	padding-bottom: 35px;
}

.about-nav {
list-style-type: none;
padding: 0;
margin-top: 0;
	a {
		font-size: 18px;
		color: white;
		text-decoration: none;
		display: inline-block;
		margin-bottom: 25px;
	}
	a:hover {
		color: #ffed00;
	}
}

.inner-ten-box {
	width: 58%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

 .twit-icon {
 	display: inline-block;
	width: 28px;
	height: 23px;
	background: url("/images/landing/twitter.png") no-repeat center center;
	float: left;
    margin-top: 8px;
}

.margin-icon {
	margin-left: 58px;
}

//footer 

.footer {
	height: auto;
	background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
	background-size: 115% 115%;
	padding: 30px 100px 30px 15px;
}

.inner-footer {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

.soc-icon-footer {
	display: inline-block;
	width: 64px;
	height: 64px;
}

.gplus-brand, .twitter-brand, .linkedin-brand, .pinterest-brand {
	margin-right: 5px;
}

.gplus-brand {background: url("/images/landing/gplus-brand.png") no-repeat center center;}
.twitter-brand {background: url("/images/landing/twitter-brand.png") no-repeat center center;}
.linkedin-brand {background: url("/images/landing/linkedin-brand.png") no-repeat center center;}
.pinterest-brand {background: url("/images/landing/pinterest-brand.png") no-repeat center center;}
.youtube-brand {background: url("/images/landing/youtube-brand.png") no-repeat center center;}

.footer-div {
	display: flex;
    align-items: center;

    img {
    	margin-right: 15px;
    }
}

.link-footer {
	font-size: 24px;
	color: #4166e5;
	text-decoration: none;
}

.right-link-footer {
	margin-right: 115px;
}

.slick-slide img {
	margin: 0 auto;
}

.del-price-land {
	position: relative; 
} 

.del-price-land:before { 
	border-bottom: 2px solid red; 
	position: absolute; 
	content: ""; 
	width: 100%; 
	height: 50%; 
	-webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.del-price-land:after { 
	border-bottom: 2px solid red; 
	position: absolute; 
	content: ""; 
	width: 100%; 
	height: 50%;
	-webkit-transform: rotate(-172deg);
    -moz-transform: rotate(-172deg);
    -o-transform: rotate(-172deg);
    -ms-transform: rotate(-172deg);
    transform: rotate(-172deg);
	left: 0;
	bottom: 0;
}

.slick-dots li {
	display: inline-block;
    width: 100px !important;
    height: 13px !important;
    background: #568da4;
    cursor: pointer;
    margin-right: 35px !important;
    margin-left: 0 !important;

    button {
    	display: none !important;
    }
}

.slick-dots li.slick-active {
	display: inline-block;
    width: 100px;
    height: 13px;
    background: #ff7800;
}

.slick-list {
	padding-bottom: 40px !important;
}

.slick-dotted.slick-slider {
	margin-bottom: 50px !important;
}


////////////////////////////////////////////////////////////

.slider-box {
	width: 100%;
}

.container-for-second-slider .five-section-first-p {
	padding: 10px 0 0 0;
}

.head-right-nav li a {
	font-size: 16px;
	padding: 3px 28px;
}

.pos-relative {
	position: relative;
} 

.box-checkbox {
	display: flex;
    flex-direction: column;

    label {
    	font-size: 20px;
    	color: #1a397f;
    	display: flex;
    	align-items: flex-end;
    	margin-right: 35px;
    	margin-bottom: 15px;
    }
    input[type=checkbox] {
    	display: none;
    }
}

.checkbox-style {
	display: inline-block;
	width: 23px;
	height: 24px;
	background: url("/images/landing/check.png") no-repeat left bottom;
	margin-right: 15px;
	background-size: 100% 100%;
}

input[type=checkbox]:checked + .checkbox-style {
	background: url("/images/landing/check-active.png") no-repeat left bottom;
	margin-right: 15px;
	width: 31px;
	height: 28px;
	background-size: 100% 100%;
}


.outer-box-checkbox {
	display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.txt-align {
	text-align: center;
}

.nav-landing {
	display: flex;
	justify-content: space-between;
.main-logo {
		margin: 0;
		padding: 0;
		padding-top: 5px;
		padding-left: 10px;
		float: left;

		img {
			width: 250px;
			max-width: 100%;
		}
	}
}

.main-nav-box {
	display: flex;
	position: relative;
}

.main-login {
	font-size: 18px;
	color: white;
	background: #ff8227;
	text-decoration: none;
	width: 129px;
	height: 32px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background-color: #ff8227;
	text-align: center;
    padding-top: 5px;
    margin-left: 10px;
}

.main-login:hover {
	background: #009fe3;
}

.main-img-search {
	width: 32px;
	height: 31px;
	margin-right: 0px;
	margin-left: 0px;
	background: url("/images/landing/scale.png") no-repeat center center;
}

.main-img-search:hover {
	background: url("/images/landing/scale_hover.png") no-repeat center center;
}

.block-search {
	display: flex;
	align-items: center;
}

.main-content-landing {
	padding-top: 38px;
	width: 100%;
	padding-bottom: 150px;
	background: url("/images/landing/bcg_1.png") no-repeat center 15px;
	background-size: 100% 100%;
}

.inner-main-content-landing {
	display: flex;
	justify-content: center;
}

.pin-container {
	margin-left: 70px;
	text-align: center;

	img {
		width: 40%;
	}
}    

.pin-text {
	display: flex;
    flex-direction: column;
	font-family: Oduda;
	font-size: 28px;
	font-weight: 900;
	color: #ff8227;

	p {
		margin: 0;
		align-self: center;
		padding-bottom: 20px;
	}
}

.inner-text-pin {
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 900;
	color: #1a397f;
	// padding-left: 8%;
	padding-top: 25px;
   	width: 50%;
}

.margin-div {
	margin-left: 45px;
}

.input-box-pin {
	display: flex;
	justify-content: center;

	p {
		font-family: "Open Sans";
		font-size: 15px;
		font-weight: 900;
		color: #ff8227;
		margin-top: 45px;
    	margin-bottom: 10px;
    	padding: 5px;
	}

	.get-started {
		display: block;
		min-width: 280px;
		width: 280px;
		font-family: Oduda;
		font-size: 18px;
		font-weight: 600;
		color: #ff8227;
		margin-left: 0px;
		border: none;
		height: 50px;
		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		box-shadow: 0 5px 3px rgba(4,140,222,.75);
		border-radius: 20px;
		background: url("/images/landing/bgc_btn.png") no-repeat;
		outline: none;
		cursor: pointer;
		text-align: center;
    	padding-top: 15px;
	}

	input {
		height: 50px;
		font-family: Oduda;
		font-size: 18px;
		font-weight: 900;
		color: #015f99;
		background-color: #86f4ff;
		border-radius: 20px;
		border: none;
		outline: none;
		padding-left: 30px;
		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
		box-shadow: 0 5px 3px rgba(4,140,222,.75);
		padding-right: 90px;
		max-width: 500px;
		text-align: center;
	}
}

.flex {
	display: flex;
	flex-wrap: wrap;
}

.flex-sub {
	flex-direction: column;
    align-items: center;
}

.flex-outer {
	display: flex;
    justify-content: center;
}

.main-submit {
	position: absolute;
    top: 0;
    right: 0;
	font-family: Oduda;
	width: 70px;
	font-size: 14px;
	font-weight: 600;
	color: white;
	border: none;
	height: 50px;
	border-radius: 0 20px 20px 0;
	background: url("/images/landing/widget_btn.png") no-repeat;
	background-size: 100% 100%;
	outline: none;
	cursor: pointer;
}

// planshet-landing

.planshet-landing {
	background: #0f86e8;
	padding-bottom: 40px;
}

.inner-img-planshet {
	text-align: center;
	// display: flex;
	// justify-content: center;
	padding-top: 15px;
	img {
		width: 50%;
		height: auto;
		display: block;
		margin: 0 auto;
	}
}

.title-planshet-landing {
	// display: flex;
	background: #0873d7;
	margin-top: 15px;
	padding: 0 0 15px 0;
}

.inner-title-section-planshet {
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.left-title-planshet {
	width: 100%;
}

.inner-left-title-planshet {
	display: flex;
	width: 40%;
	justify-content: space-between;
	img {
		max-width: 100%;
	}
	p {
		margin: 0;
		font-size: 14px;
		color: white;
		width: 70%;
		padding-top: 10px;
	}
}

.inner-width-planshet {
	width: 25%;
    // display: flex;  // IE
    // flex-direction: column;
    // align-items: flex-end;
}

.inner-title-section-planshet {
	.inner-left-title-planshet:first-child {
		padding-right: 2%;
	}
	.inner-left-title-planshet:last-child {
		padding-left: 2%;
	}
}

.contact-us {
	color: #a4e8ff;
}

//three-section

.three-section {
	background: #6bdcff;
}

.three-section-h1 {
	font-size: 36px;
	color: #014ca9;
	font-weight: 600;
	text-align: center;
	padding-top: 75px;
	padding-bottom: 45px;
	margin: 0;
}

.three-section-h2 {
	font-size: 24px;
	color: #014ca9;
	font-weight: 400;
	text-align: center;
	margin: 0;
	padding-bottom: 70px;
}

.box-img-three {
	padding: 20px 60px;
	background: url("/images/landing/bcg-item.png") no-repeat center center;
	background-size: 100% 100%;
	width: 36%;
	min-width: 300px;
	// max-width: 300px;
	padding-bottom: 5px;

	ul {
		font-size: 14px;
		color: #1a397f;
		font-weight: 400;
		list-style-type: none;
		list-style-image: url("/images/landing/style-type.png");
	}
}

.inner-box-img-three {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 88px;

	img {
		width: 5.57vw;
    	max-width: 2.5vw;
    	min-width: 85px;
    	border-radius: 25%;
		-webkit-border-radius: 21px/19px;
		-moz-border-radius: 21px/19px;
		border-radius: 21px/19px;
		-webkit-box-shadow: 0 0 16px rgba(0,0,0,.75);
		-moz-box-shadow: 0 0 16px rgba(0,0,0,.75);
		box-shadow: 0 0 16px rgba(0,0,0,.75);
	}

	p {
		font-size: 19px;
		color: #014687;
		font-weight: 800;
		margin: 0;
		padding-left: 15px;
	}
}

.container-three {
	display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .box-img-three:first-child {
		margin-right: 3%;	
		margin-bottom: 10px;
    }
    .box-img-three:last-child {
		margin-left: 3%;	
		margin-bottom: 10px;
    }
}

.btn-more-topic {
	width: 20%;
	background: url("/images/landing/btn.png") no-repeat center center;
	background-size: 100% 100%;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 17px;
	color: #014687;
	font-weight: 600;
	padding: 15px 0px;
	margin-top: 30px;
	margin-bottom: 130px;
	display: inline-block;
	text-decoration: none;
}

.btn-more-topic:active {
	background: url("/images/landing/btn-pressed.png") no-repeat center center;
	width: 20%;
	background-size: 100% 100%;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 17px;
	color: #014687;
	font-weight: 600;
	padding: 15px 0px;
	margin-top: 30px;
	margin-bottom: 130px;
	display: inline-block;
	text-decoration: none;
}

//four section

.four-section {
	background: #22caff;
}

.column-four {
	display: flex;
    justify-content: center;

    .inner-column:first-child {
		padding-right: 2%;
    }
    .inner-column:last-child {
		padding-left: 2%;
    }
}    

.inner-column {
	width: 30%;
	
	p {
		text-align: center;
	}

	a {
		display: inline-block;
		background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 20px;
		color: white;
		text-decoration: none;
		padding: 10px 0;
		width: 100%;
		font-size: 18px;
		font-weight: 900;
		background-size: 5%;
	}
	a:hover {
		display: inline-block;
		background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 20px;
		color: white;
		text-decoration: none;
		padding: 10px 0;
		width: 100%;
		font-size: 18px;
		font-weight: 900;
		background-size: 5%;
	}
}

.btn-four {
	margin-bottom: 70px;
}

.btn-four:active {
	margin-bottom: 70px;
}

//five section 

.five-section {
	padding-bottom: 30px;
	background: #ffffff;
}

.outer-five-section {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.inner-five-section {
	width: 57%;
	display: flex;
	align-self: center;
}

.bold {
	font-weight: 800 !important;
}

.five-section-first-p {
	font-size: 16px;
	color: #1a397f;
	font-weight: 600;
	margin: 0;
}

.five-section-two-p {
	font-size: 16px;
	color: #1a397f;
	font-weight: 800;
	margin: 45px 0 10px 0;
}

.five-section-three-p {
	font-size: 14px;
	color: #1a397f;
	font-weight: 600;
	margin: 0;
}

.pagination {
	margin: 45px 0;
	display: flex;
    justify-content: center;
	.active-pagin {
		display: inline-block;
		width: 100px;
		height: 13px;
		background: #ff7800;
	}
	span {
		display: inline-block;
		width: 100px;
		height: 13px;
		background: #568da4;
		cursor: pointer;
		margin-right: 35px;
	}
	span:last-child {
		margin-right: 0;
	}
}

.five-section-img {
	max-width: 221px;
	max-height: 208px;
	min-width: 115px;
	width: 50%;
	margin-right: 25px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	background-color: #fff;
	-webkit-box-shadow: 1px 1px 2px rgba(0,0,0,.75);
	-moz-box-shadow: 1px 1px 2px rgba(0,0,0,.75);
	box-shadow: 1px 1px 2px rgba(0,0,0,.75);
	border: solid 1px #c8edfb;
}

.five-section-h1 {
	padding-top: 0;
	padding-bottom: 80px;
}

.two-slider-outer {
	display: flex;
    justify-content: center;
}

.inner-two-slider {
	display: flex;
	width: 57%;
	justify-content: space-between;
}

.item-slider {
	width: 20%;
}

//six section

.six-section {
	background-color: #b3f4fe;
	background-image: -webkit-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -moz-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -o-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: -ms-linear-gradient(top, #4fc7fe, #d0f8fe);
	background-image: linear-gradient(to bottom, #4fc7fe, #d0f8fe);
}

.inner-six-section {
	display: flex;
}

.six-left-sidebar {
	width: 18%;
	position: relative;
	text-align: center;
	img {
		max-width: 100%;
    	padding-top: 170px;
    	padding-left: 10px;
    	padding-right: 10px;
	}
	p {
		position: absolute;
    	font-size: 30px;
    	font-family: Oduda;
    	top: 32%;
    	margin: 0;
    	left: 0;
    	color: #005c83;
    	font-weight: 800;
    	text-align: center;
	}
	.pos-rel {
		position: relative;
	}
	// background: url("/images/landing/ball.png") no-repeat center 170px;
	// background-size: 85%;
}

.center-sidebar {
	width: 64%;
	background: #b3f4fe;

	h1 {
		font-size: 36px;
		font-weight: 600;
		color: #005c83;
		text-align: center;
		margin-top: 80px;
		margin-bottom: 30px;

	}
	h2 {
		font-size: 24px;
		font-weight: 400;
		color: #005c83;
		text-align: center;
		margin-top: 0;
		margin-bottom: 90px;
	}
	.parent-box {
		padding: 45px 20px;
		
		.child-profile {
			text-align: left;
			font-size: 15px;
		}
		.price-text {
			font-size: 20px;
			margin-bottom: 0;
    		margin-top: 50px;
		}
		.del-price-land {
			font-size: 15px;
		}
	}
}
.del-price-land:before {height: 35%;}

.six-right-sidebar {width: 18%;}

.inner-center-sidebar {
	width: 21%;
	img {
		max-width: 100%;
	}
	.parent-box {
		margin-bottom: 80px;
		margin-top: 30px;
	}
}

.child-profile-price {
	text-align: center !important;
}

.head-btn {
	display: inline-block;
	font-size: 24px;
	color: #ffffff;
	background: #009fe3;
	text-decoration: none;
	padding: 10px 0;
	width: 100%;
	text-align: center;
	margin-bottom: 45px;
	cursor: pointer;
	font-weight: 600;
}

.footer-btn {
	display: inline-block;
	font-size: 24px;
	font-weight: 600;
	color: #ffffff;
	background: #009fe3;
	text-decoration: none;
	padding: 10px 0;
	width: 100%;
	text-align: center;
	margin-bottom: 95px;
	cursor: pointer;
}

.head-btn:hover, .footer-btn:hover {
	background: #ff8227;	
}

.flex-center {
	display: flex;
    justify-content: center;

    .inner-center-sidebar {
		margin-right: 5%;
    }
    .inner-center-sidebar:last-child {
		margin-right: 0;
    }
}

// seven section

.seven-section {
	padding-bottom: 50px;
	background: white;
}

.seven-section-h1 {
	font-size: 36px;
	font-weight: 600;
	text-align: center;
	margin: 0;
	padding: 75px 0 30px 0;
	color: #00539a;
}

.seven-section-p {
	font-size: 24px;
	font-weight: 600;
	color: #0063b7;
	margin: 0;
	padding-bottom: 30px;
	padding-left: 200px;
    text-align: left;

	a {
		font-size: 18px;
		color: #009fe3;
		padding-left: 35px;
	}
}

.slider-box {
	display: flex;
    justify-content: center;
}

.inner-slider-box {
	// display: flex;
    // justify-content: center;
    width: 80%;
    position: relative;
}


.box-sliders {
	display: flex;
    justify-content: center;
	// .slider-item {
	// 	padding-right: 2%;
	// }
	.slider-item:last-child {
	padding-right: 0;
  }
}

.left-slide-nav {
	position: absolute;
	top: calc(50% - 18.5px);
	left: -47px;
	width: 21px;
	height: 37px;
	background: url("/images/landing/left-arrow.png") no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
}

.left-slide-nav:hover {
	background: url("/images/landing/left-arrow-hover.png") no-repeat;
	background-size: 100% 100%;
	top: calc(50% - 18.5px);
	left: -47px;
	width: 21px;
	height: 37px;
}

.right-slide-nav {
	position: absolute;
	top: calc(50% - 18.5px);
	right: -47px;
	width: 21px;
	height: 37px;
	background: url("/images/landing/right-arrow.png") no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
}

.right-slide-nav:hover {
	background: url("/images/landing/right-arrow-hover.png") no-repeat;
	background-size: 100% 100%;
	top: calc(50% - 18.5px);
	right: -47px;
	width: 21px;
	height: 37px;
}

.two-slider {
	margin-top: 95px;
}

// eight section 

.eight-section {
	padding-bottom: 30px;
	background: #e6fcff;
}

.eight-section-h1 {
	font-size: 36px;
	font-weight: 600;
	color: #00539a;
	margin: 0;
	padding-top: 85px;
	text-align: center;
}

.eight-section-p {
	font-size: 24px;
	font-weight: 600;
	color: #0048b5;
	margin: 0;
	padding-top: 65px;
}

.eight-section-two-p {
	font-size: 15px;
	font-weight: 400;
	color: #4f5050;
	margin: 0;
	padding: 0 0 20px 0;
}

.link-blog {
	font-size: 15px;
	color: #0f86e8;
	display: inline-block;
}

.blog-box {
	width: 75%;
    margin: 0 auto;
}

.img-box {
	align-self: flex-start;
	width: 40%;
}

.title-blog {
	display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-left: 5%;
}

.outer-titel {
	display: flex;
}

.date-p {
	padding-top: 15px;
}

//nine section 

.nine-section {
	padding-bottom: 150px;
	background: #0f86e8;
}

.about-h1 {
	font-size: 36px;
	color: #ffed00;
	margin: 0;
	padding: 80px 0 10px 0;
	text-align: center;
}

.about-box {
	display: flex;
	align-items: center;
	width: 75%;
	margin: 0 auto;
}

.about-p {
	font-size: 16px;
	color: #ffffff;
	margin: 0;
	padding-left: 40px;
}

// ten section 

.ten-section {
	background: #4663e5;
	padding-top: 35px;
	padding-bottom: 35px;
}

.first-inner-box-ten {
	width: 26%;
}

.first-inner-box-p {
	font-size: 20px;
	color: #ffed00;
	margin: 0;
	padding-bottom: 25px;
}

.two-inner-box-p {
	font-size: 14px;
	color: white;
	margin: 0;
	line-height: 2;
}

.pad-ten-box {
	padding-bottom: 35px;
}

.about-nav {
list-style-type: none;
padding: 0;
	a {
		font-size: 14px;
		color: white;
		text-decoration: none;
		display: inline-block;
		margin-bottom: 25px;
	}
	a:hover {
		color: #ffed00;
	}
}

.inner-ten-box {
	width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

 .twit-icon {
 	display: inline-block;
	width: 28px;
	height: 23px;
	background: url("/images/landing/twitter.png") no-repeat center center;
	float: left;
    margin-top: 8px;
}

.margin-icon {
	margin-left: 58px;
}

//footer 

.footer {
	// flex: 0 0 auto;
	height: auto;
	background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
	background-size: 115% 115%;
	padding: 5px 15px 5px 15px;
}

.inner-footer {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

.soc-icon-footer {
	display: inline-block;
	width: 32px;
	height: 32px;
	background-size: 100% 100%;
}

.gplus-brand, .twitter-brand, .linkedin-brand, .pinterest-brand {
	margin-right: 5px;
}

.footer-div {
	display: flex;
    align-items: center;

    img {
    	width: 50%;
    	margin-right: 15px;
    }
}

.link-footer {
	font-size: 14px;
	color: #4166e5;
	text-decoration: none;
}

.right-link-footer {
	margin-right: 115px;
}

.slick-slide img {
	margin: 0 auto;
}

.about-img {
	width: 163px;
}

.test1 {
	width: 115px;
	display: block;
}

.test2 {
	width: calc(100% - 115px);
    display: block;
}

.adaptive-container-show {
	display: flex !important;
}
	.adaptive-show {
	display: block !important;
}

.disp-mobile {
	display: none;
}

.disp-desck {
	display: block;
}