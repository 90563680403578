.center-block-payment {
	width: 100%;
    min-width: 240px;
    max-width: 400px;
    margin: 0 auto;
    padding-bottom: 200px;
}

.title-card {
	font-family: 'Open Sans', serif;
	font-size: 18px;
	color: #000;
	font-weight: 700;
}

.bot-icon-pay {
	text-align: center;
	padding-top: 25px;
	div {
		font-family: 'Open Sans', serif;
		font-size: 14px;
		color: #006;
		font-weight: 600;
		padding-top: 5px;
	}
}

.btn-sub-pay {
	display: block;
	font-family: 'Open Sans', serif;
	font-size: 20px;
	color: #fff;
	font-weight: 600;
	margin: 0 auto;
	padding: 5px 20px;
	border: none;
	background: #009fe3;
	cursor: pointer;
}
.inner-blog-box p._pay-cont {
	margin-top: 15px;
	text-align: center;
}
._btn-sub {
	display: inline-block;
	font-family: 'Open Sans', serif;
	font-size: 20px;
	color: #fff;
	font-weight: 600;
	margin: 0 auto;
	padding: 5px 20px;
	border: none;
	background: #009fe3;
	cursor: pointer;
	text-decoration: none;
}
._btn-s {
	width: 240px;
}
.ladda-button .ladda-label {
	min-width: 230px;
}
// .ladda-button[data-style=expand-right] .ladda-spinner {
//     opacity: 0;
// }
// .ladda-button[data-style=expand-right][data-loading] .ladda-spinner {
//     opacity: 1;
// }
.btn-sub-pay._btn-s.ladda-button:focus,
.btn-sub-pay._btn-s.ladda-button:visited,
.btn-sub-pay._btn-s.ladda-button:active {
	outline: none;
}
.hosted-field {
	height: 25px;
    width: 200px;
    background: #e9fdff;
    color: #1294cc;
}

.container-pay {
	display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    align-items: flex-start;
}

.container-pay-card {
	display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: flex-end;
}

.inner-cont-pay {
	width: 100%;
    display: flex;
    justify-content: space-between;
}

.error-field {
	font-family: 'Open Sans';
	font-size: 16px;
	color: #c00;
	font-weight: 600;
}

@media (max-width: 460px) {
	.container-pay {
	    flex-direction: column; 
	    align-items: center;
	}
	.hosted-field {
		margin: 10px 0;
	}
	.container-pay-card {
		align-items: center;
	}
	.inner-cont-pay {
		flex-direction: column;
    	align-items: center;
    	padding-top: 15px;
	}
   
}