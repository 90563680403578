.main-reset {
	background: #fff;
	height: 500px;
	margin-bottom: 120px;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);
}

.reset-title {
	background: #f96908;
	margin: 0; 
}

.center-block-reset {
	width: 300px;
	min-width: 300px;
	margin: 0 auto;
}

.inner-reset-title {
	display: block;
	text-align: center;
	color: #fff;
	font-size: 18px;
	padding: 10px 0;
	font-weight: 700;
}

.reset-provide {
	color: #000;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	padding: 10px 0;
}

.reset-input-title {
	color: #000;
	font-size: 16px;
	font-weight: 700;
	margin: 0;
	padding: 10px 0;

}

.reset-input {
	width: 250px;
	background: #e9fdff;
	color: #000;
	border: none;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	outline-color: rgba(204, 0, 0, 0.8);
	margin: 0 0 15px 0;
}

.alert-reset {
	color: #cc0000;
	font-size: 16px;
	font-weight: 700;
	margin: 0 0 15px 0;

	a {
		color: #cc0000;
	}
}

.btn-reset {
	display: block;
	width: 250px;
	color: #fff;
	text-decoration: none;
	background: #009fe3;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 15px;
	text-align: center;
}

.btn-reset:hover {
	background: #f96908;
}

.support-staff {
	color: #cc0000;
	font-size: 14px;
	font-weight: 600;

	a {
		color: #cc0000;
	}
}