@charset "UTF-8";
@import url(/plugins/normalize.css);
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800';
@font-face {
  font-family: Oduda;
  /* Имя шрифта */
  src: url(/fonts/Oduda-Bold-Demo.otf);
  /* Путь к файлу со шрифтом */
  src: url("/fonts/oduda-bold-demo-webfont.eot");
  src: url("/fonts/oduda-bold-demo-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/oduda-bold-demo-webfont.woff2") format("woff2"), url("/fonts/oduda-bold-demo-webfont.woff") format("woff"), url("/fonts/oduda-bold-demo-webfont.ttf") format("truetype"), url("/fonts/oduda-bold-demo-webfont.svg#Oduda") format("svg"); }

* {
  box-sizing: border-box; }

html {
  height: auto;
  overflow-y: auto !important; }

body {
  height: auto;
  font-family: 'Open Sans', sans-serif; }

._main {
  min-height: 100vh;
  flex-grow: 1; }

.inner-content._pay-access {
  max-width: 77%;
  margin: 3vh auto; }

._main._pay-access-main {
  min-height: auto; }

/*show*/
.two-slider-outer.csh {
  height: auto; }

/*hide*/
.two-slider-outer.ch {
  height: 0px;
  overflow: hidden; }

._my-subscription-cont {
  max-height: 183px;
  overflow-y: auto; }

._cansel-p-cont {
  max-height: 278px;
  overflow-y: auto; }
  ._cansel-p-cont .cancel-p {
    margin: 10px 0; }

.active-tab {
  background-color: #ff8227 !important;
  color: #fff !important; }

.active-tab a {
  border-right: 3px solid transparent !important; }

.flow {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto; }

.w-foot {
  height: 50px; }

.marg-bot-vh {
  padding-bottom: 100px; }

.main-content {
  flex: 1 0 auto;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }

.ngdialog-close {
  display: none; }

label {
  cursor: pointer; }

.ngdialog.popup-child-sign.ngdialog-theme-default .ngdialog-content,
.ngdialog.popup-choose-sign-in.ngdialog-theme-default .ngdialog-content {
  width: 100%;
  background: transparent; }

.ngdialog.popup-child-sign.ngdialog-theme-default.ng-scope,
.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope,
.ngdialog.popup-choose-sign-in.ngdialog-theme-default.ng-scope {
  padding-bottom: 0;
  padding-top: 60px;
  overflow-y: scroll;
  margin-right: -20px; }

.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope {
  padding-bottom: 0;
  padding-top: 20px;
  overflow-y: scroll;
  margin-right: -20px; }

.logo {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-left: 0%;
  float: left; }

.main-nav {
  position: relative;
  height: 38px;
  background-color: #0ec3fc;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  border: none;
  text-align: right; }

.devider-line {
  height: 45px;
  width: 2px;
  border-right: 2px solid #e1e1e1;
  position: absolute;
  top: 9px;
  padding: 0;
  margin: 0;
  margin-left: -1px; }

.head-right-nav {
  font-size: 0;
  padding-left: 0;
  list-style-type: none;
  background: #009fe3;
  float: right;
  margin-bottom: 30px;
  min-height: 64px; }
  .head-right-nav li {
    display: inline-block;
    margin-left: -1px;
    margin-right: -2px; }
  .head-right-nav li:last-child {
    margin-right: 0; }
  .head-right-nav li:first-child a {
    border-left: none; }
  .head-right-nav li:last-child a {
    border-right: none; }
  .head-right-nav li a {
    display: inline-block;
    padding: 10px;
    color: white;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    margin-top: 9px;
    margin-bottom: 9px;
    border-right: 3px solid #e1e1e1;
    padding-left: 30px;
    padding-right: 30px; }
  .head-right-nav li:hover {
    background: #ff8227; }
  .head-right-nav li:hover a {
    border-color: transparent; }

#app-layout {
  background: url("/images/background.png") no-repeat;
  background-size: cover; }

.main-content {
  width: 100%;
  display: flex; }

.user-info {
  float: right;
  width: 60%; }

.inner-content {
  width: 64%;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  background: #b3f4fe;
  padding-top: 35px;
  padding-right: 4%;
  padding-left: 4%;
  order: 2;
  flex-grow: 3; }

.inner-h1 {
  font-size: 48px;
  text-align: center;
  font-weight: 900;
  color: #005c83;
  padding-bottom: 65px; }

.avatar {
  width: 30%;
  float: left; }
  .avatar img {
    width: 100%;
    border-radius: 50%; }

.avatar-register {
  text-align: center; }
  .avatar-register img {
    border-radius: 0; }

.padding-label {
  padding-right: 43px; }

.label-style {
  font-size: 30px;
  font-weight: 900;
  color: #005c83;
  padding-top: 45px;
  padding-bottom: 5px; }

.label-style:first-child {
  padding-top: 0; }

.input-style {
  display: block;
  font-size: 24px;
  padding: 9.5px;
  border: none;
  width: 100%; }

.alert {
  color: #ff0000;
  font-size: 24px;
  font-weight: normal;
  padding-left: 0;
  padding-bottom: 0; }

.pass-info {
  font-size: 24px;
  font-weight: normal;
  padding-left: 0;
  padding-bottom: 0; }

.title-label {
  font-size: 30px;
  padding-bottom: 65px;
  color: #005c83;
  font-weight: 900;
  display: inline-block; }

.title-block {
  font-size: 30px;
  padding: 80px 0;
  color: #005c83;
  font-weight: 900; }

.sign-up {
  display: block;
  background: #ff8227;
  color: white;
  padding: 5px 20%;
  margin-bottom: 120px;
  font-size: 30px;
  border: none;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  text-align: center; }

.sign-up:hover {
  background: #009fe3;
  color: white;
  outline: none; }

.label-radio {
  margin-left: 20px;
  display: inline-block;
  height: 50px;
  width: 65px;
  background: white;
  font-size: 21px;
  padding-top: 9px;
  text-align: center; }

.radio-check {
  margin-left: 30px !important; }

label {
  display: inline-block;
  position: relative; }

.custom-radio {
  position: absolute;
  display: block;
  width: 39px;
  height: 39px;
  background: url("/images/radio.png");
  top: 5px;
  left: 15px; }

label > input[type=checkbox]:checked + span {
  background: url("/images/radio_checked.png"); }

label > input[type=radio]:checked + span {
  background: url("/images/radio_checked.png"); }

.right-sidebar {
  width: 18%;
  flex-wrap: wrap;
  order: 3;
  flex-grow: 1; }

.socio-box {
  padding-left: 10px; }

.right-sicio-img {
  float: left;
  display: inline-block;
  width: 30%;
  min-width: 85px;
  height: 37px;
  background: #6477a4;
  text-decoration: none;
  color: white;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px; }

.right-sicio-like {
  float: left;
  display: flex;
  width: 30%;
  min-width: 85px;
  height: 37px;
  background: #6477a4;
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
  margin-right: 5px; }

.right-sicio-tweet {
  display: flex;
  width: 30%;
  min-width: 85px;
  height: 37px;
  background: #00aced;
  text-decoration: none;
  color: white;
  text-align: center;
  margin-bottom: 10px; }

.inner-soci-span {
  padding-top: 10px;
  padding-left: 0px; }

.icon-socio-like {
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url("/images/like.png") no-repeat;
  margin-left: 5px;
  margin-right: 5px; }

.icon-socio-tweet {
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url("/images/twitter.png") no-repeat;
  margin-left: 0px; }

.ngdialog.popup-parent-sign.ngdialog-theme-default .ngdialog-content {
  width: 100%;
  background: transparent; }

.background-popup {
  height: 1100px; }

.inner-popup {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 2300px;
  height: 1900px;
  background: url("/images/sign_parent.png") no-repeat center center;
  background-size: 100% 100%;
  transform: scale(0.4);
  top: -450px; }

.popup-h1 {
  font-family: Oduda;
  font-weight: 900;
  font-size: 120px;
  margin: 0;
  text-align: center;
  padding-top: 150px;
  color: #ff8000; }

.close-popup {
  display: block;
  width: 133px;
  height: 126px;
  background: url("/images/close.png") no-repeat;
  background-size: 100%;
  border: none;
  outline: none;
  position: absolute;
  right: 120px;
  top: 120px;
  cursor: pointer; }

.align-center {
  text-align: center; }

.soc-seti-google {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 50%;
  height: 118px;
  background: url("/images/button_google.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  margin-top: 150px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  -moz-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  border-radius: 20px;
  cursor: pointer; }

.soc-seti-google:active {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 50%;
  height: 118px;
  background: url("/images/button_google.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  margin-top: 150px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  -moz-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  border-radius: 20px;
  cursor: pointer; }

.soc-seti-face {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 50%;
  height: 118px;
  background: url("/images/button_face.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  -webkit-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  -moz-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  border-radius: 20px;
  cursor: pointer; }

.soc-seti-face:active {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 50%;
  height: 118px;
  background: url("/images/button_face.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  -webkit-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  -moz-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  border-radius: 20px;
  cursor: pointer; }

.min-soc-google {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 8%;
  height: 118px;
  background: url("/images/button_min.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  margin-top: 150px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  -moz-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(255, 249, 153, 0.52);
  border-radius: 20px;
  cursor: pointer; }

.min-soc-google:active {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 8%;
  height: 118px;
  background: url("/images/button_min.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  margin-top: 150px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  -moz-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  border-radius: 20px;
  cursor: pointer; }

.min-soc-face {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 8%;
  height: 118px;
  background: url("/images/button_min_two.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  -webkit-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  -moz-box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  box-shadow: 0 10px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -12px 9px 1px rgba(120, 164, 255, 0.98);
  border-radius: 20px;
  cursor: pointer; }

.min-soc-face:active {
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 57px;
  display: inline-block;
  width: 8%;
  height: 118px;
  background: url("/images/button_min_two.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  -webkit-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  -moz-box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  box-shadow: 0 -10px 2px 1px rgba(3, 114, 181, 0.86);
  border-radius: 20px;
  cursor: pointer; }

.popup-or {
  font-family: Oduda;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 100px;
  color: #ff8509;
  text-align: center;
  margin-bottom: 50px; }

.parent-email, .parent-pass {
  font-family: Oduda;
  font-weight: 900;
  font-size: 80px;
  padding: 50px 80px;
  color: #a14403;
  width: 50%;
  height: 199px;
  -webkit-border-radius: 100px/99px 99px 100px 100px;
  -moz-border-radius: 100px/99px 99px 100px 100px;
  border-radius: 100px/99px 99px 100px 100px;
  background-color: #ffcb04;
  -webkit-box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111, 51, 35, 0.65);
  -moz-box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111, 51, 35, 0.65);
  box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111, 51, 35, 0.65);
  border: solid 6px #7a3827;
  outline: none;
  display: block;
  margin: 0 auto; }

.parent-pass {
  margin-top: 50px; }

.parent-email::-webkit-input-placeholder,
.parent-pass::-webkit-input-placeholder {
  color: #9e4203; }

.parent-email::-moz-placeholder,
.parent-pass::-moz-placeholder {
  color: #9e4203; }

/* Firefox 19+ */
.parent-email:-moz-placeholder,
.parent-pass:-moz-placeholder {
  color: #9e4203; }

/* Firefox 18- */
.parent-email:-ms-input-placeholder,
.parent-pass:-ms-input-placeholder {
  color: #9e4203; }

.alert-popup {
  font-size: 60px;
  color: #f2090a;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 80px; }

.done-button {
  margin: 0 auto;
  display: block;
  width: 25%;
  height: 237px;
  background: url("/images/done.png") no-repeat;
  background-size: 100% 100%;
  border: none;
  outline: none;
  margin-top: 50px;
  cursor: pointer; }

.done-button:active {
  margin: 0 auto;
  display: block;
  width: 25%;
  height: 237px;
  background: url("/images/done_pressed.png") no-repeat;
  background-size: 100% 100%;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 50px; }

.policy {
  text-align: center;
  margin-top: 155px; }
  .policy a {
    font-size: 56px;
    color: #910303;
    text-decoration: none;
    font-weight: 900; }

.forgot-pass a, .new-user a {
  font-size: 48px;
  color: #910303;
  text-decoration: underline;
  font-weight: 900; }

.forgot-pass {
  float: left;
  margin-left: 80px; }

.new-user {
  float: right;
  margin-right: 80px; }

.background-popup-student {
  height: 750px; }

.inner-popup-student {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 2300px;
  height: 1400px;
  background: url("/images/sign_student.png") no-repeat center center;
  background-size: 100% 100%;
  transform: scale(0.4);
  top: -350px; }

.new-user-student {
  text-align: center;
  margin-top: 40px; }
  .new-user-student a {
    font-size: 56px;
    color: #910303;
    text-decoration: underline;
    font-weight: 900; }

.popup-h1-student {
  font-family: Oduda;
  font-weight: 900;
  font-size: 120px;
  margin: 0;
  text-align: center;
  padding-top: 240px;
  margin-bottom: 170px;
  color: #ff8000; }

.alert-popup-student {
  font-size: 60px;
  color: #f2090a;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px; }

.form-add-child {
  width: 65%;
  margin: 0 auto; }

.add-student-page {
  display: flex;
  justify-content: center;
  padding-bottom: 80px; }

.select-one {
  margin-right: 20%; }
  .select-one img {
    border-radius: 50%; }

.select-two img {
  border-radius: 50%; }

.under-user {
  display: block;
  margin-top: 30px;
  text-align: center;
  padding-right: 20px; }
  .under-user input[type="radio"] {
    opacity: 0; }

.custom-radio-add {
  position: absolute;
  display: block;
  width: 39px;
  height: 39px;
  background: url("/images/radio.png");
  top: -5px;
  left: 40px; }

.label-add {
  font-size: 26px;
  color: #0085be;
  padding-left: 20px; }

.inner-block-add-input {
  margin: 0 auto; }

.add-student-button {
  margin-top: 80px;
  float: right; }

.block-sales {
  margin: 0 auto;
  background: #009fe3;
  padding: 10px;
  margin-top: 220px;
  width: 80%; }

.our-deal, .inner-text-right-sidebar, .save-procent {
  text-align: center; }

.our-deal {
  font-size: 30px;
  color: #feec00;
  margin-bottom: 20px; }

.inner-text-right-sidebar {
  font-size: 24px;
  color: white;
  margin-bottom: 20px; }

.save-procent {
  font-size: 41px;
  color: #feec00;
  margin-bottom: 20px;
  margin-top: 20px; }

.parent-box {
  background: white;
  padding: 20px;
  margin-bottom: 50px;
  padding-bottom: 50px; }
  .parent-box img {
    width: 100%;
    border-radius: 50%; }

.child-profile {
  font-size: 26px;
  color: #4f5050;
  text-align: center; }

.price-text {
  font-family: Oduda;
  font-size: 28px;
  color: #ff8227;
  text-align: center; }

.buy {
  display: inline-block;
  color: white;
  background: #009fe3;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 30px;
  font-weight: 800; }

.buy:hover {
  display: inline-block;
  color: white;
  background: #ff8227;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  cursor: pointer; }

.del-price {
  position: relative; }

.del-price:before {
  border-bottom: 3px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg); }

.del-price:after {
  border-bottom: 3px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  -webkit-transform: rotate(-172deg);
  -moz-transform: rotate(-172deg);
  -o-transform: rotate(-172deg);
  -ms-transform: rotate(-172deg);
  transform: rotate(-172deg);
  left: 0;
  bottom: 0; }

.left-sidebar {
  flex-grow: 1;
  width: 18%;
  order: 1; }

.nav-profile {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 75px; }

.profile-button {
  font-size: 24px;
  font-weight: 900;
  display: inline-block;
  width: 30%;
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0; }

.profile-button:hover {
  font-size: 24px;
  font-weight: 900;
  display: inline-block;
  width: 30%;
  color: white;
  background: #ff8227;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0; }

.inner-block-input-profile {
  width: 55%;
  margin: 0 auto; }

.change-profile {
  padding: 10px 0 0 0;
  margin: 0;
  text-align: right;
  font-size: 21px;
  color: #4f5050; }

.inner-block-input-student {
  width: 55%;
  margin: 0 auto;
  margin-bottom: 100px; }

.new-student-button {
  font-size: 24px;
  font-weight: 900;
  margin: 0 auto;
  float: none;
  margin-bottom: 50px;
  width: 55%;
  padding: 10px; }

.add-student-input {
  padding-bottom: 10px; }

.manage-purchases {
  width: 100%; }
  .manage-purchases .inner-h1-manage {
    font-size: 48px;
    font-weight: 900;
    text-align: center;
    color: #005c83;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 50px; }
  .manage-purchases .title-manage {
    padding-left: 70px;
    font-weight: 900;
    font-size: 30px;
    color: #005c83;
    background: #78dfff;
    margin-bottom: 35px; }

.outer-manage-block {
  padding: 0px 10px 10px 10px;
  background: #e9fdff;
  overflow-y: auto;
  overflow-x: auto; }
  .outer-manage-block .table-div-box:nth-child(odd) {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .outer-manage-block .table-div-box:nth-child(even) {
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .outer-manage-block .table-div-box:last-child {
    border-bottom: none; }

.inner-line-manage {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap; }

.inner-two-line-manage {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap; }

.inner-line-manage, .inner-two-line-manage {
  border-bottom: 2px solid #78dfff; }

.inner-flex {
  display: flex; }

.p-inner-manage {
  font-size: 30px;
  color: #474747;
  padding-left: 20px;
  font-weight: 600; }

.button-inner-line {
  align-self: center;
  font-size: 24px;
  height: 75px;
  font-weight: 900;
  display: inline-block;
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 80px; }

.button-inner-line:hover {
  font-size: 24px;
  height: 75px;
  font-weight: 900;
  display: inline-block;
  color: white;
  background: #ff8227;
  border: none;
  outline: none;
  cursor: pointer; }

.buy-manage {
  width: 35%;
  margin: 50px 0 100px 0; }

.buy-manage:hover {
  width: 35%; }

.button-buy-manage {
  display: flex;
  justify-content: center; }

.inner-h1-manage-two {
  padding-top: 50px;
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #005c83;
  padding-bottom: 50px; }

.radio-manage {
  align-self: center; }
  .radio-manage span {
    top: -5px; }
  .radio-manage .title-radio {
    font-size: 24px;
    color: #474747;
    font-weight: 600;
    padding-left: 5px; }

.add-more-friends-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  color: #005c83;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 15px;
  margin: 10px auto 0 auto;
  text-align: center; }

.svg-plus {
  height: 10px;
  width: 10px;
  margin: 0 5px 0 0px; }

.plus {
  fill: #ff8227; }

.add-more-friends-btn:hover .plus {
  fill: #009fe3; }

.inner-h1-invite {
  font-size: 48px;
  text-align: center;
  font-weight: 900;
  color: #005c83;
  padding-bottom: 35px;
  padding-top: 50px;
  margin: 0; }

.invite-title {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 100px;
  color: #005c83; }

.invite-card {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.invite-avatar {
  width: 40%;
  order: 2;
  position: relative;
  bottom: 120px; }

.invite-info {
  order: 1;
  width: 50%; }

.invite-button {
  display: flex;
  margin-top: 110px;
  justify-content: flex-end; }

.invite-submit {
  margin-right: -40%;
  z-index: 1000;
  min-width: 85%; }

.font-24 {
  font-size: 24px; }

.font-bold {
  font-weight: bold; }

.color-blue {
  color: #0085be; }

.btn-blue {
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  padding: 10px 40px;
  cursor: pointer; }

.btn-blue:hover {
  background: #ff8227; }

.p-student-title {
  color: #0085be;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  -ms-word-break: break-all;
  word-break: break-all; }

.parent-dash {
  display: flex;
  justify-content: space-around;
  padding-left: 3%;
  padding-right: 3%; }
  .parent-dash .inner-parent-dash {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer; }
    .parent-dash .inner-parent-dash img {
      width: 100%;
      max-width: 50%;
      border-radius: 50%;
      box-shadow: 0 0 10px 0px #000000, 0 0 5px rgba(255, 255, 190, 0.75);
      margin-top: 10px;
      margin-bottom: 10px;
      height: auto; }

.btn-box-parent-dash {
  align-self: flex-end;
  margin-left: 10%; }
  .btn-box-parent-dash button, .btn-box-parent-dash a {
    width: 305px;
    display: inline-block;
    text-decoration: none;
    text-align: center; }

.student-margin-top {
  margin-bottom: 30px; }

.student-margin {
  margin-right: 15px; }

.result-student {
  background: #78dfff;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 35px 0;
  padding-right: 4%;
  padding-left: 4%; }

.inner-result-student {
  padding: 15px; }
  .inner-result-student p:last-child {
    font-size: 22px;
    color: #005c83;
    font-weight: bold;
    margin: 0; }
  .inner-result-student p:first-child, .inner-result-student .clock {
    font-family: Oduda;
    font-size: 50px;
    color: #ff8227;
    font-weight: bold;
    margin: 0; }

.clock-box {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .clock-box img {
    height: 100%; }

.nav-table-dash {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  .nav-table-dash button {
    margin-right: 15px; }
  .nav-table-dash button:last-child {
    margin-right: 0px; }

.report-student-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 10px 6%;
  background: #32b7eb;
  margin: 0 0 60px 0; }

.table-report {
  display: flex;
  background: #78dfff;
  padding-right: 6%;
  padding-left: 6%;
  align-items: center;
  margin-bottom: 45px; }
  .table-report span {
    font-size: 20px;
    color: #0085be;
    width: 15.2%;
    text-align: center;
    padding: 10px 0;
    font-weight: 600; }
  .table-report span:first-child {
    font-size: 30px;
    color: #005c83;
    width: 24%;
    font-weight: 900;
    text-align: left; }
  .table-report span:last-child {
    padding-right: 3%; }

.cont-box {
  margin-right: 4%;
  margin-left: 4%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #78dfff; }
  .cont-box span {
    font-size: 24px;
    font-weight: 600;
    color: #7c7c7c;
    width: 15.2%;
    text-align: center;
    padding: 15px 0; }
  .cont-box span:first-child {
    font-size: 24px;
    color: #7c7c7c;
    width: 24%;
    text-align: left;
    padding-left: 3%; }
  .cont-box span:last-child {
    padding-right: 3%; }

.outer-cont-box {
  background: #e9fdff;
  padding-bottom: 100px;
  margin-bottom: 90px; }

.indicatorContainer {
  height: 60px; }

.title-curr {
  padding: 10px 0 10px 6%;
  font-weight: 900;
  font-size: 30px;
  color: #005c83;
  background: #78dfff;
  margin-bottom: 35px; }

.buy-curriculum {
  height: auto;
  font-size: 36px;
  font-weight: 600;
  margin: 30px 0 80px 0;
  padding: 10px 0; }

.buy-curriculum:hover {
  height: auto; }

.outer-rewards-box {
  padding-bottom: 5px;
  margin-bottom: 25px; }
  .outer-rewards-box span.reward-span {
    font-size: 30px;
    font-weight: 600;
    color: #474747;
    width: 50%; }
  .outer-rewards-box .inner-reward-img {
    width: 5%;
    display: flex;
    justify-content: center; }
  .outer-rewards-box .cont-box:last-child {
    border-bottom: none; }

.submit-rewards {
  padding-bottom: 100px;
  background: url("/images/baloon.png") no-repeat 90% 30%; }
  .submit-rewards .rewards-p {
    width: 30%;
    padding: 15px 50px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    background: #009fe3;
    margin-bottom: 10px; }
  .submit-rewards .marg-rew {
    margin-bottom: 25px; }
  .submit-rewards .submit-title-rew {
    font-size: 30px;
    font-weight: 900;
    color: #005c83;
    padding-left: 2%;
    white-space: nowrap; }

.select-reward {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  font-weight: 600;
  color: #474747; }
  .select-reward #reward {
    display: inline-block;
    overflow: hidden;
    border: none;
    width: 40%;
    padding: 10px;
    min-width: 290px; }

.text-reward {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .text-reward input {
    border: none;
    font-size: 30px;
    font-weight: 600;
    color: #474747;
    text-align: center;
    padding: 10px 0;
    width: 65%; }

.num-reward {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .num-reward input {
    border: none;
    font-size: 30px;
    font-weight: 600;
    color: #474747;
    width: 150px;
    padding: 10px 0 10px 25px; }

.main-reward {
  width: 70%;
  position: relative; }

.buy-reward {
  width: 19%;
  min-width: 200px;
  position: absolute;
  right: -40%;
  bottom: 0;
  font-size: 24px;
  font-weight: 600; }

.buy-reward:hover {
  width: 19%; }

.parent-dash.two-child {
  padding: 0; }
  .parent-dash.two-child .inner-parent-dash {
    width: 33%; }
  .parent-dash.two-child .btn-box-parent-dash {
    width: 33%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
    align-self: flex-start; }
    .parent-dash.two-child .btn-box-parent-dash a, .parent-dash.two-child .btn-box-parent-dash button {
      margin: 0;
      width: 85%;
      display: inline-block;
      text-decoration: none;
      text-align: center; }
    .parent-dash.two-child .btn-box-parent-dash .student-margin-top {
      margin-bottom: 25px; }
    .parent-dash.two-child .btn-box-parent-dash .student-margin {
      margin-bottom: 35px; }

.no-active-student {
  margin-bottom: 0;
  padding-bottom: 0;
  background: #78dfff; }

.three-child {
  padding-left: 0;
  padding-right: 0; }
  .three-child .inner-parent-dash {
    width: 50%; }
  .three-child .inner-parent-dash._single-child {
    width: 60%; }
  .three-child .btn-box-parent-dash {
    width: 100%;
    max-width: 235px;
    min-width: 235px;
    text-align: right;
    margin-left: 3%; }
    .three-child .btn-box-parent-dash button, .three-child .btn-box-parent-dash a {
      margin: 0;
      width: 100%;
      max-width: 235px;
      min-width: 235px;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .three-child .btn-box-parent-dash button._btn-edit {
      margin-bottom: 10px; }
    .three-child .btn-box-parent-dash .student-margin-top {
      margin-bottom: 5px; }
    .three-child .btn-box-parent-dash .student-margin {
      margin-bottom: 5px; }
  .three-child ._btn-edit {
    margin-bottom: 5px; }
  .three-child .btn-box-parent-dash._parent-dash {
    font-size: 0; }

.sign-in-popup {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 2300px;
  height: 1400px;
  background: url("/images/signIn/bcg.png") no-repeat center center;
  background-size: 100% 100%;
  transform: scale(0.4);
  top: -350px; }

.popup-h1-student-inner-sign {
  padding-bottom: 140px;
  font-family: Oduda;
  font-weight: 900;
  font-size: 120px;
  margin: 0;
  text-align: center;
  padding-top: 240px;
  color: #ff8000;
  padding-bottom: 70px; }

.box-sign-in {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  width: 80%;
  margin-top: 75px; }

.inner-box-sign-in {
  text-align: center;
  padding-bottom: 70px; }
  .inner-box-sign-in img {
    max-width: 100%; }
  .inner-box-sign-in a {
    display: block;
    font-size: 68px;
    font-family: Oduda;
    font-weight: 900;
    color: #007e00;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 110px;
    height: 28px; }
  .inner-box-sign-in a:hover {
    display: block;
    font-size: 68px;
    font-family: Oduda;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 110px;
    background-color: #fff;
    text-shadow: 0px 2px 3px white;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    height: 28px;
    color: #006300; }

.close-popup-sign {
  display: block;
  width: 133px;
  height: 126px;
  background: url("/images/close.png") no-repeat;
  background-size: 100%;
  border: none;
  outline: none;
  position: absolute;
  right: 120px;
  top: 120px;
  cursor: pointer; }

.outer-rewards-box {
  background: #e9fdff;
  padding-bottom: 10px;
  margin-bottom: 25px; }
  .outer-rewards-box span.reward-span {
    font-size: 15px;
    font-weight: 600;
    color: #474747;
    width: 50%; }
  .outer-rewards-box span.reward-span._rew-span-w {
    width: 30%; }
  .outer-rewards-box .inner-reward-img {
    width: 50%;
    display: flex;
    justify-content: center; }
    .outer-rewards-box .inner-reward-img ._rew-img {
      max-width: 60px;
      max-height: 60px; }
  .outer-rewards-box .inner-reward-img._rew-img {
    width: 20%; }
  .outer-rewards-box .cont-box:last-child {
    border-bottom: none; }

.submit-rewards {
  padding-bottom: 170px;
  background: url("/images/baloon.png") no-repeat 90% 30%; }
  .submit-rewards .rewards-p {
    width: 35%;
    padding: 15px 50px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    background: #009fe3;
    margin-bottom: 10px; }
  .submit-rewards .marg-rew {
    margin-bottom: 25px; }
  .submit-rewards .submit-title-rew {
    font-size: 15px;
    font-weight: 900;
    color: #005c83;
    padding-left: 2%; }

.select-reward {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  color: #474747; }
  .select-reward #reward {
    display: inline-block;
    overflow-y: auto;
    border: none;
    width: 40%;
    padding: 10px;
    min-width: 150px;
    min-height: 95px; }

.text-reward {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .text-reward input {
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: #474747;
    text-align: center;
    padding: 10px 0;
    width: 65%; }

.reward-radio {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .reward-radio input[type=radio] {
    display: none; }
  .reward-radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 13px;
    width: 60px;
    height: 70px;
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid transparent; }
  .reward-radio .pop-corn {
    background-image: url("/images/pop-corn.png"); }
  .reward-radio .boul {
    background-image: url("/images/boul.png"); }
  .reward-radio .toy {
    background-image: url("/images/toy.png"); }
  .reward-radio .baloon {
    background-image: url("/images/baloon.png");
    background-size: 32px; }
  .reward-radio input[type=radio]:checked + label {
    border: 2px solid #009fe3; }

.num-reward {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .num-reward input {
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: #474747;
    width: 150px;
    padding-left: 25px; }

.main-reward {
  width: 70%;
  position: relative; }

.buy-reward {
  width: 30% !important;
  min-width: 200px;
  position: absolute;
  right: -45%;
  bottom: 0;
  font-size: 14px;
  font-weight: 600; }

.buy-reward:hover {
  width: 30% !important; }

.logo {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-left: 20px;
  float: left;
  text-align: left; }
  .logo img {
    width: 50%; }

.main-nav {
  position: relative;
  height: 38px;
  background-color: #0ec3fc;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  border: none;
  text-align: right;
  margin-bottom: 30px; }

.head-right-nav {
  min-height: 38px; }

.head-right-nav li a {
  padding: 3px 15px;
  margin-top: 7px;
  margin-bottom: 7px; }

.devider-line {
  height: 45px;
  width: 2px;
  border-right: 2px solid #e1e1e1;
  position: absolute;
  top: 9px;
  padding: 0;
  margin: 0;
  margin-left: -1px; }

#app-layout {
  background: url("/images/background.png") no-repeat;
  background-size: cover; }

.main-content {
  width: 100%;
  display: flex; }

.user-info {
  float: right;
  width: 60%; }

.inner-content {
  width: 77%;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  background: #b3f4fe;
  padding-top: 35px;
  padding-right: 2%;
  padding-left: 2%;
  order: 2;
  flex-grow: 3; }

.inner-h1 {
  font-size: 24px;
  text-align: center;
  font-weight: 900;
  color: #005c83;
  padding-bottom: 65px; }

.avatar {
  width: 30%;
  float: left; }
  .avatar img {
    width: 50%; }

.avatar-register .width-avatar {
  width: 100%;
  max-width: 150px; }

.padding-label {
  padding-right: 0px; }

.label-style {
  font-size: 15px;
  font-weight: 900;
  color: #005c83;
  padding-top: 45px;
  padding-bottom: 5px; }

.label-style:first-child {
  padding-top: 0; }

.input-style {
  display: block;
  font-size: 14px;
  padding: 9.5px;
  border: none;
  width: 100%; }

.alert {
  color: #ff0000;
  font-size: 14px;
  font-weight: normal;
  padding-left: 0;
  padding-bottom: 0; }

.pass-info {
  font-size: 14px;
  font-weight: normal;
  padding-left: 0;
  padding-bottom: 0; }

.title-label {
  font-size: 15px;
  padding-bottom: 65px;
  color: #005c83;
  font-weight: 900;
  display: inline-block; }

.title-block {
  font-size: 15px;
  padding: 80px 0;
  color: #005c83;
  font-weight: 900; }

.sign-up {
  display: block;
  background: #ff8227;
  color: white;
  padding: 5px 20%;
  margin-bottom: 120px;
  font-size: 15px;
  border: none;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
  text-align: center; }

.sign-up:hover {
  background: #009fe3;
  color: white;
  outline: none; }

.label-radio {
  margin-left: 20px;
  display: inline-block;
  height: 50px;
  width: 65px;
  background: white;
  font-size: 21px;
  padding-top: 9px;
  text-align: center; }

.radio-check {
  margin-left: 30px !important; }

label {
  display: inline-block;
  position: relative; }

.custom-radio {
  position: absolute;
  display: block;
  width: 39px;
  height: 39px;
  background: url("/images/radio.png");
  top: 5px;
  left: 15px; }

label > input[type=checkbox]:checked + span {
  background: url("/images/radio_checked.png"); }

label > input[type=radio]:checked + span {
  background: url("/images/radio_checked.png"); }

.right-sidebar {
  width: auto;
  min-width: 22%;
  flex-wrap: wrap;
  order: 3;
  flex-grow: 1; }

.socio-box {
  padding-left: 10px; }

.right-sicio-img {
  float: left;
  display: inline-block;
  width: 85px;
  height: 37px;
  background: #6477a4;
  text-decoration: none;
  color: white;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px; }

.right-sicio-like {
  float: left;
  display: flex;
  width: 85px;
  height: 37px;
  background: #6477a4;
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
  margin-right: 5px; }

.right-sicio-tweet {
  display: flex;
  width: 85px;
  height: 37px;
  background: #00aced;
  text-decoration: none;
  color: white;
  text-align: center;
  margin-bottom: 10px; }

.inner-soci-span {
  padding-top: 10px;
  padding-left: 0px; }

.icon-socio-like {
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url("/images/like.png") no-repeat;
  margin-left: 5px;
  margin-right: 5px; }

.icon-socio-tweet {
  display: inline-block;
  width: 33px;
  height: 30px;
  margin-top: 7px;
  background: url("/images/twitter.png") no-repeat;
  margin-left: 3px; }

.font-24 {
  font-size: 14px; }

.form-add-child {
  width: 70%;
  margin: 0 auto; }

.add-student-page {
  display: flex;
  justify-content: center;
  padding-bottom: 80px; }

.select-one {
  margin-right: 20%; }

.under-user {
  display: block;
  margin-top: 30px;
  text-align: center;
  padding-right: 20px;
  cursor: pointer; }

.custom-radio-add {
  position: absolute;
  display: block;
  width: 39px;
  height: 39px;
  background: url("/images/radio.png");
  top: -10px;
  left: 55px; }

.label-add {
  font-size: 16px;
  color: #0085be;
  padding-left: 25px; }

.inner-block-add-input {
  margin: 0 auto; }

.add-student-button {
  margin-top: 40px;
  margin-bottom: 60px;
  float: right; }

.block-sales {
  margin: 0 auto;
  background: #009fe3;
  padding: 10px;
  margin-top: 110px;
  width: 80%;
  max-width: 180px; }

.our-deal, .inner-text-right-sidebar, .save-procent {
  text-align: center; }

.our-deal {
  font-size: 30px;
  color: #feec00;
  margin-bottom: 20px; }

.inner-text-right-sidebar {
  font-size: 14px;
  color: white;
  margin-bottom: 20px; }

.save-procent {
  font-size: 20px;
  color: #feec00;
  margin-bottom: 20px;
  margin-top: 20px; }

.parent-box {
  background: white;
  padding: 20px;
  margin-bottom: 50px;
  padding-bottom: 50px; }
  .parent-box img {
    width: 100%; }

.child-profile {
  font-size: 16px;
  color: #4f5050;
  text-align: center; }

.price-text {
  font-family: Oduda;
  font-size: 14px;
  color: #ff8227;
  text-align: center; }

.buy {
  display: inline-block;
  color: white;
  background: #009fe3;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 800; }

.buy:hover {
  display: inline-block;
  color: white;
  background: #ff8227;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  cursor: pointer; }

.left-sidebar {
  flex-grow: 1;
  min-width: 22%;
  order: 1; }

.nav-profile {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 35px; }

.profile-button {
  font-size: 14px;
  font-weight: 900;
  display: inline-block;
  width: 30%;
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0; }

.profile-button:hover {
  font-size: 14px;
  font-weight: 900;
  display: inline-block;
  width: 30%;
  color: white;
  background: #ff8227;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0; }

.inner-block-input-profile {
  width: 70%;
  margin: 0 auto; }

.change-profile {
  padding: 10px 0 0 0;
  margin: 0;
  text-align: right;
  font-size: 14px;
  color: #4f5050; }

.inner-block-input-student {
  width: 55%;
  margin: 0 auto;
  margin-bottom: 200px; }

.new-student-button {
  font-size: 14px;
  font-weight: 900;
  margin: 0 auto;
  float: none;
  margin-bottom: 50px;
  width: 55%;
  padding: 10px; }

.add-student-input {
  padding-bottom: 10px; }

.manage-purchases {
  width: 100%; }
  .manage-purchases .inner-h1-manage {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: #005c83;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 50px; }
  .manage-purchases .title-manage {
    padding-left: 70px;
    font-weight: 900;
    font-size: 15px;
    color: #005c83;
    background: #78dfff;
    margin-bottom: 35px; }

.outer-manage-block {
  padding: 0px 10px 10px 10px;
  background: #e9fdff;
  overflow-y: auto;
  overflow-x: auto; }
  .outer-manage-block .table-div-box:nth-child(odd) {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .outer-manage-block .table-div-box:nth-child(even) {
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    flex-wrap: wrap; }
  .outer-manage-block .table-div-box:last-child {
    border-bottom: none; }

.inner-line-manage {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; }

.inner-two-line-manage {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap; }

.inner-line-manage, .inner-two-line-manage {
  border-bottom: 2px solid #78dfff; }

.inner-flex {
  display: flex;
  align-items: center; }

.bord-rad-30 {
  border-radius: 30px;
  width: 115px; }

.media-in-flex {
  display: flex; }

.p-inner-manage {
  font-size: 15px;
  color: #474747;
  padding-left: 20px;
  font-weight: 600; }

.button-inner-line {
  width: 100px;
  align-self: center;
  font-size: 14px;
  height: 50px;
  font-weight: 900;
  display: inline-block;
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 40px; }

.button-inner-line:hover {
  font-size: 14px;
  width: 100px;
  height: 50px;
  font-weight: 900;
  display: inline-block;
  color: white;
  background: #ff8227;
  border: none;
  outline: none;
  cursor: pointer; }

.buy-manage {
  width: 35%;
  margin: 50px 0 100px 0; }

.buy-manage:hover {
  width: 35%; }

.button-buy-manage {
  display: flex;
  justify-content: center; }

.inner-h1-manage-two {
  padding-top: 50px;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  color: #005c83;
  padding-bottom: 50px; }

.radio-manage {
  align-self: center; }
  .radio-manage span {
    top: -10px; }
  .radio-manage .title-radio {
    font-size: 14px;
    color: #474747;
    font-weight: 600;
    padding-left: 5px; }

.inner-h1-invite {
  font-size: 24px;
  text-align: center;
  font-weight: 900;
  color: #005c83;
  padding-bottom: 35px;
  padding-top: 50px;
  margin: 0; }

.invite-title {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 100px;
  color: #005c83; }

.invite-card {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.invite-avatar {
  width: 40%;
  order: 2;
  position: relative;
  bottom: 120px; }

.invite-info {
  order: 1;
  width: 50%; }

.invite-button {
  display: flex;
  margin-top: 110px;
  justify-content: flex-end; }

.invite-submit {
  margin-right: -40%;
  z-index: 1000;
  min-width: 85%; }

.btn-blue {
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  padding: 10px 40px;
  cursor: pointer; }

.btn-blue:hover {
  background: #ff8227; }

.p-student-title {
  color: #0085be;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  width: 100%; }

.parent-dash {
  display: flex;
  justify-content: space-between;
  padding-left: 0%;
  padding-right: 0%; }
  .parent-dash .inner-parent-dash {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px; }

.btn-box-parent-dash {
  align-self: flex-end;
  margin-left: 10%; }
  .btn-box-parent-dash button, .btn-box-parent-dash a {
    width: 305px;
    display: inline-block;
    text-decoration: none;
    text-align: center; }

._three-child-slider {
  display: flex;
  position: relative;
  flex-grow: 1; }
  ._three-child-slider .arrow-switch-left {
    position: absolute;
    width: 25px !important;
    height: 30px !important;
    margin-top: 0 !important;
    top: 35%;
    left: 0; }
  ._three-child-slider .arrow-switch-right {
    position: absolute;
    width: 25px !important;
    height: 30px !important;
    margin-top: 0 !important;
    top: 35%;
    right: 0; }

.student-margin-top {
  margin-bottom: 30px; }

.student-margin {
  margin-right: 15px; }

.result-student {
  background: #78dfff;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 0 35px 0;
  padding-right: 0;
  padding-left: 0; }

.inner-result-student {
  padding: 10px 5px 10px 5px; }
  .inner-result-student span {
    display: inline-block;
    min-width: 30px;
    text-align: center; }
  .inner-result-student img {
    width: 50%; }
  .inner-result-student p:last-child {
    font-size: 14px;
    color: #005c83;
    font-weight: bold;
    margin: 0; }
  .inner-result-student p:first-child, .inner-result-student .clock {
    font-family: Oduda;
    font-size: 25px;
    color: #ff8227;
    font-weight: bold;
    margin: 0; }

.clock-box {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .clock-box img {
    width: 50%; }

.nav-table-dash {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  .nav-table-dash button {
    margin-right: 15px; }
  .nav-table-dash button:last-child {
    margin-right: 0px; }

.report-student-title {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 10px 6%;
  background: #32b7eb;
  margin: 0 0 5px 0; }

.table-report {
  display: flex;
  background: #78dfff;
  padding-right: 4%;
  padding-left: 4%;
  align-items: center;
  margin-bottom: 5px; }
  .table-report span {
    font-size: 14px;
    color: #0085be;
    width: 15.2%;
    text-align: center;
    padding: 10px 0;
    font-weight: 600; }
  .table-report span:first-child {
    font-size: 15px;
    color: #005c83;
    width: 24%;
    font-weight: 900;
    text-align: left;
    padding-left: 1%; }
  .table-report span:last-child {
    padding-right: 0%; }

.cont-box {
  margin-right: 4%;
  margin-left: 4%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #78dfff; }
  .cont-box span {
    font-size: 14px;
    font-weight: 600;
    color: #7c7c7c;
    width: 15.2%;
    text-align: center;
    padding: 15px 0; }
  .cont-box span:first-child {
    font-size: 14px;
    color: #7c7c7c;
    width: 24%;
    text-align: left;
    padding-left: 1%; }
  .cont-box span:last-child {
    padding-right: 0%; }

.cont-box-rewards {
  margin-right: 4%;
  margin-left: 4%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #78dfff;
  cursor: pointer; }
  .cont-box-rewards span {
    font-size: 14px;
    font-weight: 600;
    color: #7c7c7c;
    width: 15.2%;
    text-align: center;
    padding: 15px 0; }
  .cont-box-rewards span:first-child {
    font-size: 14px;
    color: #7c7c7c;
    width: 24%;
    text-align: left;
    padding-left: 1%; }
  .cont-box-rewards span:last-child {
    padding-right: 0%; }
  .cont-box-rewards .reward-info {
    font-size: 14px;
    color: #7c7c7c;
    margin: 0;
    width: 30%;
    text-align: right; }
    .cont-box-rewards .reward-info p {
      margin: 0; }

.drag-box-date {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between; }
  .drag-box-date span.reward-span {
    width: 24%; }
  .drag-box-date span.reward-span-date {
    width: 28%;
    text-align: right; }
  .drag-box-date div.inner-reward-img {
    width: 50%; }

.outer-cont-box {
  background: #e9fdff;
  padding-bottom: 10px;
  margin-bottom: 40px; }

.indicatorContainer {
  height: 60px; }

.title-curr {
  padding: 10px 0 10px 6%;
  font-weight: 900;
  font-size: 15px;
  color: #005c83;
  background: #78dfff;
  margin-bottom: 35px; }

.buy-curriculum {
  height: auto;
  font-size: 18px;
  font-weight: 600;
  margin: 30px 0 80px 0;
  padding: 10px 0; }

.buy-curriculum:hover {
  height: auto; }

.navbar-brand.main-logo {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-left: 1%; }
  .navbar-brand.main-logo img {
    width: 250px;
    max-width: 100%; }

.pad-0 {
  padding-left: 0;
  padding-right: 0; }

.dragg-class {
  width: 100%;
  display: flex;
  align-items: center; }

.dragg-class._dragg-cl {
  justify-content: space-between;
  padding: 7px 0; }

.marg-bot {
  margin-bottom: 200px;
  overflow: hidden; }

.result-student-manage {
  height: 30px; }

.title-new-manage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: white;
  padding: 15px 10px 15px 10px; }

.inner-title-new-manage {
  flex-grow: 1; }

._my-subscription-cont .inner-title-new-manage-p {
  margin: 5px;
  padding-top: 0; }

.box-line-manage {
  border-left: 2px solid #78dfff;
  padding: 20px 20px 20px 35px; }
  .box-line-manage a {
    display: block;
    background: #009fe3;
    text-decoration: none;
    color: white;
    padding: 5px;
    font-size: 16px;
    font-weight: 700;
    text-align: center; }
  .box-line-manage a:hover {
    background: #ff8227; }

.inner-title-new-manage p {
  margin: 0;
  color: #474747;
  font-weight: 600;
  font-size: 16px;
  padding-top: 3px; }

.inner-title-new-manage .inner-title-new-manage-title {
  padding-top: 0px;
  font-size: 18px;
  color: #005c83;
  font-weight: 700; }

.center-block-invite {
  text-align: center; }

.inner-media-profile, .check-box-week {
  display: flex;
  justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  margin-bottom: 5px; }

.ie-avatar {
  width: 100%;
  min-height: 100%; }

._no-student-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; }

._add_stud_btn {
  text-align: center;
  color: white;
  background: #009fe3;
  border: none;
  outline: none;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  width: 235px;
  margin-left: 30px;
  text-decoration: none; }

._no-student-avatar {
  width: 110px;
  height: 110px;
  background: url(/images/childDash/avatar1.png) no-repeat;
  background-position: center;
  background-size: 100%;
  display: inline-block; }

#mobile-login {
  display: none; }

.yellow-btn {
  display: none; }

.two-section-landing {
  min-height: 100%; }

.fix-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000; }

.max-flex {
  display: flex;
  justify-content: space-between; }

.inner-main-content-landing, .pin-text, .outer-box-checkbox,
.input-box-pin, .inner-img-planshet, .left-title-planshet, .three-section-h1,
.three-section-h2, .container-three, .max-content, .outer-five-section,
.five-section-h1, .two-slider-outer, .inner-six-section, .seven-section-h1,
.seven-section-p, .slider-box, .eight-section-h1, .blog-box, .about-h1,
.about-box, .inner-ten-box, .inner-footer {
  max-width: 1300px !important;
  margin: 0 auto !important;
  width: 100%; }

.max-content {
  width: 100%; }

.mobile-nav {
  border: none;
  outline: none;
  padding-top: 8px;
  padding-bottom: 3px;
  border-radius: 10px;
  background: #ff8227;
  cursor: pointer;
  display: none;
  z-index: 30000;
  width: auto;
  height: auto; }

.mobile-nav:hover {
  background: #009fe3; }

.line-nav {
  height: 2px;
  background: white;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto; }

.pos-relative {
  position: relative; }

.box-checkbox {
  display: flex;
  flex-direction: column; }
  .box-checkbox label {
    font-size: 40px;
    font-weight: 600;
    color: #1a397f;
    display: flex;
    align-items: flex-end;
    margin-right: 35px;
    margin-bottom: 15px;
    cursor: default; }
  .box-checkbox input[type=checkbox] {
    display: none; }

.checkbox-style {
  display: inline-block;
  width: 61px;
  height: 56px;
  background: url("/images/landing/check.png") no-repeat left bottom;
  margin-right: 15px; }

input[type=checkbox]:checked + .checkbox-style {
  background: url("/images/landing/check-active.png") no-repeat left bottom;
  margin-right: 15px; }

.outer-box-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }

.txt-align {
  text-align: center; }

.nav-landing {
  display: flex;
  justify-content: space-between; }
  .nav-landing .main-logo {
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 1%;
    float: left; }
    .nav-landing .main-logo img {
      max-width: 100%; }

.main-nav-box {
  display: flex; }

.main-login {
  font-size: 28px;
  color: white;
  background: #ff8227;
  text-decoration: none;
  width: 129px;
  height: 40px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #ff8227;
  text-align: center;
  padding-top: 3px;
  margin-left: 10px;
  outline: none; }

.main-login:hover {
  background: #009fe3; }

.main-img-search {
  opacity: 0;
  width: 32px;
  height: 31px;
  background: url("/images/landing/scale.png") no-repeat center center; }

.main-img-search:hover {
  background: url("/images/landing/scale_hover.png") no-repeat center center; }

.block-search {
  display: flex;
  align-items: center; }

.main-content-landing {
  width: 100%;
  padding-bottom: 500px;
  background: url("/images/landing/bcg_1.png") no-repeat center 0;
  background-size: 100% 100%;
  margin: 0 auto; }

.inner-main-content-landing {
  display: flex;
  justify-content: center; }

.pin-container {
  margin-left: 115px;
  text-align: center; }
  .pin-container img {
    width: 80%; }

.pin-text {
  display: flex;
  flex-direction: column;
  font-family: Oduda;
  font-size: 57px;
  font-weight: 900;
  color: #ff8227; }
  .pin-text p {
    margin: 0;
    align-self: center; }

.inner-text-pin {
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 900;
  color: #1a397f;
  padding-top: 25px;
  width: 50%; }

.input-box-pin {
  display: flex;
  justify-content: center; }
  .input-box-pin p {
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 900;
    color: #1a397f;
    margin-top: 45px;
    margin-bottom: 10px; }
  .input-box-pin .get-started {
    min-width: 370px;
    font-family: Oduda;
    font-size: 36px;
    font-weight: 600;
    color: #ff8227;
    margin-left: 45px;
    border: none;
    height: 70px;
    -webkit-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    -moz-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    border-radius: 20px;
    background: url("/images/landing/bgc_btn.png") no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    padding-top: 12px;
    text-decoration: none; }
  .input-box-pin input {
    height: 70px;
    font-family: Oduda;
    font-size: 36px;
    font-weight: 900;
    color: #015f99;
    background-color: #86f4ff;
    border-radius: 20px;
    border: none;
    outline: none;
    padding-left: 30px;
    -webkit-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    -moz-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    padding-right: 155px;
    max-width: 500px;
    text-align: center; }
  .input-box-pin input::-webkit-input-placeholder {
    color: #015f99; }

.flex {
  display: flex;
  flex-wrap: wrap; }

.flex-sub {
  flex-direction: column;
  align-items: center; }

.flex-outer {
  display: flex;
  justify-content: center; }

.main-submit {
  position: absolute;
  top: 0;
  right: 0;
  font-family: Oduda;
  width: 140px;
  font-size: 24px;
  font-weight: 600;
  color: white;
  border: none;
  height: 70px;
  border-radius: 0 20px 20px 0;
  background: url("/images/landing/widget_btn.png") no-repeat;
  background-size: 100% 100%;
  outline: none;
  cursor: pointer; }

.planshet-landing {
  background: #0f86e8;
  padding-bottom: 40px; }

.inner-img-planshet {
  text-align: center;
  padding-top: 30px; }
  .inner-img-planshet img {
    width: 90%;
    height: auto; }

.title-planshet-landing {
  background: #0873d7;
  margin-top: 40px;
  padding: 0 0 15px 0; }

.inner-title-section-planshet {
  display: flex;
  justify-content: center;
  padding-top: 30px; }

.left-title-planshet {
  width: 100%; }

.inner-left-title-planshet {
  display: flex;
  width: 40%;
  justify-content: space-between; }
  .inner-left-title-planshet img {
    max-width: 100%; }
  .inner-left-title-planshet p {
    margin: 0;
    font-size: 28px;
    color: white;
    width: 70%;
    padding-top: 10px; }

.inner-width-planshet {
  width: 25%; }

.inner-title-section-planshet .inner-left-title-planshet:first-child {
  padding-right: 2%; }

.inner-title-section-planshet .inner-left-title-planshet:last-child {
  padding-left: 2%; }

.contact-us {
  color: #a4e8ff; }

.list-style-land {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 42px;
  height: 37px;
  background: url("/images/landing/style-type.png") no-repeat center center; }

.three-section {
  background: #6bdcff; }

.three-section-h1 {
  font-size: 72px;
  color: #014ca9;
  font-weight: 600;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 45px;
  margin: 0; }

.three-section-h2 {
  font-size: 48px;
  color: #014ca9;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding-bottom: 70px; }

.box-img-three {
  padding: 40px 60px;
  background: url("/images/landing/bcg-item.png") no-repeat center center;
  background-size: 100% 100%;
  width: 30%;
  min-width: 500px; }
  .box-img-three ul {
    font-size: 28px;
    color: #1a397f;
    font-weight: 400;
    list-style-type: none;
    padding: 0; }
    .box-img-three ul li {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 52px;
      height: 45px; }

.inner-box-img-three {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px; }
  .inner-box-img-three img {
    -webkit-border-radius: 21px/19px;
    -moz-border-radius: 21px/19px;
    border-radius: 21px/19px;
    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.75); }
  .inner-box-img-three p {
    font-size: 38px;
    color: #014687;
    font-weight: 800;
    margin: 0;
    padding-left: 15px; }

.container-three {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .container-three .box-img-three:first-child {
    margin-right: 3%; }
  .container-three .box-img-three:last-child {
    margin-left: 3%; }

.btn-more-topic {
  width: 20%;
  background: url("/images/landing/btn.png") no-repeat center center;
  background-size: 100% 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 34px;
  color: #014687;
  font-weight: 600;
  padding: 25px 0px;
  margin-top: 30px;
  margin-bottom: 130px;
  display: inline-block; }

.btn-more-topic:active {
  background: url("/images/landing/btn-pressed.png") no-repeat center center;
  width: 20%;
  background-size: 100% 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 34px;
  color: #014687;
  font-weight: 600;
  padding: 25px 0px;
  margin-top: 30px;
  margin-bottom: 130px;
  display: inline-block; }

.four-section {
  background: #22caff; }

.column-four {
  display: flex;
  justify-content: center; }
  .column-four .inner-column:first-child {
    padding-right: 2%; }
  .column-four .inner-column:last-child {
    padding-left: 2%; }

.inner-column {
  width: 30%; }
  .inner-column p {
    text-align: center; }
  .inner-column a {
    display: inline-block;
    background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 40px;
    color: white;
    text-decoration: none;
    padding: 20px 0;
    width: 100%;
    font-size: 36px;
    font-weight: 900; }
  .inner-column a:hover {
    display: inline-block;
    background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 40px;
    color: white;
    text-decoration: none;
    padding: 20px 0;
    width: 100%;
    font-size: 36px;
    font-weight: 900; }

.btn-four {
  margin-bottom: 70px; }

.btn-four:active {
  margin-bottom: 70px; }

.five-section {
  padding-bottom: 30px;
  background: #ffffff; }

.outer-five-section {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.inner-five-section {
  width: 57%;
  display: flex;
  align-self: center; }

.contenier-for-slide {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  -webkit-justify-content: space-between;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 2px; }

.bold {
  font-weight: 800 !important; }

.five-section-first-p {
  font-size: 24px;
  color: #1a397f;
  font-weight: 600;
  margin: 0;
  padding-left: 25px; }

.five-section-two-p {
  font-size: 24px;
  color: #1a397f;
  font-weight: 800;
  margin: 45px 0 10px 0;
  padding-left: 25px; }

.five-section-three-p {
  font-size: 18px;
  color: #1a397f;
  font-weight: 600;
  margin: 0;
  padding-left: 25px; }

.pagination {
  display: none; }

.five-section-img {
  max-width: 221px;
  max-height: 208px;
  margin-right: 25px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
  border: solid 1px #c8edfb; }

.five-section-h1 {
  padding-top: 0;
  padding-bottom: 80px; }

.two-slider-outer {
  display: flex;
  justify-content: center; }

.inner-two-slider {
  display: flex;
  width: 57%;
  justify-content: space-between; }

.container-for-second-slider {
  display: flex !important;
  justify-content: space-between !important;
  margin-left: 2px; }
  .container-for-second-slider p {
    padding: 0; }
  .container-for-second-slider .item-slider {
    width: 30%; }

.five-section-img {
  margin: 0 !important; }

.item-slider {
  width: 20%; }

.six-section {
  background-color: #b3f4fe;
  background-image: -webkit-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -moz-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -o-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -ms-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: linear-gradient(to bottom, #4fc7fe, #d0f8fe); }

.inner-six-section {
  display: flex; }

.six-left-sidebar {
  width: 18%;
  position: relative;
  text-align: center; }
  .six-left-sidebar img {
    max-width: 100%;
    padding-top: 170px; }
  .six-left-sidebar p {
    width: 100%;
    text-align: center;
    left: 0;
    position: absolute;
    font-size: 60px;
    font-family: Oduda;
    top: 26%;
    margin: 0;
    color: #005c83;
    font-weight: 800;
    text-align: center; }
  .six-left-sidebar .pos-rel {
    position: relative; }

.center-sidebar {
  width: 64%;
  background: #b3f4fe; }
  .center-sidebar h1 {
    font-size: 72px;
    font-weight: 600;
    color: #005c83;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 30px; }
  .center-sidebar h2 {
    font-size: 48px;
    font-weight: 400;
    color: #005c83;
    text-align: center;
    margin-top: 0;
    margin-bottom: 90px; }
  .center-sidebar .parent-box {
    padding: 45px 20px; }
    .center-sidebar .parent-box .child-profile {
      text-align: left;
      font-size: 30px; }
    .center-sidebar .parent-box .price-text {
      font-size: 40px;
      margin-bottom: 0;
      margin-top: 50px; }
    .center-sidebar .parent-box .del-price {
      font-size: 30px; }

.six-right-sidebar {
  width: 18%; }

.inner-center-sidebar {
  width: 21%;
  text-align: center; }
  .inner-center-sidebar img {
    max-width: 100%; }
  .inner-center-sidebar .parent-box {
    margin-bottom: 80px;
    margin-top: 30px; }

.child-profile-price {
  text-align: center !important; }

.head-btn {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
  cursor: pointer;
  font-weight: 600; }

.footer-btn {
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 95px;
  cursor: pointer; }

.head-btn:hover, .footer-btn:hover {
  background: #ff8227; }

.flex-center {
  display: flex;
  justify-content: center; }
  .flex-center .inner-center-sidebar {
    margin-right: 5%; }
  .flex-center .inner-center-sidebar:last-child {
    margin-right: 0; }

.seven-section {
  padding-bottom: 50px;
  background: white; }

.seven-section-h1 {
  font-size: 72px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 75px 0 30px 0;
  color: #00539a; }

.seven-section-p {
  font-size: 48px;
  font-weight: 600;
  color: #0063b7;
  margin: 0;
  padding-bottom: 30px;
  padding-left: 18%;
  text-align: left; }
  .seven-section-p a {
    font-size: 36px;
    color: #009fe3;
    padding-left: 35px; }

.slider-box {
  display: flex;
  justify-content: center; }

.inner-slider-box {
  width: 80%;
  position: relative; }

.box-sliders {
  display: flex;
  justify-content: center; }
  .box-sliders .slider-item:last-child {
    padding-right: 0; }

.left-slide-nav {
  position: absolute;
  top: calc(50% - 74px);
  left: -104px;
  width: 84px;
  height: 148px;
  background: url("/images/landing/left-arrow.png") no-repeat;
  cursor: pointer;
  z-index: 9990; }

.left-slide-nav:hover {
  background: url("/images/landing/left-arrow-hover.png") no-repeat; }

.right-slide-nav {
  position: absolute;
  top: calc(50% - 74px);
  right: -104px;
  width: 84px;
  height: 148px;
  background: url("/images/landing/right-arrow.png") no-repeat;
  cursor: pointer;
  z-index: 9990; }

.right-slide-nav:hover {
  background: url("/images/landing/right-arrow-hover.png") no-repeat; }

.two-slider {
  margin-top: 95px; }

.eight-section {
  padding-bottom: 30px;
  background: #e6fcff; }

.eight-section-h1 {
  font-size: 72px;
  font-weight: 600;
  color: #00539a;
  margin: 0;
  padding-top: 85px;
  text-align: center; }

.eight-section-p {
  font-size: 48px;
  font-weight: 600;
  color: #0048b5;
  margin: 0;
  padding-top: 65px; }

.eight-section-two-p {
  font-size: 30px;
  font-weight: 400;
  color: #4f5050;
  margin: 0;
  padding: 0 0 20px 0;
  width: 100%; }

.link-blog {
  font-size: 30px;
  color: #0f86e8;
  display: inline-block; }

.blog-box {
  width: 75%;
  margin: 0 auto;
  height: 100%; }

.img-box {
  align-self: flex-start;
  height: 100%; }

.title-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin-left: 5%; }

.outer-titel {
  display: flex; }

.date-p {
  padding-top: 15px; }

.nine-section {
  padding-bottom: 150px;
  background: #0f86e8; }

.about-h1 {
  font-size: 72px;
  color: #ffed00;
  margin: 0;
  padding: 80px 0 10px 0;
  text-align: center; }

.about-box {
  display: flex;
  align-items: center;
  width: 75%;
  margin: 0 auto; }

.about-p {
  font-size: 30px;
  color: #ffffff;
  margin: 0;
  padding-left: 40px;
  width: 75%; }

.ten-section {
  background: #4663e5;
  padding-top: 70px;
  padding-bottom: 135px; }

.first-inner-box-ten {
  width: 26%; }

.first-inner-box-p {
  font-size: 30px;
  color: #ffed00;
  margin: 0;
  padding-bottom: 25px; }

.two-inner-box-p {
  font-size: 18px;
  color: white;
  margin: 0;
  line-height: 2; }

.pad-ten-box {
  padding-bottom: 35px; }

.about-nav {
  list-style-type: none;
  padding: 0;
  margin-top: 0; }
  .about-nav a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 25px; }
  .about-nav a:hover {
    color: #ffed00; }

.inner-ten-box {
  width: 58%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto; }

.twit-icon {
  display: inline-block;
  width: 28px;
  height: 23px;
  background: url("/images/landing/twitter.png") no-repeat center center;
  float: left;
  margin-top: 8px; }

.margin-icon {
  margin-left: 58px; }

.footer {
  height: auto;
  background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
  background-size: 115% 115%;
  padding: 30px 100px 30px 15px; }

.inner-footer {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-wrap: wrap; }

.soc-icon-footer {
  display: inline-block;
  width: 64px;
  height: 64px; }

.gplus-brand, .twitter-brand, .linkedin-brand, .pinterest-brand {
  margin-right: 5px; }

.gplus-brand {
  background: url("/images/landing/gplus-brand.png") no-repeat center center; }

.twitter-brand {
  background: url("/images/landing/twitter-brand.png") no-repeat center center; }

.linkedin-brand {
  background: url("/images/landing/linkedin-brand.png") no-repeat center center; }

.pinterest-brand {
  background: url("/images/landing/pinterest-brand.png") no-repeat center center; }

.youtube-brand {
  background: url("/images/landing/youtube-brand.png") no-repeat center center; }

.footer-div {
  display: flex;
  align-items: center; }
  .footer-div img {
    margin-right: 15px; }

.link-footer {
  font-size: 24px;
  color: #4166e5;
  text-decoration: none; }

.right-link-footer {
  margin-right: 115px; }

.slick-slide img {
  margin: 0 auto; }

.del-price-land {
  position: relative; }

.del-price-land:before {
  border-bottom: 2px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg); }

.del-price-land:after {
  border-bottom: 2px solid red;
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  -webkit-transform: rotate(-172deg);
  -moz-transform: rotate(-172deg);
  -o-transform: rotate(-172deg);
  -ms-transform: rotate(-172deg);
  transform: rotate(-172deg);
  left: 0;
  bottom: 0; }

.slick-dots li {
  display: inline-block;
  width: 100px !important;
  height: 13px !important;
  background: #568da4;
  cursor: pointer;
  margin-right: 35px !important;
  margin-left: 0 !important; }
  .slick-dots li button {
    display: none !important; }

.slick-dots li.slick-active {
  display: inline-block;
  width: 100px;
  height: 13px;
  background: #ff7800; }

.slick-list {
  padding-bottom: 40px !important; }

.slick-dotted.slick-slider {
  margin-bottom: 50px !important; }

.slider-box {
  width: 100%; }

.container-for-second-slider .five-section-first-p {
  padding: 10px 0 0 0; }

.head-right-nav li a {
  font-size: 16px;
  padding: 3px 28px; }

.pos-relative {
  position: relative; }

.box-checkbox {
  display: flex;
  flex-direction: column; }
  .box-checkbox label {
    font-size: 20px;
    color: #1a397f;
    display: flex;
    align-items: flex-end;
    margin-right: 35px;
    margin-bottom: 15px; }
  .box-checkbox input[type=checkbox] {
    display: none; }

.checkbox-style {
  display: inline-block;
  width: 23px;
  height: 24px;
  background: url("/images/landing/check.png") no-repeat left bottom;
  margin-right: 15px;
  background-size: 100% 100%; }

input[type=checkbox]:checked + .checkbox-style {
  background: url("/images/landing/check-active.png") no-repeat left bottom;
  margin-right: 15px;
  width: 31px;
  height: 28px;
  background-size: 100% 100%; }

.outer-box-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }

.txt-align {
  text-align: center; }

.nav-landing {
  display: flex;
  justify-content: space-between; }
  .nav-landing .main-logo {
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 10px;
    float: left; }
    .nav-landing .main-logo img {
      width: 250px;
      max-width: 100%; }

.main-nav-box {
  display: flex;
  position: relative; }

.main-login {
  font-size: 18px;
  color: white;
  background: #ff8227;
  text-decoration: none;
  width: 129px;
  height: 32px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #ff8227;
  text-align: center;
  padding-top: 5px;
  margin-left: 10px; }

.main-login:hover {
  background: #009fe3; }

.main-img-search {
  width: 32px;
  height: 31px;
  margin-right: 0px;
  margin-left: 0px;
  background: url("/images/landing/scale.png") no-repeat center center; }

.main-img-search:hover {
  background: url("/images/landing/scale_hover.png") no-repeat center center; }

.block-search {
  display: flex;
  align-items: center; }

.main-content-landing {
  padding-top: 38px;
  width: 100%;
  padding-bottom: 150px;
  background: url("/images/landing/bcg_1.png") no-repeat center 15px;
  background-size: 100% 100%; }

.inner-main-content-landing {
  display: flex;
  justify-content: center; }

.pin-container {
  margin-left: 70px;
  text-align: center; }
  .pin-container img {
    width: 40%; }

.pin-text {
  display: flex;
  flex-direction: column;
  font-family: Oduda;
  font-size: 28px;
  font-weight: 900;
  color: #ff8227; }
  .pin-text p {
    margin: 0;
    align-self: center;
    padding-bottom: 20px; }

.inner-text-pin {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 900;
  color: #1a397f;
  padding-top: 25px;
  width: 50%; }

.margin-div {
  margin-left: 45px; }

.input-box-pin {
  display: flex;
  justify-content: center; }
  .input-box-pin p {
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 900;
    color: #ff8227;
    margin-top: 45px;
    margin-bottom: 10px;
    padding: 5px; }
  .input-box-pin .get-started {
    display: block;
    min-width: 280px;
    width: 280px;
    font-family: Oduda;
    font-size: 18px;
    font-weight: 600;
    color: #ff8227;
    margin-left: 0px;
    border: none;
    height: 50px;
    -webkit-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    -moz-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    border-radius: 20px;
    background: url("/images/landing/bgc_btn.png") no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    padding-top: 15px; }
  .input-box-pin input {
    height: 50px;
    font-family: Oduda;
    font-size: 18px;
    font-weight: 900;
    color: #015f99;
    background-color: #86f4ff;
    border-radius: 20px;
    border: none;
    outline: none;
    padding-left: 30px;
    -webkit-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    -moz-box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    box-shadow: 0 5px 3px rgba(4, 140, 222, 0.75);
    padding-right: 90px;
    max-width: 500px;
    text-align: center; }

.flex {
  display: flex;
  flex-wrap: wrap; }

.flex-sub {
  flex-direction: column;
  align-items: center; }

.flex-outer {
  display: flex;
  justify-content: center; }

.main-submit {
  position: absolute;
  top: 0;
  right: 0;
  font-family: Oduda;
  width: 70px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
  height: 50px;
  border-radius: 0 20px 20px 0;
  background: url("/images/landing/widget_btn.png") no-repeat;
  background-size: 100% 100%;
  outline: none;
  cursor: pointer; }

.planshet-landing {
  background: #0f86e8;
  padding-bottom: 40px; }

.inner-img-planshet {
  text-align: center;
  padding-top: 15px; }
  .inner-img-planshet img {
    width: 50%;
    height: auto;
    display: block;
    margin: 0 auto; }

.title-planshet-landing {
  background: #0873d7;
  margin-top: 15px;
  padding: 0 0 15px 0; }

.inner-title-section-planshet {
  display: flex;
  justify-content: center;
  padding-top: 30px; }

.left-title-planshet {
  width: 100%; }

.inner-left-title-planshet {
  display: flex;
  width: 40%;
  justify-content: space-between; }
  .inner-left-title-planshet img {
    max-width: 100%; }
  .inner-left-title-planshet p {
    margin: 0;
    font-size: 14px;
    color: white;
    width: 70%;
    padding-top: 10px; }

.inner-width-planshet {
  width: 25%; }

.inner-title-section-planshet .inner-left-title-planshet:first-child {
  padding-right: 2%; }

.inner-title-section-planshet .inner-left-title-planshet:last-child {
  padding-left: 2%; }

.contact-us {
  color: #a4e8ff; }

.three-section {
  background: #6bdcff; }

.three-section-h1 {
  font-size: 36px;
  color: #014ca9;
  font-weight: 600;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 45px;
  margin: 0; }

.three-section-h2 {
  font-size: 24px;
  color: #014ca9;
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding-bottom: 70px; }

.box-img-three {
  padding: 20px 60px;
  background: url("/images/landing/bcg-item.png") no-repeat center center;
  background-size: 100% 100%;
  width: 36%;
  min-width: 300px;
  padding-bottom: 5px; }
  .box-img-three ul {
    font-size: 14px;
    color: #1a397f;
    font-weight: 400;
    list-style-type: none;
    list-style-image: url("/images/landing/style-type.png"); }

.inner-box-img-three {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px; }
  .inner-box-img-three img {
    width: 5.57vw;
    max-width: 2.5vw;
    min-width: 85px;
    border-radius: 25%;
    -webkit-border-radius: 21px/19px;
    -moz-border-radius: 21px/19px;
    border-radius: 21px/19px;
    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.75); }
  .inner-box-img-three p {
    font-size: 19px;
    color: #014687;
    font-weight: 800;
    margin: 0;
    padding-left: 15px; }

.container-three {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .container-three .box-img-three:first-child {
    margin-right: 3%;
    margin-bottom: 10px; }
  .container-three .box-img-three:last-child {
    margin-left: 3%;
    margin-bottom: 10px; }

.btn-more-topic {
  width: 20%;
  background: url("/images/landing/btn.png") no-repeat center center;
  background-size: 100% 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  color: #014687;
  font-weight: 600;
  padding: 15px 0px;
  margin-top: 30px;
  margin-bottom: 130px;
  display: inline-block;
  text-decoration: none; }

.btn-more-topic:active {
  background: url("/images/landing/btn-pressed.png") no-repeat center center;
  width: 20%;
  background-size: 100% 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  color: #014687;
  font-weight: 600;
  padding: 15px 0px;
  margin-top: 30px;
  margin-bottom: 130px;
  display: inline-block;
  text-decoration: none; }

.four-section {
  background: #22caff; }

.column-four {
  display: flex;
  justify-content: center; }
  .column-four .inner-column:first-child {
    padding-right: 2%; }
  .column-four .inner-column:last-child {
    padding-left: 2%; }

.inner-column {
  width: 30%; }
  .inner-column p {
    text-align: center; }
  .inner-column a {
    display: inline-block;
    background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 20px;
    color: white;
    text-decoration: none;
    padding: 10px 0;
    width: 100%;
    font-size: 18px;
    font-weight: 900;
    background-size: 5%; }
  .inner-column a:hover {
    display: inline-block;
    background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 20px;
    color: white;
    text-decoration: none;
    padding: 10px 0;
    width: 100%;
    font-size: 18px;
    font-weight: 900;
    background-size: 5%; }

.btn-four {
  margin-bottom: 70px; }

.btn-four:active {
  margin-bottom: 70px; }

.five-section {
  padding-bottom: 30px;
  background: #ffffff; }

.outer-five-section {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.inner-five-section {
  width: 57%;
  display: flex;
  align-self: center; }

.bold {
  font-weight: 800 !important; }

.five-section-first-p {
  font-size: 16px;
  color: #1a397f;
  font-weight: 600;
  margin: 0; }

.five-section-two-p {
  font-size: 16px;
  color: #1a397f;
  font-weight: 800;
  margin: 45px 0 10px 0; }

.five-section-three-p {
  font-size: 14px;
  color: #1a397f;
  font-weight: 600;
  margin: 0; }

.pagination {
  margin: 45px 0;
  display: flex;
  justify-content: center; }
  .pagination .active-pagin {
    display: inline-block;
    width: 100px;
    height: 13px;
    background: #ff7800; }
  .pagination span {
    display: inline-block;
    width: 100px;
    height: 13px;
    background: #568da4;
    cursor: pointer;
    margin-right: 35px; }
  .pagination span:last-child {
    margin-right: 0; }

.five-section-img {
  max-width: 221px;
  max-height: 208px;
  min-width: 115px;
  width: 50%;
  margin-right: 25px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  border: solid 1px #c8edfb; }

.five-section-h1 {
  padding-top: 0;
  padding-bottom: 80px; }

.two-slider-outer {
  display: flex;
  justify-content: center; }

.inner-two-slider {
  display: flex;
  width: 57%;
  justify-content: space-between; }

.item-slider {
  width: 20%; }

.six-section {
  background-color: #b3f4fe;
  background-image: -webkit-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -moz-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -o-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: -ms-linear-gradient(top, #4fc7fe, #d0f8fe);
  background-image: linear-gradient(to bottom, #4fc7fe, #d0f8fe); }

.inner-six-section {
  display: flex; }

.six-left-sidebar {
  width: 18%;
  position: relative;
  text-align: center; }
  .six-left-sidebar img {
    max-width: 100%;
    padding-top: 170px;
    padding-left: 10px;
    padding-right: 10px; }
  .six-left-sidebar p {
    position: absolute;
    font-size: 30px;
    font-family: Oduda;
    top: 32%;
    margin: 0;
    left: 0;
    color: #005c83;
    font-weight: 800;
    text-align: center; }
  .six-left-sidebar .pos-rel {
    position: relative; }

.center-sidebar {
  width: 64%;
  background: #b3f4fe; }
  .center-sidebar h1 {
    font-size: 36px;
    font-weight: 600;
    color: #005c83;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 30px; }
  .center-sidebar h2 {
    font-size: 24px;
    font-weight: 400;
    color: #005c83;
    text-align: center;
    margin-top: 0;
    margin-bottom: 90px; }
  .center-sidebar .parent-box {
    padding: 45px 20px; }
    .center-sidebar .parent-box .child-profile {
      text-align: left;
      font-size: 15px; }
    .center-sidebar .parent-box .price-text {
      font-size: 20px;
      margin-bottom: 0;
      margin-top: 50px; }
    .center-sidebar .parent-box .del-price-land {
      font-size: 15px; }

.del-price-land:before {
  height: 35%; }

.six-right-sidebar {
  width: 18%; }

.inner-center-sidebar {
  width: 21%; }
  .inner-center-sidebar img {
    max-width: 100%; }
  .inner-center-sidebar .parent-box {
    margin-bottom: 80px;
    margin-top: 30px; }

.child-profile-price {
  text-align: center !important; }

.head-btn {
  display: inline-block;
  font-size: 24px;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
  cursor: pointer;
  font-weight: 600; }

.footer-btn {
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 95px;
  cursor: pointer; }

.head-btn:hover, .footer-btn:hover {
  background: #ff8227; }

.flex-center {
  display: flex;
  justify-content: center; }
  .flex-center .inner-center-sidebar {
    margin-right: 5%; }
  .flex-center .inner-center-sidebar:last-child {
    margin-right: 0; }

.seven-section {
  padding-bottom: 50px;
  background: white; }

.seven-section-h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 75px 0 30px 0;
  color: #00539a; }

.seven-section-p {
  font-size: 24px;
  font-weight: 600;
  color: #0063b7;
  margin: 0;
  padding-bottom: 30px;
  padding-left: 200px;
  text-align: left; }
  .seven-section-p a {
    font-size: 18px;
    color: #009fe3;
    padding-left: 35px; }

.slider-box {
  display: flex;
  justify-content: center; }

.inner-slider-box {
  width: 80%;
  position: relative; }

.box-sliders {
  display: flex;
  justify-content: center; }
  .box-sliders .slider-item:last-child {
    padding-right: 0; }

.left-slide-nav {
  position: absolute;
  top: calc(50% - 18.5px);
  left: -47px;
  width: 21px;
  height: 37px;
  background: url("/images/landing/left-arrow.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer; }

.left-slide-nav:hover {
  background: url("/images/landing/left-arrow-hover.png") no-repeat;
  background-size: 100% 100%;
  top: calc(50% - 18.5px);
  left: -47px;
  width: 21px;
  height: 37px; }

.right-slide-nav {
  position: absolute;
  top: calc(50% - 18.5px);
  right: -47px;
  width: 21px;
  height: 37px;
  background: url("/images/landing/right-arrow.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer; }

.right-slide-nav:hover {
  background: url("/images/landing/right-arrow-hover.png") no-repeat;
  background-size: 100% 100%;
  top: calc(50% - 18.5px);
  right: -47px;
  width: 21px;
  height: 37px; }

.two-slider {
  margin-top: 95px; }

.eight-section {
  padding-bottom: 30px;
  background: #e6fcff; }

.eight-section-h1 {
  font-size: 36px;
  font-weight: 600;
  color: #00539a;
  margin: 0;
  padding-top: 85px;
  text-align: center; }

.eight-section-p {
  font-size: 24px;
  font-weight: 600;
  color: #0048b5;
  margin: 0;
  padding-top: 65px; }

.eight-section-two-p {
  font-size: 15px;
  font-weight: 400;
  color: #4f5050;
  margin: 0;
  padding: 0 0 20px 0; }

.link-blog {
  font-size: 15px;
  color: #0f86e8;
  display: inline-block; }

.blog-box {
  width: 75%;
  margin: 0 auto; }

.img-box {
  align-self: flex-start;
  width: 40%; }

.title-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin-left: 5%; }

.outer-titel {
  display: flex; }

.date-p {
  padding-top: 15px; }

.nine-section {
  padding-bottom: 150px;
  background: #0f86e8; }

.about-h1 {
  font-size: 36px;
  color: #ffed00;
  margin: 0;
  padding: 80px 0 10px 0;
  text-align: center; }

.about-box {
  display: flex;
  align-items: center;
  width: 75%;
  margin: 0 auto; }

.about-p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding-left: 40px; }

.ten-section {
  background: #4663e5;
  padding-top: 35px;
  padding-bottom: 35px; }

.first-inner-box-ten {
  width: 26%; }

.first-inner-box-p {
  font-size: 20px;
  color: #ffed00;
  margin: 0;
  padding-bottom: 25px; }

.two-inner-box-p {
  font-size: 14px;
  color: white;
  margin: 0;
  line-height: 2; }

.pad-ten-box {
  padding-bottom: 35px; }

.about-nav {
  list-style-type: none;
  padding: 0; }
  .about-nav a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 25px; }
  .about-nav a:hover {
    color: #ffed00; }

.inner-ten-box {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto; }

.twit-icon {
  display: inline-block;
  width: 28px;
  height: 23px;
  background: url("/images/landing/twitter.png") no-repeat center center;
  float: left;
  margin-top: 8px; }

.margin-icon {
  margin-left: 58px; }

.footer {
  height: auto;
  background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
  background-size: 115% 115%;
  padding: 5px 15px 5px 15px; }

.inner-footer {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-wrap: wrap; }

.soc-icon-footer {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: 100% 100%; }

.gplus-brand, .twitter-brand, .linkedin-brand, .pinterest-brand {
  margin-right: 5px; }

.footer-div {
  display: flex;
  align-items: center; }
  .footer-div img {
    width: 50%;
    margin-right: 15px; }

.link-footer {
  font-size: 14px;
  color: #4166e5;
  text-decoration: none; }

.right-link-footer {
  margin-right: 115px; }

.slick-slide img {
  margin: 0 auto; }

.about-img {
  width: 163px; }

.test1 {
  width: 115px;
  display: block; }

.test2 {
  width: calc(100% - 115px);
  display: block; }

.adaptive-container-show {
  display: flex !important; }

.adaptive-show {
  display: block !important; }

.disp-mobile {
  display: none; }

.disp-desck {
  display: block; }

.center-padd {
  padding-left: 2%; }

.flex-auto {
  flex: 1 0 auto; }

.inner-child-cont, .surprise-rewards-main-no-rewards,
.surprise-rewards-main, .pet-rewards-main {
  max-width: 1300px;
  margin: 0 auto; }

.child-nav {
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  height: 38px;
  background-color: #0ec3fc;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  border: none;
  text-align: right; }
  .child-nav .child-logo {
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 60px;
    float: left; }
    .child-nav .child-logo img {
      max-width: 100%; }

.head-child-nav {
  font-size: 0;
  padding-left: 0;
  list-style-type: none;
  background: #009fe3;
  float: right;
  margin-bottom: 30px;
  min-height: 38px; }
  .head-child-nav li {
    display: inline-block;
    margin-left: -1px;
    margin-right: -2px; }
  .head-child-nav li:last-child {
    margin-right: 0; }
  .head-child-nav li:first-child a {
    border-left: none; }
  .head-child-nav li:last-child a {
    border-right: none; }
  .head-child-nav li a {
    display: inline-block;
    padding: 1px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    margin-top: 9px;
    margin-bottom: 9px;
    border-right: 3px solid #e1e1e1;
    padding-left: 15px;
    padding-right: 15px; }
  .head-child-nav li:hover {
    background: #ff8227; }
  .head-child-nav li:hover a {
    border-color: transparent; }

.main-child-nav-box {
  display: -webkit-flex;
  display: flex; }

.main-login-child {
  font-size: 18px;
  color: white;
  background: #009fe3;
  text-decoration: none;
  width: 129px;
  height: 32px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-align: center;
  padding-top: 5px;
  margin-left: 35px;
  margin-right: 35px;
  font-weight: 600; }

.main-login-child:hover {
  background: #ff8227; }

.child-nav-box {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center; }

.footer-child {
  height: auto;
  background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
  background-size: 115% 115%;
  padding: 5px 15px 5px 15px; }

.inner-footer-child {
  height: 100%;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: space-between;
  -webkit-flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.footer-div-child {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-flex-wrap: wrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .footer-div-child img {
    width: 181px;
    margin-right: 0px; }

.link-footer-child {
  font-size: 16px;
  color: #4166e5;
  text-decoration: none;
  margin-left: 65px; }

.main-child-container {
  background: url("/images/childDash/child_bcg.png") no-repeat center center;
  background-size: cover;
  padding-bottom: 115px;
  height: 100%;
  flex: 1 0 auto; }

.active-box, .active-ball {
  display: block; }
  .active-box img, .active-ball img {
    max-width: 70%; }

.left-child-content {
  width: 80%;
  padding-right: 10%;
  padding-left: 10%; }

.right-child-sidebar {
  width: 20%; }

.image-right-box-child {
  padding-bottom: 60px;
  text-align: center; }
  .image-right-box-child p {
    font-size: 14px;
    color: #3d298b;
    font-weight: 700;
    text-align: center; }

.child-dash-avatar {
  border-radius: 50%;
  box-shadow: 0 0 10px 0px #000000, 0 0 158px rgba(255, 255, 190, 0.75); }

.inner-child-cont {
  display: -webkit-flex;
  display: flex;
  padding: 10px 20px 0 20px; }

.child-inner-avatar {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-flex-wrap: wrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .child-inner-avatar p {
    font-size: 24px;
    font-family: Oduda;
    font-weight: 700;
    color: #ff8227;
    height: 60px;
    line-height: 63px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    border-radius: 45px;
    background-color: #fff362;
    text-align: left;
    width: 40%;
    padding-left: 5%;
    margin-left: 40px; }

.result-child-rewards {
  background: url("/images/childDash/bcgRewards.png") no-repeat center center;
  background-size: 100% 100%;
  border-radius: 30px;
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: flex-end;
  -webkit-flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 10px 0;
  padding-right: 4%;
  padding-left: 4%;
  -webkit-box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75);
  -moz-box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75);
  box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75); }
  .result-child-rewards .inner-result-student {
    padding: 5px; }
    .result-child-rewards .inner-result-student img {
      width: 35%; }
    .result-child-rewards .inner-result-student span {
      font-size: 18px; }

.result-child-rewards .inner-result-student p:last-child {
  font-size: 12px; }

.title-child-score {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center; }
  .title-child-score .left-title-score {
    font-family: Oduda;
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    color: #1892ff;
    width: 59%;
    padding-left: 3%; }
  .title-child-score .right-title-score {
    font-family: Oduda;
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #ff8227;
    width: 40%; }

.section-play {
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  -webkit-flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: url("/images/childDash/bcgFish.png") no-repeat center center;
  background-size: 100% 100%;
  border-radius: 45px;
  -webkit-box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75);
  -moz-box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75);
  box-shadow: 0 10px 4px 3px rgba(4, 140, 222, 0.75);
  margin-bottom: 10px; }

.inner-section-play-first {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center; }
  .inner-section-play-first img {
    -webkit-align-self: center;
    align-self: center;
    margin-left: 30px; }
  .inner-section-play-first p {
    font-size: 28px;
    font-family: Oduda;
    font-weight: 700;
    color: #ff8000;
    padding-left: 75px; }

.inner-section-play-second {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 0; }
  .inner-section-play-second .fish-container-0 {
    width: 305px;
    height: 65px;
    background: url("/images/childDash/goldfish_empty.png") no-repeat center center; }
  .inner-section-play-second .fish-container-1 {
    width: 305px;
    height: 65px;
    background: url("/images/childDash/goldfish1.png") no-repeat center center, url("/images/childDash/goldfish_empty.png") no-repeat center center; }
  .inner-section-play-second .fish-container-2 {
    width: 305px;
    height: 65px;
    background: url("/images/childDash/goldfish2.png") no-repeat center center, url("/images/childDash/goldfish_empty.png") no-repeat center center; }
  .inner-section-play-second .fish-container-3 {
    width: 305px;
    height: 65px;
    background: url("/images/childDash/goldfish3.png") no-repeat center center, url("/images/childDash/goldfish_empty.png") no-repeat center center; }
  .inner-section-play-second .play-btn-child {
    display: inline-block;
    width: 199px;
    height: 65px;
    background: url("/images/childDash/play.png") no-repeat center center;
    margin-right: 30px;
    margin-left: 30px; }
  .inner-section-play-second .play-btn-child:active {
    display: inline-block;
    width: 199px;
    height: 65px;
    background: url("/images/childDash/play_pressed.png") no-repeat center center;
    margin-right: 30px;
    margin-left: 30px; }
  .inner-section-play-second .finish-btn-child {
    display: inline-block;
    width: 199px;
    height: 65px;
    background: url("/images/childDash/finish.png") no-repeat center center;
    margin-right: 30px;
    margin-left: 30px; }
  .inner-section-play-second .finish-btn-child:active {
    display: inline-block;
    width: 199px;
    height: 65px;
    background: url("/images/childDash/finish_pressed.png") no-repeat center center;
    margin-right: 30px;
    margin-left: 30px; }

.percent-title-child {
  padding-bottom: 5px; }
  .percent-title-child span {
    font-family: Oduda;
    font-size: 16px;
    font-weight: 600;
    color: #1892ff;
    display: inline-block;
    width: 16%; }
  .percent-title-child span:first-child {
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
    width: 48%;
    padding-left: 3%; }

.container-report-child .section-play .inner-section-play-first p {
  font-size: 20px; }

.container-report-child .section-play .inner-section-play-first {
  width: 46%; }

.round-progress-child {
  width: 50%;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .round-progress-child .inner-round-progress-child {
    position: relative;
    display: inline-block;
    width: 30%;
    font-size: 16px;
    color: #912c03;
    font-weight: 600; }

.inner-progress-percent {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: block;
  width: 100%;
  font-size: 16px;
  color: #912c03;
  text-align: center;
  font-weight: 600; }

.outer-div-progress-percent {
  position: relative;
  margin-right: 19%;
  margin-left: 4%;
  margin-top: 3px; }

.child-choose-avatar .main-child-container .inner-child-cont
.left-child-content .section-play p {
  font-size: 21px; }

.change-avatar-p {
  font-size: 24px;
  font-weight: 700;
  font-family: Oduda;
  color: #ff8227;
  padding: 40px 0 10px 2%;
  margin: 0; }

.choose-avatar-number {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-bottom: 30px; }
  .choose-avatar-number .radio-avatar {
    display: none; }
  .choose-avatar-number .label-avatar {
    cursor: pointer;
    position: relative;
    font-size: 25px;
    font-family: Oduda;
    color: #ff8227;
    padding-left: 90px;
    padding-right: 30px;
    white-space: nowrap;
    display: flex;
    align-items: center; }
    .choose-avatar-number .label-avatar img {
      margin-left: 30px; }
  .choose-avatar-number span {
    background-image: none !important; }
  .choose-avatar-number .span-avatar {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 14.5px);
    display: block;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #fff362;
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
    -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 0px 4px #00c6f6;
    border: solid 3px #fff; }
  .choose-avatar-number .radio-avatar:checked + .span-avatar {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #ff8227;
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 2px 5px #00c6f6;
    -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 2px 5px #00c6f6;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.75), inset 0 0 2px 5px #00c6f6; }

.choose-avatar-number:last-child {
  padding-bottom: 0; }

.outer-div-avatar {
  width: 100%;
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  padding-bottom: 60px; }

.inner-child-cont-avatar {
  padding: 0px 20px 0 20px; }

.pet-rewards-main {
  max-width: 800px;
  min-height: 500px;
  padding: 0 60px 25px 60px;
  width: 60%;
  margin: 0 auto;
  background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
  background-size: 100% 100%; }

.title-pet {
  font-size: 28px;
  font-family: Oduda;
  font-weight: 700;
  color: #fff;
  text-shadow: 5px 5px 5px #912c03;
  margin: 0;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px; }

.main-pet-contenier {
  display: flex;
  justify-content: space-between;
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  text-align: center;
  padding-top: 25px; }
  .main-pet-contenier .inner-pet-container {
    position: relative; }
    .main-pet-contenier .inner-pet-container img {
      width: 60%;
      max-width: 60%;
      min-width: 110px;
      height: 100%; }
    .main-pet-contenier .inner-pet-container p {
      font-size: 16px;
      font-family: "Open Sans";
      color: #2b010c;
      font-weight: 700;
      margin: 0;
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 18%; }

.surprise-rewards-main {
  min-height: 450px;
  max-width: 800px;
  padding: 0 10px;
  width: 60%;
  margin: 0 auto 200px auto;
  background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
  background-size: 100% 100%;
  padding-bottom: 10px; }

.title-surprise {
  font-size: 42px;
  font-family: Oduda;
  font-weight: 700;
  color: #fff;
  text-shadow: 5px 5px 5px #912c03;
  margin: 0;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 100px; }

.cont-surprise {
  position: relative;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 230px;
  -webkit-border-radius: 28px/37px 37px 28px 28px;
  -moz-border-radius: 28px/37px 37px 28px 28px;
  border-radius: 28px/37px 37px 28px 28px;
  background-color: #ffcb04;
  -webkit-box-shadow: 0 0 42px 7px rgba(255, 255, 255, 0.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111, 51, 35, 0.75);
  -moz-box-shadow: 0 0 42px 7px rgba(255, 255, 255, 0.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111, 51, 35, 0.75);
  box-shadow: 0 0 42px 7px rgba(255, 255, 255, 0.75), inset 0 0 36px 4px #ffc55d, inset 0 0 5px rgba(111, 51, 35, 0.75);
  border: solid 6px #c76904;
  background-image: -webkit-linear-gradient(top, #ffcf0a, #fff137 30%);
  background-image: -moz-linear-gradient(top, #ffcf0a, #fff137 30%);
  background-image: -o-linear-gradient(top, #ffcf0a, #fff137 30%);
  background-image: -ms-linear-gradient(top, #ffcf0a, #fff137 30%);
  background-image: linear-gradient(to bottom, #ffcf0a, #fff137 30%); }

.index-surprice {
  z-index: 1000; }

.light-bcg {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  width: 100%; }

.section-surprise {
  display: -webkit-flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
  -webkit-flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 10px; }
  .section-surprise p {
    font-size: 20px;
    font-family: Oduda;
    color: #0080e3;
    font-weight: 700;
    padding-left: 60px;
    margin: 0; }

.width-surprice {
  width: 55%; }

.flex-surprice {
  display: flex;
  display: -webkit-flex;
  padding-top: 15px; }

.right-progress-side {
  width: 45%;
  padding-top: 0;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.inner-right-surp-side {
  font-size: 20px;
  font-family: Oduda;
  font-weight: 700;
  color: #0068b8;
  margin: 0;
  text-align: center; }

.inner-right-surp-side-second {
  font-size: 21px;
  font-family: Oduda;
  font-weight: 700;
  color: #0068b8;
  margin: 0;
  text-align: center; }

.inner-big-title-progress {
  font-size: 20px;
  font-family: Oduda;
  font-weight: 700;
  color: #0080e3;
  position: absolute;
  margin: 0;
  width: 100%;
  text-align: center;
  top: calc(50% - 13px); }

.outer-box-progree-big {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  position: relative; }

.pos-rel-big {
  position: relative; }

.pos-abs-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  width: 50%; }

.right-progress-side-no-rewards {
  width: 100%;
  padding-top: 90px; }

.flex-surprice-no-rewards {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-flex-direction: column;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.surprise-rewards-main-no-rewards {
  max-width: 800px;
  padding: 0 60px;
  width: 50%;
  margin: 0 auto;
  background: url("/images/childDash/bcg_rewards.png") no-repeat center center;
  background-size: 100% 100%;
  padding-bottom: 340px; }

.no-surprice-p {
  font-size: 20px;
  font-family: Oduda;
  font-weight: 700;
  color: #0080e3;
  margin: 0;
  text-align: center; }

.child-nav .child-logo img {
  width: 250px;
  max-width: 100%; }

.inner-section-play-first p {
  font-size: 20px;
  padding-left: 1vw;
  margin: 0; }

.title-child-score .right-title-score {
  font-size: 18px; }

.title-child-score .left-title-score {
  font-size: 22px; }

.child-inner-avatar p {
  font-size: 18px;
  font-family: Oduda;
  font-weight: 700;
  color: #ff8227;
  height: 40px;
  line-height: 43px;
  border-radius: 45px;
  background-color: #fff362;
  text-align: left;
  width: auto;
  min-width: 130px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 40px; }

.inner-section-play-second .fish-container-0,
.inner-section-play-second .fish-container-1,
.inner-section-play-second .fish-container-2,
.inner-section-play-second .fish-container-3 {
  width: 155px;
  background-size: 100%; }

.inner-section-play-second .play-btn-child,
.inner-section-play-second .play-btn-child:active,
.inner-section-play-second .finish-btn-child:active,
.inner-section-play-second .finish-btn-child {
  width: 100px;
  background-size: 100%; }

.inner-section-play-first img {
  width: 80px;
  border-radius: 15px; }

.child-dash-avatar {
  width: 110px;
  min-width: 110px;
  height: 108px; }

.choose-avatar-number .child-dash-avatar {
  width: 90px;
  min-width: 90px;
  height: 88px; }

.title-pet {
  padding-bottom: 25px; }

.inner-big-title-progress {
  height: 33px; }

.surprise-rewards-main-no-rewards {
  padding-bottom: 100px; }

.right-progress-side-no-rewards {
  padding-top: 10px; }

.section-surprise p {
  padding-left: 8.25vw; }

.cont-surprise {
  width: 100px;
  height: 90px; }

.index-surprice {
  max-width: 60%;
  width: 60%; }

.rel-pos-li {
  position: relative; }

.dropDownMenuChild {
  position: absolute;
  left: 0;
  top: 38px;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #009fe3;
  z-index: 100; }
  .dropDownMenuChild li {
    display: block;
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: none; }

.dropDownMenuChild li a {
  padding: 3px 28px;
  border-right: none;
  cursor: pointer; }

.bord-right-none {
  border-right: none !important; }

.rel-pos-li.logout-child {
  display: none; }

.box-switch-test {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row; }

.switch-test {
  font-family: Oduda;
  font-size: 16px;
  font-weight: 700;
  color: #1892ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: 180px;
  height: 40px;
  margin: 10px 15px 10px 0;
  text-align: center;
  -webkit-border-radius: 24px/36px 36px 37px 37px;
  -moz-border-radius: 24px/36px 36px 37px 37px;
  border-radius: 24px/36px 36px 37px 37px;
  background-color: #fff362;
  -webkit-box-shadow: -1px 6px 4px 3px rgba(51, 153, 255, 0.76);
  -moz-box-shadow: -1px 6px 4px 3px rgba(51, 153, 255, 0.76);
  box-shadow: -1px 6px 4px 3px rgba(51, 153, 255, 0.76); }

.arrow-switch-left {
  display: block;
  width: 20px;
  height: 30px;
  background: url("/images/childDash/left_arr.png") no-repeat center center;
  background-size: 100% 100%;
  margin-left: 5px; }

.arrow-switch-right {
  display: block;
  width: 20px;
  height: 30px;
  background: url("/images/childDash/right_arr.png") no-repeat center center;
  background-size: 100% 100%;
  margin-right: 5px; }

.arrow-switch-left:hover {
  display: block;
  width: 20px;
  height: 30px;
  background: url("/images/childDash/left_arr_active.png") no-repeat center center;
  background-size: 100% 100%;
  margin-left: 5px; }

.arrow-switch-right:hover {
  display: block;
  width: 20px;
  height: 30px;
  background: url("/images/childDash/right_arr_active.png") no-repeat center center;
  background-size: 100% 100%;
  margin-right: 5px; }

.main-contact-us {
  flex: 1 0 auto; }
  .main-contact-us__section {
    margin-top: 68px; }

.contact__container {
  display: flex;
  padding-left: 14vw;
  padding-right: 10px; }

.title-contact {
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  padding: 0px 0 5px 0;
  margin: 0;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75); }

.title-contact-loc {
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  padding: 10px 0 10px 0;
  margin: 0;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75); }

.contact {
  background: #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75); }
  .contact p {
    margin: 0; }

.contact-form {
  color: black;
  font-weight: 600;
  font-size: 16px; }
  .contact-form input, .contact-form textarea {
    width: 45%;
    background: #e9fdff;
    border: none;
    resize: none;
    padding: 10px; }
  .contact-form textarea {
    width: 100%;
    height: 150px; }
  .contact-form__title {
    padding: 20px 0 20px 0; }
  .contact-form__subject, .contact-form__robot, .contact-form__comments {
    padding: 20px 0 0 0; }
  .contact-form__valid-email, .contact-form__valid-robot {
    font-size: 14px;
    color: red; }
  .contact-form__privacy {
    padding: 10px 0 15px 0; }
    .contact-form__privacy a {
      color: black; }

.box-submit {
  text-align: right; }
  .box-submit__submit {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    background: #009fe3;
    padding: 10px 0 10px 0;
    width: 40%;
    text-align: center;
    margin: 20px 0 20px 0; }
  .box-submit__submit:hover {
    background: #ff8227; }

.adress {
  margin: 0 0 20px 0;
  background: #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  font-size: 16px;
  color: black;
  font-weight: 700; }
  .adress p {
    margin: 0; }
  .adress__mailing {
    text-align: center;
    padding: 20px 0 10px 0; }
  .adress__box {
    text-align: center;
    padding: 0px 0 20px 0; }

.deals {
  min-height: 1000px;
  background: #b3f4fe; }

.deals-inner-six-section {
  display: flex; }

.deals-six-right-sidebar {
  width: 18%; }

.deals-six-left-sidebar {
  width: 18%;
  position: relative;
  text-align: center; }

.deals-six-left-sidebar .pos-rel {
  position: relative; }

.deals-six-left-sidebar img {
  max-width: 75%;
  min-width: 150px;
  padding-top: 90px;
  padding-left: 10px;
  padding-right: 10px; }

.deals-six-left-sidebar p {
  width: 100%;
  position: absolute;
  font-size: 24px;
  font-family: Oduda;
  top: 27%;
  margin: 0;
  left: 0;
  color: #005c83;
  font-weight: 800;
  text-align: center; }

.deals-center-sidebar {
  width: 64%;
  background: #b3f4fe; }
  .deals-center-sidebar h1 {
    font-size: 24px;
    font-weight: 600;
    color: #005c83;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px; }
  .deals-center-sidebar h2 {
    font-size: 18px;
    font-weight: 400;
    color: #005c83;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px; }

.deals-flex-center .deals-inner-center-sidebar {
  margin-right: 40px; }

.deals-inner-center-sidebar {
  width: 27%;
  text-align: center; }
  .deals-inner-center-sidebar img {
    max-width: 70%; }

.deals-head-btn {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 600; }

.deals-head-btn:hover {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background: #ff8227;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 600; }

.deals-footer-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: #009fe3;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer; }

.deals-footer-btn:hover {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: #ff8227;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer; }

.deals-parent-box {
  background: white;
  padding: 15px;
  margin-bottom: 0px; }

.deals-child-profile {
  font-size: 16px;
  color: #4f5050;
  text-align: left;
  margin: 0;
  margin-bottom: 5px; }

.deals-child-profile-center {
  font-size: 16px;
  color: #4f5050;
  text-align: center;
  margin: 0;
  margin-bottom: 51px; }

.deals-flex-center {
  display: flex;
  justify-content: center; }

.deals-price-text {
  font-family: Oduda;
  font-size: 20px;
  color: #ff8227;
  text-align: center;
  margin-bottom: 0; }

.deals-del-price {
  position: relative;
  font-size: 16px; }

.deals-del-price:before {
  border-bottom: 2px solid red;
  position: absolute;
  content: "";
  width: 140%;
  height: 50%;
  transform: rotate(-172deg);
  left: -20px;
  bottom: 0px;
  right: 0;
  top: 10px; }

.cancel-anytime {
  display: block;
  text-align: center;
  color: #ff0000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  outline: none; }

.cancel-anytime:hover {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #c51f1f; }

.ask-title {
  font-size: 36px;
  font-weight: 600;
  color: #005c83;
  padding: 20px 0;
  text-align: center;
  background: #e9fdff;
  border-bottom: 1px solid #78dfff;
  margin-bottom: 25px; }
  .ask-title p {
    margin: 0; }

.box-text-bottom-deals {
  width: 64%;
  margin: 0 auto; }
  .box-text-bottom-deals p {
    padding: 10px 0 0 20px;
    margin: 0; }
  .box-text-bottom-deals p:nth-child(odd) {
    color: #005c83;
    font-size: 18px;
    font-weight: 700; }
  .box-text-bottom-deals p:nth-child(even) {
    color: #005c83;
    font-size: 18px;
    font-weight: 600; }

.customize {
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  padding-bottom: 20px;
  flex: 1 0 auto; }

.inner-customize {
  margin: 0 auto; }

.customize-title {
  text-align: center;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px; }

.align-box {
  width: 80%;
  margin: 0 auto; }

.inner-title-cust {
  font-size: 18px;
  color: black;
  margin: 0;
  padding: 10px 0;
  font-weight: 700; }

.box-btn-customize a {
  display: inline-block;
  color: white;
  background: #009fe3;
  text-decoration: none;
  padding: 10px 0;
  text-align: center;
  width: 30%; }

.box-btn-customize a:hover {
  display: inline-block;
  color: white;
  background: #ff8227; }

.box-btn-customize a:nth-child(n+2) {
  margin-left: 36px; }

.width-100 {
  display: flex; }

.width-30 {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .width-30 p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .width-30 p span {
      display: block;
      background: #e9fdff;
      padding: 10px 20px;
      color: #005c83;
      font-size: 18px;
      font-weight: 600; }

.two-column-customize {
  display: flex; }

.width-left-column {
  width: calc(60% + 36px);
  margin-right: 36px; }

.width-right-column {
  width: 30%; }

.bcg-b {
  background: #e9fdff;
  padding: 20px; }
  .bcg-b p {
    margin: 0; }

.check-cust {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #6d7677;
  background: none !important;
  margin-right: 15px; }

.check-cust-label {
  cursor: pointer;
  position: relative; }
  .check-cust-label input {
    display: none; }

.check-cust-label .check:checked + .check-cust:before {
  content: "";
  width: 18px;
  height: 16px;
  display: inline-block;
  position: absolute;
  background: url("/images/customize/chosen.png") no-repeat center center;
  bottom: 3px;
  left: 0;
  top: 0; }

.title-bold-cust {
  font-size: 18px;
  font-weight: 700;
  color: black;
  margin: 0;
  padding: 10px 0; }

.cupon-code {
  border: none;
  background: #e9fdff;
  width: 47.5%;
  padding: 10px; }

.error-cupon {
  margin: 0;
  color: red;
  padding: 5px 0;
  font-size: 16px; }

.cupon-box {
  padding-top: 20px;
  display: flex;
  align-items: center; }

.content-all-info {
  background: #e9fdff;
  padding: 5px; }
  .content-all-info p {
    margin: 0; }

.content-all-info-title p {
  font-size: 18px;
  color: black;
  font-weight: 600; }

.content-all-info-title p:last-child {
  padding-bottom: 30px; }

.inner-p-content-all-info {
  font-size: 16px;
  font-weight: 700;
  color: black;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px; }

.total-price-p {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  color: #cc3333;
  padding-top: 15px;
  padding-bottom: 13px; }

.inner-cupon-box {
  width: calc(60% + 36px);
  margin-right: 36px; }

.secure-payment {
  display: block;
  padding: 10px;
  width: 30%;
  text-decoration: none;
  color: white;
  background: #009fe3;
  text-align: center; }

.secure-payment:hover {
  display: block;
  padding: 10px;
  width: 30%;
  text-decoration: none;
  color: white;
  background: #ff8227;
  text-align: center; }

.red-price {
  color: red; }

.more-test {
  padding: 20px 0 0 0;
  background: #ffffff;
  display: flex;
  flex: 1 0 auto;
  margin-top: 40px !important; }

.more-tests-main {
  width: 64%; }

.first-div-tests {
  background: #e9fdff;
  padding: 10px 30px;
  margin-bottom: 20px; }

.first-p-tests {
  font-size: 20px;
  font-weight: 700;
  color: #005a87;
  margin: 0;
  padding: 0 0 15px 0; }

.second-p-tests {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin: 0;
  padding: 0 0 15px 0; }

.first-span-test {
  display: inline-block;
  width: 50%;
  padding: 5px 0 5px 15px;
  color: black; }

.second-span-test {
  display: inline-block;
  width: 50%;
  padding: 5px 0;
  color: black; }

.tests-table-first {
  width: 80%;
  margin: 0 auto; }
  .tests-table-first span {
    font-size: 16px;
    color: black;
    font-weight: 600; }
  .tests-table-first div:nth-child(2n) {
    background: #b3f4ff; }
  .tests-table-first div:nth-child(2n + 1) {
    background: #d0f9ff; }
  .tests-table-first div:nth-child(1) {
    background: #009fe3; }
    .tests-table-first div:nth-child(1) span {
      color: white; }

.tests-table-first .span-color-blue {
  color: #005a87; }

.tests-table-second {
  width: 80%;
  margin: 0 auto; }

.inner-tests-table-second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #78dfff; }
  .inner-tests-table-second span {
    display: inline-block;
    width: 18%;
    text-align: center;
    padding: 5px 0;
    font-size: 18px;
    color: #7c7c7c;
    font-weight: 600; }

.inner-tests-table-second:first-child {
  border: none;
  background: #a1eaff; }
  .inner-tests-table-second:first-child span {
    color: #000000; }

.inner-tests-table-second:last-child {
  border: none; }

.last-p-tests {
  font-size: 18px;
  font-weight: 700;
  color: black;
  margin: 0;
  padding: 15px 0 0px 0; }

.last-div-tests {
  width: 80%;
  margin: 0 auto; }

.answer-tests-box {
  padding-top: 10px; }
  .answer-tests-box span {
    display: block;
    width: 120px;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 700;
    color: black; }
  .answer-tests-box span:nth-child(odd) {
    background: #d2f9ff; }
  .answer-tests-box span:nth-child(even) {
    background: #98e5fb; }
  .answer-tests-box span:last-child {
    background: none;
    font-size: 18px;
    font-weight: 700;
    color: #1e6c93; }

.devider-div-tests {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  background: url("/images/tests/bcg-line.png") no-repeat 0 0;
  background-size: 100% 100%;
  margin-bottom: 20px; }
  .devider-div-tests img {
    box-shadow: 0 0 5px #008be3;
    border-radius: 20px; }

.inner-devider-div-tests {
  display: flex;
  flex-direction: column;
  padding-left: 10px; }
  .inner-devider-div-tests span {
    font-family: Oduda;
    font-size: 16px;
    font-weight: bold;
    color: #fff48a;
    text-shadow: 0 0 5px #008be3; }

.bottom-div-tests {
  background: #e9fdff;
  padding: 10px 30px 50px 30px;
  margin-bottom: 0px; }

.bottom-p-tests {
  font-size: 20px;
  font-weight: 600;
  color: #0063b7;
  margin: 0;
  text-align: left; }
  .bottom-p-tests a {
    font-size: 18px;
    color: #009fe3;
    padding-left: 10%; }
  .bottom-p-tests a:hover {
    font-size: 18px;
    color: #ff9d00;
    padding-left: 10%; }

.bottom-img {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }
  .bottom-img img {
    width: 18%;
    height: 100%; }

.more-test-left-sidebar-menu .active-test-menu {
  display: none; }

.blog {
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  margin: 0 auto;
  padding: 20px;
  flex: 1 0 auto; }

.box-blog-title {
  text-align: center;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  padding-bottom: 10px;
  margin-top: 50px !important; }

.inner-blog-box {
  width: 90%;
  margin: 0 auto; }
  .inner-blog-box p {
    margin: 0; }

.blog-img {
  width: 35%;
  height: 100%;
  float: left;
  margin-right: 10px; }

.p-title-main-blog {
  font-size: 20px;
  color: #073881;
  font-weight: 600;
  padding-bottom: 20px; }

.p-title-main-blog-data {
  font-size: 18px;
  color: #4f5050;
  font-weight: 600;
  padding-bottom: 20px; }

.blog-num-p {
  font-size: 16px;
  color: #4f5050;
  font-weight: 400;
  padding-bottom: 20px; }

@media (max-width: 500px) {
  .blog-img {
    float: none;
    min-width: 200px;
    display: block;
    margin: 10px auto; }
  .blog-num-p {
    font-size: 14px; } }

.more-resources {
  background: #ffffff;
  display: flex;
  padding: 20px 0 60px 0;
  flex: 1 0 auto;
  margin-top: 40px !important; }

.more-resources-left-sidebar {
  width: 18%;
  padding: 0 10px; }
  .more-resources-left-sidebar a {
    display: block;
    text-decoration: none; }

.more-resources-main {
  flex: 1 0 auto;
  width: 64%; }

.more-resources-right-sidebar {
  width: 18%;
  padding: 0 10px; }

.left-resources-p {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  background: #4682e5;
  text-align: center;
  margin: 0;
  margin-bottom: 10px; }

.left-resources-a {
  font-size: 16px;
  color: white;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  padding: 10px 30px 10px 10px;
  background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 20px;
  background-size: 10px 12px; }

.left-resources-a:hover {
  font-size: 16px;
  color: white;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
  padding: 10px 30px 10px 10px;
  background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 20px;
  background-size: 10px 12px; }

.left-resources-a._skills-desctop-hide.active,
.left-resources-a._topics-desctop-hide.active,
.left-resources-a.active-test-menu.active {
  background: #ff9d00 url("/images/landing/arrow-bottom.png") no-repeat center right 20px;
  background-size: 12px 12px; }

.btn-resources {
  font-size: 16px;
  font-weight: 600;
  color: #4663e5;
  background: url("/images/resources/btn.png") no-repeat 0 0;
  background-size: 100% 100%;
  padding: 10px;
  text-align: center;
  margin: 20px 0 20px 0; }

.btn-resources:active {
  font-size: 16px;
  font-weight: 600;
  color: #4663e5;
  background: url("/images/resources/btn_active.png") no-repeat 0 0;
  background-size: 100% 100%;
  padding: 10px;
  text-align: center;
  margin: 20px 0 20px 0; }

.title-main-resources {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  margin: 0;
  background: url("/images/resources/bcg.png") no-repeat 0 0;
  background-size: 100% 100%;
  padding: 5px 0;
  text-align: center; }

.resources-right-sidebar-bcg {
  padding: 10px 25px;
  background: url("/images/resources/sitebar.png") no-repeat 0 0;
  background-size: 100% 100%;
  text-align: center; }
  .resources-right-sidebar-bcg p {
    margin: 0; }

.img-resources-sidebar {
  -webkit-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  width: 70%;
  margin-bottom: 15px; }

.our-resources {
  font-family: Oduda;
  font-weight: 700;
  font-size: 28px;
  color: #236fc4;
  padding: 10px 0; }

.choose-resources {
  font-size: 16px;
  font-weight: 600;
  color: #00539a; }

.save-resources {
  font-family: Oduda;
  font-weight: 700;
  font-size: 28px;
  color: #ff4200;
  padding: 15px 0; }

.child-test-resources {
  font-family: Oduda;
  font-size: 18px;
  font-weight: 600;
  color: #02b1cb; }

.del-resources {
  display: inline-block;
  font-size: 18px;
  color: #474747;
  font-weight: 700;
  margin: 25px 0;
  position: relative; }

.del-resources:before {
  content: "";
  width: 143px;
  height: 68px;
  display: block;
  position: absolute;
  background: url("/images/resources/del.png") no-repeat 0 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto; }

.price-resoueces {
  font-family: Oduda;
  font-size: 24px;
  font-weight: 600;
  color: #ff8227; }

.btn-buy-resources {
  display: block;
  padding: 15px 0;
  margin: 20px 0;
  width: 100%;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background: url("/images/resources/side-btn.png") no-repeat 0 0;
  background-size: 100% 100%; }

.btn-buy-resources:active {
  display: block;
  padding: 15px 0;
  margin: 20px 0;
  width: 100%;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background: url("/images/resources/side-btn_active.png") no-repeat 0 0;
  background-size: 100% 100%; }

.inner-item-a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: white;
  font-weight: 600;
  width: 100%;
  background: #009fe3;
  padding: 5px 0;
  text-align: center;
  margin: 10px 0 15px 0; }

.inner-item-a:hover {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: white;
  font-weight: 600;
  width: 100%;
  background: #ff8227;
  padding: 5px 0;
  text-align: center;
  margin: 10px 0 15px 0; }

.inner-item-img {
  width: 100%; }

.item-resources {
  width: 150px;
  max-width: 150px;
  height: 100%;
  flex-grow: 1;
  margin-right: 10px; }

.box-for-item-resources {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto; }

.inner-title-main-resources {
  font-size: 20px;
  font-weight: 600;
  color: #0063b7;
  width: 80%;
  margin: 20px auto; }

.more-res-left-sidebar-menu .active-res-test-menu {
  display: none; }

.more-res-left-sidebar-menu .active-res-test-menu._active-hide-menu {
  display: none; }

.mobile-more-resources-right-sidebar {
  display: none;
  font-family: Oduda;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 30px;
  width: 84%;
  margin: 15px auto;
  background: url("/images/sidebar/banner_horizontal.png") no-repeat 0 0;
  background-size: 100% 100%;
  -webkit-box-shadow: 0 5px 4px 3px rgba(4, 140, 222, 0.75);
  -moz-box-shadow: 0 5px 4px 3px rgba(4, 140, 222, 0.75);
  box-shadow: 0 5px 4px 3px rgba(4, 140, 222, 0.75);
  border-radius: 30px; }
  .mobile-more-resources-right-sidebar p {
    margin: 0; }

.box-side-mob-save {
  display: flex;
  align-items: flex-end; }

.side-mob-save {
  font-family: Oduda;
  font-size: 20px;
  color: #ff4200;
  transform: rotate(-15deg);
  margin-bottom: 10px; }

.side-mob-choose {
  font-size: 20px;
  font-weight: 800;
  color: #1892ff;
  padding-bottom: 10px; }

.side-mob-child {
  font-size: 16px;
  font-weight: 800;
  color: #1892ff;
  text-align: center; }

.btn-buy-resources-sidebar {
  width: 120px;
  padding: 10px 0; }

.btn-buy-resources-sidebar:active {
  width: 120px;
  padding: 10px 0; }

.inner-mob-side-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.img-side-bar-mob img {
  width: 70px;
  height: 70px; }

.price-res-side {
  padding-top: 25px;
  padding-bottom: 10px; }

.main-reset {
  background: #fff;
  height: 500px;
  margin-bottom: 120px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.75); }

.reset-title {
  background: #f96908;
  margin: 0; }

.center-block-reset {
  width: 300px;
  min-width: 300px;
  margin: 0 auto; }

.inner-reset-title {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding: 10px 0;
  font-weight: 700; }

.reset-provide {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 10px 0; }

.reset-input-title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 10px 0; }

.reset-input {
  width: 250px;
  background: #e9fdff;
  color: #000;
  border: none;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  outline-color: rgba(204, 0, 0, 0.8);
  margin: 0 0 15px 0; }

.alert-reset {
  color: #cc0000;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 15px 0; }
  .alert-reset a {
    color: #cc0000; }

.btn-reset {
  display: block;
  width: 250px;
  color: #fff;
  text-decoration: none;
  background: #009fe3;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
  text-align: center; }

.btn-reset:hover {
  background: #f96908; }

.support-staff {
  color: #cc0000;
  font-size: 14px;
  font-weight: 600; }
  .support-staff a {
    color: #cc0000; }

.register-for-socio {
  padding-bottom: 50px;
  max-width: 1300px;
  height: 800px;
  margin-top: 50px;
  top: -250px; }

.min-soc-google-register, .min-soc-face-register {
  width: 12%;
  font-size: 16px;
  padding-top: 4px;
  height: 35px;
  margin-top: 0;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
  box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
  color: white;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  cursor: pointer; }

.min-soc-google-register {
  background: url(/images/button_min.png) no-repeat;
  background-size: 100% 100%; }

.min-soc-face-register {
  background: url(/images/button_min_two.png) no-repeat;
  background-size: 100% 100%; }

.soc-seti-google-register, .soc-seti-face-register {
  width: 70%;
  font-size: 16px;
  padding-top: 4px;
  height: 35px;
  margin-top: 0px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
  box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
  color: white;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  cursor: pointer; }

.soc-seti-google-register {
  background: url(/images/button_google.png) no-repeat;
  background-size: 100% 100%; }

.min-soc-google-register:active, .min-soc-face-register:active,
.soc-seti-google-register:active, .soc-seti-face-register:active {
  -webkit-box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86);
  box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86); }

.soc-seti-face-register {
  background: url(/images/button_face.png) no-repeat;
  background-size: 100% 100%; }

@media (min-width: 1400px) {
  .soc-seti-google-register, .soc-seti-face-register {
    height: 70px;
    font-size: 20px;
    padding-top: 0;
    line-height: 70px; }
  .min-soc-google-register, .min-soc-face-register {
    height: 70px;
    font-size: 20px;
    padding-top: 0;
    line-height: 70px; } }

.ngdialog.popup-cancelation.ngdialog-theme-default {
  margin-right: -20px;
  padding-top: 68px; }
  .ngdialog.popup-cancelation.ngdialog-theme-default .ngdialog-content {
    background: transparent;
    padding: 0; }

.box-cancel-sub {
  background: #fff;
  text-align: center;
  padding-bottom: 10px; }

.head-cancel {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 900;
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  margin: 0;
  text-align: center;
  padding: 10px 0;
  background-color: #0ec3fc;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.34);
  position: relative; }

.cancel-p {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 900;
  padding: 0 5px; }

.cancel-p-red {
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #c00;
  padding: 0 5px; }
  .cancel-p-red a {
    color: #c00; }

.btn-yes-no {
  display: inline-block;
  font-weight: 900;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 5px 40px;
  background: #009fe3;
  text-decoration: none;
  outline: none; }

.btn-yes-no:first-child {
  margin-right: 20px; }

.btn-yes-no:hover {
  display: inline-block;
  font-weight: 900;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 5px 40px;
  background: #ff8227;
  text-decoration: none;
  outline: none; }

.btn-close-sub {
  text-decoration: none;
  position: absolute;
  right: 10px;
  top: 3px;
  cursor: pointer; }

.sub-title {
  font-weight: 800;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background: #ff6b08;
  padding: 10px 0;
  margin-left: 5px;
  margin-right: 5px; }

.btn-yes-no-close:first-child {
  margin-right: 0; }

.center-block-payment {
  width: 100%;
  min-width: 240px;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 200px; }

.title-card {
  font-family: 'Open Sans', serif;
  font-size: 18px;
  color: #000;
  font-weight: 700; }

.bot-icon-pay {
  text-align: center;
  padding-top: 25px; }
  .bot-icon-pay div {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #006;
    font-weight: 600;
    padding-top: 5px; }

.btn-sub-pay {
  display: block;
  font-family: 'Open Sans', serif;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 0 auto;
  padding: 5px 20px;
  border: none;
  background: #009fe3;
  cursor: pointer; }

.inner-blog-box p._pay-cont {
  margin-top: 15px;
  text-align: center; }

._btn-sub {
  display: inline-block;
  font-family: 'Open Sans', serif;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 0 auto;
  padding: 5px 20px;
  border: none;
  background: #009fe3;
  cursor: pointer;
  text-decoration: none; }

._btn-s {
  width: 240px; }

.ladda-button .ladda-label {
  min-width: 230px; }

.btn-sub-pay._btn-s.ladda-button:focus,
.btn-sub-pay._btn-s.ladda-button:visited,
.btn-sub-pay._btn-s.ladda-button:active {
  outline: none; }

.hosted-field {
  height: 25px;
  width: 200px;
  background: #e9fdff;
  color: #1294cc; }

.container-pay {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  align-items: flex-start; }

.container-pay-card {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-end; }

.inner-cont-pay {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.error-field {
  font-family: 'Open Sans';
  font-size: 16px;
  color: #c00;
  font-weight: 600; }

@media (max-width: 460px) {
  .container-pay {
    flex-direction: column;
    align-items: center; }
  .hosted-field {
    margin: 10px 0; }
  .container-pay-card {
    align-items: center; }
  .inner-cont-pay {
    flex-direction: column;
    align-items: center;
    padding-top: 15px; } }

@media (max-width: 1400px) {
  .price-text {
    font-size: 20px; }
  .del-price {
    font-size: 16px; }
  .del-price:before {
    height: 40%; }
  .inner-popup-student {
    height: auto;
    top: 0;
    width: 50%;
    transform: none; }
  .popup-h1-student {
    font-size: 40px;
    padding-top: 50px;
    margin-bottom: 50px; }
  .close-popup {
    right: 40px;
    top: 40px;
    width: 50px;
    height: 50px; }
  .parent-email, .parent-pass {
    font-size: 26px;
    height: 50px;
    padding: 0px 30px; }
  .parent-pass {
    margin-top: 15px; }
  .alert-popup-student {
    font-size: 18px;
    margin: 0;
    width: 100%;
    position: absolute; }
  .done-button {
    width: 120px;
    height: 70px;
    margin-top: 30px; }
  .inner-popup-student .done-button {
    width: 150px; }
  .done-button:active {
    width: 120px;
    height: 70px;
    margin-top: 30px; }
  .inner-popup-student .done-button:active {
    width: 150px; }
  .new-user-student {
    margin-top: 20px;
    padding-bottom: 20px; }
  .new-user-student a {
    font-size: 16px; }
  .popup-parent-sign .inner-popup {
    height: auto;
    top: 0;
    width: 55%;
    transform: none; }
  .popup-parent-sign .popup-h1 {
    font-size: 40px;
    padding-top: 35px;
    margin-bottom: 40px; }
  .popup-parent-sign .min-soc-google, .popup-parent-sign .min-soc-face {
    font-size: 18px;
    height: 40px;
    margin-top: 0px;
    padding-top: 8px;
    margin-bottom: 15px;
    box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52); }
  .popup-parent-sign .min-soc-google:active, .popup-parent-sign .min-soc-face:active {
    font-size: 18px;
    height: 40px;
    margin-top: 0px;
    padding-top: 8px;
    margin-bottom: 15px;
    box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86); }
  .popup-parent-sign .p-popup-parent {
    margin-top: 0;
    margin-bottom: 0; }
  .popup-parent-sign .soc-seti-google, .popup-parent-sign .soc-seti-face {
    font-size: 18px;
    height: 40px;
    margin-top: 0px;
    padding-top: 8px;
    margin-bottom: 15px;
    box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52); }
  .popup-parent-sign .soc-seti-face, .popup-parent-sign .min-soc-face {
    margin-top: 0px; }
  .popup-parent-sign .soc-seti-google:active, .popup-parent-sign .soc-seti-face:active {
    font-size: 18px;
    height: 40px;
    margin-top: 0px;
    padding-top: 8px;
    margin-bottom: 15px;
    box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86); }
  .popup-parent-sign .popup-or {
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 10px; }
  .popup-parent-sign .close-popup {
    right: 40px;
    top: 35px;
    width: 50px;
    height: 50px; }
  .popup-parent-sign .parent-email, .popup-parent-sign .parent-pass {
    font-size: 26px;
    height: 50px;
    padding: 0px 30px;
    width: 58%; }
  .popup-parent-sign .parent-pass {
    margin-top: 15px; }
  .popup-parent-sign .alert-popup-student {
    font-size: 18px; }
  .popup-parent-sign .done-button {
    width: 150px;
    height: 70px;
    margin-top: 30px; }
  .popup-parent-sign .alert-popup {
    font-size: 18px;
    margin-bottom: 0;
    width: 100%;
    position: absolute;
    margin-top: 0px; }
  .popup-parent-sign .done-button:active {
    width: 150px;
    height: 70px;
    margin-top: 30px; }
  .popup-parent-sign .forgot-pass a, .popup-parent-sign .new-user a {
    font-size: 18px; }
  .popup-parent-sign .policy {
    margin-top: 50px;
    padding-bottom: 30px; }
    .popup-parent-sign .policy a {
      font-size: 20px; }
  .sign-in-popup {
    position: relative;
    margin: 0 auto;
    width: 55%;
    height: auto;
    background: url("/images/signIn/bcg.png") no-repeat center center;
    background-size: 100% 100%;
    transform: none;
    top: 0; }
  .popup-h1-student-inner-sign {
    padding-bottom: 50px;
    font-family: Oduda;
    font-weight: 900;
    font-size: 40px;
    margin: 0;
    text-align: center;
    padding-top: 50px;
    color: #ff8000;
    padding-bottom: 24px; }
  .box-sign-in {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    width: 80%;
    margin-top: 30px; }
  .inner-box-sign-in {
    text-align: center;
    padding-bottom: 70px; }
    .inner-box-sign-in img {
      width: 55%;
      max-width: 100%; }
    .inner-box-sign-in a {
      display: block;
      font-size: 34px;
      font-family: Oduda;
      font-weight: 900;
      color: #007e00;
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 50px;
      height: 28px; }
    .inner-box-sign-in a:hover {
      font-size: 34px;
      margin-top: 50px;
      height: 28px;
      background-color: #fff;
      text-shadow: 0px 2px 3px white;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -ms-background-clip: text;
      -o-background-clip: text;
      background-clip: text;
      color: #006300; }
  .close-popup-sign {
    display: block;
    width: 50px;
    height: 50px;
    background: url("/images/close.png") no-repeat;
    background-size: 100%;
    border: none;
    outline: none;
    position: absolute;
    right: 50px;
    top: 40px;
    cursor: pointer; } }

@media (max-width: 1000px) {
  .popup-parent-sign .policy a {
    font-size: 16px;
    display: inline-block; }
  .popup-parent-sign .forgot-pass a, .popup-parent-sign .new-user a {
    font-size: 14px; }
  .new-user {
    margin-right: 40px; }
  .forgot-pass {
    margin-left: 40px; } }

@media (max-width: 900px) {
  .inner-popup-student {
    width: 60%; }
    .inner-popup-student .new-user-student a {
      font-size: 14px; }
    .inner-popup-student .close-popup {
      right: 30px;
      top: 25px;
      width: 40px;
      height: 40px; }
    .inner-popup-student .parent-email, .inner-popup-student .parent-pass {
      font-size: 20px;
      border: solid 3px #7a3827;
      width: 60%; }
    .inner-popup-student .done-button {
      width: 100px;
      height: 50px;
      margin-top: 20px; }
    .inner-popup-student .done-button:hover {
      width: 100px;
      height: 50px;
      margin-top: 30px; }
    .inner-popup-student .popup-h1-student {
      font-size: 20px;
      margin-bottom: 20px;
      padding-top: 30px; }
  .popup-parent-sign .alert-popup {
    font-size: 14px; }
  .popup-parent-sign .inner-popup {
    width: 75%; }
  .popup-parent-sign .popup-h1 {
    font-size: 24px;
    margin-bottom: 30px; }
  .popup-parent-sign .popup-or {
    font-size: 24px; }
  .popup-parent-sign .close-popup {
    right: 30px;
    top: 30px;
    width: 40px;
    height: 40px; }
  .popup-parent-sign .done-button {
    width: 100px;
    height: 50px;
    margin-top: 30px; }
  .popup-parent-sign .done-button:hover {
    width: 100px;
    height: 50px;
    margin-top: 30px; }
  .popup-parent-sign .parent-email, .popup-parent-sign .parent-pass {
    font-size: 20px;
    border: solid 3px #7a3827;
    height: 40px; }
  .popup-parent-sign .soc-seti-google, .popup-parent-sign .soc-seti-face {
    font-size: 14px;
    padding-top: 2px;
    height: 30px; }
  .popup-parent-sign .soc-seti-google:hover, .popup-parent-sign .soc-seti-face:hover {
    font-size: 14px;
    padding-top: 2px;
    height: 30px; }
  .popup-parent-sign .min-soc-google, .popup-parent-sign .min-soc-face {
    font-size: 14px;
    padding-top: 2px;
    height: 30px; }
  .popup-parent-sign .min-soc-google:hover, .popup-parent-sign .min-soc-face:hover {
    font-size: 14px;
    padding-top: 2px;
    height: 30px; } }

@media (max-width: 768px) {
  .ngdialog.popup-child-sign.ngdialog-theme-default.ng-scope,
  .ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope,
  .ngdialog.popup-choose-sign-in.ngdialog-theme-default.ng-scope {
    margin-right: 0; }
  .ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope {
    margin-right: 0; }
  .popup-parent-sign .inner-popup {
    width: 80%; }
  .inner-popup-student {
    width: 70%; }
  .sign-in-popup {
    position: relative;
    margin: 0 auto;
    width: 70%;
    height: auto;
    background: url("/images/signIn/bcg.png") no-repeat center center;
    background-size: 100% 100%;
    transform: none;
    top: 0; }
  .popup-h1-student-inner-sign {
    padding-bottom: 50px;
    font-family: Oduda;
    font-weight: 900;
    font-size: 20px;
    margin: 0;
    text-align: center;
    padding-top: 40px;
    color: #ff8000;
    padding-bottom: 14px; }
  .box-sign-in {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    width: 80%;
    margin-top: 20px; }
  .inner-box-sign-in {
    text-align: center;
    padding-bottom: 50px; }
    .inner-box-sign-in img {
      width: 55%;
      max-width: 100%; }
    .inner-box-sign-in a {
      display: block;
      font-size: 18px;
      font-family: Oduda;
      font-weight: 900;
      color: #007e00;
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 20px; }
    .inner-box-sign-in a:hover {
      font-size: 18px;
      margin-top: 50px;
      height: 28px;
      background-color: #fff;
      text-shadow: 0px 2px 3px white;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -ms-background-clip: text;
      -o-background-clip: text;
      background-clip: text;
      color: #006300; }
  .close-popup-sign {
    display: block;
    width: 40px;
    height: 40px;
    background: url("/images/close.png") no-repeat;
    background-size: 100%;
    border: none;
    outline: none;
    position: absolute;
    right: 40px;
    top: 35px;
    cursor: pointer; } }

@media (max-width: 550px) {
  .popup-parent-sign .inner-popup {
    width: 100%; } }

@media (max-width: 500px) {
  .ngdialog.popup-cancelation.ngdialog-theme-default {
    margin-right: 0;
    padding-top: 45px; }
  .ngdialog.popup-cancelation.ngdialog-theme-default .ngdialog-content {
    width: 305px; }
    .ngdialog.popup-cancelation.ngdialog-theme-default .ngdialog-content ._cansel-p-cont {
      max-height: 177px;
      margin-bottom: 25px; }
    .ngdialog.popup-cancelation.ngdialog-theme-default .ngdialog-content .cancel-p {
      font-size: 14px;
      margin: 5px;
      padding: 0; }
  .inner-popup-student {
    width: 100%; }
  .sign-in-popup {
    width: 100%; } }

@media (max-width: 450px) {
  .popup-parent-sign .min-soc-google, .popup-parent-sign .min-soc-face,
  .popup-parent-sign .min-soc-google:hover, .popup-parent-sign .min-soc-face:hover {
    width: 12%; }
  .popup-parent-sign .soc-seti-google, .popup-parent-sign .soc-seti-face,
  .popup-parent-sign .soc-seti-google:hover, .popup-parent-sign .soc-seti-face:hover {
    width: 70%; }
  .popup-parent-sign .parent-email, .popup-parent-sign .parent-pass {
    width: 82%; }
  .popup-parent-sign .forgot-pass {
    float: none;
    text-align: center;
    margin: 0;
    margin-top: 5px; }
  .popup-parent-sign .new-user {
    text-align: center;
    float: none;
    margin: 0; }
  .popup-parent-sign .policy {
    margin-top: 0px;
    padding-bottom: 15px;
    margin-bottom: 0; }
  .popup-parent-sign .close-popup {
    right: 20px;
    top: 20px; } }

@media (min-width: 3000px) {
  .sign-in-popup,
  .inner-popup-student,
  .inner-popup {
    transform: scale(0.3); } }

@media (max-width: 1800px) {
  .main-login {
    font-size: 18px;
    width: 115px; } }

@media (max-width: 1400px) {
  .main-content-landing {
    padding-top: 38px;
    width: 100%;
    padding-bottom: 150px;
    background: url("/images/landing/bcg_1.png") no-repeat center 15px;
    background-size: 100% 100%; } }

@media (max-width: 1190px) {
  .pressedMenu {
    display: block; }
  .unpressedMenu {
    display: none !important; }
  .mobile-nav {
    display: block;
    position: absolute;
    right: 155px;
    top: 3px;
    cursor: pointer; }
  .head-nav-landing {
    background: transparent;
    position: relative;
    top: 18px;
    left: 65px;
    display: block;
    padding-top: 20px;
    z-index: 1000; }
  .parent-nav-media {
    background: transparent;
    position: relative;
    top: 18px;
    left: -80px;
    display: block;
    padding-top: 20px;
    z-index: 1000; }
  .head-nav-landing li {
    height: auto;
    display: block;
    background: #009fe3;
    text-align: center; }
    .head-nav-landing li a {
      display: block;
      width: 115px;
      padding: 15px 10px !important;
      margin-top: 0 !important;
      border: none;
      margin-bottom: 0 !important; }
  .head-nav-landing li:last-child {
    margin-right: -2px; } }

@media (max-width: 1090px) {
  .inner-ten-box {
    flex-wrap: wrap; }
    .inner-ten-box .first-inner-box-ten {
      width: 50%; }
    .inner-ten-box .first-inner-box-p {
      margin-left: 0; } }

@media (max-width: 1110px) {
  .six-left-sidebar p {
    font-size: 22px;
    top: 38%; }
  .right-slide-nav {
    right: -60px; }
  .left-slide-nav {
    left: -60px; }
  .right-slide-nav:hover {
    right: -60px; }
  .left-slide-nav:hover {
    left: -60px; } }

@media (max-width: 695px) {
  .title-new-manage {
    flex-direction: column; }
    .title-new-manage .box-line-manage a {
      display: inline-block; }
    .title-new-manage .box-line-manage {
      border-left: 0;
      padding: 20px 20px 20px 35px;
      text-align: center; }
  ._main._pay-access-main {
    min-height: 72vh; }
  .two-slider {
    margin-top: 0; }
  .disp-mobile {
    display: block; }
  .disp-desck {
    display: none; }
  .test2 {
    width: 100%; }
  .input-box-pin p {
    margin-top: 10px; }
  .nav-landing .main-logo img {
    position: absolute;
    z-index: 20000; }
  .block-search {
    display: none; }
  .head-nav-landing {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    padding-top: 20px;
    z-index: 10000; }
    .head-nav-landing li a {
      width: 100%; }
  #mobile-login {
    display: block; }
  .mobile-nav {
    position: static;
    margin: 3px 10px 3px 0; }
  .mobile-nav:hover {
    position: static;
    margin: 3px 10px 3px 0; }
  .main-nav-box {
    position: static; }
  .margin-div {
    margin-left: 0; }
  .box-checkbox label {
    font-size: 16px;
    margin-right: 45px; }
  .pin-text p {
    font-size: 22px; }
  .pin-container {
    margin-left: 0; }
  .right-slide-nav {
    right: 0px; }
  .left-slide-nav {
    left: 0px; }
  .right-slide-nav:hover {
    right: 0px; }
  .left-slide-nav:hover {
    left: 0px; }
  .pin-text {
    text-align: center; }
  .outer-box-checkbox {
    flex-wrap: wrap; }
  .box-checkbox:last-child label {
    margin-right: 85px; }
  .flex-outer {
    flex-direction: column; }
  .input-box-pin .get-started {
    margin-left: 0; }
  .input-box-pin input {
    max-width: 280px; }
  .main-content-landing {
    padding-bottom: 150px;
    margin-bottom: -100px; }
  .container-three {
    flex-direction: column;
    align-items: center; }
  .container-three .box-img-three:last-child {
    margin-left: 0; }
  .container-three .box-img-three:first-child {
    margin-right: 0;
    margin-bottom: 30px; }
  .btn-more-topic {
    width: 40%;
    margin-bottom: 65px; }
  .btn-more-topic:hover {
    width: 40%;
    margin-bottom: 65px; }
  .center-sidebar h2 {
    margin-bottom: 45px; }
  .flex-center {
    flex-direction: column; }
  .flex-center .inner-center-sidebar {
    align-self: center;
    margin-right: 0;
    width: 50%; }
    .flex-center .inner-center-sidebar .parent-box {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 10px 10px; }
      .flex-center .inner-center-sidebar .parent-box .child-profile {
        text-align: center; }
    .flex-center .inner-center-sidebar .head-btn {
      margin-bottom: 10px;
      font-size: 16px; }
    .flex-center .inner-center-sidebar .footer-btn {
      font-size: 16px;
      margin-bottom: 50px; }
  .inner-center-sidebar img {
    max-width: 50%; }
  .three-section-h1 {
    padding-top: 25px;
    padding-bottom: 10px;
    font-size: 18px; }
  .three-section-h2 {
    padding-bottom: 10px;
    font-size: 14px; }
  .container-three, .box-img-three {
    display: none; }
  .container-three:first-child {
    display: flex; }
  .container-three .box-img-three:first-child {
    display: block;
    margin-bottom: 10px; }
  .inner-box-img-three p {
    font-size: 16px; }
  .btn-more-topic {
    width: auto;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 15px 10px; }
  .btn-more-topic:hover {
    width: auto;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 15px 10px; }
  .inner-column {
    width: 45%; }
  .inner-column p:nth-child(n+4) {
    display: none; }
  .slick-dots li {
    width: 25% !important;
    margin-right: 5px !important; }
  .contenier-for-slide {
    flex-direction: column; }
    .contenier-for-slide p {
      padding: 10px 0 0 0; }
  .container-for-second-slider {
    flex-direction: column; }
    .container-for-second-slider p {
      padding-top: 10px; }
    .container-for-second-slider .five-section-first-p {
      padding: 10px 0 0 0; }
    .container-for-second-slider .item-slider {
      width: 100%;
      padding-top: 10px; }
  .five-section-h1 {
    display: none; }
  .yellow-btn {
    display: block; }
    .yellow-btn a {
      margin: 10px 10px;
      padding: 10px 15px;
      display: inline-block; }
    .yellow-btn a:hover {
      margin: 10px 10px;
      padding: 10px 15px; }
  .center-sidebar h1 {
    font-size: 18px;
    margin: 10px 0;
    padding-top: 15px; }
  .center-sidebar h2 {
    font-size: 14px;
    margin-bottom: 15px; }
  .about-box {
    flex-direction: column; }
    .about-box .about-p {
      padding: 20px 0 0 0;
      font-size: 14px;
      text-align: center; }
  .inner-ten-box .first-inner-box-ten {
    width: 100%;
    padding-bottom: 15px; }
  .five-section-first-p {
    font-size: 14px; }
  .five-section-two-p {
    margin-top: 15px;
    font-size: 14px; }
  .five-section-three-p {
    font-size: 14px; }
  .five-section-img {
    width: 30%; }
  .seven-section-h1 {
    font-size: 18px;
    padding: 25px 0 10px 0; }
  .seven-section-p {
    font-size: 16px;
    padding-bottom: 15px;
    padding-left: 0;
    text-align: center; }
    .seven-section-p a {
      font-size: 16px;
      padding-left: 2%; }
  .eight-section-h1 {
    font-size: 18px;
    padding-top: 25px; }
  .eight-section-p {
    padding-top: 10px;
    font-size: 16px; }
  .blog-box {
    text-align: center;
    display: none; }
  .blog-box:nth-child(2) {
    display: block;
    padding-bottom: 10px;
    height: auto; }
  .outer-titel {
    display: block; }
    .outer-titel .img-box {
      margin: 0 auto; }
  .title-blog {
    margin: 20px 0 0 0;
    width: 100%;
    display: block; }
  .about-img {
    width: 123px; }
  .img-box {
    align-self: center;
    min-width: 200px;
    display: block; }
  .about-h1 {
    font-size: 20px;
    padding: 25px 0 10px 0; }
  .about-nav {
    margin: 0 auto; }
    .about-nav a {
      margin-bottom: 10px; }
  .inner-ten-box .first-inner-box-p {
    padding-bottom: 10px; }
  .pad-ten-box {
    padding-bottom: 15px; }
  .nine-section {
    padding-bottom: 20px; }
  .ten-section {
    padding-top: 10px;
    padding-bottom: 10px; }
  .footer {
    padding: 10px 10px 10px 10px; }
    .footer .right-link-footer {
      margin-right: 65px; }
  .footer-div {
    padding: 5px 0;
    justify-content: center; }
  .inner-footer {
    justify-content: center; }
  .link-footer {
    margin-right: 25px; } }

@media (max-width: 940px) {
  .right-link-footer {
    margin-right: 20px; }
  .center-sidebar {
    width: 100%; }
  .six-right-sidebar {
    display: none; }
  .six-left-sidebar {
    display: none; } }

@media (max-width: 360px) {
  .box-checkbox label {
    margin-right: 0; }
  .box-checkbox:last-child {
    margin-right: 50px;
    padding-left: 10px; }
  .box-checkbox:last-child label {
    margin-right: 0; }
  .mobile-nav {
    right: 215px; }
  .mobile-nav:hover {
    right: 215px; }
  .main-login {
    width: 83px; }
  .inner-title-section-planshet {
    flex-direction: column;
    align-items: center; }
    .inner-title-section-planshet .inner-left-title-planshet {
      width: 90%; }
  .inner-title-section-planshet .inner-left-title-planshet:first-child {
    padding-bottom: 20px; } }

@media (min-width: 1280px) and (min-height: 600px) and (max-height: 900px) {
  .main-content-landing {
    background-size: 100% 110%;
    height: 100vh;
    max-height: 1100px; }
    .main-content-landing .pin-container img {
      width: 40%; }
  .three-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .three-section .inner-box-img-three {
      height: 70px; }
    .three-section .three-section-h1 {
      font-size: 36px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .three-section .three-section-h2 {
      font-size: 24px;
      padding-bottom: 15px; }
    .three-section .box-img-three ul {
      margin: 0; }
    .three-section .box-img-three {
      padding: 10px 60px; }
    .three-section .btn-more-topic {
      padding: 15px 0px;
      margin-top: 10px;
      margin-bottom: 0px; }
    .three-section .container-three {
      margin-bottom: 10px; }
  .box-img-three ul li {
    height: 37px; }
  .four-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .four-section .three-section-h1 {
      font-size: 36px;
      padding-top: 25px;
      padding-bottom: 25px; }
    .four-section .three-section-h2 {
      font-size: 24px;
      padding-bottom: 25px; }
  .five-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .five-section .three-section-h1 {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 36px; }
    .five-section .slick-list {
      padding-bottom: 10px !important; }
  .six-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .six-section .inner-six-section {
      height: 100vh; }
    .six-section .center-sidebar h1 {
      font-size: 36px;
      margin-top: 15px;
      margin-bottom: 15px; }
    .six-section .center-sidebar h2 {
      margin-bottom: 25px; }
    .six-section .head-btn {
      margin-bottom: 15px; }
    .six-section .inner-center-sidebar .parent-box {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 10px 20px; }
    .six-section .footer-btn {
      margin-bottom: 0; }
  .six-left-sidebar img {
    padding-top: 30px; }
  .six-left-sidebar p {
    top: 15%; }
  .seven-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .seven-section .slick-list {
      padding-bottom: 0 !important; }
    .seven-section .slick-slide img {
      width: 45%; }
  .two-slider {
    margin-top: 20px; }
  .seven-section-h1 {
    padding: 10px 0 10px 0; }
  .seven-section-p {
    padding-bottom: 10px; }
  .eight-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .eight-section-h1 {
    padding-top: 10px; }
  .eight-section-p {
    padding-top: 15px; }
  .img-box {
    width: 30%; }
  .nine-section {
    height: 50vh;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0; }
    .nine-section .about-h1 {
      padding: 0; }
  .ten-section {
    height: auto; } }

@media (min-width: 1280px) and (min-height: 901px) {
  .main-content-landing {
    height: 100vh;
    max-height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .main-content-landing .pin-container {
      margin-top: -10vh; }
    .main-content-landing .pin-container img {
      width: 70%; }
  .inner-box-img-three {
    height: 88px; }
  .three-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .three-section .three-section-h1 {
      padding-top: 25px;
      padding-bottom: 25px; }
    .three-section .three-section-h2 {
      padding-bottom: 20px; }
    .three-section .box-img-three {
      padding: 10px 60px; }
    .three-section .btn-more-topic {
      padding: 15px 0px;
      margin-top: 0px;
      margin-bottom: 0px; }
    .three-section .container-three {
      margin-bottom: 10px; }
  .four-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .four-section .three-section-h1 {
      font-size: 36px;
      padding-top: 25px;
      padding-bottom: 25px; }
    .four-section .three-section-h2 {
      font-size: 24px;
      padding-bottom: 25px; }
  .five-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .five-section .three-section-h1 {
      padding-top: 40px;
      padding-bottom: 30px; }
  .six-section {
    height: 100vh; }
  .inner-six-section {
    height: 100vh; }
  .six-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .six-section .inner-six-section {
      height: 100vh; }
    .six-section .center-sidebar h1 {
      font-size: 52px;
      margin-top: 20px;
      margin-bottom: 20px; }
    .six-section .center-sidebar h2 {
      margin-bottom: 35px; }
    .six-section .head-btn {
      margin-bottom: 15px; }
    .six-section .inner-center-sidebar .parent-box {
      margin-bottom: 25px;
      margin-top: 25px;
      padding: 45px 20px; }
    .six-section .footer-btn {
      margin-bottom: 0; }
  .six-left-sidebar img {
    padding-top: 30px; }
  .six-left-sidebar p {
    top: 15%; }
  .seven-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .seven-section .slick-slide img {
      width: 80%; }
  .seven-section-h1 {
    padding: 30px 0 30px 0; }
  .two-slider {
    margin-top: 15px; }
  .eight-section {
    height: 100vh;
    max-height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .eight-section-h1 {
    padding-top: 25px; }
  .eight-section-p {
    padding-top: 40px; }
  .img-box {
    width: 35%; }
  .nine-section {
    height: 50vh;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0; }
    .nine-section .about-h1 {
      padding: 0; }
  .ten-section {
    height: auto; } }

@media (max-width: 695px) and (max-height: 570px) {
  ._main._pay-access-main {
    min-height: 65vh; } }

@media (max-width: 695px) and (max-height: 485px) {
  ._main._pay-access-main {
    min-height: 60vh; } }

@media (max-width: 695px) and (min-width: 600px) and (min-height: 640px) {
  ._main._pay-access-main {
    min-height: 81vh; } }

@media (max-width: 1921px) {
  .student-margin {
    margin-bottom: 15px; } }

@media (min-width: 1px) and (min-height: 1px) and (max-height: 900px) {
  .block-sales {
    margin-top: 20px; }
  .our-deal {
    margin: 0; }
  .inner-text-right-sidebar {
    margin-top: 5px;
    margin-bottom: 5px; }
  .save-procent {
    margin-top: 5px;
    margin-bottom: 5px; }
  .parent-box img {
    width: 75%;
    margin: 0 auto;
    display: block; }
  .child-profile {
    margin-top: 5px;
    margin-bottom: 5px; }
  .parent-box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .inner-content {
    padding-top: 5px; }
    .inner-content .inner-h1 {
      padding-bottom: 15px;
      margin: 0;
      padding-top: 10px; }
  .select-one img, .select-two img {
    width: 50%; }
  .select-one, .select-two {
    text-align: center; }
  .under-user {
    margin-top: 15px; }
  .label-style {
    padding-top: 5px; }
  .input-style {
    padding: 5px; }
  .add-student-page {
    padding-bottom: 10px; }
  .add-student-button {
    margin-top: 15px;
    margin-bottom: 10px; }
  .label-radio {
    font-size: 16px;
    padding-top: 7px;
    height: 30px; }
  .custom-radio {
    top: -3px; }
  .title-label {
    font-size: 16px;
    padding-bottom: 10px; }
  .change-profile {
    padding: 0; }
  .select-one {
    margin-right: 0; }
  .title-block {
    padding: 0 0; }
  .nav-profile {
    padding-top: 10px;
    padding-bottom: 10px; }
  .manage-purchases .inner-h1-manage {
    padding-bottom: 0; }
  .inner-h1-manage-two {
    padding: 0; }
  .result-student {
    margin: 0 0 10px 0; }
  .title-curr {
    margin-bottom: 10px;
    margin-top: 0; }
  .nav-table-dash {
    margin-bottom: 10px; }
  .outer-manage-block .table-div-box:nth-child(even),
  .outer-manage-block .table-div-box:nth-child(odd) {
    padding-bottom: 20px;
    padding-top: 20px; }
  .invite-title {
    margin-bottom: 50px; }
  .invite-button {
    margin-top: 50px; }
  .invite-avatar {
    bottom: 100px; }
  .invite-submit {
    margin-bottom: 80px; } }

@media (min-width: 1px) and (min-height: 901px) {
  .block-sales {
    margin-top: 20px; }
  .our-deal {
    margin: 0; }
  .inner-text-right-sidebar {
    margin-top: 5px;
    margin-bottom: 5px; }
  .save-procent {
    margin-top: 5px;
    margin-bottom: 5px; }
  .parent-box img {
    width: 75%;
    margin: 0 auto;
    display: block; }
  .child-profile {
    margin-top: 5px;
    margin-bottom: 5px; }
  .parent-box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .inner-content {
    padding-top: 5px; }
    .inner-content .inner-h1 {
      padding-top: 10px;
      padding-bottom: 15px;
      margin: 0; }
  .select-one img, .select-two img {
    width: 100%; }
  .select-one, .select-two {
    text-align: center; }
  .under-user {
    margin-top: 15px; }
  .label-style {
    padding-top: 5px; }
  .input-style {
    padding: 10px; }
  .add-student-page {
    padding-bottom: 10px; }
  .add-student-button {
    margin-top: 15px;
    margin-bottom: 10px; }
  .title-block {
    padding: 0 0; }
  .title-label {
    padding-bottom: 30px; }
  .label-radio {
    font-size: 16px;
    padding-top: 7px;
    height: 30px; }
  .custom-radio {
    top: -3px; }
  .title-label {
    font-size: 16px;
    padding-bottom: 10px; }
  .change-profile {
    padding: 0; }
  .nav-profile {
    padding-bottom: 50px; } }

@media (max-width: 1300px) {
  .left-sidebar {
    display: none; } }

@media (max-width: 1200px) {
  .socio-box {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .socio-box .right-sicio-img, .socio-box .right-sicio-like {
      margin-right: 0; } }

@media (max-width: 900px) {
  .socio-box {
    flex-direction: row;
    justify-content: center; }
    .socio-box .right-sicio-like {
      margin-bottom: 0;
      margin-right: 5px; }
    .socio-box .right-sicio-img {
      margin-bottom: 0;
      margin-right: 5px; }
    .socio-box .right-sicio-tweet {
      margin-bottom: 0; }
  .under-user input[type="radio"] {
    display: none; }
  .under-user {
    display: flex;
    justify-content: center;
    align-items: center; }
    .under-user .custom-radio-add {
      position: static; }
  .manage-purchases .inner-h1-manage, .inner-h1-manage-two {
    font-size: 16px; }
  .right-sidebar {
    margin-bottom: 10px; }
  .main-content {
    flex-direction: column; }
  .inner-content {
    width: 100%;
    margin-bottom: 10px; }
  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .inner-content .inner-h1 {
    font-size: 16px; } }

@media (max-width: 695px) {
  .p-inner-manage {
    display: none; }
  .three-child .btn-box-parent-dash button, .three-child .btn-box-parent-dash a {
    padding: 10px 5px;
    max-width: 100%;
    min-width: 100%; }
  .main-reward {
    width: 100%; }
  .submit-rewards {
    padding-bottom: 20px; }
  .buy-reward {
    position: static;
    margin: 10px auto; }
  .select-reward {
    flex-direction: column; }
  .submit-rewards .marg-rew {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .submit-rewards .text-reward .reward-radio label {
    width: 20%;
    height: 60px;
    background-size: 90%; }
  .submit-rewards .text-reward .reward-radio label.baloon {
    background-size: 60%; }
  .submit-rewards .submit-title-rew {
    white-space: normal; }
  .submit-rewards .rewards-p {
    padding: 10px 20px; }
  .nav-table-dash {
    flex-direction: column; }
  .nav-table-dash button {
    margin-right: 0;
    margin-bottom: 10px; }
  .outer-cont-box {
    margin-bottom: 0; }
  .user-info {
    width: 95%; }
  .invite-card {
    flex-direction: column; }
  .invite-avatar {
    text-align: center;
    order: 1;
    bottom: 15px; }
    .invite-avatar img {
      min-width: 120px; }
  .invite-submit {
    margin-right: 0;
    margin: 0 auto; }
  .inner-h1-invite {
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .invite-title {
    margin-bottom: 40px; }
  .parent-dash {
    flex-wrap: wrap; }
  .parent-dash .inner-parent-dash {
    order: 2; }
  .three-child .btn-box-parent-dash {
    order: 1;
    margin-left: 0;
    margin-bottom: 10px; }
  ._three-child-slider {
    order: 2; }
  .three-child .inner-parent-dash {
    width: 33.3%;
    flex-grow: 1; }
  .table-report span:nth-child(n + 2) {
    display: none; }
  .three-child .btn-box-parent-dash {
    width: 100%;
    max-width: 100%;
    min-width: 100%; } }

@media (max-width: 610px) {
  .marg-bot .add-student-button {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .title-block {
    text-align: center; }
  .outer-media-profile .inner-media-profile {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .outer-media-profile .label-inner-media-profile {
    margin-bottom: 5px; } }

@media (max-width: 550px) {
  .inner-line-manage {
    flex-direction: column; } }

@media (max-width: 450px) {
  .title-block {
    text-align: center; }
  .check-box-week {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .custom-label-media {
    margin-bottom: 5px; }
  .sign-up {
    cursor: pointer;
    margin-left: auto;
    margin-right: auto; }
  .media-in-flex {
    flex-direction: column; }
  .radio-manage {
    margin: 10px 0;
    margin-left: -30px; }
  .button-inner-line {
    margin-left: 0; }
  .cont-box span:first-child {
    display: none; }
  .cont-box {
    justify-content: center;
    margin-right: 0;
    margin-left: 0; }
  .cont-box span {
    width: 19.5%; } }

@media (max-width: 400px) {
  .ie-avatar {
    width: 80%; } }

@media (min-width: 1400px) {
  .main-child-container {
    background: url("/images/childDash/child_bcg.png") no-repeat center center;
    background-size: 100% 100%;
    padding-bottom: 115px;
    height: 100%;
    flex: 1 0 auto; } }

@media (max-width: 1190px) {
  .dropDownMenuChild {
    position: absolute;
    left: -115px;
    top: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #009fe3; }
    .dropDownMenuChild li {
      display: block;
      font-size: 16px;
      margin: 0 !important;
      padding: 0;
      border: none; }
  .dropDownMenuChild li a {
    padding: 3px 28px;
    border-right: none;
    cursor: pointer; } }

@media (max-width: 1060px) {
  .left-child-content {
    padding-right: 5%;
    padding-left: 0; } }

@media (max-width: 1000px) {
  .surprise-rewards-main-no-rewards .title-pet {
    font-size: 18px; }
  .surprise-rewards-main-no-rewards .no-surprice-p, .surprise-rewards-main-no-rewards .inner-right-surp-side, .surprise-rewards-main-no-rewards .inner-right-surp-side-second {
    font-size: 16px; }
  .surprise-rewards-main .title-pet {
    font-size: 18px; }
  .surprise-rewards-main .inner-right-surp-side, .surprise-rewards-main .inner-right-surp-side-second {
    font-size: 18px; }
  .surprise-rewards-main .section-surprise p {
    padding-left: 6.25vw;
    font-size: 16px; } }

@media (max-width: 900px) {
  .outer-div-avatar .choose-avatar-number {
    padding-bottom: 0px;
    padding-top: 30px; }
  .pet-rewards-main {
    width: 70%;
    padding: 0 30px 25px 30px; }
    .pet-rewards-main .title-pet {
      font-size: 18px;
      padding-top: 35px; }
  .main-pet-contenier {
    flex-wrap: wrap;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: center; }
  .inner-pet-container {
    margin-right: 15px; }
  .main-pet-contenier .inner-pet-container img {
    width: 120px; }
  .surprise-rewards-main {
    width: 65%; }
  .media-left-child-content .section-play {
    flex-direction: column;
    align-items: center;
    -webkit-flex-direction: column;
    -webkit-align-items: center;
    padding: 5px 0; }
  .outer-div-avatar {
    flex-direction: -webkit-column;
    flex-direction: column; }
  .surprise-rewards-main-no-rewards {
    width: 60%; }
  .percent-title-child {
    display: none; }
  .box-switch-test {
    margin-bottom: 10px; } }

@media (max-width: 800px) {
  .surprise-rewards-main {
    width: 75%; } }

@media (max-width: 695px) {
  .rel-pos-li.logout-child {
    display: block; }
  .dropDownMenuChild {
    position: absolute;
    left: 0;
    top: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #009fe3; }
  .flow {
    display: block;
    -webkit-flex-direction: initial;
    flex-direction: initial; }
  .pet-rewards-main {
    width: 85%;
    padding: 0 20px 25px 20px; }
  .surprise-rewards-main {
    width: 85%; }
  .surprise-rewards-main-no-rewards {
    width: 75%; }
  .container-report-child .section-play .inner-section-play-first p {
    display: none; }
  .outer-div-progress-percent {
    margin-right: 0;
    margin-left: 0; }
  .round-progress-child .inner-round-progress-child {
    text-align: center; } }

@media (max-width: 550px) {
  .pet-rewards-main {
    width: 100%;
    padding: 0 10px 25px 10px; }
  .surprise-rewards-main {
    width: 100%;
    padding-bottom: 20px; }
    .surprise-rewards-main .title-pet {
      padding-top: 40px; }
  .section-surprise {
    -webkit-flex-direction: column;
    flex-direction: column; }
  .right-progress-side {
    padding-right: 25px; }
  .surprise-rewards-main-no-rewards {
    width: 100%; }
  .inner-child-cont {
    -webkit-flex-direction: column;
    flex-direction: column; }
  .left-child-content {
    width: 100%;
    padding-right: 0; }
  .right-child-sidebar {
    width: 30%;
    margin: 0 auto; }
  .image-right-box-child {
    padding-bottom: 10px; }
  .choose-avatar-number .label-avatar {
    padding-left: 45px; }
  .outer-div-progress-percent {
    margin-right: 0;
    margin-left: 0; }
  .round-progress-child .inner-round-progress-child {
    text-align: center; }
  .round-progress-child {
    padding-right: 20px;
    width: 60%; }
  .container-report-child .section-play .inner-section-play-first {
    width: 30%; } }

@media (max-width: 445px) {
  .pet-rewards-main .title-pet {
    padding-top: 50px; } }

@media (max-width: 400px) {
  .media-left-child-content .inner-section-play-second {
    -webkit-flex-direction: column;
    flex-direction: column; }
  .child-inner-avatar {
    -webkit-flex-direction: column;
    flex-direction: column; }
    .child-inner-avatar p {
      margin-left: 0; }
  .result-child-rewards .inner-result-student {
    text-align: center; }
  .inner-section-play-second .fish-container-0,
  .inner-section-play-second .fish-container-1,
  .inner-section-play-second .fish-container-2,
  .inner-section-play-second .fish-container-3 {
    height: 45px; }
  .inner-section-play-second .play-btn-child,
  .inner-section-play-second .play-btn-child:active,
  .inner-section-play-second .finish-btn-child:active,
  .inner-section-play-second .finish-btn-child {
    height: 50px;
    display: none; }
  .inner-section-play-first img {
    width: 55px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    margin-left: 15px; }
  .round-progress-child {
    width: 70%; } }

@media (max-width: 1100px) {
  .more-test {
    flex-wrap: wrap; }
  .our-resources {
    font-size: 20px; }
  .choose-resources {
    font-size: 14px; }
  .save-resources {
    font-size: 20px; }
  .child-test-resources {
    font-size: 16px; }
  .del-resources {
    font-size: 16px; }
  .del-resources:before {
    content: "";
    width: 143px;
    height: 68px;
    display: block;
    position: absolute;
    background: url(/images/resources/del.png) no-repeat 0 0;
    top: 0;
    left: 10px;
    right: 0;
    bottom: -20px;
    margin: auto;
    background-size: 60%; }
  .price-resoueces {
    font-size: 18px; }
  .btn-buy-resources {
    font-size: 14px; }
  .btn-buy-resources:active {
    font-size: 14px; }
  .inner-tests-table-second span {
    font-size: 14px; }
  .more-resources-right-sidebar {
    width: 20%;
    margin: 10px auto;
    min-width: 200px; }
  .more-resources-left-sidebar {
    width: 28%; }
  .more-tests-main {
    width: 71%; }
  .tests-table-first span {
    font-size: 14px; }
  .tests-table-first {
    width: 85%; } }

@media (min-width: 696px) {
  .more-resources-left-sidebar.more-test-left-sidebar-menu ._all-test-menu {
    display: block !important; } }

@media (max-width: 695px) {
  .more-tests-main {
    width: 100%; }
  .more-resources-left-sidebar {
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .more-test-left-sidebar-menu .active-test-menu {
    display: block !important; }
  .btn-more-tests {
    display: none !important; }
  ._all-test-menu {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px; } }

@media (max-width: 550px) {
  .tests-table-first span {
    font-size: 10px; }
  .tests-table-first {
    width: 100%; }
  .first-span-test {
    padding: 5px 0 5px 5px; }
  .tests-table-second {
    width: 100%; }
  .inner-tests-table-second span {
    font-size: 10px; } }

@media (max-width: 1100px) {
  .deals {
    padding-bottom: 20px; }
  .deals-flex-center .deals-inner-center-sidebar:last-child {
    margin-right: 0; }
  .deals-six-right-sidebar {
    display: none; }
  .ask-title {
    font-size: 20px; }
  .deals-center-sidebar {
    width: 82%; }
  .box-text-bottom-deals p:nth-child(odd),
  .box-text-bottom-deals p:nth-child(even) {
    font-size: 16px; } }

@media (max-width: 900px) {
  .deals-six-left-sidebar {
    display: none; }
  .deals-center-sidebar {
    width: 100%; }
  .deals-price-text {
    font-size: 16px; }
  .deals-del-price, .deals-child-profile {
    font-size: 14px; } }

@media (max-width: 600px) {
  .deals-flex-center {
    flex-direction: column;
    align-items: center; }
  .deals-flex-center .deals-inner-center-sidebar {
    margin-right: 0;
    width: 50%; } }

@media (max-width: 900px) {
  .two-column-customize {
    flex-direction: column; }
  .width-left-column {
    width: 100%;
    margin-right: 0; }
  .width-right-column {
    width: 50%; }
  .cupon-code {
    width: 77.5%; }
  .secure-payment {
    margin-top: 10px; } }

@media (max-width: 695px) {
  .align-box {
    width: 95%; }
  .width-100 {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .box-btn-customize a {
    width: 50%;
    margin-bottom: 5px; }
  .box-btn-customize a:nth-child(n+2) {
    margin-left: 0; }
  .width-30 {
    width: 50%;
    margin: 0 auto; }
  .width-right-column {
    width: 100%; }
  .inner-title-cust {
    text-align: center; } }

@media (max-width: 530px) {
  .width-30 {
    width: 100%; }
  .width-30 p span {
    margin-left: 10px; }
  .width-30 p {
    justify-content: center; } }

@media (max-width: 1100px) {
  .more-resources {
    flex-wrap: wrap; }
  .more-resources-right-sidebar {
    display: none;
    width: 20%;
    margin: 10px auto;
    min-width: 200px; }
  .mobile-more-resources-right-sidebar {
    display: flex; }
  .more-resources-left-sidebar {
    width: 28%; }
  .more-resources-main {
    width: 71%; } }

@media (min-width: 696px) {
  .more-res-left-sidebar-menu .active-res-test-menu {
    display: block !important; }
  .more-res-left-sidebar-menu .active-res-test-menu._active-hide-menu {
    display: none !important; }
  .more-resources-left-sidebar .more-res-left-sidebar-menu ._all-test-menu {
    display: block !important; }
  .more-res-left-sidebar-menu .left-resources-a.active-res-test-menu._topics-desctop-hide {
    display: none !important; }
  .more-res-left-sidebar-menu .left-resources-a.active-res-test-menu._skills-desctop-hide {
    display: none !important; } }

@media (max-width: 695px) {
  .more-resources-main {
    width: 100%; }
  .more-resources-left-sidebar {
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .more-res-left-sidebar-menu .active-res-test-menu {
    display: block !important; }
  .btn-first-res, .btn-more-skils-res {
    display: none !important; } }

@media (max-width: 400px) {
  .item-resources {
    margin: 0 auto; } }
