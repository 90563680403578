//all madia
@media (max-width: 1400px) {
	// .inner-block-input-profile, .inner-block-input-student {
	// 	width: 100%;
	// }
	// .inner-line-manage, .inner-two-line-manage {
	// 	justify-content: center;
	// }
	.price-text {font-size: 20px;}
	.del-price {font-size: 16px;}
	.del-price:before {height: 40%;}

	.inner-popup-student {
		height: auto;
		top: 0;
		width: 50%;
		transform: none;
	}
	.popup-h1-student {
		font-size: 40px;
    	padding-top: 50px;
    	margin-bottom: 50px;
	}
	.close-popup {
		right: 40px;
    	top: 40px;
    	width: 50px;
    	height: 50px;
	}
	.parent-email, .parent-pass {
		font-size: 26px;
    	height: 50px;
		padding: 0px 30px;
	}
	.parent-pass {
    	margin-top: 15px;
	}
	.alert-popup-student {
		font-size: 18px;
    	margin: 0;
    	width: 100%;
    	position: absolute;
	}
	.done-button {
		// width: 170px;
  //   	height: 100px;
  		width: 120px;
    	height: 70px;
    	margin-top: 30px;
	}
	.inner-popup-student .done-button {
		width: 150px;
	}
	.done-button:active {
		width: 120px;
    	height: 70px;
    	margin-top: 30px;
	}
	.inner-popup-student .done-button:active {
		width: 150px;
	}
	.new-user-student {
		margin-top: 20px;
    	padding-bottom: 20px;
	}
	.new-user-student a {
		font-size: 16px;
	}

//popup-parent-sign

.popup-parent-sign {
	.inner-popup {
		height: auto;
		top: 0;
		width: 55%;
		transform: none;
	}
	.popup-h1 {
		font-size: 40px;
    	padding-top: 35px;
    	margin-bottom: 40px;
	}

	.min-soc-google, .min-soc-face {
		font-size: 18px;
		height: 40px;
    	margin-top: 0px;
    	padding-top: 8px; 
    	margin-bottom: 15px;
    	box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
	}
	.min-soc-google:active, .min-soc-face:active {
		font-size: 18px;
		height: 40px;
    	margin-top: 0px;
    	padding-top: 8px; 
    	margin-bottom: 15px;
    	box-shadow: 0 -5px 2px 1px rgba(3,114,181,.86);
	}

	.p-popup-parent {
		margin-top: 0;
		margin-bottom: 0;
	}
	.soc-seti-google, .soc-seti-face {
		font-size: 18px;
		height: 40px;
    	margin-top: 0px;
    	padding-top: 8px;
    	margin-bottom: 15px;
    	box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
	}
	.soc-seti-face, .min-soc-face {
		margin-top: 0px;
	}

	.soc-seti-google:active, .soc-seti-face:active {
		font-size: 18px;
		height: 40px;
    	margin-top: 0px;
    	padding-top: 8px;
    	margin-bottom: 15px;
    	box-shadow: 0 -5px 2px 1px rgba(3,114,181,.86);
	}

	.popup-or {
		font-size: 40px;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.close-popup {
		right: 40px;
    	top: 35px;
    	width: 50px;
    	height: 50px;
	}
	.parent-email, .parent-pass {
		font-size: 26px;
    	height: 50px;
		padding: 0px 30px;
		width: 58%;
	}
	.parent-pass {
    	margin-top: 15px;
	}
	.alert-popup-student {
		font-size: 18px;
	}
	.done-button {
		width: 150px;
    	height: 70px;
    	margin-top: 30px;
	}
	.alert-popup {
		font-size: 18px;
		margin-bottom: 0;
		width: 100%;
    	position: absolute;
    	margin-top: 0px;
	}
	.done-button:active {
		width: 150px;
    	height: 70px;
    	margin-top: 30px;
	}
	.forgot-pass a, .new-user a {
		font-size: 18px; 
	}
	.policy {
		margin-top: 50px;
		padding-bottom: 30px;
		a {
			font-size: 20px;
		}
	  }
	}

//signIn

.sign-in-popup {
	position: relative;
	margin: 0 auto;
	width: 55%;
	height: auto;
	background: url('/images/signIn/bcg.png') no-repeat center center;
	background-size: 100% 100%;
	transform: none;
	top: 0;
}

.popup-h1-student-inner-sign {
	padding-bottom: 50px;
	font-family: Oduda;
	font-weight: 900;
	font-size: 40px;
	margin: 0;
	text-align: center;
	padding-top: 50px;
	color: #ff8000;
	padding-bottom: 24px;
}

.box-sign-in {
	display: flex;
    margin: 0 auto;
    justify-content: space-around;
    width: 80%;
    margin-top: 30px;
}

.inner-box-sign-in {
	text-align: center;
	padding-bottom: 70px;
	img {
		width: 55%;
		max-width: 100%;
	}
	a {
		display: block;
		font-size: 34px;
		font-family: Oduda;
		font-weight: 900;
		color: #007e00;
		text-transform: uppercase;
		text-decoration: none;
		margin-top: 50px;
		height: 28px;
	}
	a:hover {
		font-size: 34px;
		margin-top: 50px;
		height: 28px;
		background-color: #fff;
    	text-shadow: 0px 2px 3px rgb(255,255,255);
    	-webkit-background-clip: text;
    	-moz-background-clip: text;
    	-ms-background-clip: text;
    	-o-background-clip: text;
    	background-clip: text;
    	color: #006300;
	}
}

	.close-popup-sign {
		display: block;
		width: 50px;
		height: 50px;
		background: url('/images/close.png') no-repeat;
		background-size: 100%;
		border: none;
		outline: none;
		position: absolute;
	    right: 50px;
	    top: 40px;
	    cursor: pointer;
	}

}

@media (max-width: 1000px) {
	.popup-parent-sign .policy a {
		font-size: 16px;
		display: inline-block;
	}
	.popup-parent-sign .forgot-pass a, .popup-parent-sign .new-user a {
		font-size: 14px;
	}
	.new-user {
		margin-right: 40px;
	}
	.forgot-pass {
		margin-left: 40px;
	}
}

@media (max-width: 900px) {
	.inner-popup-student {
		width: 60%;
		
		.new-user-student a {
			font-size: 14px;
		}
		.close-popup {
    		right: 30px;
    		top: 25px;
    		width: 40px;
    		height: 40px;
    	}
		.parent-email, .parent-pass {
			font-size: 20px;
			border: solid 3px #7a3827;
			width: 60%;
		}
		.done-button {
    		width: 100px;
    		height: 50px;
    		margin-top: 20px;
		}
		.done-button:hover {
    		width: 100px;
    		height: 50px;
    		margin-top: 30px;
		}
		.popup-h1-student {
			font-size: 20px;
			margin-bottom: 20px;
			padding-top: 30px;
		}
	}

	//parent
	.popup-parent-sign .alert-popup {
		font-size: 14px;
	}
	.popup-parent-sign .inner-popup {
		width: 75%;
	}
	.popup-parent-sign .popup-h1 {
		font-size: 24px;
		margin-bottom: 30px;
	}
	.popup-parent-sign .popup-or {
		font-size: 24px;
	}
	.popup-parent-sign .close-popup {
	    right: 30px;
	    top: 30px;
	    width: 40px;
	    height: 40px;
	}
	.popup-parent-sign .done-button {
    	width: 100px;
    	height: 50px;
    	margin-top: 30px;
	}
	.popup-parent-sign .done-button:hover {
    	width: 100px;
    	height: 50px;
    	margin-top: 30px;
	}
	.popup-parent-sign .parent-email, .popup-parent-sign .parent-pass {
		font-size: 20px;
		border: solid 3px #7a3827;
		height: 40px;
	}
	.popup-parent-sign .soc-seti-google, .popup-parent-sign .soc-seti-face {
		font-size: 14px;
		padding-top: 2px;
		height: 30px;
	}
	.popup-parent-sign .soc-seti-google:hover, .popup-parent-sign .soc-seti-face:hover {
		font-size: 14px;
		padding-top: 2px;
		height: 30px;
	}
	.popup-parent-sign .min-soc-google, .popup-parent-sign .min-soc-face {
		font-size: 14px;
		padding-top: 2px;
		height: 30px;
	}
	.popup-parent-sign .min-soc-google:hover, .popup-parent-sign .min-soc-face:hover {
		font-size: 14px;
		padding-top: 2px;
		height: 30px;
	}
}

@media (max-width: 768px) {
	.ngdialog.popup-child-sign.ngdialog-theme-default.ng-scope,
	.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope,
	.ngdialog.popup-choose-sign-in.ngdialog-theme-default.ng-scope {
	    margin-right: 0;
	}

	.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope {
	    margin-right: 0;
	}
//parent
	.popup-parent-sign .inner-popup {
		width: 80%;
	}
//student
	.inner-popup-student {
		width: 70%;
	}

	//signIn
.sign-in-popup {
	position: relative;
	margin: 0 auto;
	width: 70%;
	height: auto;
	background: url('/images/signIn/bcg.png') no-repeat center center;
	background-size: 100% 100%;
	transform: none;
	top: 0;
}

.popup-h1-student-inner-sign {
	padding-bottom: 50px;
	font-family: Oduda;
	font-weight: 900;
	font-size: 20px;
	margin: 0;
	text-align: center;
	padding-top: 40px;
	color: #ff8000;
	padding-bottom: 14px;
}

.box-sign-in {
	display: flex;
    margin: 0 auto;
    justify-content: space-around;
    width: 80%;
    margin-top: 20px;
}

.inner-box-sign-in {
	text-align: center;
	padding-bottom: 50px;
	img {
		width: 55%;
		max-width: 100%;
	}
	a {
		display: block;
		font-size: 18px;
		font-family: Oduda;
		font-weight: 900;
		color: #007e00;
		text-transform: uppercase;
		text-decoration: none;
		margin-top: 20px;
	}
	a:hover {
		font-size: 18px;
		margin-top: 50px;
		height: 28px;
		background-color: #fff;
    	text-shadow: 0px 2px 3px rgb(255,255,255);
    	-webkit-background-clip: text;
    	-moz-background-clip: text;
    	-ms-background-clip: text;
    	-o-background-clip: text;
    	background-clip: text;
    	color: #006300;
	}
}


	.close-popup-sign {
		display: block;
		width: 40px;
		height: 40px;
		background: url('/images/close.png') no-repeat;
		background-size: 100%;
		border: none;
		outline: none;
		position: absolute;
	    right: 40px;
	    top: 35px;
	    cursor: pointer;
	}

}

@media (max-width: 550px) {
//parent
	.popup-parent-sign .inner-popup {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.ngdialog.popup-cancelation.ngdialog-theme-default {
	    margin-right: 0;
	    padding-top: 45px;
	}
	.ngdialog.popup-cancelation.ngdialog-theme-default .ngdialog-content {
		width: 305px;
		._cansel-p-cont {
		    max-height: 177px;
		    margin-bottom: 25px;
		}
		.cancel-p {
		    font-size: 14px;
		    margin: 5px;
		    padding: 0;
		}
	}
	//student
	.inner-popup-student {
		width: 100%;
	}
	.sign-in-popup {
		width: 100%;
	}

}	

@media (max-width: 450px) {
	.popup-parent-sign .min-soc-google, .popup-parent-sign .min-soc-face,
	.popup-parent-sign .min-soc-google:hover, .popup-parent-sign .min-soc-face:hover {
		width: 12%;
	}
	.popup-parent-sign .soc-seti-google, .popup-parent-sign .soc-seti-face,
	.popup-parent-sign .soc-seti-google:hover, .popup-parent-sign .soc-seti-face:hover{
		width: 70%;
	}
	.popup-parent-sign .parent-email, .popup-parent-sign .parent-pass {
		width: 82%;
	}
	.popup-parent-sign {
		.forgot-pass {
			float: none;
    		text-align: center;
    		margin: 0;
    		margin-top: 5px;
		}
		.new-user {
			text-align: center;
    		float: none;
    		margin: 0;
		}
		.policy {
			margin-top: 0px;
    		padding-bottom: 15px;
    		margin-bottom: 0;
		}
		.close-popup {
			right: 20px;
    		top: 20px;
		}
	}
}

@media (min-width: 3000px) {
	.sign-in-popup,
	.inner-popup-student,
	.inner-popup {
	    transform: scale(0.3);
	}
}