// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "/plugins/normalize.css";
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800';
@font-face {
    font-family: Oduda; /* Имя шрифта */
    src: url(/fonts/Oduda-Bold-Demo.otf); /* Путь к файлу со шрифтом */
    src: url('/fonts/oduda-bold-demo-webfont.eot');
    src: url('/fonts/oduda-bold-demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/oduda-bold-demo-webfont.woff2') format('woff2'),
         url('/fonts/oduda-bold-demo-webfont.woff') format('woff'),
         url('/fonts/oduda-bold-demo-webfont.ttf') format('truetype'),
         url('/fonts/oduda-bold-demo-webfont.svg#Oduda') format('svg');
}

*{
	box-sizing: border-box;
}

html {
	height: auto;
	overflow-y: auto !important;
}

body {
	height: auto;
	font-family: 'Open Sans', sans-serif;
}
._main {
	min-height: 100vh;
	flex-grow: 1;
}
.inner-content._pay-access {
	max-width: 77%;
	margin: 3vh auto;
}
._main._pay-access-main {
	min-height: auto;
}
/*show*/
.two-slider-outer.csh {
	height: auto;
}
/*hide*/
.two-slider-outer.ch {
	height: 0px;
	overflow: hidden;
}
._my-subscription-cont {
	max-height: 183px;
    overflow-y: auto;
}
._cansel-p-cont {
	max-height: 278px;
    overflow-y: auto;
    .cancel-p {
	    margin: 10px 0;
	}
}
.active-tab {
	background-color: #ff8227 !important;
	color: #fff !important;
}

.active-tab a {
    border-right: 3px solid transparent !important;
}
.flow {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
}

.w-foot {
	height: 50px;
}

.marg-bot-vh {
	padding-bottom: 100px;
}

.main-content {
	flex: 1 0 auto;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
}

.ngdialog-close {
	display: none;
}

label {cursor: pointer;}

.ngdialog.popup-child-sign.ngdialog-theme-default .ngdialog-content, 
.ngdialog.popup-choose-sign-in.ngdialog-theme-default .ngdialog-content{
	width: 100%;
	background: transparent;
}

.ngdialog.popup-child-sign.ngdialog-theme-default.ng-scope,
.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope,
.ngdialog.popup-choose-sign-in.ngdialog-theme-default.ng-scope {
	padding-bottom: 0;
    padding-top: 60px;
    overflow-y: scroll;
    margin-right: -20px;
}

.ngdialog.popup-parent-sign.ngdialog-theme-default.ng-scope {
	padding-bottom: 0;
    padding-top: 20px;
    overflow-y: scroll;
    margin-right: -20px;
}

.logo {
	margin: 0;
	padding: 0;
	padding-top: 5px;
	padding-left: 0%;
	float: left;
}

.main-nav {
	position: relative;
	height: 38px;
	background-color: #0ec3fc;
	-webkit-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	-moz-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	box-shadow: 0 3px 2px rgba(0,0,0,.34);
	border: none;
	text-align: right;
}

.devider-line {
	height: 45px;
	width: 2px;
	border-right: 2px solid #e1e1e1;
	position: absolute;
	top: 9px;
	padding: 0;
	margin: 0;
	margin-left: -1px;
}

.head-right-nav {
	font-size: 0;
	padding-left: 0;
	list-style-type: none;
	background: #009fe3;
	float: right;
	margin-bottom: 30px;
	min-height: 64px;

	li {
		display: inline-block;
		margin-left: -1px;
    	margin-right: -2px;	
	}
			
	li:last-child {
		margin-right: 0;
	}

	li:first-child a {
		border-left: none;	
	}

	li:last-child a {
		border-right: none;	
	}

	li a {
		display: inline-block;
		padding: 10px;;
		color: white;
		text-decoration: none;
		font-size: 24px;
		font-weight: 600;
		margin-top: 9px;
    	margin-bottom: 9px;
    	border-right: 3px solid #e1e1e1;
    	padding-left: 30px;
    	padding-right: 30px;
	}

	li:hover {
		background: #ff8227;
	}

	li:hover a {
		border-color: transparent;
	}
}
#app-layout {
	background: url('/images/background.png') no-repeat;
	background-size: cover;
}
.main-content {
	width: 100%;
	display: flex;
}

.user-info {
	float: right;
	width: 60%;
}
.inner-content {
	width: 64%;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);
	background: #b3f4fe;
    padding-top: 35px;
    // padding-right: 130px;
    // padding-left: 130px;
    padding-right: 4%;
    padding-left: 4%;
    order: 2;
    flex-grow: 3;
}

.inner-h1 {
	font-size: 48px;
	text-align: center;
	font-weight: 900;
	color: #005c83;
	padding-bottom: 65px;
}

.avatar {
	width: 30%;
	float: left;
	// margin-left: 3%;
	// margin-right: 5%;
	img {
		width: 100%;
		border-radius: 50%;
	}
}

.avatar-register {
	text-align: center;
	img {
		border-radius: 0;
	}
}

.padding-label {
	padding-right: 43px;
}

.label-style {
	font-size: 30px;
	font-weight: 900;
	color: #005c83;
	padding-top: 45px;
	padding-bottom: 5px;
}

.label-style:first-child {
	padding-top: 0;
}
.input-style {
	display: block;
	font-size: 24px;
	padding: 9.5px;
	border: none;
	width: 100%;
}

.alert {
	color: #ff0000;
	font-size: 24px;
	font-weight: normal;
	padding-left: 0;
	padding-bottom: 0;
}

.pass-info {
	font-size: 24px;
	font-weight: normal;
	padding-left: 0;
	padding-bottom: 0;
}

.login-card {
	// display: flex;
	// flex-wrap: wrap;
}

.title-label {
	font-size: 30px;
	padding-bottom: 65px;
	color: #005c83;
	font-weight: 900;
	display: inline-block;
}

.title-block {
	font-size: 30px;
	padding: 80px 0;
	color: #005c83;
	font-weight: 900;
}

.sign-up {
	display: block;
	background: #ff8227;
	color: white;
	padding: 5px 20%;
	margin-bottom: 120px;
	font-size: 30px;
	border: none;
	outline: none;
	margin-top: 20px;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
}

.sign-up:hover {
	background: #009fe3;
	color: white;
	outline: none;
}

.label-radio {
	margin-left: 20px;
	display: inline-block;
	height: 50px;
	width: 65px;
	background: white;
	font-size: 21px;
	padding-top: 9px;
	text-align: center;
}

.radio-check {
	margin-left: 30px !important;
}

label {
	display: inline-block;
	position: relative;
}

.custom-radio {
	position: absolute;
	display: block;
	width: 39px;
	height: 39px;
	background: url('/images/radio.png');
	top: 5px;
    left: 15px;
}

label > input[type=checkbox]:checked + span {
		background: url('/images/radio_checked.png');
   }

label > input[type=radio]:checked + span {
		background: url('/images/radio_checked.png');
   }

.right-sidebar {
	width: 18%;
	// padding-left: 10px;
	flex-wrap: wrap;
	order: 3;
    flex-grow: 1;
}

.socio-box {
	padding-left: 10px;
}

// .right-sicio-img {
// 	float: left;
// 	display: inline-block;
// 	width: 110px;
// 	height: 37px;
// 	background: #6477a4;
// 	text-decoration: none;
// 	color: white;
// 	text-align: center;
// 	padding-top: 10px;
// 	margin-bottom: 10px;
// 	margin-right: 5px;
// }

// .right-sicio-like {
// 	float: left;
// 	display: flex;
// 	width: 110px;
// 	height: 37px;
// 	background: #6477a4;
// 	text-decoration: none;
// 	color: white;
// 	margin-bottom: 10px;
// 	margin-right: 5px;
// }

// .right-sicio-tweet {
// 	display: flex;
// 	width: 110px;
// 	height: 37px;
// 	background: #00aced;
// 	text-decoration: none;
// 	color: white;
// 	text-align: center;
// 	// padding-top: 12px;
// 	margin-bottom: 10px;
// }

// .inner-soci-span {
// 	padding-top: 10px;
//     padding-left: 10px;
// }

// .icon-socio-like {
// 	display: inline-block;
// 	width: 37px;
// 	height: 37px;
// 	background: url("/images/like.png") no-repeat;
// 	// margin-top: -12px;
//     margin-left: 10px;
// }

// .icon-socio-tweet {
// 	display: inline-block;
// 	width: 37px;
// 	height: 37px;
// 	background: url("/images/twitter.png") no-repeat;
// 	// margin-top: -12px;
//     margin-left: 5px;
// }

///////

.right-sicio-img {
	float: left;
	display: inline-block;
	width: 30%;
    min-width: 85px;
	height: 37px;
	background: #6477a4;
	text-decoration: none;
	color: white;
	text-align: center;
	padding-top: 10px;
	margin-bottom: 10px;
	margin-right: 5px;
}

.right-sicio-like {
	float: left;
	display: flex;
	width: 30%;
    min-width: 85px;
	height: 37px;
	background: #6477a4;
	text-decoration: none;
	color: white;
	margin-bottom: 10px;
	margin-right: 5px;
}

.right-sicio-tweet {
	display: flex;
	width: 30%;
    min-width: 85px;
	height: 37px;
	background: #00aced;
	text-decoration: none;
	color: white;
	text-align: center;
	// padding-top: 12px;
	margin-bottom: 10px;
}

.inner-soci-span {
	padding-top: 10px;
    padding-left: 0px;
}

.icon-socio-like {
	display: inline-block;
	width: 37px;
	height: 37px;
	background: url("/images/like.png") no-repeat;
	// margin-top: -12px;
    margin-left: 5px;
    margin-right: 5px;
}

.icon-socio-tweet {
	display: inline-block;
	width: 37px;
	height: 37px;
	background: url("/images/twitter.png") no-repeat;
	// margin-top: -12px;
    margin-left: 0px;
}

//popup parent

.ngdialog.popup-parent-sign.ngdialog-theme-default .ngdialog-content{
width: 100%;
background: transparent;
}

.background-popup {
   height: 1100px;
}

.inner-popup {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 2300px;
	height: 1900px;
	background: url('/images/sign_parent.png') no-repeat center center;
	background-size: 100% 100%;
	transform: scale(0.4);
	top: -450px;
}

.popup-h1 {
	font-family: Oduda;
	font-weight: 900;
	font-size: 120px;
	margin: 0;
	text-align: center;
	padding-top: 150px;
	color: #ff8000;
}

.close-popup {
	display: block;
	width: 133px;
	height: 126px;
	background: url('/images/close.png') no-repeat;
	background-size: 100%;
	border: none;
	outline: none;
	position: absolute;
    right: 120px;
    top: 120px;
    cursor: pointer;
}

.align-center {
	text-align: center;
}

.soc-seti-google {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 50%;
	height: 118px;
	background: url('/images/button_google.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
    margin-top: 150px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	-moz-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	border-radius: 20px;
	cursor: pointer;
}

.soc-seti-google:active {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 50%;
	height: 118px;
	background: url('/images/button_google.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
    margin-top: 150px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	-moz-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	border-radius: 20px;
	cursor: pointer;
}

.soc-seti-face {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 50%;
	height: 118px;
	background: url('/images/button_face.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
		-webkit-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	-moz-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	border-radius: 20px;
	cursor: pointer;
}

.soc-seti-face:active {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 50%;
	height: 118px;
	background: url('/images/button_face.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
	-webkit-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	-moz-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	border-radius: 20px;
	cursor: pointer;
}

.min-soc-google {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 8%;
	height: 118px;
	background: url('/images/button_min.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
    margin-top: 150px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	-moz-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(255,249,153,.52);
	border-radius: 20px;
	cursor: pointer;
}

.min-soc-google:active {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 8%;
	height: 118px;
	background: url('/images/button_min.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
    margin-top: 150px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	-moz-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	border-radius: 20px;
	cursor: pointer;
}

.min-soc-face {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 8%;
	height: 118px;
	background: url('/images/button_min_two.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
	-webkit-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	-moz-box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	box-shadow: 0 10px 2px 1px rgba(3,114,181,.86), inset 0 -12px 9px 1px rgba(120,164,255,.98);
	border-radius: 20px;
	cursor: pointer;
}

.min-soc-face:active {
	color: white;
	text-decoration: none;
	text-align: center;
	font-size: 57px;
	display: inline-block;
	width: 8%;
	height: 118px;
	background: url('/images/button_min_two.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 50px;
	-webkit-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	-moz-box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	box-shadow: 0 -10px 2px 1px rgba(3,114,181,.86);
	border-radius: 20px;
	cursor: pointer;
}

.popup-or {
	font-family: Oduda;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 100px;
	color: #ff8509;
	text-align: center;
	margin-bottom: 50px;
}

.parent-email, .parent-pass {
	font-family: Oduda;
	font-weight: 900;
	font-size: 80px;
	padding: 50px 80px;
	color: #a14403;
	width: 50%;
	height: 199px;
	-webkit-border-radius: 100px/99px 99px 100px 100px;
	-moz-border-radius: 100px/99px 99px 100px 100px;
	border-radius: 100px/99px 99px 100px 100px;
	background-color: #ffcb04;
	-webkit-box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111,51,35,.65);
	-moz-box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111,51,35,.65);
	box-shadow: inset 0 0 36px 4px #ffc55d, inset 0 0 35px rgba(111,51,35,.65);
	border: solid 6px #7a3827;
	outline: none;
	display: block;
	margin: 0 auto;
}

.parent-pass {
	margin-top: 50px;
}

.parent-email::-webkit-input-placeholder,
 .parent-pass::-webkit-input-placeholder {color:#9e4203;}
.parent-email::-moz-placeholder,
.parent-pass::-moz-placeholder {color:#9e4203;}/* Firefox 19+ */
.parent-email:-moz-placeholder,
.parent-pass:-moz-placeholder {color:#9e4203;}/* Firefox 18- */
.parent-email:-ms-input-placeholder,
.parent-pass:-ms-input-placeholder {color:#9e4203;}

.alert-popup {
	font-size: 60px;
	color: #f2090a;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 80px;
}

.done-button {
	margin: 0 auto;
	display: block;
	width: 25%;
	height: 237px;
	background: url("/images/done.png") no-repeat;
	background-size: 100% 100%;
	border: none;
	outline: none;
	margin-top: 50px;
	cursor: pointer;
}

.done-button:active {
	margin: 0 auto;
	display: block;
	width: 25%;
	height: 237px;
	background: url("/images/done_pressed.png") no-repeat;
	background-size: 100% 100%;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 50px;
}

.policy {
	text-align: center;
    margin-top: 155px;
 a {
		font-size: 56px;
		color: #910303;
		text-decoration: none;
		font-weight: 900;
	}
}

.forgot-pass, .new-user {
	a {
		font-size: 48px; 
		color: #910303;
		text-decoration: underline;
		font-weight: 900;
	}
}

.forgot-pass {
	float: left;
	margin-left: 80px;
}

.new-user {
	float: right;
	margin-right: 80px;
}

// student sign up

.background-popup-student {
   height: 750px;
}

.inner-popup-student {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 2300px;
	height: 1400px;
	background: url('/images/sign_student.png') no-repeat center center;
	background-size: 100% 100%;
	transform: scale(0.4);
	top: -350px;
}

.new-user-student {
	text-align: center;
    margin-top: 40px;
 a {
		font-size: 56px;
		color: #910303;
		text-decoration: underline;
		font-weight: 900;
	}
}

.popup-h1-student {
	font-family: Oduda;
	font-weight: 900;
	font-size: 120px;
	margin: 0;
	text-align: center;
	padding-top: 240px;
    margin-bottom: 170px;
	color: #ff8000;
}

.alert-popup-student {
	font-size: 60px;
	color: #f2090a;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;
}

// add student
.form-add-child {
	width: 65%;
    margin: 0 auto;
}

.add-student-page {
	display: flex;
    justify-content: center;
    padding-bottom: 80px;
}

.select-one {
	// margin-right: 130px;
	margin-right: 20%;

	img {
		border-radius: 50%;
	}
}

.select-two img {
	border-radius: 50%;
}

.under-user {
	input[type="radio"] {opacity: 0;}
	display: block;
	margin-top: 30px;
	text-align: center;
	padding-right: 20px;
}

.custom-radio-add {
	position: absolute;
	display: block;
	width: 39px;
	height: 39px;
	background: url('/images/radio.png');
	top: -5px;
    left: 40px;
}

.label-add {
	font-size: 26px;
	color: #0085be; 
	padding-left: 20px;
}

.inner-block-add-input {
	margin: 0 auto;
}

.add-student-button {
	margin-top: 80px;
	float: right;
}

.block-sales {
	margin: 0 auto;
	background: #009fe3;
	padding: 10px;
	margin-top: 220px;
	width: 80%;

}

.our-deal, .inner-text-right-sidebar, .save-procent {
	text-align: center;
}

.our-deal {
	font-size: 30px; 
	color: #feec00; 
	margin-bottom: 20px;
}
.inner-text-right-sidebar {
	font-size: 24px; 
	color: white; 
	margin-bottom: 20px;
}
.save-procent {
	font-size: 41px; 
	color: #feec00; 
	margin-bottom: 20px; 
	margin-top: 20px;
}

.parent-box {
	img {
		width: 100%;
		border-radius: 50%;
	}
	background: white;
	padding: 20px;
	margin-bottom: 50px;
	padding-bottom: 50px;
}

.child-profile {
	font-size: 26px;
	color: #4f5050;
	text-align: center;
}

.price-text {
	font-family: Oduda;
	font-size: 28px;
	color: #ff8227;
	text-align: center;
}

.buy {
	display: inline-block;
	color: white;
	background: #009fe3;
	width: 100%;
	height: 45px;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 30px;
	font-weight: 800;
}

.buy:hover {
	display: inline-block;
	color: white;
	background: #ff8227;
	width: 100%;
	height: 45px;
	border: none;
	outline: none;
	cursor: pointer;
}

.del-price {
	position: relative; 
} 

.del-price:before { 
	border-bottom: 3px solid red; 
	position: absolute; 
	content: ""; 
	width: 100%; 
	height: 50%; 
	-webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

.del-price:after { 
	border-bottom: 3px solid red; 
	position: absolute; 
	content: ""; 
	width: 100%; 
	height: 50%; 
	-webkit-transform: rotate(-172deg);
    -moz-transform: rotate(-172deg);
    -o-transform: rotate(-172deg);
    -ms-transform: rotate(-172deg);
    transform: rotate(-172deg);
	left: 0;
	bottom: 0;
}

.left-sidebar {
	flex-grow: 1;
    width: 18%;
    order: 1;
}

//profile

.nav-profile {
	display: flex;
	justify-content: space-between;
	padding-top: 35px;
	padding-bottom: 75px;
}

.profile-button {
	font-size: 24px;
	font-weight: 900;
	display: inline-block;
	width: 30%;
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 0;
}

.profile-button:hover {
	font-size: 24px;
	font-weight: 900;
	display: inline-block;
	width: 30%;
	color: white;
	background: #ff8227;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 0;
}

.inner-block-input-profile {
	width: 55%;
    margin: 0 auto;
}

.change-profile {
	padding: 10px 0 0 0;
	margin: 0;
	text-align: right;
	font-size: 21px;
	color: #4f5050;
}

// add new student profile

.inner-block-input-student {
	width: 55%;
	margin: 0 auto;
	margin-bottom: 100px;
}

.new-student-button {
	font-size: 24px;
	font-weight: 900;
	margin: 0 auto;
	float: none;
	margin-bottom: 50px;
	width: 55%;
    padding: 10px;
}

.add-student-input {
	padding-bottom: 10px;
}

// manage purchases

.manage-purchases {
	width: 100%;

	.inner-h1-manage {
		font-size: 48px;
		font-weight: 900;
		text-align: center;
		color: #005c83;
		padding-top: 0px;
		margin-top: 0px;
		padding-bottom: 50px;
	}

	.title-manage {
		padding-left: 70px;
		font-weight: 900;
		font-size: 30px;
		color: #005c83;
		background: #78dfff;
		margin-bottom: 35px;
	}
}

.outer-manage-block {
	padding: 0px 10px 10px 10px;
	background: #e9fdff;
	overflow-y: auto;
	overflow-x: auto;

	.table-div-box:nth-child(odd) {
		display: flex;
		padding-bottom: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.table-div-box:nth-child(even) {
		display: flex;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.table-div-box:last-child {
		border-bottom: none;
	}
}

.inner-line-manage {
	display: flex;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 40px;
	padding-right: 20px;
	justify-content: space-between;
	flex-wrap: wrap;
}

.inner-two-line-manage {
	display: flex;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 40px;
	padding-right: 20px;
	justify-content: space-between;
	flex-wrap: wrap;
}

.inner-line-manage, .inner-two-line-manage  {
	border-bottom: 2px solid #78dfff;
 }

.inner-flex {
	display: flex;
}

 .p-inner-manage {
 	font-size: 30px;
 	color: #474747;
 	padding-left: 20px;
 	font-weight: 600;
 }

.button-inner-line {
	align-self: center;
	font-size: 24px;
	height: 75px;
	font-weight: 900;
	display: inline-block;
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	cursor: pointer;
	margin-left: 80px;
} 

.button-inner-line:hover {
	font-size: 24px;
	height: 75px;
	font-weight: 900;
	display: inline-block;
	color: white;
	background: #ff8227;
	border: none;
	outline: none;
	cursor: pointer;
} 

.buy-manage {
	width: 35%;
	margin: 50px 0 100px 0;
}

.buy-manage:hover {
	width: 35%;
}

.button-buy-manage {
	display: flex;
	justify-content: center;
}

.inner-h1-manage-two {
	padding-top: 50px;
	font-size: 48px;
	font-weight: 900;
	text-align: center;
	color: #005c83;
	padding-bottom: 50px;
}

.radio-manage {
	align-self: center;

	span {
		top: -5px;
	}

	.title-radio {
		font-size: 24px;
		color: #474747;
		font-weight: 600;
		padding-left: 5px;
	}
}

//invite

.add-more-friends-btn {
	cursor: pointer;
	border: none;
	background: transparent;
	color: #005c83;
	display: block;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 15px;
    margin: 10px auto 0 auto;
    text-align: center;
}

.svg-plus {
	height: 10px;
	width: 10px;
	margin: 0 5px 0 0px;
}

.plus {
	fill: #ff8227;
}

// .add-more-friends-btn:hover {
// 	background: #ff8227;
// }

.add-more-friends-btn:hover .plus {
	fill: #009fe3;
}

.inner-h1-invite {
	font-size: 48px;
	text-align: center;
	font-weight: 900;
	color: #005c83;
	padding-bottom: 35px;
	padding-top: 50px;
	margin: 0;
}

.invite-title {
	font-size: 30px;
	text-align: center;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 100px;
	color: #005c83;
}

.invite-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.invite-avatar {
	width: 40%;
	// height: 520px;
	order: 2;
	position: relative;
	bottom: 120px;
}

.invite-info {
	order: 1;
	width: 50%;
}

.invite-button {
	display: flex;
	margin-top: 110px;
	justify-content: flex-end;
}

.invite-submit {
	margin-right: -40%;
	z-index: 1000;
	min-width: 85%;
}

//parent dash

.font-24 {
	font-size: 24px;
}

.font-bold {
	font-weight: bold;
}

.color-blue {
	color: #0085be;
}

.btn-blue {
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	padding: 10px 40px;
	cursor: pointer;
}

.btn-blue:hover {
	background: #ff8227;
	
}

.p-student-title {
	color: #0085be;
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	-ms-word-break: break-all;
	word-break: break-all;
	 // white-space: pre-wrap;      /* CSS3 */   
  //  white-space: -moz-pre-wrap; /* Firefox */    
  //  white-space: -pre-wrap;     /* Opera 7 */   
  //  white-space: -o-pre-wrap;   /* Opera 7 */    
  //  word-wrap: break-word;      /* IE */
  //  word-break: break-all;
}

.parent-dash {
	display: flex;
	justify-content: space-around;
	//padding-left: 7%;
    //padding-right: 7%;
 	padding-left: 3%;
    padding-right: 3%;

.inner-parent-dash {
	    display: flex;
    	align-items: center;
    	flex-direction: column;
    	cursor: pointer;
    	img {
    		width: 100%;
    		max-width: 50%;
    		border-radius: 50%;
    		box-shadow: 0 0 10px 0px #000000, 0 0 5px rgba(255, 255, 190, 0.75);
    		margin-top: 10px;
    		margin-bottom: 10px;
    		height: auto;
    		// min-height: 50%;
    	}
    }
}

.btn-box-parent-dash {
	align-self: flex-end;
	margin-left: 10%;

	button, a {
		width: 305px;
		display: inline-block;
    	text-decoration: none;
    	text-align: center;		
	}
}

.student-margin-top {
	margin-bottom: 30px;
}

.student-margin {
	margin-right: 15px;
}

.result-student {
	background: #78dfff;
	display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 35px 0;
    padding-right: 4%;
    padding-left: 4%;
}

.inner-result-student {
	padding: 15px;
	p:last-child {
		font-size: 22px;
		color: #005c83;
		font-weight: bold;
		margin: 0;
	}
	p:first-child, .clock {
		font-family: Oduda;
		font-size: 50px;
		color: #ff8227;
		font-weight: bold;
		margin: 0;
	}
}

.clock-box {
	display: flex;
    align-items: center;
    flex-direction: column;

    img {
    	height: 100%;
    }
}

.nav-table-dash {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	button {
		margin-right: 15px;
	}
	button:last-child {
		margin-right: 0px;
	}
}

.report-student-title {
	font-size: 24px;
	font-weight: bold;
	color: white;
	padding: 10px 6%;
	background: #32b7eb;
	margin: 0 0 60px 0;
}

.table-report {
	display: flex;
	background: #78dfff;
	padding-right: 6%;
	padding-left: 6%;
    align-items: center;
    margin-bottom: 45px;

	span {
		font-size: 20px;
		color: #0085be;
		width: 15.2%;
		text-align: center;
		padding: 10px 0;
		font-weight: 600;
	}
	span:first-child {
		font-size: 30px;
		color: #005c83;
		width: 24%;
		font-weight: 900;
		text-align: left;
	}
	span:last-child {
		padding-right: 3%;
	}
	
}

.cont-box {
	margin-right: 4%;
	margin-left: 4%;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #78dfff;

	span {
		font-size: 24px;
		font-weight: 600;
		color: #7c7c7c;
		width: 15.2%;
		text-align: center;
		padding: 15px 0;
	}
	span:first-child {
		font-size: 24px;
		color: #7c7c7c;
		width: 24%;
		text-align: left;
		padding-left: 3%;
	}
	span:last-child {
		padding-right: 3%;
	}
	

}

.outer-cont-box {
	background: #e9fdff;
	padding-bottom: 100px;
	margin-bottom: 90px;
}

.indicatorContainer {
	height: 60px;
}

//curriculum

.title-curr {
	padding: 10px 0 10px 6%;
    font-weight: 900;
    font-size: 30px;
    color: #005c83;
    background: #78dfff;
    margin-bottom: 35px;
}

.buy-curriculum {
	height: auto;
	font-size: 36px;
	font-weight: 600;
	margin: 30px 0 80px 0;
	padding: 10px 0;
}

.buy-curriculum:hover {
	height: auto;
}

//rewards

.outer-rewards-box {
	padding-bottom: 5px;
	margin-bottom: 25px;
	span.reward-span {
		font-size: 30px;
		font-weight: 600;
		color: #474747;
		width: 50%;
	}
	.inner-reward-img {
		width: 5%;
    	display: flex;
    	justify-content: center;
	}
	.cont-box:last-child {
		border-bottom: none;
	} 
}

.submit-rewards {
	padding-bottom: 100px;
	background: url("/images/baloon.png") no-repeat 90% 30%;
	.rewards-p {
		width: 30%;
		padding: 15px 50px;
		color: white;
		font-size: 24px;
		font-weight: 600;
		background: #009fe3;
		margin-bottom: 10px;
	}
	.marg-rew {
		margin-bottom: 25px;
	}
	.submit-title-rew {
		font-size: 30px;
		font-weight: 900;
		color: #005c83;
		padding-left: 2%;
		white-space: nowrap;
	}
}

.select-reward {
	display: flex;
    justify-content: space-between;
	font-size: 30px;
	font-weight: 600;
	color: #474747;
		#reward {
		display: inline-block;
		overflow: hidden;
		border: none;
		width: 40%;
    	padding: 10px;
    	min-width: 290px;
	}
}

.text-reward { 
	display: flex;
    justify-content: space-between;
    align-items: center;
	input {
		border: none;
		font-size: 30px;
		font-weight: 600;
		color: #474747;
		text-align: center;
		padding: 10px 0;
		width: 65%;
	}
}
.num-reward {
	display: flex;
    justify-content: space-between;
    align-items: center;
	input {
		border: none;
		font-size: 30px;
		font-weight: 600;
		color: #474747;
		width: 150px;
		padding: 10px 0 10px 25px;

	}
}

.main-reward {
	width: 70%;
	position: relative;
}

.buy-reward {
	width: 19%;
	min-width: 200px;
	position: absolute;
    // right: -250px;
    right: -40%;
    bottom: 0;
    font-size: 24px;
    font-weight: 600;
}

.buy-reward:hover {
	width: 19%;
}

//two child dash

.parent-dash.two-child {
	padding: 0;

	.inner-parent-dash {
		width: 33%;
	}
	.btn-box-parent-dash {
		width: 33%;
		margin-left: 1%;
    	margin-right: 1%;
		text-align: center;
		    align-self: flex-start;
		a, button {
			margin: 0;
			width: 85%;
			display: inline-block;
    		text-decoration: none;
    		text-align: center;
		}
		.student-margin-top {
			margin-bottom: 25px;
		}
		.student-margin {
			margin-bottom: 35px;
		}
	}

}

.no-active-student {
	margin-bottom: 0;
	padding-bottom: 0;
    background: #78dfff;
}

//three child dash

.three-child {
	padding-left: 0;
	padding-right: 0;
	.inner-parent-dash {
		// width: 22%;
		// width: 100%;
		width: 50%;
	}
	.inner-parent-dash._single-child {
		width: 60%;
	}
	.btn-box-parent-dash {
		// width: 34%;
		// width: 100%;
		width: 100%;
		max-width: 235px;
		min-width: 235px;
		text-align: right;
		margin-left: 3%;
		button, a {
			margin: 0;
			width: 100%;
			max-width: 235px;
			min-width: 235px;
			display: inline-block;
    		text-decoration: none;
    		text-align: center;
    		white-space: nowrap;
    		overflow: hidden;
   			text-overflow: ellipsis;
		}
		button._btn-edit {
			margin-bottom: 10px;
		}
		.student-margin-top {
			margin-bottom: 5px;
		}
		.student-margin {
			margin-bottom: 5px;
		}
	}
	._btn-edit {
		margin-bottom: 5px;
	}
	.btn-box-parent-dash._parent-dash {
		font-size: 0;
	}
}

//signIn

.sign-in-popup {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 2300px;
	height: 1400px;
	background: url('/images/signIn/bcg.png') no-repeat center center;
	background-size: 100% 100%;
	transform: scale(0.4);
	top: -350px;
}

.popup-h1-student-inner-sign {
	padding-bottom: 140px;
	font-family: Oduda;
	font-weight: 900;
	font-size: 120px;
	margin: 0;
	text-align: center;
	padding-top: 240px;
	color: #ff8000;
	padding-bottom: 70px;
}

.box-sign-in {
	display: flex;
    margin: 0 auto;
    justify-content: space-around;
    width: 80%;
    margin-top: 75px;
}

.inner-box-sign-in {
	text-align: center;
	padding-bottom: 70px;
	img {
		max-width: 100%;
	}
	a {
		display: block;
		font-size: 68px;
		font-family: Oduda;
		font-weight: 900;
		color: #007e00;
		text-transform: uppercase;
		text-decoration: none;
		margin-top: 110px;
		height: 28px;
	}
	a:hover {
		display: block;
		font-size: 68px;
		font-family: Oduda;
		font-weight: 900;
		// color: #007800;
		text-transform: uppercase;
		text-decoration: none;
		margin-top: 110px;
		// text-shadow: -1px 9px 5px rgba(0,0,0,.75);
		background-color: #fff;
    	text-shadow: 0px 2px 3px rgb(255,255,255);
    	-webkit-background-clip: text;
    	-moz-background-clip: text;
    	-ms-background-clip: text;
    	-o-background-clip: text;
    	background-clip: text;
    	height: 28px;
    	color: #006300;
	}
}

.close-popup-sign {
	display: block;
	width: 133px;
	height: 126px;
	background: url('/images/close.png') no-repeat;
	background-size: 100%;
	border: none;
	outline: none;
	position: absolute;
    right: 120px;
    top: 120px;
    cursor: pointer;
}

//////////////////////////////////////////////////////////////////////

//rewards

.outer-rewards-box {
	background: #e9fdff;
    padding-bottom: 10px;
	margin-bottom: 25px;
	span.reward-span {
		font-size: 15px;
		font-weight: 600;
		color: #474747;
		width: 50%;
	}
	span.reward-span._rew-span-w {
		width: 30%;
	}
	.inner-reward-img {
		width: 50%;
    	display: flex;
    	justify-content: center;
    	._rew-img {
    		max-width: 60px;
    		max-height: 60px;
    	}
	}
	.inner-reward-img._rew-img {
		width: 20%;
	}
	.cont-box:last-child {
		border-bottom: none;
	} 
}

.submit-rewards {
	padding-bottom: 170px;
	background: url("/images/baloon.png") no-repeat 90% 30%;
	.rewards-p {
		width: 35%;
		padding: 15px 50px;
		color: white;
		font-size: 14px;
		font-weight: 600;
		background: #009fe3;
		margin-bottom: 10px;
	}
	.marg-rew {
		margin-bottom: 25px;
	}
	.submit-title-rew {
		font-size: 15px;
		font-weight: 900;
		color: #005c83;
		padding-left: 2%;
	}
}

.select-reward {
	display: flex;
    justify-content: space-between;
	font-size: 15px;
	font-weight: 600;
	color: #474747;
		#reward {
		display: inline-block;
		overflow-y: auto;
		border: none;
		width: 40%;
    	padding: 10px;
    	min-width: 150px;
    	min-height: 95px;

	}
}

.text-reward { 
	display: flex;
    justify-content: space-between;
    align-items: center;
	input {
		border: none;
		font-size: 15px;
		font-weight: 600;
		color: #474747;
		text-align: center;
		padding: 10px 0;
		width: 65%;
	}
}
.reward-radio {
	width: 65%;
	display: flex;
    justify-content: space-between;
    align-items: center;
	input[type=radio] {
	    display: none;
	}
	label {
		display: inline-block;
		cursor: pointer;
		position: relative;
		padding: 0;
		margin: 0;
		font-size: 13px;
		width: 60px;
		height: 70px;
		background-size: 50px;
		background-repeat: no-repeat;
		background-position: center;
		border: 2px solid transparent;
	}
	.pop-corn {
		background-image: url("/images/pop-corn.png");
	}
	.boul {
		background-image: url("/images/boul.png");
	}
	.toy {
		background-image: url("/images/toy.png");
	}
	.baloon {
		background-image: url("/images/baloon.png");
		background-size: 32px;
	}
	input[type=radio]:checked + label {
	    border: 2px solid #009fe3;
	}
}
.num-reward {
	display: flex;
    justify-content: space-between;
    align-items: center;
	input {
		border: none;
		font-size: 15px;
		font-weight: 600;
		color: #474747;
		width: 150px;
		padding-left: 25px;
	}
}

.main-reward {
	width: 70%;
	position: relative;
}

.buy-reward {
	width: 30% !important;
	min-width: 200px;
	position: absolute;
    // right: -250px;
    right: -45%;
    bottom: 0;
    font-size: 14px;
    font-weight: 600;
}

.buy-reward:hover {
	width: 30% !important;
}

.logo {
	margin: 0;
	padding: 0;
	padding-top: 5px;
	padding-left: 20px;
	float: left;
	text-align: left;
	img {
		width: 50%;
	}
}

.main-nav {
	position: relative;
	height: 38px;
	background-color: #0ec3fc;
	-webkit-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	-moz-box-shadow: 0 3px 2px rgba(0,0,0,.34);
	box-shadow: 0 3px 2px rgba(0,0,0,.34);
	border: none;
	text-align: right;
	margin-bottom: 30px;
}

.head-right-nav {
    min-height: 38px;
}

.head-right-nav li a {
    padding: 3px 15px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.devider-line {
	height: 45px;
	width: 2px;
	border-right: 2px solid #e1e1e1;
	position: absolute;
	top: 9px;
	padding: 0;
	margin: 0;
	margin-left: -1px;
}

#app-layout {
	background: url('/images/background.png') no-repeat;
	background-size: cover;
}
.main-content {
	width: 100%;
	display: flex;
}

.user-info {
	float: right;
	width: 60%;
}
.inner-content {
	width: 77%;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);
	background: #b3f4fe;
    padding-top: 35px;
    // padding-right: 130px;
    // padding-left: 130px;
    padding-right: 2%;
    padding-left: 2%;
    order: 2;
    flex-grow: 3;
}

.inner-h1 {
	font-size: 24px;
	text-align: center;
	font-weight: 900;
	color: #005c83;
	padding-bottom: 65px;
}

.avatar {
	width: 30%;
	float: left;
	// margin-left: 3%;
	// margin-right: 5%;
	img {
		width: 50%;
	}
}

.avatar-register .width-avatar {
	width: 100%;
	max-width: 150px;
}

.padding-label {
	padding-right: 0px;
}

.label-style {
	font-size: 15px;
	font-weight: 900;
	color: #005c83;
	padding-top: 45px;
	padding-bottom: 5px;
}

.label-style:first-child {
	padding-top: 0;
}
.input-style {
	display: block;
	font-size: 14px;
	padding: 9.5px;
	border: none;
	width: 100%;
}

.alert {
	color: #ff0000;
	font-size: 14px;
	font-weight: normal;
	padding-left: 0;
	padding-bottom: 0;
}

.pass-info {
	font-size: 14px;
	font-weight: normal;
	padding-left: 0;
	padding-bottom: 0;
}

.login-card {
	// display: flex;
	// flex-wrap: wrap;
}

.title-label {
	font-size: 15px;
	padding-bottom: 65px;
	color: #005c83;
	font-weight: 900;
	display: inline-block;
}

.title-block {
	font-size: 15px;
	padding: 80px 0;
	color: #005c83;
	font-weight: 900;
}

.sign-up {
	display: block;
	background: #ff8227;
	color: white;
	padding: 5px 20%;
	margin-bottom: 120px;
	font-size: 15px;
	border: none;
	outline: none;
	margin-top: 20px;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
}

.sign-up:hover {
	background: #009fe3;
	color: white;
	outline: none;
}

.label-radio {
	margin-left: 20px;
	display: inline-block;
	height: 50px;
	width: 65px;
	background: white;
	font-size: 21px;
	padding-top: 9px;
	text-align: center;
}

.radio-check {
	margin-left: 30px !important;
}

label {
	display: inline-block;
	position: relative;
}

.custom-radio {
	position: absolute;
	display: block;
	width: 39px;
	height: 39px;
	background: url('/images/radio.png');
	top: 5px;
    left: 15px;
}

label > input[type=checkbox]:checked + span {
		background: url('/images/radio_checked.png');
   }

label > input[type=radio]:checked + span {
		background: url('/images/radio_checked.png');
   }

.right-sidebar {
	width: auto;
	min-width: 22%;
	// padding-left: 10px;
	flex-wrap: wrap;
	order: 3;
    flex-grow: 1;
}

.socio-box {
	padding-left: 10px;
}

.right-sicio-img {
	float: left;
	display: inline-block;
	width: 85px;
	height: 37px;
	background: #6477a4;
	text-decoration: none;
	color: white;
	text-align: center;
	padding-top: 10px;
	margin-bottom: 10px;
	margin-right: 5px;
}

.right-sicio-like {
	float: left;
	display: flex;
	width: 85px;
	height: 37px;
	background: #6477a4;
	text-decoration: none;
	color: white;
	margin-bottom: 10px;
	margin-right: 5px;
}

.right-sicio-tweet {
	display: flex;
	width: 85px;
	height: 37px;
	background: #00aced;
	text-decoration: none;
	color: white;
	text-align: center;
	// padding-top: 12px;
	margin-bottom: 10px;
}

.inner-soci-span {
	padding-top: 10px;
    padding-left: 0px;
}

.icon-socio-like {
	display: inline-block;
	width: 37px;
	height: 37px;
	background: url("/images/like.png") no-repeat;
	// margin-top: -12px;
    margin-left: 5px;
    margin-right: 5px;
}

.icon-socio-tweet {
	display: inline-block;
    width: 33px;
    height: 30px;
    margin-top: 7px;
    background: url("/images/twitter.png") no-repeat;
    margin-left: 3px;
}

.font-24 {
	font-size: 14px;
}

// add student
.form-add-child {
	width: 70%;
    margin: 0 auto;
}

.add-student-page {
	display: flex;
    justify-content: center;
    padding-bottom: 80px;
}

.select-one {
	// margin-right: 130px;
	margin-right: 20%;
}

.under-user {
	display: block;
	margin-top: 30px;
	text-align: center;
	padding-right: 20px;
	cursor: pointer;
}

.custom-radio-add {
	position: absolute;
	display: block;
	width: 39px;
	height: 39px;
	background: url('/images/radio.png');
	top: -10px;
    left: 55px;
}

.label-add {
	font-size: 16px;
	color: #0085be; 
	padding-left: 25px;
}

.inner-block-add-input {
	margin: 0 auto;
}

.add-student-button {
	margin-top: 40px;
	margin-bottom: 60px;
	float: right;
}

.block-sales {
	margin: 0 auto;
	background: #009fe3;
	padding: 10px;
	margin-top: 110px;
	width: 80%;
	max-width: 180px;
}

.our-deal, .inner-text-right-sidebar, .save-procent {
	text-align: center;
}

.our-deal {
	font-size: 30px; 
	color: #feec00; 
	margin-bottom: 20px;
}
.inner-text-right-sidebar {
	font-size: 14px; 
	color: white; 
	margin-bottom: 20px;
}
.save-procent {
	font-size: 20px; 
	color: #feec00; 
	margin-bottom: 20px; 
	margin-top: 20px;
}

.parent-box {
	img {
		width: 100%;
	}
	background: white;
	padding: 20px;
	margin-bottom: 50px;
	padding-bottom: 50px;
}

.child-profile {
	font-size: 16px;
	color: #4f5050;
	text-align: center;
}

.price-text {
	font-family: Oduda;
	font-size: 14px;
	color: #ff8227;
	text-align: center;
}

.buy {
	display: inline-block;
	color: white;
	background: #009fe3;
	width: 100%;
	height: 45px;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 15px;
	font-weight: 800;
}

.buy:hover {
	display: inline-block;
	color: white;
	background: #ff8227;
	width: 100%;
	height: 45px;
	border: none;
	outline: none;
	cursor: pointer;
}

.left-sidebar {
	flex-grow: 1;
    // width: 18%;
    min-width: 22%;
    order: 1;
}

//profile

.nav-profile {
	display: flex;
	justify-content: space-between;
	padding-top: 35px;
	padding-bottom: 35px;
}

.profile-button {
	font-size: 14px;
	font-weight: 900;
	display: inline-block;
	width: 30%;
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 0;
}

.profile-button:hover {
	font-size: 14px;
	font-weight: 900;
	display: inline-block;
	width: 30%;
	color: white;
	background: #ff8227;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 0;
}

.inner-block-input-profile {
	width: 70%;
    margin: 0 auto;
}

.change-profile {
	padding: 10px 0 0 0;
	margin: 0;
	text-align: right;
	font-size: 14px;
	color: #4f5050;
}

// add new student profile

.inner-block-input-student {
	width: 55%;
	margin: 0 auto;
	margin-bottom: 200px;
}

.new-student-button {
	font-size: 14px;
	font-weight: 900;
	margin: 0 auto;
	float: none;
	margin-bottom: 50px;
	width: 55%;
    padding: 10px;
}

.add-student-input {
	padding-bottom: 10px;
}

// manage purchases

.manage-purchases {
	width: 100%;

	.inner-h1-manage {
		font-size: 24px;
		font-weight: 900;
		text-align: center;
		color: #005c83;
		padding-top: 0px;
		margin-top: 0px;
		padding-bottom: 50px;
	}

	.title-manage {
		padding-left: 70px;
		font-weight: 900;
		font-size: 15px;
		color: #005c83;
		background: #78dfff;
		margin-bottom: 35px;
	}
}

.outer-manage-block {
	padding: 0px 10px 10px 10px;
	background: #e9fdff;
	overflow-y: auto;
	overflow-x: auto;

	.table-div-box:nth-child(odd) {
		display: flex;
		padding-bottom: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.table-div-box:nth-child(even) {
		display: flex;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.table-div-box:last-child {
		border-bottom: none;
	}
}

.inner-line-manage {
	display: flex;
	padding: 10px;
	width: 100%;
    // min-width: 650px;
	justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.inner-two-line-manage {
	display: flex;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 40px;
	padding-right: 20px;
	justify-content: space-between;
	flex-wrap: wrap;
}

.inner-line-manage, .inner-two-line-manage  {
	border-bottom: 2px solid #78dfff;
 }

.inner-flex {
	display: flex;
	align-items: center;
}

.bord-rad-30 {
	border-radius: 30px;
	width: 115px;
}

.media-in-flex {
	display: flex;
}

 .p-inner-manage {
 	font-size: 15px;
 	color: #474747;
 	padding-left: 20px;
 	font-weight: 600;
 }

.button-inner-line {
	width: 100px;
	align-self: center;
	font-size: 14px;
	height: 50px;
	font-weight: 900;
	display: inline-block;
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	cursor: pointer;
	margin-left: 40px;
} 

.button-inner-line:hover {
	font-size: 14px;
	width: 100px;
	height: 50px;
	font-weight: 900;
	display: inline-block;
	color: white;
	background: #ff8227;
	border: none;
	outline: none;
	cursor: pointer;
} 

.buy-manage {
	width: 35%;
	margin: 50px 0 100px 0;
}

.buy-manage:hover {
	width: 35%;
}

.button-buy-manage {
	display: flex;
	justify-content: center;
}

.inner-h1-manage-two {
	padding-top: 50px;
	font-size: 24px;
	font-weight: 900;
	text-align: center;
	color: #005c83;
	padding-bottom: 50px;
}

.radio-manage {
	align-self: center;

	span {
		top: -10px;
	}

	.title-radio {
		font-size: 14px;
		color: #474747;
		font-weight: 600;
		padding-left: 5px;
	}
}

//invite

.inner-h1-invite {
	font-size: 24px;
	text-align: center;
	font-weight: 900;
	color: #005c83;
	padding-bottom: 35px;
	padding-top: 50px;
	margin: 0;
}

.invite-title {
	font-size: 15px;
	text-align: center;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 100px;
	color: #005c83;
}

.invite-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.invite-avatar {
	width: 40%;
	// height: 520px;
	order: 2;
	position: relative;
	bottom: 120px;
}

.invite-info {
	order: 1;
	width: 50%;
}

.invite-button {
	display: flex;
	margin-top: 110px;
	justify-content: flex-end;
}

.invite-submit {
	margin-right: -40%;
	z-index: 1000;
	min-width: 85%;
}

//parent dash

.btn-blue {
	color: white;
	background: #009fe3;
	border: none;
	outline: none;
	padding: 10px 40px;
	cursor: pointer;
}

.btn-blue:hover {
	background: #ff8227;
	
}

.p-student-title {
	color: #0085be;
	font-size: 14px;
	font-weight: bold;
	margin: 0;
	width: 100%;
}

.parent-dash {
	display: flex;
	// justify-content: space-around;
	justify-content: space-between;
	//padding-left: 7%;
    //padding-right: 7%;
 	padding-left: 0%;
    padding-right: 0%;
	.inner-parent-dash {
	    display: flex;
    	align-items: center;
    	flex-direction: column;
    	// height: 100%;
    	text-align: center;
    	padding-bottom: 10px;
    }
}

.btn-box-parent-dash {
	align-self: flex-end;
	margin-left: 10%;

	button, a {
		width: 305px;
		display: inline-block;
    	text-decoration: none;
    	text-align: center;		
	}
}
._three-child-slider {
	display: flex;
	position: relative;
	flex-grow: 1;
	.arrow-switch-left {
		position: absolute;
	    width: 25px !important;
	    height: 30px !important;
	    margin-top: 0 !important;
	    top: 35%;
	    left: 0;
	}
	.arrow-switch-right {
	    position: absolute;
	    width: 25px !important;
	    height: 30px !important;
	    margin-top: 0!important;
	    top: 35%;
	    right: 0;
	}
}
.student-margin-top {
	margin-bottom: 30px;
}

.student-margin {
	margin-right: 15px;
}

.result-student {
	background: #78dfff;
	display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 35px 0;
    padding-right: 0;
    padding-left: 0;
}

.inner-result-student {
	padding: 10px 5px 10px 5px;
		
	span {
		display: inline-block;
    	min-width: 30px;
    	text-align: center;
    }

	img {width: 50%;}

	p:last-child {
		font-size: 14px;
		color: #005c83;
		font-weight: bold;
		margin: 0;
	}
	p:first-child, .clock {
		font-family: Oduda;
		font-size: 25px;
		color: #ff8227;
		font-weight: bold;
		margin: 0;
	}
}

.clock-box {
	display: flex;
    align-items: center;
    flex-direction: column;

    img {width: 50%;}
}

.nav-table-dash {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	button {
		margin-right: 15px;
	}
	button:last-child {
		margin-right: 0px;
	}
}

.report-student-title {
	font-size: 14px;
	font-weight: bold;
	color: white;
	padding: 10px 6%;
	background: #32b7eb;
	margin: 0 0 5px 0;
}

.table-report {
	display: flex;
	background: #78dfff;
	padding-right: 4%;
	padding-left: 4%;
    align-items: center;
    margin-bottom: 5px;

	span {
		font-size: 14px;
		color: #0085be;
		width: 15.2%;
		text-align: center;
		padding: 10px 0;
		font-weight: 600;
	}
	span:first-child {
		font-size: 15px;
		color: #005c83;
		width: 24%;
		font-weight: 900;
		text-align: left;
		padding-left: 1%;
	}
	span:last-child {
		padding-right: 0%;
	}
	
}

.cont-box {
	margin-right: 4%;
	margin-left: 4%;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #78dfff;

	span {
		font-size: 14px;
		font-weight: 600;
		color: #7c7c7c;
		width: 15.2%;
		text-align: center;
		padding: 15px 0;
	}
	span:first-child {
		font-size: 14px;
		color: #7c7c7c;
		width: 24%;
		text-align: left;
		padding-left: 1%;
	}
	span:last-child {
		padding-right: 0%;
	}
}

.cont-box-rewards {
	margin-right: 4%;
	margin-left: 4%;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #78dfff;
	cursor: pointer;
	span {
		font-size: 14px;
		font-weight: 600;
		color: #7c7c7c;
		width: 15.2%;
		text-align: center;
		padding: 15px 0;
	}
	span:first-child {
		font-size: 14px;
		color: #7c7c7c;
		width: 24%;
		text-align: left;
		padding-left: 1%;
	}
	span:last-child {
		padding-right: 0%;
	}
	.reward-info {
		font-size: 14px;
		color: #7c7c7c;
		margin: 0;
		width: 30%;
		text-align: right;
		p {
			margin: 0;
		}
	}
}	

.drag-box-date {
	width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;

    span.reward-span {
    	width: 24%;
    }
    span.reward-span-date {
    	width: 28%;
    	text-align: right;
    }
    div.inner-reward-img {
    	width: 50%;
    }
}

.outer-cont-box {
	background: #e9fdff;
	padding-bottom: 10px;
	margin-bottom: 40px;
}

.indicatorContainer {
	height: 60px;
}

//curriculum

.title-curr {
	padding: 10px 0 10px 6%;
    font-weight: 900;
    font-size: 15px;
    color: #005c83;
    background: #78dfff;
    margin-bottom: 35px;
}

.buy-curriculum {
	height: auto;
	font-size: 18px;
	font-weight: 600;
	margin: 30px 0 80px 0;
	padding: 10px 0;
}

.buy-curriculum:hover {
	height: auto;
}

.navbar-brand.main-logo {
	margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 1%;

	img {
			width: 250px;
			max-width: 100%;
		}
}

.pad-0 {
	padding-left: 0;
	padding-right: 0;
}

.dragg-class {
	width: 100%;
    display: flex;
    align-items: center;
}
.dragg-class._dragg-cl {
	justify-content: space-between;
	padding: 7px 0;
}

.marg-bot {
	margin-bottom: 200px;
	overflow: hidden;
}

.result-student-manage {
	height: 30px;
}

.title-new-manage {
	display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background: white;
    padding: 15px 10px 15px 10px;
}
.inner-title-new-manage {
	flex-grow: 1;
}
._my-subscription-cont .inner-title-new-manage-p {
	margin: 5px;
	padding-top: 0;
}
.box-line-manage {
	border-left: 2px solid #78dfff;
	padding: 20px 20px 20px 35px;
	a {
		display: block;
		background: #009fe3;
		text-decoration: none;
		color: white;
		padding: 5px;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
	}
	a:hover {
		background: #ff8227;
	}
}

.inner-title-new-manage {
	p {
		margin: 0;
		color: #474747;
		font-weight: 600;
		font-size: 16px;
		padding-top: 3px;
	}
	.inner-title-new-manage-title {
		padding-top: 0px;
		font-size: 18px;
		color: #005c83;
		font-weight: 700;
	}
}

.center-block-invite {
	text-align: center;
}

.inner-media-profile, .check-box-week {
	display: flex;
    justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    margin-bottom: 5px;
}

.ie-avatar {
	width: 100%;
	min-height: 100%;
}
._no-student-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
}
._add_stud_btn {
	text-align: center;
	color: white;
    background: #009fe3;
    border: none;
    outline: none;
    padding: 10px 40px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 235px;
    margin-left: 30px;
    text-decoration: none;
}
._no-student-avatar {
	width: 110px;
	height: 110px;
	background: url(/images/childDash/avatar1.png) no-repeat;
	background-position: center;
	background-size: 100%;
	display: inline-block;
}




@import "_landing";
@import "_child_dashboard";
@import "_contact_us";

@import "_deals";
@import "_customize";
@import "_more_test";
@import "_read_more_blog";
@import "_more_resources";

@import "_reset-pass";
@import "_popup-socio";
@import "_cancel_subs";
@import "_payment";

@import "_media";
@import "_media_landing";
@import "_media_dashboard";
@import "_media_child_dash";
@import "_media_more_test";
@import "_media_deals";
@import "_media_customize";
@import "_media_more_resources";

