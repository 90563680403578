@media (max-width: 900px) {
	.two-column-customize {
		flex-direction: column;
	}
	.width-left-column {
		width: 100%;
		margin-right: 0;
	}
	.width-right-column {
		width: 50%;
	}
	.cupon-code {
		width: 77.5%;
	}
	.secure-payment {
		margin-top: 10px;
	}
}

@media (max-width: 695px) {
	.align-box {
		width: 95%;
	}
	.width-100 {
		display: flex;
    	flex-direction: column;
    	align-items: center;
	}
	.box-btn-customize a {
		width: 50%;
		margin-bottom: 5px;
	}
	.box-btn-customize a:nth-child(n+2) {
		margin-left: 0;
	}
	.width-30 {
		width: 50%;
		margin: 0 auto;
	}
	.width-right-column {
		width: 100%;
	}
	.inner-title-cust {
		text-align: center;
	}
}

@media (max-width: 530px) {
	.width-30 {
		width: 100%;
	}
	.width-30 p span {
		margin-left: 10px;
	}
	.width-30 p {
		justify-content: center;
	}
}