//tests

.more-test {
	padding: 20px 0 0 0;
	background: #ffffff;
    display: flex;
    flex: 1 0 auto;
    margin-top: 40px !important;
}

.more-tests-main {
	width: 64%;
}

.first-div-tests {
	background: #e9fdff;
	padding: 10px 30px;
	margin-bottom: 20px;
}

.first-p-tests {
	font-size: 20px;
	font-weight: 700;
	color: #005a87;
	margin: 0;
	padding: 0 0 15px 0;
}

.second-p-tests {
	font-size: 16px;
	font-weight: 600;
	color: black;
	margin: 0;
	padding: 0 0 15px 0;
}

.first-span-test {
	display: inline-block;
	width: 50%;
	padding: 5px 0 5px 15px;
	color: black;
}

.second-span-test {
	display: inline-block;
	width: 50%;
	padding: 5px 0;
	color: black;
}

.tests-table-first {
	width: 80%;
    margin: 0 auto;

    span  {
    	font-size: 16px;
    	color: black;
    	font-weight: 600;
    }

	div:nth-child(2n) {
		background: #b3f4ff;
	}
	div:nth-child(2n + 1) {
		background: #d0f9ff;
	}
	div:nth-child(1) {
		background: #009fe3;

		span {
			color: white;
		}
	}
}

.tests-table-first .span-color-blue {
	color: #005a87;
}

.tests-table-second {
	width: 80%;
    margin: 0 auto;
}

.inner-tests-table-second {
	display: flex;
    align-items: center;
    justify-content: space-between;
	border-bottom: 2px solid #78dfff;

	span {
		display: inline-block;
		width: 18%;
		text-align: center;
		padding: 5px 0;
		font-size: 18px;
		color: #7c7c7c;
		font-weight: 600;
	}
}

.inner-tests-table-second:first-child {
	border: none;
	background: #a1eaff;

	span {
		color: #000000;
	}
}

.inner-tests-table-second:last-child {
	border: none;
}

.last-p-tests {
	font-size: 18px;
	font-weight: 700;
	color: black;
	margin: 0;
	padding: 15px 0 0px 0;
}

.last-div-tests {
	width: 80%;
	margin: 0 auto;
}

.answer-tests-box {
	padding-top: 10px;
	span {
		display: block;
    	width: 120px;
		padding: 5px 0;
		font-size: 18px;
		font-weight: 700;
		color: black;
	}
	span:nth-child(odd) {
		background: #d2f9ff; 
	}
	span:nth-child(even) {
		background: #98e5fb;
	}
	span:last-child {
		background: none;
		font-size: 18px;
		font-weight: 700;
		color: #1e6c93;
	}
}

.devider-div-tests {
	display: flex;
    justify-content: center;
	padding: 5px 0;
	background: url("/images/tests/bcg-line.png") no-repeat 0 0;
	background-size: 100% 100%;
	margin-bottom: 20px;

	img {
		box-shadow: 0 0 5px #008be3;
		border-radius: 20px;
	}
}

.inner-devider-div-tests {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    span {
    	font-family: Oduda;
		font-size: 16px;
		font-weight: bold;
		color: #fff48a;
		text-shadow: 0 0 5px #008be3;
    }
}

.bottom-div-tests {
	background: #e9fdff;
    padding: 10px 30px 50px 30px;
    margin-bottom: 0px;
}

.bottom-p-tests {
	font-size: 20px;
    font-weight: 600;
    color: #0063b7;
    margin: 0;
    text-align: left;

    a {
    	font-size: 18px;
    	color: #009fe3;
    	padding-left: 10%;
    }
    a:hover {
    	font-size: 18px;
    	color: #ff9d00;
    	padding-left: 10%;
    }
}

.bottom-img {
	display: flex;
    justify-content: space-between;
    margin-top: 20px;

    img {
    	width: 18%;
    	height: 100%;
    }
}

.more-test-left-sidebar-menu .active-test-menu {
	display: none;
}