@media (max-width: 1100px) {
    .deals {
        padding-bottom: 20px;
    }
    .deals-flex-center .deals-inner-center-sidebar:last-child {
        margin-right: 0;
    }
    .deals-six-right-sidebar {
        display: none;
    }
    .ask-title {
        font-size: 20px;
    }
    .deals-center-sidebar {
        width: 82%;
    }
    .box-text-bottom-deals p:nth-child(odd),
    .box-text-bottom-deals p:nth-child(even) {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .deals-six-left-sidebar {
        display: none;
    }
    .deals-center-sidebar {
        width: 100%;
    }
    .deals-price-text {
        font-size: 16px;
    }
    .deals-del-price, .deals-child-profile {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .deals-flex-center {
        flex-direction: column;
        align-items: center;
    }
    .deals-flex-center .deals-inner-center-sidebar {
        margin-right: 0;
        width: 50%;
    }
}
