//all media landing

@media (max-width: 1800px) {
	// 	.head-right-nav li a {
	// 	font-size: 16px;
	// 	padding: 14px 28px;
	// }
		.main-login {
			font-size: 18px;
			width: 115px;
		}	
}

// @media (max-width: 1510px) {
// 	.nav-landing .main-logo img {
// 		width: 360px;
// 	}
// }

@media (max-width: 1400px) {
	.main-content-landing {
		padding-top: 38px;
		width: 100%;
		padding-bottom: 150px;
		background: url("/images/landing/bcg_1.png") no-repeat center 15px;
		background-size: 100% 100%;
	}
}

// adaptive nav
@media (max-width: 1190px) {

.pressedMenu {
	display: block;
}

.unpressedMenu {
	display: none !important;
}
	.mobile-nav {
		display: block;
		position: absolute;
	    right: 155px;
	    top: 3px;
	    cursor: pointer;
	}	
	.head-nav-landing {
		background: transparent;
		position: relative;
	    top: 18px;
    	left: 65px;
	    display: block;
	    padding-top: 20px;
	    z-index: 1000;
	}

	.parent-nav-media {
		background: transparent;
		position: relative;
	    top: 18px;
    	left: -80px;
	    display: block;
	    padding-top: 20px;
	    z-index: 1000;
	}

	.head-nav-landing li {
		height: auto;
		display: block;
		background: #009fe3;
		text-align: center;
		a {
			display: block;
			width: 115px;
    		padding: 15px 10px !important;
    		margin-top: 0 !important;
    		border: none;
    		margin-bottom: 0 !important;
		}
	}
		.head-nav-landing li:last-child {
    		margin-right: -2px;
		}

		// .mobile-nav:hover + .head-nav-landing, .head-nav-landing:hover {
		// 	display: block;
		// }	
}
// end adaptive nav

// @media (max-width: 1800px) {

// .box-checkbox {

//     label {
//     	font-size: 30px;
//     }
// }

// .pin-text {
// 	font-size: 40px;
// }

// .input-box-pin {

// 	p {
// 		font-size: 20px;
// 	}

// 	.get-started {
// 		font-size: 26px;
// 		padding-top: 15px;
// 	}

// 	input {
// 		font-size: 26px;
// 	}
// }

// .main-submit {
// 	font-size: 12px;
// }

// // planshet-landing

// .inner-left-title-planshet {
// 	p {
// 		font-size: 22px;
// 	}
// }

// //three-section

// .three-section-h1 {
// 	font-size: 52px;
// }

// .three-section-h2 {
// 	font-size: 32px;
// }

// .box-img-three {

// 	ul {
// 		font-size: 22px;
// 	}
// }

// .inner-box-img-three {

// 	p {
// 		font-size: 26px;
// 	}
// }

// .btn-more-topic {
// 	font-size: 26px;
// }

// .btn-more-topic:active {
// 	font-size: 26px;
// }

// //four section

// .inner-column {
// 	a {
// 		font-size: 26px;
// 	}
// 	a:hover {
// 		font-size: 26px;
// 	}
// }

// //five section 

// .five-section-first-p {
// 	font-size: 20px;
// }

// .five-section-two-p {
// 	font-size: 20px;
// }

// .five-section-three-p {
// 	font-size: 16px;
// }

// //six section

// .six-left-sidebar {
// 	p {
//     	font-size: 46px;
// 	}
// }

// .center-sidebar {

// 	h1 {
// 		font-size: 52px;
// 	}
// 	h2 {
// 		font-size: 32px;
// 	}
// 	.parent-box {
		
// 		.child-profile {
// 			font-size: 24px;
// 		}
// 		.price-text {
// 			font-size: 24px;
// 		}
// 		.del-price-land {
// 			font-size: 20px;
// 		}
// 	}
// }

// .head-btn {
// 	font-size: 20px;
// }

// .footer-btn {
// 	font-size: 20px;
// }

// // seven section

// .seven-section-h1 {
// 	font-size: 52px;
// }

// .seven-section-p {
// 	font-size: 32px;

// 	a {
// 		font-size: 22px;
// 	}
// }

// // eight section 

// .eight-section-h1 {
// 	font-size: 52px;
// }

// .eight-section-p {
// 	font-size: 36px;
// }

// .eight-section-two-p {
// 	font-size: 24px;
// }

// .link-blog {
// 	font-size: 24px;
// }

// //nine section 

// .about-h1 {
// 	font-size: 52px;
// }

// .about-p {
// 	font-size: 24px;
// }

// // ten section 

// .first-inner-box-p {
// 	font-size: 24px;
// }

// .two-inner-box-p {
// 	font-size: 18px;
// }

// .about-nav {
// 	a {
// 		font-size: 18px;
// 	}
// }

// .link-footer {
// 	font-size: 18px;

// }}

// @media (max-width: 1366px) {

// .container-for-second-slider .five-section-first-p {
// 	padding: 10px 0 0 0;
// }

// .head-right-nav li a {
// 	font-size: 16px;
// 	padding: 14px 28px;
// }

// .pos-relative {
// 	position: relative;
// } 

// .box-checkbox {
// 	display: flex;
//     flex-direction: column;

//     label {
//     	font-size: 20px;
//     	color: #1a397f;
//     	display: flex;
//     	align-items: flex-end;
//     	margin-right: 35px;
//     	margin-bottom: 15px;
//     }
//     input[type=checkbox] {
//     	display: none;
//     }
// }

// .checkbox-style {
// 	display: inline-block;
// 	width: 23px;
// 	height: 24px;
// 	background: url("/images/landing/check.png") no-repeat left bottom;
// 	margin-right: 15px;
// 	background-size: 100% 100%;
// }

// input[type=checkbox]:checked + .checkbox-style {
// 	background: url("/images/landing/check-active.png") no-repeat left bottom;
// 	margin-right: 15px;
// 	width: 31px;
// 	height: 28px;
// 	background-size: 100% 100%;
// }


// .outer-box-checkbox {
// 	display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 50px;
// }

// .txt-align {
// 	text-align: center;
// }

// .nav-landing {
// 	display: flex;
// 	justify-content: space-between;
// .main-logo {
// 		margin: 0;
// 		padding: 0;
// 		padding-top: 5px;
// 		padding-left: 1%;
// 		float: left;

// 		img {
// 			width: 250px;
// 			max-width: 100%;
// 		}
// 	}
// }

// .main-nav-box {
// 	display: flex;
// 	position: relative;
// }

// .main-login {
// 	font-size: 18px;
// 	color: white;
// 	background: #ff8227;
// 	text-decoration: none;
// 	width: 129px;
// 	height: 32px;
// 	-webkit-border-radius: 20px;
// 	-moz-border-radius: 20px;
// 	border-radius: 20px;
// 	background-color: #ff8227;
// 	text-align: center;
//     padding-top: 5px;
//     margin-left: 10px;
// }

// .main-login:hover {
// 	background: #009fe3;
// }

// .main-img-search {
// 	width: 32px;
// 	height: 31px;
// 	margin-right: 0px;
// 	margin-left: 0px;
// 	background: url("/images/landing/scale.png") no-repeat center center;
// }

// .main-img-search:hover {
// 	background: url("/images/landing/scale_hover.png") no-repeat center center;
// }

// .block-search {
// 	display: flex;
// 	align-items: center;
// }

// .main-content-landing {
// 	width: 100%;
// 	padding-bottom: 150px;
// 	background: url("/images/landing/bcg_1.png") no-repeat center 15px;
// 	background-size: 100% 100%;
// }

// .inner-main-content-landing {
// 	display: flex;
// 	justify-content: center;
// }

// .pin-container {
// 	margin-left: 70px;
// 	text-align: center;

// 	img {
// 		width: 40%;
// 	}
// }    

// .pin-text {
// 	display: flex;
//     flex-direction: column;
// 	font-family: Oduda;
// 	font-size: 28px;
// 	font-weight: 900;
// 	color: #ff8227;

// 	p {
// 		margin: 0;
// 		align-self: center;
// 	}
// }

// .inner-text-pin {
// 	font-family: "Open Sans";
// 	font-size: 12px;
// 	font-weight: 900;
// 	color: #1a397f;
// 	// padding-left: 8%;
// 	padding-top: 25px;
//    	width: 50%;
// }

// .input-box-pin {
// 	display: flex;
// 	justify-content: center;

// 	p {
// 		font-family: "Open Sans";
// 		font-size: 15px;
// 		font-weight: 900;
// 		color: #1a397f;
// 		margin-top: 45px;
//     	margin-bottom: 10px;
//     	background: #fff;
//     	padding: 5px;
//     	border-radius: 5px;
// 	}

// 	.get-started {
// 		min-width: 280px;
// 		width: 280px;
// 		font-family: Oduda;
// 		font-size: 18px;
// 		font-weight: 600;
// 		color: #ff8227;
// 		margin-left: 45px;
// 		border: none;
// 		height: 50px;
// 		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		border-radius: 20px;
// 		background: url("/images/landing/bgc_btn.png") no-repeat;
// 		outline: none;
// 		cursor: pointer;
// 		text-align: center;
//     	padding-top: 15px;
// 	}

// 	input {
// 		height: 50px;
// 		font-family: Oduda;
// 		font-size: 18px;
// 		font-weight: 900;
// 		color: #015f99;
// 		background-color: #86f4ff;
// 		border-radius: 20px;
// 		border: none;
// 		outline: none;
// 		padding-left: 30px;
// 		-webkit-box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		-moz-box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		box-shadow: 0 5px 3px rgba(4,140,222,.75);
// 		padding-right: 90px;
// 		max-width: 500px;
// 		text-align: center;
// 	}
// }

// .flex {
// 	display: flex;
// 	flex-wrap: wrap;
// }

// .flex-sub {
// 	flex-direction: column;
//     align-items: center;
// }

// .flex-outer {
// 	display: flex;
//     justify-content: center;
// }

// .main-submit {
// 	position: absolute;
//     top: 0;
//     right: 0;
// 	font-family: Oduda;
// 	width: 70px;
// 	font-size: 12px;
// 	font-weight: 600;
// 	color: white;
// 	border: none;
// 	height: 50px;
// 	border-radius: 0 20px 20px 0;
// 	background: url("/images/landing/widget_btn.png") no-repeat;
// 	background-size: 100% 100%;
// 	outline: none;
// 	cursor: pointer;
// }

// // planshet-landing

// .planshet-landing {
// 	background: #0f86e8;
// 	padding-bottom: 40px;
// }

// .inner-img-planshet {
// 	text-align: center;
// 	display: flex;
// 	justify-content: center;
// 	padding-top: 15px;
// 	img {
// 		width: 50%;
// 		height: auto;
// 	}
// }

// .title-planshet-landing {
// 	display: flex;
// 	background: #0873d7;
// 	margin-top: 15px;
// 	padding: 0 0 15px 0;
// }

// .inner-title-section-planshet {
// 	display: flex;
// 	justify-content: center;
// 	padding-top: 30px;
// }

// .left-title-planshet {
// 	width: 100%;
// }

// .inner-left-title-planshet {
// 	display: flex;
// 	width: 40%;
// 	justify-content: space-between;
// 	img {
// 		max-width: 100%;
// 	}
// 	p {
// 		margin: 0;
// 		font-size: 14px;
// 		color: white;
// 		width: 70%;
// 		padding-top: 10px;
// 	}
// }

// .inner-width-planshet {
// 	width: 25%;
//     // display: flex;  // IE
//     // flex-direction: column;
//     // align-items: flex-end;
// }

// .inner-title-section-planshet {
// 	.inner-left-title-planshet:first-child {
// 		padding-right: 2%;
// 	}
// 	.inner-left-title-planshet:last-child {
// 		padding-left: 2%;
// 	}
// }

// .contact-us {
// 	color: #a4e8ff;
// }

// //three-section

// .three-section {
// 	background: #6bdcff;
// }

// .three-section-h1 {
// 	font-size: 36px;
// 	color: #014ca9;
// 	font-weight: 600;
// 	text-align: center;
// 	padding-top: 75px;
// 	padding-bottom: 45px;
// 	margin: 0;
// }

// .three-section-h2 {
// 	font-size: 24px;
// 	color: #014ca9;
// 	font-weight: 400;
// 	text-align: center;
// 	margin: 0;
// 	padding-bottom: 70px;
// }

// .box-img-three {
// 	padding: 20px 60px;
// 	background: url("/images/landing/bcg-item.png") no-repeat center center;
// 	background-size: 100% 100%;
// 	width: 36%;
// 	min-width: 300px;
// 	// max-width: 300px;
// 	padding-bottom: 5px;

// 	ul {
// 		font-size: 14px;
// 		color: #1a397f;
// 		font-weight: 400;
// 		list-style-type: none;
// 		list-style-image: url("/images/landing/style-type.png");
// 	}
// }

// .inner-box-img-three {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	height: 88px;

// 	img {
// 		width: 5.57vw;
//     	max-width: 100%;
//     	min-width: 85px;
//     	border-radius: 25%;
// 		-webkit-border-radius: 21px/19px;
// 		-moz-border-radius: 21px/19px;
// 		border-radius: 21px/19px;
// 		-webkit-box-shadow: 0 0 16px rgba(0,0,0,.75);
// 		-moz-box-shadow: 0 0 16px rgba(0,0,0,.75);
// 		box-shadow: 0 0 16px rgba(0,0,0,.75);
// 	}

// 	p {
// 		font-size: 19px;
// 		color: #014687;
// 		font-weight: 800;
// 		margin: 0;
// 		padding-left: 15px;
// 	}
// }

// .container-three {
// 	display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//     margin-bottom: 30px;

//     .box-img-three:first-child {
// 		margin-right: 3%;	
//     }
//     .box-img-three:last-child {
// 		margin-left: 3%;	
//     }
// }

// .btn-more-topic {
// 	width: 20%;
// 	background: url("/images/landing/btn.png") no-repeat center center;
// 	background-size: 100% 100%;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	font-size: 17px;
// 	color: #014687;
// 	font-weight: 600;
// 	padding: 25px 0px;
// 	margin-top: 30px;
// 	margin-bottom: 130px;
// 	display: inline-block;
// }

// .btn-more-topic:active {
// 	background: url("/images/landing/btn-pressed.png") no-repeat center center;
// 	width: 20%;
// 	background-size: 100% 100%;
// 	border: none;
// 	outline: none;
// 	cursor: pointer;
// 	font-size: 17px;
// 	color: #014687;
// 	font-weight: 600;
// 	padding: 25px 0px;
// 	margin-top: 30px;
// 	margin-bottom: 130px;
// 	display: inline-block;
// }

// //four section

// .four-section {
// 	background: #22caff;
// }

// .column-four {
// 	display: flex;
//     justify-content: center;

//     .inner-column:first-child {
// 		padding-right: 2%;
//     }
//     .inner-column:last-child {
// 		padding-left: 2%;
//     }
// }    

// .inner-column {
// 	width: 30%;
	
// 	p {
// 		text-align: center;
// 	}

// 	a {
// 		display: inline-block;
// 		background: #4682e5 url("/images/landing/arrow.png") no-repeat center right 20px;
// 		color: white;
// 		text-decoration: none;
// 		padding: 10px 0;
// 		width: 100%;
// 		font-size: 18px;
// 		font-weight: 900;
// 		background-size: 5%;
// 	}
// 	a:hover {
// 		display: inline-block;
// 		background: #ff9d00 url("/images/landing/arrow.png") no-repeat center right 20px;
// 		color: white;
// 		text-decoration: none;
// 		padding: 10px 0;
// 		width: 100%;
// 		font-size: 18px;
// 		font-weight: 900;
// 		background-size: 5%;
// 	}
// }

// .btn-four {
// 	margin-bottom: 70px;
// }

// .btn-four:active {
// 	margin-bottom: 70px;
// }

// //five section 

// .five-section {
// 	padding-bottom: 30px;
// 	background: #ffffff;
// }

// .outer-five-section {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// }

// .inner-five-section {
// 	width: 57%;
// 	display: flex;
// 	align-self: center;
// }

// .bold {
// 	font-weight: 800 !important;
// }

// .five-section-first-p {
// 	font-size: 16px;
// 	color: #1a397f;
// 	font-weight: 600;
// 	margin: 0;
// }

// .five-section-two-p {
// 	font-size: 16px;
// 	color: #1a397f;
// 	font-weight: 800;
// 	margin: 45px 0 10px 0;
// }

// .five-section-three-p {
// 	font-size: 14px;
// 	color: #1a397f;
// 	font-weight: 600;
// 	margin: 0;
// }

// .pagination {
// 	margin: 45px 0;
// 	display: flex;
//     justify-content: center;
// 	.active-pagin {
// 		display: inline-block;
// 		width: 100px;
// 		height: 13px;
// 		background: #ff7800;
// 	}
// 	span {
// 		display: inline-block;
// 		width: 100px;
// 		height: 13px;
// 		background: #568da4;
// 		cursor: pointer;
// 		margin-right: 35px;
// 	}
// 	span:last-child {
// 		margin-right: 0;
// 	}
// }

// .five-section-img {
// 	max-width: 221px;
// 	max-height: 208px;
// 	min-width: 115px;
// 	width: 50%;
// 	margin-right: 25px;
// 	-webkit-border-radius: 16px;
// 	-moz-border-radius: 16px;
// 	border-radius: 16px;
// 	background-color: #fff;
// 	-webkit-box-shadow: 1px 1px 4px rgba(0,0,0,.75);
// 	-moz-box-shadow: 1px 1px 4px rgba(0,0,0,.75);
// 	box-shadow: 1px 1px 4px rgba(0,0,0,.75);
// 	border: solid 1px #c8edfb;
// }

// .five-section-h1 {
// 	padding-top: 0;
// 	padding-bottom: 80px;
// }

// .two-slider-outer {
// 	display: flex;
//     justify-content: center;
// }

// .inner-two-slider {
// 	display: flex;
// 	width: 57%;
// 	justify-content: space-between;
// }

// .item-slider {
// 	width: 20%;
// }

// //six section

// .six-section {
// 	background-color: #b3f4fe;
// 	background-image: -webkit-linear-gradient(top, #4fc7fe, #d0f8fe);
// 	background-image: -moz-linear-gradient(top, #4fc7fe, #d0f8fe);
// 	background-image: -o-linear-gradient(top, #4fc7fe, #d0f8fe);
// 	background-image: -ms-linear-gradient(top, #4fc7fe, #d0f8fe);
// 	background-image: linear-gradient(to bottom, #4fc7fe, #d0f8fe);
// }

// .inner-six-section {
// 	display: flex;
// }

// .six-left-sidebar {
// 	width: 18%;
// 	position: relative;
// 	text-align: center;
// 	img {
// 		max-width: 100%;
//     	padding-top: 170px;
//     	padding-left: 10px;
//     	padding-right: 10px;
// 	}
// 	p {
// 		position: absolute;
//     	font-size: 30px;
//     	font-family: Oduda;
//     	top: 32%;
//     	margin: 0;
//     	left: 0;
//     	color: #005c83;
//     	font-weight: 800;
//     	text-align: center;
// 	}
// 	.pos-rel {
// 		position: relative;
// 	}
// 	// background: url("/images/landing/ball.png") no-repeat center 170px;
// 	// background-size: 85%;
// }

// .center-sidebar {
// 	width: 64%;
// 	background: #b3f4fe;

// 	h1 {
// 		font-size: 36px;
// 		font-weight: 600;
// 		color: #005c83;
// 		text-align: center;
// 		margin-top: 80px;
// 		margin-bottom: 30px;

// 	}
// 	h2 {
// 		font-size: 24px;
// 		font-weight: 400;
// 		color: #005c83;
// 		text-align: center;
// 		margin-top: 0;
// 		margin-bottom: 90px;
// 	}
// 	.parent-box {
// 		padding: 45px 20px;
		
// 		.child-profile {
// 			text-align: left;
// 			font-size: 15px;
// 		}
// 		.price-text {
// 			font-size: 20px;
// 			margin-bottom: 0;
//     		margin-top: 50px;
// 		}
// 		.del-price-land {
// 			font-size: 15px;
// 		}
// 	}
// }
// .del-price-land:before {height: 35%;}

// .six-right-sidebar {width: 18%;}

// .inner-center-sidebar {
// 	width: 21%;
// 	img {
// 		max-width: 100%;
// 	}
// 	.parent-box {
// 		margin-bottom: 80px;
// 		margin-top: 30px;
// 	}
// }

// .child-profile-price {
// 	text-align: center !important;
// }

// .head-btn {
// 	display: inline-block;
// 	font-size: 24px;
// 	color: #ffffff;
// 	background: #009fe3;
// 	text-decoration: none;
// 	padding: 10px 0;
// 	width: 100%;
// 	text-align: center;
// 	margin-bottom: 45px;
// 	cursor: pointer;
// 	font-weight: 600;
// }

// .footer-btn {
// 	display: inline-block;
// 	font-size: 24px;
// 	font-weight: 600;
// 	color: #ffffff;
// 	background: #009fe3;
// 	text-decoration: none;
// 	padding: 10px 0;
// 	width: 100%;
// 	text-align: center;
// 	margin-bottom: 95px;
// 	cursor: pointer;
// }

// .head-btn:hover, .footer-btn:hover {
// 	background: #ff8227;	
// }

// .flex-center {
// 	display: flex;
//     justify-content: center;

//     .inner-center-sidebar {
// 		margin-right: 5%;
//     }
//     .inner-center-sidebar:last-child {
// 		margin-right: 0;
//     }
// }

// // seven section

// .seven-section {
// 	padding-bottom: 50px;
// 	background: white;
// }

// .seven-section-h1 {
// 	font-size: 36px;
// 	font-weight: 600;
// 	text-align: center;
// 	margin: 0;
// 	padding: 75px 0 30px 0;
// 	color: #00539a;
// }

// .seven-section-p {
// 	font-size: 24px;
// 	font-weight: 600;
// 	color: #0063b7;
// 	margin: 0;
// 	padding-bottom: 30px;
// 	padding-left: 16%;
//     text-align: left;

// 	a {
// 		font-size: 18px;
// 		color: #009fe3;
// 		padding-left: 35px;
// 	}
// }

// .slider-box {
// 	display: flex;
//     justify-content: center;
// }

// .inner-slider-box {
// 	// display: flex;
//     // justify-content: center;
//     width: 80%;
//     position: relative;
// }


// .box-sliders {
// 	display: flex;
//     justify-content: center;
// 	// .slider-item {
// 	// 	padding-right: 2%;
// 	// }
// 	.slider-item:last-child {
// 	padding-right: 0;
//   }
// }

// .left-slide-nav {
// 	position: absolute;
// 	top: calc(50% - 18.5px);
// 	left: -47px;
// 	width: 21px;
// 	height: 37px;
// 	background: url("/images/landing/left-arrow.png") no-repeat;
// 	background-size: 100% 100%;
// 	cursor: pointer;
// }

// .left-slide-nav:hover {
// 	background: url("/images/landing/left-arrow-hover.png") no-repeat;
// 	background-size: 100% 100%;
// 	top: calc(50% - 18.5px);
// 	left: -47px;
// 	width: 21px;
// 	height: 37px;
// }

// .right-slide-nav {
// 	position: absolute;
// 	top: calc(50% - 18.5px);
// 	right: -47px;
// 	width: 21px;
// 	height: 37px;
// 	background: url("/images/landing/right-arrow.png") no-repeat;
// 	background-size: 100% 100%;
// 	cursor: pointer;
// }

// .right-slide-nav:hover {
// 	background: url("/images/landing/right-arrow-hover.png") no-repeat;
// 	background-size: 100% 100%;
// 	top: calc(50% - 18.5px);
// 	right: -47px;
// 	width: 21px;
// 	height: 37px;
// }

// .two-slider {
// 	margin-top: 95px;
// }

// // eight section 

// .eight-section {
// 	padding-bottom: 30px;
// 	background: #e6fcff;
// }

// .eight-section-h1 {
// 	font-size: 36px;
// 	font-weight: 600;
// 	color: #00539a;
// 	margin: 0;
// 	padding-top: 85px;
// 	text-align: center;
// }

// .eight-section-p {
// 	font-size: 24px;
// 	font-weight: 600;
// 	color: #0048b5;
// 	margin: 0;
// 	padding-top: 65px;
// }

// .eight-section-two-p {
// 	font-size: 15px;
// 	font-weight: 400;
// 	color: #4f5050;
// 	margin: 0;
// 	padding: 0 0 20px 0;
// }

// .link-blog {
// 	font-size: 15px;
// 	color: #0f86e8;
// 	display: inline-block;
// }

// .blog-box {
// 	width: 75%;
//     margin: 0 auto;
// }

// .img-box {
// 	align-self: flex-start;
// 	width: 40%;
// }

// .title-blog {
// 	display: flex;
//     flex-direction: column;
//     align-items: center;
//     width: 60%;
//     margin-left: 5%;
// }

// .outer-titel {
// 	display: flex;
// }

// .date-p {
// 	padding-top: 15px;
// }

// //nine section 

// .nine-section {
// 	padding-bottom: 150px;
// 	background: #0f86e8;
// }

// .about-h1 {
// 	font-size: 36px;
// 	color: #ffed00;
// 	margin: 0;
// 	padding: 80px 0 10px 0;
// 	text-align: center;
// }

// .about-box {
// 	display: flex;
// 	align-items: center;
// 	width: 75%;
// 	margin: 0 auto;
// }

// .about-p {
// 	font-size: 16px;
// 	color: #ffffff;
// 	margin: 0;
// 	padding-left: 40px;
// }

// // ten section 

// .ten-section {
// 	background: #4663e5;
// 	padding-top: 35px;
// 	padding-bottom: 35px;
// }

// .first-inner-box-ten {
// 	width: 26%;
// }

// .first-inner-box-p {
// 	font-size: 20px;
// 	color: #ffed00;
// 	margin: 0;
// 	padding-bottom: 25px;
// }

// .two-inner-box-p {
// 	font-size: 14px;
// 	color: white;
// 	margin: 0;
// 	line-height: 2;
// }

// .pad-ten-box {
// 	padding-bottom: 35px;
// }

// .about-nav {
// list-style-type: none;
// padding: 0;
// 	a {
// 		font-size: 14px;
// 		color: white;
// 		text-decoration: none;
// 		display: inline-block;
// 		margin-bottom: 25px;
// 	}
// 	a:hover {
// 		color: #ffed00;
// 	}
// }

// .inner-ten-box {
// 	width: 80%;
//     display: flex;
//     justify-content: space-between;
//     margin: 0 auto;
// }

//  .twit-icon {
//  	display: inline-block;
// 	width: 28px;
// 	height: 23px;
// 	background: url("/images/landing/twitter.png") no-repeat center center;
// 	float: left;
//     margin-top: 8px;
// }

// .margin-icon {
// 	margin-left: 58px;
// }

// //footer 

// .footer {
// 	height: auto;
// 	background: #ffed00 url("/images/landing/footer.png") no-repeat center 0;
// 	background-size: 115% 115%;
// 	padding: 5px 100px 5px 15px;
// }

// .inner-footer {
// 	display: flex;
// 	align-items: center;
// 	height: 100%;
// 	justify-content: space-between;
// 	flex-wrap: wrap;
// }

// .soc-icon-footer {
// 	display: inline-block;
// 	width: 32px;
// 	height: 32px;
// 	background-size: 100% 100%;
// }

// .gplus-brand, .twitter-brand, .linkedin-brand, .pinterest-brand {
// 	margin-right: 5px;
// }

// .footer-div {
// 	display: flex;
//     align-items: center;

//     img {
//     	width: 50%;
//     	margin-right: 15px;
//     }
// }

// .link-footer {
// 	font-size: 14px;
// 	color: #4166e5;
// 	text-decoration: none;
// }

// .right-link-footer {
// 	margin-right: 115px;
// }

// .slick-slide img {
// 	margin: 0 auto;
// }

// .about-img {
// 	width: 163px;
// }

// }

@media (max-width: 1090px) {
	.inner-ten-box {
		flex-wrap: wrap;

		.first-inner-box-ten {
			width: 50%;
		}
		.first-inner-box-p {
			margin-left: 0;
		}
	}
}

@media (max-width: 1110px) {
	.six-left-sidebar p {
		font-size: 22px;
		top: 38%;
	}
	.right-slide-nav {
		right: -60px;
	}
	.left-slide-nav {
		left: -60px;
	}
	.right-slide-nav:hover {
		right: -60px;
	}
	.left-slide-nav:hover {
		left: -60px;
	}
}

@media (max-width: 695px) {
	.title-new-manage {
		flex-direction: column;
		.box-line-manage a {
			display: inline-block;
		}
		.box-line-manage {
		    border-left: 0;
		    padding: 20px 20px 20px 35px;
		    text-align: center;
		}
	}
	._main._pay-access-main {
	    min-height: 72vh;
	}
	.two-slider {
		margin-top: 0;
	}
	.disp-mobile {
		display: block;
	}

	.disp-desck {
		display: none;
	}
	
	.test2 {
		width: 100%;
	}

	.input-box-pin p {
		margin-top: 10px;
	}


	.nav-landing .main-logo img {
		position: absolute;
		z-index: 20000;
	}
	.block-search {
		display: none;
	}
	.head-nav-landing {
		position: absolute;
	    top: 18px;
	    left: 0;
	    right: 0;
	    padding-top: 20px;
	    z-index: 10000;

	    li a {
	    	width: 100%;
	    }
	}
	#mobile-login {
		display: block;
	}
	.mobile-nav {
		position: static;
	    margin: 3px 10px 3px 0;
	}
	.mobile-nav:hover {
		position: static;
	    margin: 3px 10px 3px 0;
	}
	.main-nav-box {
		position: static;
	}

	.margin-div {margin-left: 0;}
	.box-checkbox label {
		font-size: 16px;
		margin-right: 45px;
	}
	.pin-text p {
		font-size: 22px;
	}	
	.pin-container {
		margin-left: 0;
	}
	.right-slide-nav {
		right: 0px;
	}
	.left-slide-nav {
		left: 0px;
	}
	.right-slide-nav:hover {
		right: 0px;
	}
	.left-slide-nav:hover {
		left: 0px;
	}
	.pin-text {
		text-align: center;
	}
	.outer-box-checkbox {
		flex-wrap: wrap;
	}
	.box-checkbox:last-child label {
		margin-right: 85px;
	}
	.flex-outer {
		flex-direction: column;
	}
	.input-box-pin .get-started {
		margin-left: 0;
	}
	.input-box-pin input {
		max-width: 280px;
	}
	.main-content-landing {
		padding-bottom: 150px;
		margin-bottom: -100px;
	}
	.container-three {
		flex-direction: column;
    	align-items: center;
	}
    .container-three .box-img-three:last-child {
    	margin-left: 0;
    }
    .container-three .box-img-three:first-child {
    	margin-right: 0;
    	margin-bottom: 30px;
    }
    .btn-more-topic {
    	width: 40%;
    	margin-bottom: 65px;
    }
    .btn-more-topic:hover {
    	width: 40%;
    	margin-bottom: 65px;
    }
    .center-sidebar h2{
    	margin-bottom: 45px;
    }
    .flex-center {
    	flex-direction: column;
    }
    .flex-center .inner-center-sidebar {
    	align-self: center;
    	margin-right: 0;
    	width: 50%;

    	.parent-box {
    		margin-bottom: 10px;
    		margin-top: 10px;
    		padding: 10px 10px;

    		.child-profile {
				text-align: center;
    		}
    	}
    	.head-btn {
    		margin-bottom: 10px;
    		font-size: 16px;
    	}
    	.footer-btn {
    		font-size: 16px;
    		margin-bottom: 50px;
    	}
    }
    .inner-center-sidebar img {
    	max-width: 50%;
    }
    .three-section-h1 {
    	padding-top: 25px;
    	padding-bottom: 10px;
    	font-size: 18px;
    }
    .three-section-h2 {
    	padding-bottom: 10px;
    	font-size: 14px;
    }
    .container-three, .box-img-three {
		display: none;
    }
    .container-three:first-child {
		display: flex;
    }
   .container-three .box-img-three:first-child {
		display: block;
		margin-bottom: 10px;
   }
   .inner-box-img-three p {
   	font-size: 16px;
   }
   .btn-more-topic {
   	width: auto;
   	margin-bottom: 20px;
   	font-size: 14px;
   	padding: 15px 10px;
   }
   .btn-more-topic:hover {
   	width: auto;
   	margin-bottom: 20px;
   	font-size: 14px;
   	padding: 15px 10px;
   }
	.inner-column {
		width: 45%;
	}
	.inner-column p:nth-child(n+4) {
		display: none;
	}
	.slick-dots li {
		width: 25% !important;
		margin-right: 5px !important;
	}
	.contenier-for-slide {
		flex-direction: column;
		
		p {
			padding: 10px 0 0 0;
		}
	}
	.container-for-second-slider {
		flex-direction: column;
		
		p {
			padding-top: 10px;
		}
		.five-section-first-p {
			padding: 10px 0 0 0;
		}
		.item-slider {
			width: 100%;
			padding-top: 10px;
		}
	}
	.five-section-h1 {
		display: none;
	}
	// .two-slider-outer {
	// 	display: none;
	// }
	.yellow-btn {
		display: block;

		a {
			margin: 10px 10px;
			padding: 10px 15px;
			display: inline-block;
		}
		a:hover {
			margin: 10px 10px;
			padding: 10px 15px;
		}
	}
	.center-sidebar h1 {
		font-size: 18px;
		margin: 10px 0;
		padding-top: 15px;
	}
	.center-sidebar h2 {
		font-size: 14px;
		margin-bottom: 15px;
	}
	// .two-slider {
	// 	display: none;
	// }
	.about-box {
		flex-direction: column;

		.about-p {
			padding: 20px 0 0 0;
			font-size: 14px;
			text-align: center;
		}
	}
	.inner-ten-box .first-inner-box-ten {
		width: 100%;
    	padding-bottom: 15px;
	}
	.five-section-first-p {
		font-size: 14px;
	}
	.five-section-two-p {
		margin-top: 15px;
		font-size: 14px;
	}
	.five-section-three-p {
		font-size: 14px;
	}
	.five-section-img {
		width: 30%;
	}
	.seven-section-h1 {
		font-size: 18px;
		padding: 25px 0 10px 0;
	}
	.seven-section-p {
		font-size: 16px;
		padding-bottom: 15px;
		padding-left: 0;
		text-align: center;

		a {
			font-size: 16px;
			padding-left: 2%;
		}
	}
	// .eight-section {
	// 		display: flex;
 	//		flex-direction: column;
	// }
	.eight-section-h1 {
		font-size: 18px;
		padding-top: 25px;
	}
	.eight-section-p {
		padding-top: 10px;
		font-size: 16px;
	}

	.blog-box {
		text-align: center;
		display: none;
	}
	.blog-box:nth-child(2){
		display: block;
		padding-bottom: 10px;
		height: auto;
	}

	// .outer-titel {
	// 	flex-direction: column;
	// }
	.outer-titel {
		display: block;
		.img-box {
			margin: 0 auto;
		}
	}

	.title-blog {
		margin: 20px 0 0 0;
		width: 100%;
		display: block;
	}
	.about-img {
		width: 123px;
	}
	.img-box {
		align-self: center;
		min-width: 200px;
		display: block;
	}
	.about-h1 {
		font-size: 20px;
		padding: 25px 0 10px 0;
	}
	.about-nav {
		margin: 0 auto;

		a {
			margin-bottom: 10px;
		}
	}
	.inner-ten-box .first-inner-box-p {
		padding-bottom: 10px;
	}
	.pad-ten-box {
		padding-bottom: 15px;
	}
	.nine-section {
		padding-bottom: 20px;
	}
	.ten-section {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.footer {
		padding: 10px 10px 10px 10px;

		.right-link-footer {
			margin-right: 65px;
		}
	}
	.footer-div {
		padding: 5px 0;
		justify-content: center;
	}
	.inner-footer {
		justify-content: center;
	}
	.link-footer {
		margin-right: 25px;
	}
}

//////end 695

@media (max-width: 940px) {
	.right-link-footer {
		margin-right: 20px;
	}
	.center-sidebar {
		width: 100%;
	}
	.six-right-sidebar {
		display: none;
	}
	.six-left-sidebar {
		display: none;
	}

}

// @media (max-width: 1300px) {
// 	.head-right-nav li a {
// 		font-size: 16px;
//     	padding: 14px 20px;
// 	}
// }

// @media (max-width: 1190px) {
// 	.head-right-nav li a {
// 		font-size: 16px;
//     	padding: 3px 10px !important;
// 	}
// }

@media (max-width: 360px) {
	.box-checkbox label {
		margin-right: 0;
	}
	.box-checkbox:last-child {
		margin-right: 50px;
    	padding-left: 10px;
	}
	.box-checkbox:last-child label {
		margin-right: 0;
	}
	.mobile-nav {
		right: 215px;
	}
	.mobile-nav:hover {
		right: 215px;
	}
	.main-login {
		width: 83px;
	}
	.inner-title-section-planshet {
		flex-direction: column;
    	align-items: center;

    	.inner-left-title-planshet {
    		width: 90%;
    	}
	}
	.inner-title-section-planshet .inner-left-title-planshet:first-child {
		padding-bottom: 20px;
	}
}

// @media (min-width: 1200px) and (max-width: 1367px)

@media (min-width: 1280px) and (min-height: 600px) and (max-height: 900px) {
	.main-content-landing {
		background-size: 100% 110%;
		height: 100vh;
		max-height: 1100px;
		.pin-container img {
			width: 40%;
		}
	}
	.three-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.inner-box-img-three {
			height: 70px;
		}
		.three-section-h1 {
			font-size: 36px;
			padding-top: 5px;
    		padding-bottom: 5px;
		}
		.three-section-h2 {
			font-size: 24px;
			padding-bottom: 15px;
		}
		.box-img-three ul {
			margin: 0;
		}
		.box-img-three {
			padding: 10px 60px;
		}
		.btn-more-topic {
			padding: 15px 0px;
    		margin-top: 10px;
    		margin-bottom: 0px;
		}
		.container-three {
			margin-bottom: 10px;
		}
	}
	.box-img-three ul li {
		height: 37px;
	}
//four section 
	.four-section {
		min-height: 100vh;
		// max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.three-section-h1 {
			font-size: 36px;
			padding-top: 25px;
    		padding-bottom: 25px;
		}
		.three-section-h2 {
			font-size: 24px;
			padding-bottom: 25px;
		}
		
	}
//five section 
	.five-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.three-section-h1 {
			padding-top: 10px;
			padding-bottom: 10px;
			font-size: 36px;
		}
		.slick-list {
			padding-bottom: 10px !important;
		}
	}
//six section 
	.six-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.inner-six-section {
			height: 100vh;
		}
		.center-sidebar h1 {
			font-size: 36px;
			margin-top: 15px;
    		margin-bottom: 15px;
		}
		.center-sidebar h2 {
			margin-bottom: 25px;
		}
		.head-btn {
			margin-bottom: 15px;
		}
		.inner-center-sidebar .parent-box {
			margin-bottom: 10px;
    		margin-top: 10px;
    		padding: 10px 20px;
		}
		.footer-btn {
			margin-bottom: 0;
		}
	}
	.six-left-sidebar img {
		padding-top: 30px;
	}
	.six-left-sidebar p {
		top: 15%;
	}
//seven section 
	.seven-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: space-around;
		.slick-list {
			padding-bottom: 0 !important;
		}
		.slick-slide img {
			width: 45%;
		}
	}
	.two-slider {
		margin-top: 20px;
	}
	.seven-section-h1 {
		padding: 10px 0 10px 0;
	}
	.seven-section-p {
		padding-bottom: 10px;
	}

//eight section 
	.eight-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
	}
	.eight-section-h1 {
		padding-top: 10px;
	}
	.eight-section-p {
		padding-top: 15px;
	}
	.img-box {
		width: 30%;
	}

//nine section
	.nine-section {
	    height: 50vh;
	    max-height: 550px;
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	padding-bottom: 0;

    	.about-h1 {
    		padding: 0;
    	}
	}

//ten section
	.ten-section {
		height: auto;
		// display: flex;
  //   	flex-direction: column;
  //   	justify-content: center;
	}
}  

 @media (min-width: 1280px) and (min-height: 901px) {
	.main-content-landing {
		height: 100vh;
		max-height: 1200px;
		display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    .pin-container {
		    margin-top: -10vh;
		}
		.pin-container img {
			width: 70%;
		}
	}
	.inner-box-img-three {
			height: 88px;
		}
	.three-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.three-section-h1 {
			padding-top: 25px;
    		padding-bottom: 25px;
		}
		.three-section-h2 {
			padding-bottom: 20px;
		}

		.box-img-three {
			padding: 10px 60px;
		}
		.btn-more-topic {
			padding: 15px 0px;
    		margin-top: 0px;
    		margin-bottom: 0px;
		}
		.container-three {
			margin-bottom: 10px;
		}
	}
//four section 
	.four-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.three-section-h1 {
			font-size: 36px;
			padding-top: 25px;
    		padding-bottom: 25px;
		}
		.three-section-h2 {
			font-size: 24px;
			padding-bottom: 25px;
		}
	}
//five section and six
	.five-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.three-section-h1 {
			padding-top: 40px;
			padding-bottom: 30px;
		}
	}
	.six-section {height: 100vh;}
	.inner-six-section {
			height: 100vh;
		}
//six section 
	.six-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.inner-six-section {
			height: 100vh;
		}
		.center-sidebar h1 {
			font-size: 52px;
    		margin-top: 20px;
    		margin-bottom: 20px;
		}
		.center-sidebar h2 {
			margin-bottom: 35px;
		}
		.head-btn {
			margin-bottom: 15px;
		}
		.inner-center-sidebar .parent-box {
			margin-bottom: 25px;
    		margin-top: 25px;
    		padding: 45px 20px;
		}
		.footer-btn {
			margin-bottom: 0;
		}
	}
	.six-left-sidebar img {
		padding-top: 30px;
	}
	.six-left-sidebar p {
		top: 15%;
	}

//seven section 
	.seven-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		.slick-slide img {
			width: 80%;
		}
	}
	.seven-section-h1 {
		padding: 30px 0 30px 0;
	}
	.two-slider {
		margin-top: 15px;
	}
//eight section 
	.eight-section {
		height: 100vh;
		max-height: 1100px;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
	}
	.eight-section-h1 {
		padding-top: 25px;
	}
	.eight-section-p {
		padding-top: 40px;
	}
	.img-box {
		width: 35%;
	}
//nine section
	.nine-section {
	    height: 50vh;
	    max-height: 550px;
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	padding-bottom: 0;

    	.about-h1 {
    		padding: 0;
    	}
	}
//ten section
	.ten-section {
		height: auto;
		// display: flex;
  //   	flex-direction: column;
  //   	justify-content: center;

	}
}  

@media (max-width: 695px) and (max-height: 570px) {
	._main._pay-access-main {
	    min-height: 65vh;
	}
}
@media (max-width: 695px) and (max-height: 485px) {
	._main._pay-access-main {
	    min-height: 60vh;
	}
}
@media (max-width: 695px) and (min-width: 600px) and (min-height: 640px) {
	._main._pay-access-main {
	    min-height: 81vh;
	}
}