//blog

.blog {
	background: #ffffff;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,.75);
	-moz-box-shadow: 0 0 3px rgba(0,0,0,.75);
	box-shadow: 0 0 3px rgba(0,0,0,.75);
	margin: 0 auto;
	padding: 20px;
	flex: 1 0 auto;
}

.box-blog-title {
	text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    padding-bottom: 10px;
    margin-top: 50px !important;
}

.inner-blog-box {
	    width: 90%;
    	margin: 0 auto;
	p {
		margin: 0;
	}
}

.blog-img {
	width: 35%;
	height: 100%;
	float: left;
	margin-right: 10px;
}

.p-title-main-blog {
	font-size: 20px;
	color: #073881;
	font-weight: 600;
	padding-bottom: 20px;
}

.p-title-main-blog-data {
	font-size: 18px;
	color: #4f5050;
	font-weight: 600;
	padding-bottom: 20px;
}

.blog-num-p {
	font-size: 16px;
	color: #4f5050;
	font-weight: 400;
	padding-bottom: 20px;
}

@media (max-width: 500px) {
	.blog-img {
	    float: none;
	    min-width: 200px;
	    display: block;
	    margin: 10px auto;
	}
	.blog-num-p {
		font-size: 14px;
	}
}