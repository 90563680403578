
.register-for-socio {
    padding-bottom: 50px;
    max-width: 1300px;
    height: 800px;
    margin-top: 50px;
    top: -250px;
}

.min-soc-google-register, .min-soc-face-register {
	width: 12%;
	font-size: 16px;
    padding-top: 4px;
    height: 35px;
    margin-top: 0;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
    box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
    color: white;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    cursor: pointer;
}

.min-soc-google-register {
	background: url(/images/button_min.png) no-repeat;
    background-size: 100% 100%;
}

.min-soc-face-register {
	background: url(/images/button_min_two.png) no-repeat;
    background-size: 100% 100%;
}
    

.soc-seti-google-register, .soc-seti-face-register {
	width: 70%;
	font-size: 16px;
    padding-top: 4px;
    height: 35px;
    margin-top: 0px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
    box-shadow: 0 5px 2px 1px rgba(3, 114, 181, 0.86), inset 0 -6px 9px 1px rgba(255, 249, 153, 0.52);
    color: white;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 20px;
    cursor: pointer;
}

.soc-seti-google-register {
	background: url(/images/button_google.png) no-repeat;
    background-size: 100% 100%;
}

.min-soc-google-register:active, .min-soc-face-register:active,
.soc-seti-google-register:active, .soc-seti-face-register:active {
	 -webkit-box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86);
    box-shadow: 0 -5px 2px 1px rgba(3, 114, 181, 0.86);
}

.soc-seti-face-register {
	background: url(/images/button_face.png) no-repeat;
    background-size: 100% 100%;
}

@media (min-width: 1400px) {
	.soc-seti-google-register, .soc-seti-face-register {
		height: 70px;
		font-size: 20px;
    	padding-top: 0;
    	line-height: 70px;
	}
	.min-soc-google-register, .min-soc-face-register {
		height: 70px;
		font-size: 20px;
    	padding-top: 0;
    	line-height: 70px;
	}
}